import Immerable from "../store/immerable";
import { EditorInfo, ExportDestination } from "./dataset-files";
import { DatasetGradings } from "./roi-grading";

export class DatasetMetaFiles extends Immerable {
    public gradings?: DatasetGradings | null;
    public allAllowedRoiNames?: string[] | null;
    public editorInfo?: EditorInfo | null;
    public exportDestination?: ExportDestination | null;

    constructor(gradings: DatasetGradings | null, allAllowedRoiNames: string[] | null, editorInfo: EditorInfo | null, exportDestination: ExportDestination | null) {
        super();
        
        this.gradings = gradings;
        this.allAllowedRoiNames = allAllowedRoiNames;
        this.editorInfo = editorInfo;
        this.exportDestination = exportDestination;
    }
}
