import React from 'react';

import './ListSelect.css';

export default class ListSelect extends React.PureComponent<{
    options: { title: string, value: string }[], 
    onChange: (value: string, title: string) => void,
    maxSize?: number,
    value: string | undefined,
}> {

    handleChange = (evt: any) => {
        evt.stopPropagation();
        const value = evt.target.value;
        const option = this.props.options.find(o => o.value === value);
        const title = option !== undefined ? option.title : '';
        this.props.onChange(value, title);
    }

    render() {
        const { options, value } = this.props;
        const maxSize = this.props.maxSize || 10;
        return (
            <select size={Math.min(options.length, maxSize)} className="list-select" onChange={this.handleChange} value={value}>
                {options.map((opt: { title: string, value: string }, i: number) => (<option value={opt.value} key={i}>{opt.title}</option>))}
            </select>
        );
    }
}