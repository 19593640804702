/* 

A toolbar with some buttons that are supposed to have only one instance in the UI. Despite the name this can be placed anywhere, multiple times, although you
should make sure not to actually show more than once at a time to user to avoid confusion.

Usually this sort of functionality would be handled with a single main toolbar in the root layout file. This implementation allows us to also have this
global toolbar in RT Viewer without it taking an extra row of vertical space like a more traditional main toolbar would.

*/

import React from 'react';
import { connect } from 'react-redux';
import { ButtonGroup, Button } from 'react-bootstrap';
import { IoMdList, IoMdSettings, IoMdHelpCircleOutline } from 'react-icons/io';
import { IconContext } from 'react-icons';

import './GlobalToolbar.css';
import { StoreState } from '../../store/store';
import * as sagas from '../../store/sagas';
import { rtViewerApiClient } from '../../web-apis/rtviewer-api-client';
import { User } from '../../store/user';
import { isRutherford } from '../../environments';

type OwnProps = {
    // if the toolbar is NOT embedded, render some extra styles over it to position it correctly. Otherwise assume the containing component handles it.
    isEmbedded?: boolean
};

type DispatchProps = {
    setBatchJobPaneVisibility(value: boolean): void,
    setUserSettingsDialogVisibility(value: boolean): void,
    setHelpDialogVisibility(value: boolean): void,
}

type AllProps = OwnProps & StoreState & DispatchProps;

class GlobalToolbar extends React.PureComponent<AllProps>{
    handleViewBatchJobsClick = () => {
        this.props.setBatchJobPaneVisibility(true);
    }

    handleViewSettingsClick = () => {
        this.props.setUserSettingsDialogVisibility(true);
    }

    handleViewHelpClick = () => {
        this.props.setHelpDialogVisibility(true);
    }

    render() {
        // TODO: when the value of rtViewerApiClient.isMVisionUser() changes, React won't trigger an update (because it doesn't know anything about it)
        // --> this value should be read from store
        const isMVisionUser = rtViewerApiClient.isMVisionUser();
        const isEmbedded = this.props.isEmbedded !== undefined ? this.props.isEmbedded : false;
        const user = this.props.user as User;

        const toolbarButtons = (
            <div className="global-toolbar-buttons">
                <ButtonGroup>
                    <IconContext.Provider value={{ size: '18px' }}>
                        {isMVisionUser && !isRutherford() && (
                            <Button variant="outline-info" size="sm" title="View batch jobs" onClick={this.handleViewBatchJobsClick}>
                                <IoMdList />
                            </Button>)}
                        {isMVisionUser && (
                            <Button variant="outline-info" size="sm" title="View help" onClick={this.handleViewHelpClick}>
                                <IoMdHelpCircleOutline />
                            </Button>)}
                        {user.allowConfiguration() && (
                            <Button variant="outline-info" size="sm" title="Open settings" onClick={this.handleViewSettingsClick}>
                                <IoMdSettings />
                            </Button>)}

                    </IconContext.Provider>
                </ButtonGroup>
            </div>
        );

        return (
            <div className="toolbar-main-container">
                {isEmbedded ? toolbarButtons : (<div className="global-main-toolbar"><div className="toolbar-container">{toolbarButtons}</div></div>)}
            </div>
        );
    }
}

export default connect(state => Object.assign({}, state), sagas.mapDispatchToProps)(GlobalToolbar);
