/** Different kinds of RTViewer user permissions that the current logged-in user may have.
 * NOTE: any new permissions must be added to the annotation database from:
 *   https://dashboard.mvision.ai/admin/license_app/permission/
 * (or, ifthat link gets changed or broken, then through the MVision Dashboard's admin page)
 * 
 * Any new permissions must also be added to the RTViewer Backend before it will
 * support them properly. (currently mvrepo/src/mvworker/code/mvutils/data_structures/rtviewer_users.py
 * but coordinate with the backend team!)
 * 
 * Permissions themselves must be added to users via groups. This is also in MVision Dashboard:
 *   https://dashboard.mvision.ai/show_gandv
 */
export class UserPermissions {

    /** User can set the workflow state of an RT Struct to "Ready for Training". */
    canApproveRTStructsForTraining: boolean;

    /** User can set the workflow state of an RT Struct to "Obsolete". */
    canSetRTStructsAsObsolete: boolean;

    /** User can set the workflow state of an RT Struct to "Expert approved". */
    canSetRTStructsAsExpertApproved: boolean;

    /** User is able to change which contouring backend RTViewer uses. */
    canChangeBackend: boolean;

    /** User is given developer debug access, including following features:
     * 1. Show azure app registrations and log-in states in the settings dialog 
     * 2. Allow downloading of original structure set files from RTViewer (without putting them through dcmjs) 
     *    (note: this already happens by default in Contour web demo so no need to give this to demo users) */
    allowDebugMode: boolean;

    /** ANNOTATION WORKSPACE ONLY: User can access the annotation Backup Manager UI feature where they 
     * can restore previous backups of the selected structure set. */
    allowBackupRestore: boolean;

    /** ANNOTATION WORKSPACE ONLY: Users with this permission can force annotation page locks open. */
    allowForceUnlock: boolean;

    /** User can download DICOM structure set files directly to their own pc from the UI. */
    allowStructureSetDownload: boolean;

    /** User can download DICOM scans as a .ZIP file directly to their own pc from the UI.
     * NOTE: This permission should generally NOT be given to most people. */
    allowScanDownload: boolean;

    /** Allows annotation users to delete autocontoured structure sets that have been
     * saved into annotation datasets. */
    allowDeletingAutoContouredStructureSets: boolean;

    constructor(basePermissions: any = {}) {
        // default values
        this.canApproveRTStructsForTraining = false;
        this.canSetRTStructsAsObsolete = false;
        this.canSetRTStructsAsExpertApproved = false;
        this.canChangeBackend = false;
        this.allowDebugMode = false;
        this.allowBackupRestore = false;
        this.allowForceUnlock = false;
        this.allowStructureSetDownload = false;
        this.allowScanDownload = false;
        this.allowDeletingAutoContouredStructureSets = false;

        // overrides
        if (Array.isArray(basePermissions)) {
            if (basePermissions.includes('canApproveRTStructsForTraining')) {
                this.canApproveRTStructsForTraining = true;
                this.canSetRTStructsAsObsolete = true;
            }

            if (basePermissions.includes('canSetExpertApproved')) {
                this.canSetRTStructsAsExpertApproved = true;
            }

            if (basePermissions.includes('canChangeBackend')) {
                this.canChangeBackend = true;
            }

            if (basePermissions.includes('allowDebugMode')) {
                this.allowDebugMode = true;
            }

            if (basePermissions.includes('allowBackupRestore')) {
                this.allowBackupRestore = true;
            }

            if (basePermissions.includes('allowForceUnlock')) {
                this.allowForceUnlock = true;
            }

            if (basePermissions.includes('allowStructureSetDownload')) {
                this.allowStructureSetDownload = true;
            }

            if (basePermissions.includes('allowScanDownload')) {
                this.allowScanDownload = true;
            }

            if (basePermissions.includes('allowDeletingAutoContouredStructureSets')) {
                this.allowDeletingAutoContouredStructureSets = true;
            }
        }
    }

}