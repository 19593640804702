/* File loader component that loads image and structure set files necessary for live review.
Files are found from the given Azure storage account, under fileshares 'incoming' and 'contours' */

import React from 'react';
import { Button, Col, Row, Alert } from 'react-bootstrap';
import { connect } from 'react-redux';
import * as sagas from '../../store/sagas';
import * as Store from '../../store/store';
import './AzureFileLoaderLiveReview.css';
import { DownloadTask, TransferredFileType } from '../../store/file-transfer-task';
import { UrlSafeBase64 } from '../../util';

type OwnProps = {
    viewImage(scanId: string): void
}

type DispatchProps = {
}

type AllProps = OwnProps & Store.StoreState & DispatchProps;

type OwnState = {
    autoStartRTViewer: boolean,
}

export class AzureFileLoaderLiveReview extends React.Component<AllProps, OwnState> {
    displayName = AzureFileLoaderLiveReview.name

    constructor(props: AllProps) {
        super(props);

        // If the download exists already, user has come to this dialog using back button. Don't automatically move back to RTViewer
        const autoStartRTViewer = !this.props.downloads[Store.liveReviewDownloadKey];
        this.state = { autoStartRTViewer: autoStartRTViewer };
    }

    componentDidUpdate() {
        const download: DownloadTask = this.props.downloads[Store.liveReviewDownloadKey];
        const ready = download && download.ready;
        if (ready && this.state.autoStartRTViewer) {
            this.handleView();
        }
    }

    handleView = () => {
        const download: DownloadTask = this.props.downloads[Store.liveReviewDownloadKey];
        if (download && download.ready) {
            const filenames = Object.keys(download.files);
            for (let f = 0; f < filenames.length; ++f) {
                const file = download.files[filenames[f]];
                if (file.fileType === TransferredFileType.ImageSlice) {
                    this.props.viewImage(file.scanId!);
                    return;
                }
            }
        }
    }


    render = () => {
        const queryV1 = this.props.liveReviewQueries.queryV1;
        const queryV2 = this.props.liveReviewQueries.queryV2;
        const isLoading = this.props.isDownloadingLiveReviewFiles;

        const key = Store.liveReviewDownloadKey;
        const download: DownloadTask = this.props.downloads[key];
        const ready = download && download.ready;
        const failed = download && download.failed;
        const progress = (download && !failed) ? download.progressPercentage.toFixed(0) + '%' : "";

        const errorMessage = this.props.liveReviewErrorMessage;
        const hasErrorMessage = errorMessage !== undefined;

        return (
            <div className="live-review-info">
                <h3>Review customer results</h3>
                <br />
                <Col className="review-form">

                    {
                        queryV1 ? (<>
                            <Row className="query-item">
                                <div className="query-label">Reported to slack:</div>
                                <div className="query-value">{new Date(parseInt(queryV1.timeMilliseconds || "0")).toLocaleString()}</div>
                            </Row>
                            <Row className="query-item">
                                <div className="query-label">Customer:</div>
                                <div className="query-value"><b>{queryV1.user}</b></div>
                            </Row>
                            <Row className="query-item">
                                <div className="query-label">Body part examined:</div>
                                <div className="query-value"><b>{queryV1.bodyPartExamined}</b></div>
                            </Row>
                            <Row className="query-item">
                                <div className="query-label">Modality:</div>
                                <div className="query-value"><b>{queryV1.modality}</b></div>
                            </Row>
                            <Row className="query-item">
                                <div className="query-label">Storage account name:</div>
                                <div className="query-value">{queryV1.storageAccountName}</div>
                            </Row>
                            {queryV1.daemonId ?
                                <Row className="query-item">
                                    <div className="query-label">Daemon id:</div>
                                    <div className="query-value">{queryV1.daemonId}</div>
                                </Row> : null}
                            {queryV1.clientId ?
                                <Row className="query-item">
                                    <div className="query-label">Client id:</div>
                                    <div className="query-value">{queryV1.clientId}</div>
                                </Row> : null}
                            <Row className="query-item">
                                <div className="query-label">Series id:</div>
                                <div className="query-value">{queryV1.seriesId}</div>
                            </Row>
                        </>) : null
                    }

                    {
                        !queryV1 && queryV2 ? (<>
                            <Row className="query-item">
                                <div className="query-label">Reported to slack:</div>
                                <div className="query-value">{new Date(parseInt(queryV2.time || "0")).toLocaleString()}</div>
                            </Row>
                            <Row className="query-item">
                                <div className="query-label">Environment:</div>
                                <div className="query-value"><b>{queryV2.depl}</b><span>{" (" + UrlSafeBase64.decode(queryV2.api as string) + ")"}</span> </div>
                            </Row>
                            <Row className="query-item">
                                <div className="query-label">Customer:</div>
                                <div className="query-value"><b>{queryV2.user}</b></div>
                            </Row>
                            <Row className="query-item">
                                <div className="query-label">Model:</div>
                                <div className="query-value"><b>{queryV2.model}</b></div>
                            </Row>
                            <Row className="query-item">
                                <div className="query-label">Client id:</div>
                                <div className="query-value">{queryV2.cid}</div>
                            </Row>
                            <Row className="query-item">
                                <div className="query-label">Series id:</div>
                                <div className="query-value">{queryV2.sid}</div>
                            </Row>
                        </>) : null
                    }

                    <br />

                    {ready ?
                        <>
                            <Row>
                                <div>Download ready</div>
                            </Row>
                            <Row>
                                <Button variant="light" className="btn btn-default btn-sm" onClick={() => this.handleView()}>View results</Button>
                            </Row>
                        </>
                        : isLoading ?
                            <>
                                <Row>
                                    {failed ?
                                        (<div>
                                            <p><b>Download failed.</b></p>
                                            <p>Files might have been deleted due to the 24h time limit. See console (F12) for error details.</p>
                                            <p>It is also possible that the reported result has become stale after new slices have been received. In that case, new link will be reported soon.</p>
                                        </div>)
                                        : (<div>
                                            <div>Loading files</div>
                                            <div><b>{progress}</b></div>
                                        </div>)}
                                </Row>
                            </>
                            : null}
                </Col>

                {hasErrorMessage && (<Alert variant="danger">{errorMessage}</Alert>)}

            </div>);
    }
}


export default connect(
    state => Object.assign({}, state),
    sagas.mapDispatchToProps
)(AzureFileLoaderLiveReview);
