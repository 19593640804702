import React from 'react';
import { Button } from 'react-bootstrap';
import { Menu, Item, contextMenu } from 'react-contexify';
import { RiQuestionnaireLine } from 'react-icons/ri';
import { IconContext } from 'react-icons';
import { connect } from 'react-redux';

import TriStateCheckbox, { TriStateCheckboxState } from '../../common/TriStateCheckbox';
import { ApprovalIndicator } from '../../misc-components';
import { rtViewerApiClient } from '../../../web-apis/rtviewer-api-client';
import DatasetBatchJobCell from './DatasetBatchJobCell';
import { GradingWorkflowState, filterRoisNeedingFixes } from '../../../datasets/roi-grading';
import GradingState from '../../rtviewer/grading/GradingState';
import { LockAction } from '../../../datasets/dataset-files';
import { PageImage, PageStructureSet } from '../models/PagePatient';
import LinkButton from '../../common/LinkButton';
import { DatasetImage } from '../../../datasets/dataset-image';
import { Dataset } from '../../../datasets/dataset';
import { DatasetStructureSet } from '../../../datasets/dataset-structure-set';
import { StoreState } from '../../../store/store';
import { BatchJobOperation } from './DatasetPage';
import { isRutherford } from '../../../environments';
import DatasetCategory from './DatasetCategory';


type OwnProps = {
    dataset: Dataset;
    image: PageImage,
    isLastImageOfList: boolean,
    totalImages: number,
    moreItemsAvailable: boolean,
    areBatchColumnsVisible: boolean,
    patientIndex: number,
    imageIndex: number,
    batchSelections: { [checkboxId: string]: TriStateCheckboxState },
    showBatchControls?: boolean,
    batchSelectionOperations: { [checkboxId: string]: BatchJobOperation },
    getCheckboxId: (patientId: string, imageId?: string, structureSetId?: string) => string,
    handleTriStateCheckboxChange: (checkboxId: string, event: any) => void,
    handleLockClick: (datasetImage: DatasetImage, lockAction: LockAction) => void,
    handleLoadClick: (datasetImage: DatasetImage) => void,
    handleUnloadClick: (datasetImage: DatasetImage) => void,
    handleViewImageClick: (datasetImage: DatasetImage) => void,
    handleBatchOperationTypeChange: (value: { checkboxId: string, operation: BatchJobOperation }) => void,
    handleShowAllImagesClick: (image: PageImage) => void,
    handleShowAllStructureSetsClick: (image: PageImage) => void,
}

type AllProps = OwnProps & StoreState;

class DatasetTableRow extends React.PureComponent<AllProps> {
    displayName = DatasetTableRow.name

    onShowAllImagesClick = () => {
        this.props.handleShowAllImagesClick(this.props.image);
    }

    onShowAllStructureSetsClick = () => {
        this.props.handleShowAllStructureSetsClick(this.props.image);
    }

    handleShowContextMenu = (menuId: string, evt: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        contextMenu.show({ id: menuId, event: evt });
    }

    renderStructureSet = (pss: PageStructureSet, img: PageImage) => {
        const { handleTriStateCheckboxChange, getCheckboxId, batchSelections, dataset } = this.props;

        const ss = pss.structureSet;
        const editorInfo = dataset.metaFiles.editorInfo;
        const gradings = dataset.metaFiles.gradings;
        const ptId = img.image.patientId;
        const ssId = ss.sopId;
        const edits: { [timestamp: number]: string } = {}; // timestamp -> user

        let tooltip = undefined;
        let latestEditor = null;
        let gradingText = null;
        let gradingTooltip = "";
        let overallGradingComment = "";

        if (editorInfo && editorInfo[ptId] && editorInfo[ptId][ssId]) {
            const users = Object.keys(editorInfo[ptId][ssId]);
            for (let i = 0; i < users.length; ++i) {
                let timestamp = editorInfo[ptId][ssId][users[i]];
                edits[timestamp] = users[i];
            }
            const stamps = Object.keys(edits).map(stamp => parseInt(stamp)).sort();
            const latestStamp: number = stamps[stamps.length - 1];
            latestEditor = edits[latestStamp];

            tooltip = "";
            for (let i = 0; i < stamps.length; ++i) {
                tooltip += new Date(stamps[i]).toLocaleString() + " " + edits[stamps[i]] + "\r\n";
            }
        }
        const numberOfStructures = ss.roiMappings.length;
        const numberOfStructuresAsStr = `Total structures: ${numberOfStructures}`;
        const roiString = ss.roiMappings.map(rm => rm.originalName).sort().join(", ");
        tooltip = `${numberOfStructuresAsStr}\r\n\r\n${tooltip ? tooltip + "\r\n\r\n" + roiString : roiString}`;

        let unsureRois = [];
        let workflowState = GradingWorkflowState.Undefined;
        if (gradings) {
            const ssGrading = gradings.structureSets[ssId];
            if (ssGrading) {
                overallGradingComment = ssGrading.comment || '';
                const roiGradings = Object.values(ssGrading.rois).filter(r => r.value !== "0");

                if (isRutherford()) {
                    // show rutherford-specific roi gradings here instead of regular ones
                    const approvedRois = roiGradings.filter(g => g.value === "A");
                    const unapprovedRois = roiGradings.filter(g => g.value === "U");
                    gradingText = '';
                    if (approvedRois.length) {
                        gradingText += `${approvedRois.length} approved ROIs`;
                        gradingTooltip += approvedRois.map(r => `${r.roiName}: ${r.valueMeaning}`).join('\n');
                    }
                    if (unapprovedRois.length) {
                        gradingText += `${gradingText.length ? ', ' : ''}${unapprovedRois.length} unapproved ROIs`;
                        gradingTooltip += `${gradingTooltip.length ? '\n' : ''}` + unapprovedRois.map(r => `${r.roiName}: ${r.valueMeaning}`).join('\n');
                    }
                }
                else {
                    const roisToFix = filterRoisNeedingFixes(roiGradings);
                    unsureRois = roiGradings.filter(g => g.unsure === true);
                    if (roisToFix.length) {
                        gradingText = roisToFix.length + " fixes needed";
                        gradingTooltip = roisToFix.map(r => `${r.roiName}: ${r.value} - ${r.valueMeaning} ${r.unsure ? '(unsure)' : ''}`).join("\n");
                    }
                    else {
                        gradingText = "graded, OK"
                        gradingTooltip = roiGradings.map(r => `${r.roiName}: ${r.valueMeaning} ${r.unsure ? '(unsure)' : ''}`).join("\n");
                    }
                }
                const roiGradingsExcludingZero = roiGradings.map(roiGrading => parseInt(roiGrading.value)).filter(Number.isInteger);
                const countOfGradingsGreaterThanFour = roiGradingsExcludingZero.filter(roiGrading => roiGrading >= 4).length;
                const countOfGradingsFromOneToThree = roiGradingsExcludingZero.filter(roiGrading => roiGrading >= 1 && roiGrading <= 3).length;
                const sumOfGradingsAboveZero = roiGradingsExcludingZero.length > 0 ? `\n\n\nCount of gradings from 1 to 3: ${countOfGradingsFromOneToThree}\nCount of 4+ gradings: ${countOfGradingsGreaterThanFour}` : '';
                gradingTooltip = overallGradingComment || gradingTooltip ? 'Overall comment: ' + overallGradingComment + '\n' + gradingTooltip + sumOfGradingsAboveZero : '';
                gradingText = gradingText ? ' (' + gradingText + ')' : '';
                workflowState = ssGrading.workflowState;
            }
        }

        const ssInfo = (<>
            {latestEditor ? ss.label + " (" + latestEditor + ")" : ss.label}
            {gradingText && (
                <IconContext.Provider value={{ size: "18px", className: 'unsure-rois' }}>
                    <b className={"structureset-grading-info"} title={gradingTooltip}>
                        {gradingText} {(unsureRois.length > 0 || overallGradingComment.length > 0) && (<RiQuestionnaireLine />)}
                    </b>
                </IconContext.Provider>)}
        </>)

        const obsoleteClass = workflowState === GradingWorkflowState.Obsolete ? 'obsolete-structure-set' : '';

        return (
            <div key={img.seriesId + ss.sopId} className={`structure-set-item ${obsoleteClass}`}>
                <div className="structure-set-approval-indicator">
                    <ApprovalIndicator approvalStatus={ss.approvalStatus}></ApprovalIndicator>
                </div>
                <div title={tooltip} className="structure-set-info">
                    {this.props.showBatchControls ? <TriStateCheckbox
                        onCheckboxChange={handleTriStateCheckboxChange}
                        id={getCheckboxId(ptId, img.seriesId, img.seriesId + ss.sopId)}
                        checked={batchSelections[getCheckboxId(ptId, img.seriesId, img.seriesId + ss.sopId)].state}>
                        {ssInfo}
                    </TriStateCheckbox> : ssInfo
                    }
                </div>
                {this.renderWorkflowState(ss)}
            </div>
        );
    }

    renderWorkflowState = (ss: DatasetStructureSet) => {
        const { dataset } = this.props;
        const ssId = ss.sopId;
        const gradings = dataset.metaFiles.gradings;
        const minimizedWorkflowState = this.props.showBatchControls;

        let workflowState = GradingWorkflowState.Undefined;

        if (gradings) {
            const ssGrading = gradings.structureSets[ssId];
            if (ssGrading) {
                workflowState = ssGrading.workflowState;
            }
        }

        return (
            <div className={`structure-set-workflow-state ${minimizedWorkflowState ? 'minimized' : ''}`}>
                <GradingState gradingWorkflowState={workflowState} colorText={true} iconOnly={this.props.showBatchControls} />
            </div>);
    }

    renderLockCell = (pageImage: PageImage) => {
        const datasetImage = pageImage.image;
        const datasetId = this.props.dataset.getDatasetId();
        const locks = this.props.datasetLocks[datasetId];
        const lockRequests = this.props.lockRequests[datasetId];

        if (!locks) {
            return null;
        }
        if (lockRequests && lockRequests[datasetImage.seriesId] === LockAction.Lock) {
            return (<b>locking...</b>)
        }
        if (lockRequests && lockRequests[datasetImage.seriesId] === LockAction.Unlock) {
            return (<b>unlocking...</b>)
        }
        if (!locks[datasetImage.seriesId]) {
            return (<Button variant={"light"} size="sm" onClick={(evt: any) => { this.props.handleLockClick(datasetImage, LockAction.Lock) }}>Lock</Button>);
        }
        if (locks[datasetImage.seriesId] === rtViewerApiClient.username) {
            return (<Button variant={"danger"} size="sm" onClick={(evt: any) => { this.props.handleLockClick(datasetImage, LockAction.Unlock) }}>Unlock</Button>);
        }
        return (<>
            <b>{locks[datasetImage.seriesId]}</b>
            {rtViewerApiClient.permissions.allowForceUnlock ?
                <Button title="Force unlock" variant={"danger"} size="sm" onClick={(evt: any) => { this.props.handleLockClick(datasetImage, LockAction.Unlock) }}>x</Button> : null}
        </>);
    }

    renderLoadCell = (pageImage: PageImage) => {
        const datasetImage = pageImage.image;
        const download = this.props.downloads![datasetImage.downloadKey];
        const datasetId = this.props.dataset.getDatasetId();
        const locks = this.props.datasetLocks[datasetId];
        const lockRequests = this.props.lockRequests[datasetId];

        // to block users (other than the one with lock) for viewing scan, add: " || locks[datasetImage.seriesId] !== rtViewerApiClient.username " to if statement below
        if (!locks || (lockRequests && lockRequests[datasetImage.seriesId] === LockAction.Unlock)) {
            return null;
        }
        let buttonTxt = "Load"
        if (!locks || locks[datasetImage.seriesId] === undefined || (locks[datasetImage.seriesId] && locks[datasetImage.seriesId] !== rtViewerApiClient.username)) {
            buttonTxt = "Load (read-only)"
        }
        if (!download) {
            return (<Button variant={"light"} size="sm" title="Load DICOM files" onClick={(evt: any) => { this.props.handleLoadClick(datasetImage) }}>{buttonTxt}</Button>);
        }
        else if (download.failed) {
            return (<b title="Download failed! Please refresh the page and try again.">Failed!</b>);
        }
        else if (download.ready) {
            return (<Button variant={"danger"} size="sm" title="Unload DICOM files" onClick={(evt: any) => { this.props.handleUnloadClick(datasetImage) }}>Unload</Button>);
        }
        else {
            let progress = parseInt(download.progressPercentage.toString(), 10) + '%';
            return (<b>{progress}</b>);
        }
    }

    renderViewCell = (pageImage: PageImage) => {
        const datasetImage = pageImage.image;
        const download = this.props.downloads![datasetImage.downloadKey];
        const datasetId = this.props.dataset.getDatasetId();
        const locks = this.props.datasetLocks[datasetId];
        let buttonTxt = "View"
        if (locks && locks[datasetImage.seriesId] && locks[datasetImage.seriesId] !== rtViewerApiClient.username) {
            buttonTxt = "View (read-only)"
        }
        if (download && !download.failed && download.ready) {
            return (<Button variant={"light"} size="sm" title="View image" onClick={(evt: any) => { this.props.handleViewImageClick(datasetImage) }}>{buttonTxt}</Button>);
        }
        return null;
    }

    render() {
        const { image, areBatchColumnsVisible, patientIndex, imageIndex, totalImages, batchSelections, getCheckboxId, handleTriStateCheckboxChange } = this.props;
        const datasetImage = image.image;

        const patientCheckboxId = getCheckboxId(datasetImage.patientId);
        const imageCheckboxId = getCheckboxId(datasetImage.patientId, image.seriesId);
        const patientChecked = batchSelections[patientCheckboxId].state;
        const imageChecked = batchSelections[imageCheckboxId].state;

        return (
            <tr className={patientIndex % 2 ? "odd-row" : ""}>
                {imageIndex === 0 && (
                    <td rowSpan={totalImages}>
                        <div>
                            {this.props.showBatchControls ? (
                                <TriStateCheckbox
                                    onCheckboxChange={handleTriStateCheckboxChange}
                                    id={patientCheckboxId}
                                    checked={patientChecked}
                                >{datasetImage.patientId}</TriStateCheckbox>) : (<span>{datasetImage.patientId}</span>)}
                        </div>
                        <div>
                            {this.props.datasetCatagories![datasetImage.patientId] && (<DatasetCategory category={this.props.datasetCatagories![datasetImage.patientId]} />)}
                        </div>
                    </td>
                )}
                <td>
                    <div onContextMenu={(evt: React.MouseEvent<HTMLDivElement, MouseEvent>) => { this.handleShowContextMenu(image.seriesId, evt); }}>
                        {this.props.showBatchControls ? <TriStateCheckbox
                            onCheckboxChange={handleTriStateCheckboxChange}
                            id={imageCheckboxId}
                            checked={imageChecked}
                        >{datasetImage.seriesDescription || "(none)"}</TriStateCheckbox> : datasetImage.seriesDescription || "(none)"}
                    </div>
                    {this.props.isLastImageOfList && this.props.moreItemsAvailable && (<div><LinkButton onClick={this.onShowAllImagesClick}>Show all images</LinkButton></div>)}
                    <Menu id={image.seriesId} style={{ zIndex: 1000 }} animation={false} className="dataset-table-context-menu">
                        <Item onClick={(event) => { console.log(image) }}>View in console (F12)</Item>
                    </Menu>
                </td>
                <td>{datasetImage.modality}</td>
                <td className={this.props.showBatchControls ? 'minimized' : ''}>
                    <div className="structure-set-list">
                        {image.getStructureSets().map(pss => (this.renderStructureSet(pss, image)))}
                        {image.hasMoreStructureSets() && (<div className="structure-set-item"><LinkButton onClick={this.onShowAllStructureSetsClick}>Show all structure sets</LinkButton></div>)}
                    </div>
                </td>
                <td>
                    {this.renderLockCell(image)}
                </td>
                <td>
                    {this.renderLoadCell(image)}
                </td>
                <td>
                    {this.renderViewCell(image)}
                </td>
                {areBatchColumnsVisible && (
                    <DatasetBatchJobCell
                        image={image}
                        imageCheckboxId={imageCheckboxId}
                        imageChecked={imageChecked}
                        currentBatchOperation={this.props.batchSelectionOperations[imageCheckboxId]}
                        handleBatchOperationTypeChange={this.props.handleBatchOperationTypeChange}
                    />)}
            </tr>
        );
    }
}

export default connect(
    state => Object.assign({}, state)
)(DatasetTableRow);
