import { SdfSlice } from './webgl/sdf/sdf-slice';

export class Clipboard {
    private content: any;

    copy(content: any){
        if(this.content && this.content instanceof SdfSlice){
            this.content.clearTexture();
        }
        this.content = content;
    }

    paste(): any {
        if(this.content){
            return this.content;
        }
        return null;
    }
}