import { VBO } from './VertexBufferObject';
import { AttributeVBO } from './AttributeVertexBufferObject';

export class VAO {

    protected gl: WebGL2RenderingContext;
    protected id?: WebGLVertexArrayObject;
    protected vbos: Map<number, AttributeVBO>;
    private indexVBO?: VBO;
    private indexCount: number;

    constructor(gl: WebGL2RenderingContext){
        this.gl = gl;
        this.id = gl.createVertexArray() || undefined;
        if(!this.id){
            throw new Error("VertexArrayObject creation failed");
        }
        this.vbos = new Map<number, AttributeVBO>();
        this.indexVBO = undefined;
        this.indexCount = 0;
    }

    public getIndexCount(): number{
        return this.indexCount;
    }

    public createIndexBuffer(indices: number[]){
        this.indexVBO = new VBO(this.gl, this.gl.ELEMENT_ARRAY_BUFFER);
        this.indexVBO.bind();
        const buffer = new Int32Array(indices);
        this.indexVBO.storeData(buffer);
        this.indexCount = indices.length;
    }

    public createAttribute(attribute: number, dataType: number, data: number[], attrSize: number): void {
        if(this.vbos.has(attribute)) {
            throw new Error("Attribute" + attribute.toString() +" is already in use");
        }
        let buffer: ArrayBuffer;
        switch(dataType){
            case this.gl.FLOAT:
                buffer = new Float32Array(data);
                break;
            case this.gl.INT:
                buffer = new Int32Array(data);
                break;
            default:
                throw new Error("Attempting to create attribute of unknown data type " +dataType.toString());
        }
        const vbo = new AttributeVBO(this.gl, attrSize, dataType);
        vbo.bind();
        vbo.storeData(buffer);
        vbo.setPointer(attribute);
        vbo.unbind();
        this.vbos.set(attribute, vbo);
    }

    public bind(...attributes: number[]): void{
        if(this.id){
            this.gl.bindVertexArray(this.id);
            attributes.forEach(i => {
                this.gl.enableVertexAttribArray(i);
            });
        }
    }

    public unbind(...attributes: number[]): void{
        attributes.forEach(i => {
            this.gl.disableVertexAttribArray(i);
        });
        this.gl.bindVertexArray(null);
    }

    public delete(): void {
        if(this.id){
            this.gl.deleteVertexArray(this.id);
        }
        this.deleteContent();
    }

    public deleteContent(): void {
        this.vbos.forEach(vbo => {
            vbo.delete();
        });
        if(this.indexVBO){
            this.indexVBO.delete();
        }
    }
}