import { Configuration, InteractionRequiredAuthError, PublicClientApplication, AuthenticationResult } from "@azure/msal-browser";
import _ from "lodash";
import { MVISION_AUTHORITY, getClientId, getAppName, RTViewerDisplayVersion } from "../environments";
import { AppVersionInfo } from "../store/app-version-info";

export const POPUPS_BLOCKED_ERROR = 'POPUPS_BLOCKED_ERROR';

export const QUERY_PARAM_CLIENT_ID = 'clientId';


export class BackendFetchOptions {
    /** Allow caching of request response. Default: false. */
    allowCache: boolean;

    /** Return quickFetch response as text instead of json. Default: false. Only applicable to quickFetch. */
    asText: boolean;

    /** Don't append client ID to URL query parameters. Default: false. */
    noClientId: boolean;

    constructor() {
        this.allowCache = false;
        this.asText = false;
        this.noClientId = false;
    }
}


// this class models authentication to an azure app registration
export default class AppAuth {

    // name of the app registration
    appName: string;

    // client ID matching the azure app registration
    clientId: string;

    config: Configuration;

    // the set of scopes that we ask for when requesting tokens
    request: any;

    msalInstance: PublicClientApplication | null;

    // true if user has logged into this app registration, false otherwise
    isLoggedIn: boolean;

    /** The version of the app that this app auth is sending requests for. */
    appVersion: AppVersionInfo | undefined;

    constructor(appName: string, clientId: string) {
        this.appName = appName;
        this.clientId = clientId;

        this.isLoggedIn = false;
        this.appVersion = undefined;

        this.request = {
            scopes: [`${clientId}/.default`],
        };

        this.config = {
            auth: {
                clientId: this.clientId,
                authority: MVISION_AUTHORITY,
            },
            cache: {
                cacheLocation: "sessionStorage",
            },
            // system: {
            //     // uncomment this if needed
            //     loggerOptions: {
            //         loggerCallback: (logLevel: LogLevel, message: string, containsPii: boolean) => console.log(`${this.appName}: ${message}`),
            //         logLevel: LogLevel.Info,
            //         // change this to true if needed -- don't commit or deploy into production
            //         piiLoggingEnabled: false,
            //     },
            // },

        };

        this.msalInstance = null;
    }

    setAppVersion(appVersion: AppVersionInfo) {
        this.appVersion = appVersion;
    }

    async logIn() {
        if (this.msalInstance === null) { this.msalInstance = new PublicClientApplication(this.config); }

        if (!this.isLoggedIn) {
            // No user signed in
            try {
                const authResult = await this.msalInstance.loginPopup();
                if (authResult.account === null) {
                    throw new Error('No valid account after logon');
                }
                this.msalInstance.setActiveAccount(authResult.account);
            }
            catch (err) {
                console.error(err);
                if ('errorCode' in err && err.errorCode.includes('popup_window_error')) {
                    throw new Error(POPUPS_BLOCKED_ERROR);
                } else if ('errorCode' in err && (err.errorCode.includes('block_nested_popups'))) {
                    // ignore block_nested_popups errors to prevent visible error dialogs showing up on
                    // login popup windows
                }
                else throw err;
            }
        }

        this.isLoggedIn = true;
    }

    async logOut() {
        if (this.msalInstance && this.isLoggedIn) {
            // No user signed in
            try {
                await this.msalInstance.logoutPopup({
                    // postLogoutRedirectUri: "./loggedOut.html",
                    mainWindowRedirectUri: "./logged-out",
                });
            }
            catch (err) {
                console.error(err);
                if ('errorCode' in err && (err.errorCode.includes('popup_window_error') || err.errorCode.includes('user_cancelled'))) {
                    throw new Error(POPUPS_BLOCKED_ERROR);
                }
                else throw err;
            }
        }

        this.isLoggedIn = false;
    }

    private async getAccessToken(): Promise<string> {
        if (this.msalInstance === null) {
            throw new Error(`No MSAL instance for ${this.appName} -- log in before trying to get an access token!`);
        }

        const authInstance = this.msalInstance as PublicClientApplication;

        // throw if an active account has not been set
        let account = authInstance.getActiveAccount();
        if (!account) {
            throw new Error('No active account has been set');
        }

        let response: AuthenticationResult | undefined = undefined;
        const request = { ...this.request };

        // try to get the token silently, but fall back to popup window if it fails
        //
        // NOTE/TODO: asynchronous parallel operations (such as sending dicoms for auto-contouring)
        // may cause problems if the current access token (inside authInstance) has timed out and
        // msal has to fall back to acquireTokenPopup as this may cause parallel popup
        // windows which in turn will cause them to cancel each other. If this turns out to
        // be an actual problem then this code must be changed so that in case of a stale
        // token the first token/auth call/api operation must be blocking.
        //
        // see also: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/token-lifetimes.md
        try {
            response = await authInstance.acquireTokenSilent(request);
        } catch (err) {
            if (err instanceof InteractionRequiredAuthError) {
                response = await authInstance.acquireTokenPopup(request);
            }
        }

        if (!response) {
            throw new Error('Could not get response');
        }

        if  (!response.accessToken) {
            throw new Error('Could not get access token');
        }

        return response.accessToken as string;
    }

    async fetch(url: string, httpRequestOptions: any = undefined, backendFetchOptions: Partial<BackendFetchOptions> = {}, printAccessToken: boolean = false): Promise<Response> {
        if (!this.isLoggedIn) {
            throw new Error(`Must be logged into ${this.appName} before calling any MSAL APIs!`);
        }

        const token = await this.getAccessToken();
        if (printAccessToken) {
            console.log(`token: ${token}`);
        }

        const fetchOptions = httpRequestOptions || {};
        const bearer = `Bearer ${token}`;
        _.set(fetchOptions, 'headers.Authorization', bearer);

        if (!backendFetchOptions.allowCache) {
            _.set(fetchOptions, 'headers.Cache-Control', 'no-store');
            _.set(fetchOptions, 'cache', 'no-store');
            _.set(fetchOptions, 'headers.pragma', 'no-cache');
        }

        // set app version so backend knows which app and which version of it is sending the request
        _.set(fetchOptions, 'headers.appVersion', `${getAppName()}/${RTViewerDisplayVersion}/${this.appVersion ? this.appVersion.commit : 'N/A'}`);

        const fullUrl = new URL(url);

        if (!backendFetchOptions.noClientId) {
            // append client ID to query parameters
            const clientIdQueryParam = `${QUERY_PARAM_CLIENT_ID}=${getClientId()}`;
            fullUrl.search = fullUrl.search ? `${fullUrl.search}&${clientIdQueryParam}` : clientIdQueryParam;
        }

        try {
            return await fetch(fullUrl.toString(), fetchOptions);
        } catch (err) {
            console.log(`An error occurred when trying to fetch from ${url}`);
            console.log('Fetch options:');
            console.log(fetchOptions);
            console.log('Fetch error:');
            console.log(err);
            throw err;
        }
    }
}
