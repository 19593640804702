export const JFA_VS = `#version 300 es
#define POSITION_LOCATION 0
#define TEXCOORD_LOCATION 1

precision highp float;
precision highp int;

layout(location = POSITION_LOCATION) in vec2 position;
layout(location = TEXCOORD_LOCATION) in vec2 in_texcoord;

out vec3 v_texcoord;
uniform mat4 orientation;

void main()
{
    v_texcoord = (orientation * vec4(in_texcoord, 0.0, 1.0)).stp;
    gl_Position = vec4(position, 0.0, 1.0);
}
`;

export const JFA_FS = `#version 300 es
precision mediump float;
precision mediump int;

precision mediump sampler3D;
uniform sampler3D textureData;
uniform vec3 textureSize;
uniform float distancePixels;
uniform float maxDistancePixels;

in vec3 v_texcoord;

out vec4 color;


float getDist(vec4 point) {
    float x = point.r;
    float y = point.g;
    return sqrt( x*x + y*y ) + point.b;
}

void main()
{

    vec3 pixel = v_texcoord * textureSize;

    // Top row
    vec4 p1 = texture(textureData, (pixel + vec3(-1.0 * distancePixels, -1.0 * distancePixels, 0.0)) / textureSize );
    vec4 p2 = texture(textureData, (pixel + vec3(0.0, -1.0 * distancePixels, 0.0)) / textureSize );
    vec4 p3 = texture(textureData, (pixel + vec3(1.0 * distancePixels, -1.0 * distancePixels, 0.0)) / textureSize );
    
    // Middle row
    vec4 p4 = texture(textureData, (pixel + vec3(-1.0 * distancePixels, 0.0, 0.0)) / textureSize );
    vec4 p5 = texture(textureData, (pixel + vec3(0.0, 0.0, 0.0)) / textureSize );
    vec4 p6 = texture(textureData, (pixel + vec3(1.0  * distancePixels, 0.0, 0.0)) / textureSize );

    // Bottom row
    vec4 p7 = texture(textureData, (pixel + vec3(-1.0 * distancePixels, 1.0 * distancePixels, 0.0)) / textureSize );
    vec4 p8 = texture(textureData, (pixel + vec3(0.0, 1.0 * distancePixels, 0.0)) / textureSize );
    vec4 p9 = texture(textureData, (pixel + vec3(1.0 * distancePixels, 1.0 * distancePixels, 0.0)) / textureSize );

    // Add distance to each sample
    float diff = distancePixels / maxDistancePixels;
    p1.r += diff;
    p1.g += diff;
    p2.g += diff;
    p3.r += diff;
    p3.g += diff;
    p4.r += diff;
    p6.r += diff;
    p7.r += diff;
    p7.g += diff;
    p8.g += diff;
    p9.r += diff;
    p9.g += diff;

    float d1 = getDist(p1);
    float d2 = getDist(p2);
    float d3 = getDist(p3);
    float d4 = getDist(p4);
    float d5 = getDist(p5);
    float d6 = getDist(p6);
    float d7 = getDist(p7);
    float d8 = getDist(p8);
    float d9 = getDist(p9);

    vec4 closestPoint = p1;
    float d = d1;

    if(d2 < d) {
        d = d2;
        closestPoint = p2;
    }
    if(d3 < d) {
        d = d3;
        closestPoint = p3;
    }
    if(d4 < d) {
        d = d4;
        closestPoint = p4;
    }
    if(d5 < d) {
        d = d5;
        closestPoint = p5;
    }
    if(d6 < d) {
        d = d6;
        closestPoint = p6;
    }
    if(d7 < d) {
        d = d7;
        closestPoint = p7;
    }
    if(d8 < d) {
        d = d8;
        closestPoint = p8;
    }
    if(d9 < d) {
        d = d9;
        closestPoint = p9;
    }

    color = closestPoint;
    
}
`;