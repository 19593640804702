export const MARGIN_SWEEP_X_VS = `#version 300 es
#define POSITION_LOCATION 0
#define TEXCOORD_LOCATION 1

precision highp float;
precision highp int;

layout(location = POSITION_LOCATION) in vec2 position;
layout(location = TEXCOORD_LOCATION) in vec2 in_texcoord;

out vec2 v_texcoord;

void main()
{
    v_texcoord = vec2(in_texcoord.x, 1.0 - in_texcoord.y);
    gl_Position = vec4(position, 0.0, 1.0);
}
`;

export const MARGIN_SWEEP_X_FS = `#version 300 es
precision highp float;
precision highp int;

precision highp sampler2D;


uniform sampler2D textureOrig; // Original image slice before any application of x-sweep
uniform sampler2D textureBuffer; // Image slice after the previous iteration of x-sweep

uniform vec2 textureSize;
uniform float distancePixels1;
uniform float distancePixels2; 
uniform float maxDistancePixels;

uniform float x1MarginPixels;
uniform float x2MarginPixels;
uniform float isInnerMargin; // 0.0 = NO, 1.0 = YES

in vec2 v_texcoord;

out vec4 color;

bool isIn(vec2 pixel) {
    return pixel.x >= 0.0 && pixel.x < textureSize.x;
}

void main()
{
    bool isInner = isInnerMargin > 0.5;
    float inv = isInner ? -1.0 : 1.0;
    vec2 pixel = v_texcoord * textureSize;
    vec4 curr = texture(textureBuffer, v_texcoord);
    float r = curr.r;
    float g = curr.g;
    

    
    if(distancePixels1 > 0.0) {
        vec2 pixel1 = pixel + vec2(-1.0 * inv * distancePixels1, 0.0);
        if(isIn(pixel1)) {
            vec4 sample1 = texture(textureOrig, pixel1 / textureSize );
            
            if (   (isInner && curr.r < 0.5 && sample1.r >= 0.5) ||
                    (!isInner && curr.r >= 0.5 && sample1.r < 0.5) ) {
                g = (distancePixels1 - abs(sample1.r - 0.5) * maxDistancePixels ) / x1MarginPixels;
                r = isInner ? max(r, sample1.r) : min(r, sample1.r);
            } 
            else if (   (isInner && curr.r < 0.5 ) ||
                    (!isInner && curr.r >= 0.5  )) {
                r = isInner ? max(r, sample1.r) : min(r, sample1.r);
            } 
            
            
        }
    }



    
    if(distancePixels2 > 0.0) {
        vec2 pixel2 = pixel + vec2(1.0 * inv * distancePixels2, 0.0);
        if(isIn(pixel2)) {
            vec4 sample2 = texture(textureOrig, pixel2 / textureSize );
            
            if (   (isInner && curr.r < 0.5 && sample2.r >= 0.5) ||
                    (!isInner && curr.r >= 0.5 && sample2.r < 0.5) ) {
                g = (distancePixels2 - abs(sample2.r - 0.5) * maxDistancePixels ) / x2MarginPixels;
                r = isInner ? max(r, sample2.r) : min(r, sample2.r);
            }
            else if (   (isInner && curr.r < 0.5) ||
                    (!isInner && curr.r >= 0.5) ) {

                r = isInner ? max(r, sample2.r) : min(r, sample2.r);
            }
        
            
        }
    }


    color = vec4(r, g, 0.0, 0.0);
    
    
}
`;