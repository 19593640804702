import * as localStorage from '../local-storage';

export class WindowLevel { // Class will be serialized in local storage, don't add methods.
    public ww: number;
    public wc: number;
    constructor(ww: number, wc: number) {
        this.ww = ww;
        this.wc = wc;
    }
}

export const PresetSoftTissue = new WindowLevel(400, 20);
export const DefaultWindowLevelPresets: {[name: string]: WindowLevel} = {
    "CT Brainstem": new WindowLevel(82, 33),
    "CT Body": new WindowLevel(1350, -100),
    "CT Bone": new WindowLevel(2000, 300),
    "CT Lung": new WindowLevel(1600, -600),
    "CT Soft tissue": PresetSoftTissue,
    "MRI dix-w: 225/105": new WindowLevel(225, 105),
    "MRI dix-ip: 385/190": new WindowLevel(385, 190),
}

export function getWindowLevelPresets(): {[name: string]: WindowLevel} {
    let presets = localStorage.getWindowLevelPresets() || {};
    if( Object.keys(presets).length === 0) {
        presets = Object.assign(presets, DefaultWindowLevelPresets);
    }
    return presets;
}

export function setWindowLevelPreset(name: string, windowLevel: WindowLevel) {
    let presets = getWindowLevelPresets();
    presets[name] = windowLevel;
    localStorage.setWindowLevelPresets(presets);
}

export function deleteWindowLevelPreset(name: string) {
    let presets = getWindowLevelPresets();
    delete presets[name];
    localStorage.setWindowLevelPresets(presets);
}
