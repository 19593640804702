import _ from "lodash";
import { AzureFileInfo } from "../web-apis/azure-files";
import Immerable from "./immerable";
import { LiveReviewQueryV2 } from "./live-review-query";


export interface DownloadKeyTrackable {
    downloadKey: string;
}

export interface AzureFileShareRequest extends DownloadKeyTrackable {
    fileInfo: AzureFileInfo;
}

export interface ApiFileShareRequest extends DownloadKeyTrackable {
    path: string;
}


export function instanceOfAzureFileShareRequest(object: object): object is AzureFileShareRequest {
    return _.isObject(object) && 'downloadKey' in object && 'fileInfo' in object && (object as any).fileInfo instanceof AzureFileInfo;
}

export function instanceOfApiFileShareRequest(object: any): object is ApiFileShareRequest {
    return _.isObject(object) && 'downloadKey' in object && 'path' in object && _.isString((object as any).path);
}


export class AzureFileRequest extends Immerable implements AzureFileShareRequest {
    fileInfo: AzureFileInfo;
    downloadKey: string;

    constructor(fileInfo: AzureFileInfo, downloadKey: string) {
        super();

        this.fileInfo = fileInfo;
        this.downloadKey = downloadKey;
    }
}

export class LiveReviewV1FileRequest extends Immerable implements AzureFileShareRequest {
    fileInfo: AzureFileInfo;
    downloadKey: string;

    constructor(fileInfo: AzureFileInfo) {
        super();

        this.fileInfo = fileInfo;
        this.downloadKey = '';
    }
}

export class LiveReviewV2FileRequest extends Immerable implements ApiFileShareRequest {
    path: string;
    downloadKey: string;
    query: LiveReviewQueryV2;

    constructor(path: string, downloadKey: string, query: LiveReviewQueryV2) {
        super();

        this.path = path;
        this.downloadKey = downloadKey;
        this.query = query;

    }
}
