import './UnsupportedBrowserPage.css';
import React from 'react';
import { getAppName } from '../environments';



type OwnProps = {
}

type AllProps = OwnProps;

type OwnState = {
}


export class UnsupportedBrowserPage extends React.Component<AllProps, OwnState> {
    displayName = UnsupportedBrowserPage.name

    constructor(props: AllProps) {
        super(props);

        this.state = {
        };
    }


    render() {
        return (
            <div className="unsupported-container">
                <h3>{getAppName()}</h3>
                <br/>
                <p className="unsupported-error">
                    Your browser is not working! Please check if
                    <ul>
                        <li>You are using a browser that supports WebGL2. Recommended browser is Google Chrome</li>
                        <li>WebGL may be lost. You can find more information on the error <a href="https://developer.mozilla.org/en-US/docs/Web/API/WebGLRenderingContext/isContextLost#usage_notes">here</a>. Try refreshing/restarting the browser</li>
                    </ul>
                </p>
                
            </div>);
    }
}


