import { ViewManager } from "../../view-manager";
import { BoundingBox } from "../../../math/bounding-box";
import * as sdfTexture from './sdf-texture';

export class VectorField {
    viewManager: ViewManager;
    data: WebGLTexture | null;
    size: number[];
    boundingBox: BoundingBox;
    resolutionMm: number;
    maxDistanceMm: number;

    constructor(vm: ViewManager, resolutionMm: number, maxDistanceMm: number) {
        this.viewManager = vm;
        this.data = null;
        this.size = [0,0,0];
        this.boundingBox = new BoundingBox();
        this.maxDistanceMm = maxDistanceMm;
        this.resolutionMm = resolutionMm;
    }

    createTexture(bb: BoundingBox) {
        let vm = this.viewManager;
        let gl = vm.getWebGlContext();
        this.boundingBox = bb.copy();
        this.size = [
            Math.round(bb.getXSize() / this.resolutionMm),
            Math.round(bb.getYSize() / this.resolutionMm),
            Math.round(bb.getZSize() / vm.image.kSpacing) 
        ];
        this.data = sdfTexture.createVectorFieldTexture(gl, this.size);
        this.clearTexture();
    }

    clearTexture() {
        let gl = this.viewManager.getWebGlContext();
        const fb = gl.createFramebuffer();
        gl.bindFramebuffer(gl.FRAMEBUFFER, fb);
        for(let z = 0; z < this.size[2]; ++z) {
            gl.framebufferTextureLayer(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, this.data, 0, z);
            gl.clearColor(1.0, 1.0, 1.0, 1.0);
            gl.clear(gl.COLOR_BUFFER_BIT);
        }
        gl.bindFramebuffer(gl.FRAMEBUFFER, null);
        gl.deleteFramebuffer(fb);
    }
    
}