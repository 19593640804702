// Toolbar above the dicom viewer grid

import React from 'react';
import { Row, ButtonToolbar, ToggleButton, ToggleButtonGroup, Button, ButtonGroup, Container } from 'react-bootstrap';
import { StoreState } from '../../../store/store';
import { connect } from 'react-redux';
import * as sagas from '../../../store/sagas';
import { ViewerState } from '../../../rtviewer-core/viewer-state';
import BooleanOperationsToolbar from './contouring-toolbars/BooleanOperationsToolbar';
import { Checkbox } from '../../misc-components';
import { Axis } from '../../../rtviewer-core/view';

import './ContouringToolbar.css';
import { mouseTools } from '../../../rtviewer-core/mouse-tools/mouse-tools';
import { Roi } from '../../../dicom/structure-set';
import { FiAlertCircle } from "react-icons/fi";
import { IconContext } from 'react-icons';


type OwnProps = {
    viewerState: ViewerState,
}

type DispatchProps = {
}

type AllProps = OwnProps & StoreState & DispatchProps;

type OwnState = {
    refreshSwitch?: any,
    brushWidthMm: string | null,
    slicesToKeep: number,
    slicesToKeepOnRange: number,
    firstOperandSlicesToClear: number,
    secondOperandSlicesToClear: number,
    clearOutsideEveryNthRange: boolean,
}

export enum ContouringMenu {
    Line, Brush, Boolean, Crop, BorderMove, Deform, Margin, Smoothing, Clear
}

class ContouringToolbar extends React.Component<AllProps, OwnState> {
    displayName = ContouringToolbar.name

    constructor(props: AllProps) {
        super(props);
        this.state = {
            brushWidthMm: null,
            slicesToKeep: 2,
            slicesToKeepOnRange: 2,
            firstOperandSlicesToClear: 1,
            secondOperandSlicesToClear: this.props.viewerState.image.sliceIds.length,
            clearOutsideEveryNthRange: false,
        };
    }

    componentDidMount() {
        this.props.viewerState.addListener(this.updateView);
    }

    componentWillUnmount() {
        this.props.viewerState.removeListener(this.updateView);
    }
    
    updateView = () => {
        this.setState({refreshSwitch: !this.state.refreshSwitch});
    }

    handleUndo = () => {
        const vs = this.props.viewerState;
        const ss = vs.selectedStructureSet;
        const roi = vs.selectedRoi;
        if(ss && roi) {
            vs.undoStack.undo(roi);
        }
    }

    handleRedo = () => {
        const vs = this.props.viewerState;
        const ss = vs.selectedStructureSet;
        const roi = vs.selectedRoi;
        if(ss && roi) {
            vs.undoStack.redo(roi);
        }
    }

    handleClearContoursForRoi = (currentSliceOnly: boolean) => {
        const vs = this.props.viewerState;
        const vm = vs.viewManager;
        const ss = vs.selectedStructureSet;
        const roi = vs.selectedRoi;
        if(!ss || !roi) return;
        vs.undoStack.pushRoiStateBeforeEdit(roi);
        if(currentSliceOnly) {
            const img = vs.image;
            const z = vm.getScrolledSlice(Axis.Z);
            const sliceId = img.sliceIds[z];
            ss.clearSdf(roi, sliceId);
        }
        else {
            ss.clearSdf(roi, null);
        }
        // Update brush buffer if brush tool is selected
        if( vs.activeMouseTools.includes(mouseTools.brush) ) {
            mouseTools.brush.createDrawBuffer();
        }

        vs.notifyListeners();
    }

    handleKeepEveryNthSliceOthersClear = () => {
        const vs = this.props.viewerState;
        const img = vs.image;
        const sliceIds = img.sliceIds;
        const rangeStart = 0;
        const rangeEnd = sliceIds.length - 1;
        this.handleKeepEveryNthSliceOthersClearOnRange(rangeStart, rangeEnd, this.state.slicesToKeep);
    }

    handleKeepEveryNthSliceOthersClearOnRange = (rangeStart?: number, rangeEnd?: number, slicesToKeep?: number) => {
        const rangeEndcapA = rangeStart !== undefined ? rangeStart : this.state.firstOperandSlicesToClear;
        const rangeEndcapB = rangeEnd !== undefined ? rangeEnd : this.state.secondOperandSlicesToClear;
        const keepEveryNth = slicesToKeep !== undefined ? slicesToKeep : this.state.slicesToKeepOnRange;
        const vs = this.props.viewerState;
        const ss = vs.selectedStructureSet;
        const roi = vs.selectedRoi;

        if (!ss || !roi || rangeEndcapA === rangeEndcapB || !roi.sdf) { return; }

        // get all slices that have this roi's contours in them so we can figure out the current contour endcaps
        const sliceIdsWithContours = roi.sdf.findSlicesWithContours();
        if (sliceIdsWithContours.length === 0) { return; }
        const firstSlice = sliceIdsWithContours[0];
        const lastSlice = sliceIdsWithContours[sliceIdsWithContours.length - 1];

        // always retain contour and range endcaps
        const retainedSlices = [firstSlice, lastSlice, rangeEndcapA, rangeEndcapB];

        vs.undoStack.pushRoiStateBeforeEdit(roi);
        const sliceIds = vs.image.sliceIds;
        const sdfRangeLength = Math.abs(rangeEndcapA - rangeEndcapB) + 1;
        const operandToIncrement = Math.min(rangeEndcapA, rangeEndcapB);
        const sdfRange = Array.from({ length: sdfRangeLength }, (_, i) => operandToIncrement + i);
        sliceIds.forEach((sliceId, idx) => {
            const formattedIdx = idx + 1;
            if (sdfRange.includes(idx)) {
                if (formattedIdx % keepEveryNth !== 0 && !retainedSlices.includes(idx)) { ss.clearSdf(roi, sliceId); }
            } else {
                if (this.state.clearOutsideEveryNthRange) { ss.clearSdf(roi, sliceId); }
            }
        });
        // Update brush buffer if brush tool is selected
        if( vs.activeMouseTools.includes(mouseTools.brush) ) {
            mouseTools.brush.createDrawBuffer();
        }
        vs.notifyListeners();
    }

    handleBrushWidthChanged = (event: any) => {
        let val = event.target.value;
        let num = parseFloat(val);
        this.setState({brushWidthMm: val});
        if(!isNaN(num)) {
            this.props.viewerState.setBrushWidth(num);
        }
    }

    populateRangeBoundsByOperands = (operand: 'first' | 'second') => {
        const vs = this.props.viewerState;
        const currentSliceId = vs.viewManager.views[0].slice
        if (operand === "first") {
            this.setState({firstOperandSlicesToClear: currentSliceId})
        } else if (operand === "second") {
            this.setState({secondOperandSlicesToClear: currentSliceId})
        }
    }

    renderSelectRoiNotification = () => {
        return (<span className="select-a-roi-notification">Select a structure to start editing</span>);
    }
    renderBrushToolbar = () => {
        let vs = this.props.viewerState;
        let brushWidthMm = this.state.brushWidthMm !== null ? this.state.brushWidthMm : vs.brushWidthMm;
        return (
            <Row className="brush-toolbar">
                {vs.selectedRoi ? 
                <>
                    <div className="brush-width-label">Brush width (mm):</div>
                    <input className="brush-width-value" title="Brush width (Shift+up/down)" type="text" value={brushWidthMm} 
                        onChange={this.handleBrushWidthChanged} onBlur={(event) => this.setState({brushWidthMm: null})}/>
                    <Checkbox
                        label={"Erase (Alt to toggle)"}
                        isSelected={vs.erase}
                        onCheckboxChange={(evt: any) => vs.setErase(evt.target.checked)}/>
                </>
                : this.renderSelectRoiNotification()}
            </Row>
        );
    }

    renderLineToolbar = () => {
        let vs = this.props.viewerState;
        return (
            <Row className="line-toolbar">
                {vs.selectedRoi ? 
                    <Checkbox
                    label={"Erase (Alt to toggle)"}
                    isSelected={vs.erase}
                    onCheckboxChange={(evt: any) => vs.setErase(evt.target.checked)}/>
                : this.renderSelectRoiNotification()}
            </Row>
        );
    }

    renderClearToolbar = (roi: Roi | null) => {
        return (
            <>
                <Container className="clear-toolbar">
                    {roi ? (<div className='clear-contour-btns-wrapper'>
                        <Button variant="light" className="btn btn-default btn-sm clear-contour-btn" onClick={() => {this.handleClearContoursForRoi(true)}}>{"Clear " +  roi.name + " contours on current slice"}</Button>
                        <Button variant="light" className="btn btn-default btn-sm clear-contour-btn" onClick={() => {this.handleClearContoursForRoi(false)}}>{"Clear " + roi.name + " contours on all slices"}</Button>
                    </div>) : (
                        <div className="clear-tool-select-roi">Select a structure to use the Clear tool <IconContext.Provider value={{ size: '17px', className: "clear-tool-select-roi-icon" }}><FiAlertCircle /></IconContext.Provider></div>
                    )}

                    <p className="clear-nth-el-label">
                        <span className='keep-every-nth-input-wrapper'>
                            <label className='keep-every-nth-input-label' htmlFor="clearInputNumber">Keep every n-th slice:</label> 
                            <span className="keep-every-nth-input-container">
                                <input id="clearInputNumber" className="keep-every-nth-number" type="number" onChange={(event) => this.setState({slicesToKeep: parseInt(event.target.value)})} min={2} defaultValue={this.state.slicesToKeep}/>
                            </span>
                        </span>
                        <span className='clear-apply-btn-wrapper'>
                            <Button variant="light" className="btn btn-default btn-sm clear-apply-btn" disabled={!roi} onClick={() => {this.handleKeepEveryNthSliceOthersClear()}}>Apply operation</Button>
                        </span>
                    </p>
                    <p className="keep-every-nth">
                        <span className='keep-every-nth-input-wrapper'>
                            <label className='keep-every-nth-input-label' htmlFor="clearInputNumber">Keep every n-th slice:</label> 
                            <span className="keep-every-nth-input-container">
                                <input id="clearInputNumber" className="keep-every-nth-number" type="number" onChange={(event) => this.setState({slicesToKeepOnRange: parseInt(event.target.value)})} min={2} defaultValue={this.state.slicesToKeep}/>
                            </span>
                            <span className="keep-every-nth-input-container">
                                on the range from
                            </span>
                            <div className='keep-every-nth-btn-wrapper'>
                                <input className="keep-every-nth-number" type="number" onChange={(event) => this.setState({firstOperandSlicesToClear: parseInt(event.target.value)})} min={1} defaultValue={this.state.firstOperandSlicesToClear} value={this.state.firstOperandSlicesToClear}/>
                                <Button variant="dark" className="btn btn-default btn-sm keep-every-nth-current-slice-btn" onClick={() => {this.populateRangeBoundsByOperands("first")}}>Set current slice</Button>
                            </div>
                            <span className="keep-every-nth-input-container">
                                to
                            </span>
                            <div className='keep-every-nth-btn-wrapper'>    
                                <input className="keep-every-nth-number" type="number" onChange={(event) => this.setState({secondOperandSlicesToClear: parseInt(event.target.value)})} max={this.props.viewerState.image.sliceIds.length} defaultValue={this.state.secondOperandSlicesToClear} value={this.state.secondOperandSlicesToClear}/>
                                <Button variant="dark" className="btn btn-default btn-sm keep-every-nth-current-slice-btn" onClick={() => {this.populateRangeBoundsByOperands("second")}}>Set current slice</Button>
                            </div>
                        </span>
                        <span className="keep-every-nth-clear-outside-range-container">
                            <Checkbox
                                label={"Clear outside range"}
                                title="Clears contours outside the selected range if checked, keeps them if unchecked."
                                isSelected={this.state.clearOutsideEveryNthRange}
                                onCheckboxChange={() => this.setState({ clearOutsideEveryNthRange: !this.state.clearOutsideEveryNthRange })}/>
                        </span>
                        <span className='clear-apply-btn-wrapper'>
                            <Button variant="light" className="btn btn-default btn-sm clear-apply-btn" disabled={!roi} onClick={() => {this.handleKeepEveryNthSliceOthersClearOnRange()}}>Apply operation</Button>
                        </span>
                    </p>
                </Container>
            </>
        )
    }

    render() {
        const vs = this.props.viewerState;
        const roi = vs.selectedRoi;
        const selectedContouringTool = vs.contouringMenuSelection;

        // Line, Brush, Boolean, Crop, BorderMove, Deform, Margin, Smoothing
        return(
            <div>
                <Row className="contouring-tools toolbar"> 
                    <ButtonToolbar className="image-toolbar">
                        <ToggleButtonGroup  className="mr-2" type="radio" name="options" value={vs.contouringMenuSelection} onChange={(val: ContouringMenu) => vs.setContouringMenuSelection(val)}>
                            <ToggleButton variant="light" className="btn btn-default btn-sm" value={ContouringMenu.Line} >Line draw</ToggleButton>
                            <ToggleButton variant="light" className="btn btn-default btn-sm" value={ContouringMenu.Brush} >Brush</ToggleButton>
                            <ToggleButton variant="light" className="btn btn-default btn-sm" value={ContouringMenu.Boolean} >Boolean</ToggleButton>
                            {/* <ToggleButton variant="light" className="btn btn-default btn-sm" value={ContouringMenu.Crop} >Crop</ToggleButton>
                            <ToggleButton variant="light" className="btn btn-default btn-sm" value={ContouringMenu.BorderMove} >Border move</ToggleButton>
                            <ToggleButton variant="light" className="btn btn-default btn-sm" value={ContouringMenu.Deform} >Deform</ToggleButton>
                            <ToggleButton variant="light" className="btn btn-default btn-sm" value={ContouringMenu.Margin} >Margin</ToggleButton>
                            <ToggleButton variant="light" className="btn btn-default btn-sm" value={ContouringMenu.Smoothing} >Smoothing</ToggleButton>  */}
                            <ToggleButton variant="light" className="btn btn-default btn-sm" value={ContouringMenu.Clear} >Clear</ToggleButton>
                        </ToggleButtonGroup>    

                        {roi && (vs.undoStack.canUndo(roi) || vs.undoStack.canRedo(roi)) ? <ButtonGroup className="contouring-undo-buttons">
                            <Button disabled={!vs.undoStack.canUndo(roi)} variant="light" size="sm" onClick={this.handleUndo}>Undo</Button>
                            <Button disabled={!vs.undoStack.canRedo(roi)} variant="light" size="sm" onClick={this.handleRedo}>Redo</Button>
                        </ButtonGroup> : null }
                    </ButtonToolbar>
                </Row>
                <Row className="toolbar">
                    { selectedContouringTool === ContouringMenu.Boolean && <BooleanOperationsToolbar viewerState={vs} /> }
                    { selectedContouringTool === ContouringMenu.Brush && this.renderBrushToolbar()}
                    { selectedContouringTool === ContouringMenu.Line && this.renderLineToolbar()}
                    { selectedContouringTool === ContouringMenu.Clear && this.renderClearToolbar(roi)}
                </Row>
            </div>
        );
    }
}

export default connect(
    state => Object.assign({}, state),
    sagas.mapDispatchToProps
)(ContouringToolbar);