// A select component (a dropdown) for selecting a ROI from a list

// TODO:
// - does not support a "new ROI..." option (for "target ROI" selection)
// - does not support a default selection option
// - could highlight the hovered or selected ROI in the canvas (but doesn't)
// - does not support illegal options (in case some boolean operations aren't compatible with all selections, e.g. choosing the same ROI for all actions)
// - pick ROIs from ROITable?
// - pick ROIs from canvas?

import React from 'react';
import Select from 'react-select';

import './RoiSelect.css';

type OwnProps = {
  options: RoiOption[],
  onChange: (option: RoiOption) => void,
  label: string,
}

type DispatchProps = {
}

type AllProps = OwnProps & DispatchProps;

type OwnState = {
  refreshSwitch?: any,
}

export class RoiOption {
  public label: string;
  public value: string;

  constructor (label: string, value: string) {
    this.label = label;
    this.value = value;
  }
}

class RoiSelect extends React.Component<AllProps, OwnState> {

  // override some styles in react-select. rest of the overriding is done in the css file
  static selectStyles = {
    container: (provided: any) => ({
      ...provided,
      display: "inline-block",
      minWidth: "220px"
    }),
    control: (provided: any) => ({
      ...provided,
      minHeight: "unset"
    }),
    indicatorsContainer: (provided: any) => ({
      ...provided,
      height: "unset"
    }),
    clearIndicator: (provided: any) => ({
      ...provided,
      padding: "0 5px"
    }),
    dropdownIndicator: (provided: any) => ({
      ...provided,
      padding: "0 5px"
    })
  };

  handleChange = (option: any) => {
    this.props.onChange(option);
  }


  render() {
    const { options, label } = this.props;

    return (
      <span className="roi-select">
        <label>{label}: 
          <Select
            styles={RoiSelect.selectStyles}
            options={options}
            onChange={this.handleChange}
            isClearable={true}
          />
        </label>
      </span>
    );
  }
}

export default RoiSelect;