/* Structure set table */

import './StructureSetTable.css';
import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Button, Modal } from 'react-bootstrap';
import { Menu, Item, Submenu, Separator, contextMenu } from 'react-contexify';

import * as sagas from '../../store/sagas';
import { NewItemGlyph } from '../glyphs';
import * as structureSet from '../../dicom/structure-set';
import { Image } from '../../dicom/image';
import * as guid from '../../dicom/guid';
import * as datasetFiles from '../../datasets/dataset-files';
import { ContouringTask, ContouringTaskState } from '../../store/contouring-task';
import { ViewerState } from '../../rtviewer-core/viewer-state';
import * as grading from '../../datasets/roi-grading'
import { ApprovalIndicator } from '../misc-components';
import ContouringRequest from './ContouringRequest';
import { StoreState } from '../../store/store';
import { sleep } from '../../util';
import { mouseTools } from '../../rtviewer-core/mouse-tools/mouse-tools';
import DeleteStructureSetDialog from "./dialogs/DeleteStructureSetDialog";
import { AzureFileInfo } from '../../web-apis/azure-files';
import { DatasetImage } from '../../datasets/dataset-image';
import { Dataset } from '../../datasets/dataset';
import WorkState from '../../store/work-state';
import { isRutherford, isDemo } from '../../environments';
import { Backend } from '../../web-apis/backends';
import { backends } from '../../web-apis/auth';
import { convertWorkflowStateToText } from '../../datasets/roi-grading';
import ModalDialog from '../common/ModalDialog';
import { stopKeyboardPropagation } from '../../react-util';
import { User } from '../../store/user';
import BackupManager, { LatestRestoredBackups } from './dialogs/BackupManager';
import Workspace from '../../store/workspace';

type OwnProps = {
    viewerState: ViewerState,
    structureSets: structureSet.StructureSet[],
    datasetImage?: DatasetImage,
    newStructureSets: string[],
    allDatasetGradings: grading.DatasetGradings | null,
    openAddStructuresFromTemplateDialog: () => void,
    onAddRoiClick: (ss: structureSet.StructureSet) => void,
    throwError: (msg: string) => void,
    doGradingSync: (ss: structureSet.StructureSet) => void,
}

type DispatchProps = {
    undoStructureSetChanges(ss: structureSet.StructureSet): structureSet.StructureSet | null,
    deleteStructureSet: (structureSet: structureSet.StructureSet, dataset?: Dataset | null, grading?: grading.StructureSetGrading | undefined) => void,
    storeNewStructureSet(structureSet: structureSet.StructureSet): void,
    seenStructureSet(structureSetId: string): void,
    dismissContouringTask: (scanId: string) => void,
    handleUndoStructureSetChangesClick: (ss: structureSet.StructureSet) => void,
    handleNewStructureSetClick: () => void,
    saveGrading: (structureSetId: string, ssGrading: grading.StructureSetGrading | null, dataset: Dataset) => void,
    updateCurrentWorkState: (updatedWorkStateProps: Partial<WorkState>) => void,
    exportStructureSet: (structureSet: structureSet.StructureSet, img: Image, grading: grading.StructureSetGrading | undefined, vs: ViewerState, 
        task: datasetFiles.ExportTask | null, backend: Backend, originalDicomFile?: { file: ArrayBuffer, filename: string }, buildId?: string) => void,
    requestSaveScanToUserDisk: (image: Image, anonymizeScan: boolean) => void,
}

type OwnState = {
    refreshSwitch?: any,
    renameStructureSetId?: string | null,
    newLabel?: string,
    showDeleteStructureSetDialog: boolean,
    showConfirmExportDialog: boolean,
    originalDicomFile: { file: ArrayBuffer, filename: string } | null,
    isBackupManagerVisible: boolean;
    backupManagerStructureSet: structureSet.StructureSet | undefined;
    
    // store a collection of backups we have restored just recently so we can
    // show a checkmark next to them in the BackupManager component
    restoredBackups: LatestRestoredBackups;
}

type AllProps = OwnProps & StoreState & DispatchProps;

class StructureSetTable extends React.Component<AllProps, OwnState> {
    
    constructor(props: AllProps) {
        super(props);
        this.state = { 
            showDeleteStructureSetDialog: false, 
            showConfirmExportDialog: false,
            originalDicomFile: null,
            isBackupManagerVisible: false,
            backupManagerStructureSet: undefined,
            restoredBackups: {},
        };
    }

    componentDidMount() {
        const vs = this.props.viewerState;
        vs.addListener(this.updateView);
        this.updateView();
    }

    componentWillUnmount() {
        const vs = this.props.viewerState;
        vs.removeListener(this.updateView);
    }
 
    updateView = () => {
        this.setState({refreshSwitch: !this.state.refreshSwitch});
    }

    // set a given filename as the latest restored backup for the given structure set
    addRestoredBackup = (structureSetId: string, filename: string) => {
        const backups = _.cloneDeep(this.state.restoredBackups);
        backups[structureSetId] = filename;
        this.setState({ restoredBackups: backups });
    }

    getAppVersionCommit() {
        return _.get(this.props, 'appVersion.commit', '');
    }

    handleStructureSetLabelKeyPress = (event: any, ss: structureSet.StructureSet) => {
        // prevent keypresses while renaming the structure set from interacting
        // with the rest of the application
        stopKeyboardPropagation(event);

        if(event.keyCode === 13){// Enter
            ss.dataset.StructureSetLabel = event.target.value;
            ss.unsaved = true;
            this.setState({renameStructureSetId: null});
        }
        else if(event.keyCode === 27)  {// Esc
            this.setState({renameStructureSetId: null});
        }
    }

    handleStructureSetLabelChanged = (event: any, ss: structureSet.StructureSet) => {
        this.setState({newLabel: event.target.value})
    }

    handleRenameStructureSetClick = (ss: structureSet.StructureSet) => {
        this.setState({renameStructureSetId: ss.structureSetId, newLabel: ss.dataset.StructureSetLabel}, function() {
            let arr = document.getElementsByClassName("structure-set-label-edit");
            (arr[arr.length - 1] as HTMLElement).focus();
        });
    }

    handleAddGradingClick = (ss: structureSet.StructureSet) => {
        const vs = this.props.viewerState;
        const g = new grading.StructureSetGrading();
        this.props.saveGrading(ss.structureSetId, g, this.props.currentWorkState.dataset);
        vs.sizeChanged();
    }

    handleDeleteGradingClick = (ss: structureSet.StructureSet) => {
        const vs = this.props.viewerState;
        this.props.saveGrading(ss.structureSetId, null, this.props.currentWorkState.dataset);
        vs.sizeChanged();
    }

    handleSetApprovalStatus = (ss: structureSet.StructureSet, approvalStatus: string) => {
        const vs = this.props.viewerState;
        ss.dataset.ApprovalStatus = approvalStatus;
        ss.unsaved = true;
        vs.notifyListeners();
    }

    handleAddRoiClick = (ss: structureSet.StructureSet) => {
        this.props.onAddRoiClick(ss);
    }

    handleDeleteStructureSetDialogClick = () => {
        this.setState({ showDeleteStructureSetDialog: true });
    };
    
    handleCloseDeleteStructureSetDialog = () => {
        this.setState({ showDeleteStructureSetDialog: false });
    };

    handleExportClick = (originalDicomFile?: { file: ArrayBuffer, filename: string }) => {
        this.setState({ showConfirmExportDialog: true, originalDicomFile: originalDicomFile || null  });
    }

    handleDownloadScanClick = (anonymizeScan: boolean) => {
        this.props.requestSaveScanToUserDisk(this.props.viewerState.image, anonymizeScan);
    }

    handleCloseExportConfirmationDialog = () => {
        this.setState({ showConfirmExportDialog: false });
    }

    handleShowBackupManagerClick = (ss: structureSet.StructureSet) => {
        this.setState({ backupManagerStructureSet: ss, isBackupManagerVisible: true });
    }

    closeBackupManager = () => {
        this.setState({ backupManagerStructureSet: undefined, isBackupManagerVisible: false });
    }

    /** Export current structure set to backend */
    handleConfirmExport = () => {
        this.handleCloseExportConfirmationDialog();
        const vs = this.props.viewerState;
        const structureSet = vs.selectedStructureSet;
        if (structureSet !== null) {
            const allGradings = this.props.allDatasetGradings;
            const ssGrading = allGradings ? allGradings.structureSets[structureSet.structureSetId] : undefined;
            const task: datasetFiles.ExportTask | null = _.get(this.props.currentWorkState.dataset.metaFiles.exportDestination, 
                [structureSet.patientId, this.props.currentWorkState.datasetImage.seriesId], null);
            this.props.exportStructureSet(structureSet, vs.image, ssGrading, vs, task, this.props.user.currentBackend || backends.getDefaultBackend(), this.state.originalDicomFile || undefined, this.getAppVersionCommit());
        }
    }

    handleAddRoisFromTemplateOpenModalClick = () => {
        this.props.openAddStructuresFromTemplateDialog();
    }

    handlePasteRoiClick = (ss: structureSet.StructureSet) => {
        const vs = this.props.viewerState;
        const vm = vs.viewManager;
        const rois = vs.copiedRois;
        if(!rois || rois[0].structureSet === ss) return;

        // this shouldn't happen but do a sanity check here
        if (!rois.every(r => r.structureSet === rois[0].structureSet)) {
            throw new Error(`All copied ROIs must be from the same structure set!`);
        }

        setTimeout(() => { 

            let lastNewRoi: structureSet.Roi | undefined = undefined;
            for (const roi of rois) {
                const overwrite = ss.getRois().some(r => r.name === roi.name) 
                    && window.confirm(`Structure set already contains a structure called '${roi.name}'. Do you want to overwrite it? 'OK' to overwrite, 'Cancel' to add the structure as a new copy.`);

                    lastNewRoi = ss.duplicateRoiFromOtherStructureSet(vm, roi, overwrite);
            }

            if(lastNewRoi !== undefined) {
                this.props.doGradingSync(ss);
                vs.roisChanged(ss);
                if(vs.activeMouseTools.includes(mouseTools.brush)) mouseTools.brush.createDrawBuffer();
                setTimeout(function() { 
                    vs.setSelectedRoi(lastNewRoi!);
                    vs.setRoiHidden(lastNewRoi!, false);
                    vs.notifyListeners();
                }, 50);
            }
        }, 50);
    }

    handleDuplicateStructureSetClick = async (ss: structureSet.StructureSet, checkedOnly: boolean) => {
        const vs = this.props.viewerState;

        ss.modalMessage = structureSet.StructureSetModalMessages.Duplicating;
        vs.notifyListeners();
        await sleep(50); // Ensure that the modal is shown before ui hangs.

        const ssNew = structureSet.duplicateStructureSet(ss, vs, this.getAppVersionCommit());
        if (!ssNew) { return; }

        if(this.props.datasetImage){
            // This should not be needed but there are some auto-contoured structure sets in the datasets where the patientId is changed 
            // in anonymization and will lead to attempt to save the structureset to  path /ANON/... 
            ssNew.dataset.PatientID = this.props.datasetImage.patientId;
        }
        if(ss.azureFileInfo) {
            ssNew.azureFileInfo = this.props.datasetImage ?
            datasetFiles.getStructureSetFileInfo(ss.azureFileInfo.getShare(), ssNew.dataset.PatientID, ssNew.frameOfReferenceUid, ssNew.seriesUid, ssNew.structureSetId)
            : new AzureFileInfo( // On live review page
                ss.azureFileInfo.storageAccountName,
                ss.azureFileInfo.fileShareName, 
                'live-review-edit-RTSTRUCT-' + ssNew.seriesUid, 
                ssNew.structureSetId + ".dcm");
        }
        ssNew.unsaved = true;
        ssNew.existsInAzure = false;
        if(checkedOnly) {
            if(vs.hiddenRois[ss.structureSetId])
                ssNew.deleteRois(Array.from(vs.hiddenRois[ss.structureSetId]));
        }
        this.props.storeNewStructureSet(ssNew);
        ss.modalMessage = null;
        setTimeout(function() { vs.setSelectedStructureSet(ssNew, vs.image); }, 50);// Original click event would select the original roi, call this delayed
    }

    handleViewDicomClick = (ss: structureSet.StructureSet) => {
        structureSet.printDicomToConsole(ss);
    }

    handleViewImageClick = () => {
        console.log(this.props.viewerState.image.dicomTags);
    }
    

    deleteStructureSet = (ss: structureSet.StructureSet) => {
        const vs = this.props.viewerState;
        ss.deleted = true;
        ss.unsaved = true;
        if (!ss.existsInAzure) {
            this.props.deleteStructureSet(ss);
        }

        const ssList = this.props.structureSets.filter(s => !s.deleted);
        const existingSs = ssList.length > 0 ? ssList[0] : null;
        setTimeout(function() { vs.setSelectedStructureSet(existingSs, vs.image); }, 50);
        this.handleCloseDeleteStructureSetDialog();
    }

    setStructureSetAsSeen = (structureSetId: string) => {
        this.props.seenStructureSet(structureSetId);
    }

    getContouringTasks = (): ContouringTask[] => {
        const { contouringTasks } = this.props;
        const inProgressTasks:ContouringTask[] = [];
        Object.keys(contouringTasks).forEach(k => {
            const task = contouringTasks[k];
            if (task.contouringState !== ContouringTaskState.Success && !task.isDismissed) {
                inProgressTasks.push(task);
            }});
        return inProgressTasks;
    }

    handleSelectStructureSet(ss: structureSet.StructureSet) {
        const vs = this.props.viewerState;
        const image = vs.image;
        vs.setSelectedStructureSet(ss, image);

        // update current work state
        this.props.updateCurrentWorkState({ structureSetId: ss.structureSetId });
    }

    handleShowStructureSetContextMenu = (structureSetMenuId: string, evt: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        contextMenu.show({ id: structureSetMenuId, event: evt });
    }
    
    renderStructureSetRow = (ss: structureSet.StructureSet) => {
        const vs = this.props.viewerState;
        const ssId = ss.structureSetId;
        const selected = ss === vs.selectedStructureSet;

        const workspace = this.props.currentWorkspace!;       
        const canEditStructureSet = vs.canEdit && ss.canEdit();

        // allow structure set deletion if
        // * we're in local workspace
        //    AND viewerstate allows edits
        // * OR we're in annotation workspace
        //    AND viewerstate allows edits
        //    AND the current structure set allows deletion with current user permissions
        // * OR we're in any other workspace
        //    AND viewerstate allows edits
        //    AND structure set allows any kind of edits
        const canDeleteStructureSet = (vs.canEdit && workspace === Workspace.Local)
            || (vs.canEdit && workspace === Workspace.Annotation && ss.canDelete(this.props.user && (this.props.user as User).permissions.allowDeletingAutoContouredStructureSets))
            || (vs.canEdit && ss.canEdit());

        const gradings = this.props.allDatasetGradings;
        const ssGrading = gradings ? gradings.structureSets[ssId] : undefined;
        const showAddGrading = workspace === Workspace.Annotation && ssGrading === undefined;
        const showDeleteGrading = workspace === Workspace.Annotation && !showAddGrading;

        let label = ss.dataset.StructureSetLabel;
        if (vs.canEdit && ss.isOriginal) {
            label += " (original, cannot edit)";
        }
        if (isRutherford() && ssGrading !== undefined && (
            // add possible approved/unapproved workflow state to structure set label (rutherford only)
            ssGrading.workflowState === grading.GradingWorkflowState.RutherfordApproved || ssGrading.workflowState === grading.GradingWorkflowState.RutherfordUnapproved)) {
                label += ` (${convertWorkflowStateToText(ssGrading.workflowState)})`;
        }

        let t = this;
        function renderName() {
            if(t.state.renameStructureSetId === ss.structureSetId) {
                return (
                    <input className="structure-set-label-edit" type="text" value={t.state.newLabel} 
                    onChange={(event) => t.handleStructureSetLabelChanged(event, ss)}
                    onKeyDown={(event) => t.handleStructureSetLabelKeyPress(event, ss)}
                    onBlur={(event) => t.setState({renameStructureSetId: null})} />
                    );
            }
            return ss.unsaved ? (<b>{label}</b>) : label;
        }
        if(ss.deleted) { return null; }

        const isNew = this.props.newStructureSets.indexOf(ssId) !== -1;
        const originalDicomFile: { file: ArrayBuffer, filename: string } | undefined = this.props.originalStructureSets[ssId];
        const isOriginalDicomAvailable = originalDicomFile !== undefined;
        const allowStructureSetDownload = (this.props.user && (this.props.user as User).permissions.allowStructureSetDownload) || isDemo();
        const allowOriginalFileOperations = (this.props.user && (this.props.user as User).permissions.allowDebugMode) && isOriginalDicomAvailable;
        const allowScanDownload = this.props.user && (this.props.user as User).permissions.allowScanDownload;
        const allowStructureSetExport = isRutherford();

        // allow backup restore operations if this file is in azure, user has the matching permission and the structure set is not ready for training or expert approved
        const allowBackupRestore = ss.azureFileInfo && this.props.user && (this.props.user as User).permissions.allowBackupRestore;
        const isBackupRestoreEnabled = allowBackupRestore && ss.existsInAzure &&
            (!ssGrading || (ssGrading.workflowState !== grading.GradingWorkflowState.ReadyForTraining && ssGrading.workflowState !== grading.GradingWorkflowState.ExpertApproved));
        const backupManagerLinkTitle = isBackupRestoreEnabled ? undefined : 
            !ss.existsInAzure ? 'Structure set has not yet been saved so backups are not yet available.' : 'Backups cannot be restored for the structure set in its current workflow state.';

        let rowStyles = 'structure-set-row';
        if (selected) { rowStyles += ' structure-set-selected'}
        if (isNew) { rowStyles += ' structure-set-is-new'}

        const pasteRoisText = vs.copiedRois ? vs.copiedRois.length === 1 ? `Paste ${vs.copiedRois[0].name}` : `Paste ${vs.copiedRois.length} structures` : '';
        const buildId = this.getAppVersionCommit();

        return(
            <tr className={rowStyles} key={ssId}
                    onClick={(evt) => { this.handleSelectStructureSet(ss) }}
                    onDoubleClick={(evt) => { if(canEditStructureSet) this.handleRenameStructureSetClick(ss)}}
                    onMouseOver={(evt) => { if (isNew) this.setStructureSetAsSeen(ssId) } }  >
                <td>
                    <ApprovalIndicator approvalStatus={ss.dataset.ApprovalStatus}/>
                    <Menu id={ssId} style={{zIndex: 1000}} animation={false} className="structure-set-context-menu">
                        {(canEditStructureSet && ss.unsaved) ? <Item onClick={(event) => this.props.handleUndoStructureSetChangesClick(ss)}>Undo unsaved changes</Item> : null}
                        { showAddGrading ? <Item onClick={(event) => this.handleAddGradingClick(ss)} disabled={!vs.canEdit}>Add grading sheet</Item> : null }
                        { showDeleteGrading ? <Item onClick={(event) => this.handleDeleteGradingClick(ss)} disabled={!vs.canEdit}>Delete grading</Item> : null }
                        { vs.canEdit && !ss.isOriginal && !isRutherford() && (<Submenu label="Set approval status">
                            <Item onClick={(event) => this.handleSetApprovalStatus(ss, "UNAPPROVED")}>Unapproved</Item>
                            <Item onClick={(event) => this.handleSetApprovalStatus(ss, "APPROVED")}>Approved</Item>
                            <Item onClick={(event) => this.handleSetApprovalStatus(ss, "REJECTED")}>Rejected</Item>
                        </Submenu>) }
                        {canEditStructureSet ? <Item onClick={(event) => this.handleRenameStructureSetClick(ss)}>Rename</Item> : null }
                        {canEditStructureSet ? <Item onClick={(event) => this.handleAddRoiClick(ss)}>Add structure</Item> : null }
                        {canEditStructureSet ? <Item onClick={(event) => this.handleAddRoisFromTemplateOpenModalClick()}>Add structures from template...</Item> : null }
                        {canEditStructureSet && vs.copiedRois && vs.copiedRois[0].structureSet !== ss ? <Item onClick={(event) => this.handlePasteRoiClick(ss)}>{pasteRoisText}</Item> : null }
                        {canEditStructureSet ? <Separator /> : null}
                        {vs.canEdit ? <Item onClick={(event) => this.handleDuplicateStructureSetClick(ss, false)}>Duplicate</Item> : null }
                        {vs.canEdit ? <Item onClick={(event) => this.handleDuplicateStructureSetClick(ss, true)}>Duplicate (checked structures only)</Item> : null}
                        {canDeleteStructureSet && <Item onClick={this.handleDeleteStructureSetDialogClick}>Delete</Item> }
                        <Separator />
                        <Item onClick={(event) => this.handleViewImageClick()}>View DICOM tags in console (image)</Item>
                        <Item onClick={(event) => this.handleViewDicomClick(ss)}>View DICOM tags in console (structure set)</Item>
                        {allowBackupRestore && <Item disabled={!isBackupRestoreEnabled} title={backupManagerLinkTitle} onClick={() => this.handleShowBackupManagerClick(ss)}>Manage backups...</Item>}
                        {allowStructureSetExport && <Item onClick={() => this.handleExportClick()}>Export</Item>}
                        {allowStructureSetExport && allowOriginalFileOperations && <Item onClick={() => this.handleExportClick(originalDicomFile)}>Export original DICOM file (debug)</Item>}
                        {allowStructureSetDownload && <Item onClick={() => structureSet.saveStructureSetOnUserDevice(ss, vs, buildId)}>Download structure set as a DICOM file</Item>}
                        {allowStructureSetDownload && !isDemo() && allowOriginalFileOperations && <Item onClick={() => structureSet.saveStructureSetOnUserDevice(ss, vs, buildId, originalDicomFile)}>Download original structure set DICOM file (debug)</Item>}
                        {allowScanDownload && <Item onClick={() => this.handleDownloadScanClick(false)}>Download scan as a ZIP file</Item>}
                        {allowScanDownload && <Item onClick={() => this.handleDownloadScanClick(true)}>Download scan as a ZIP file (anonymized)</Item>}
                    </Menu>
                </td>
                <td className="structure-set-name">
                    <div onContextMenu={(evt: React.MouseEvent<HTMLDivElement, MouseEvent>) => { this.handleShowStructureSetContextMenu(ssId, evt); }}>
                        {renderName()}
                    </div>
                </td>
                
            </tr>
        );
    }

    render() {
        const { structureSets } = this.props;
        const ssRequiringModal = structureSets.find(ss => ss.modalMessage);
        const modalText = ssRequiringModal ? ssRequiringModal.modalMessage : null;
        const vs = this.props.viewerState;
        const ss = vs.selectedStructureSet;

        const allowScanDownload = this.props.user && (this.props.user as User).permissions.allowScanDownload;

        const isAnyTaskInProgress = this.getContouringTasks().filter(task => task.contouringState !== ContouringTaskState.Error && task.contouringState !== ContouringTaskState.Failed).length > 0
        const newStructureSetBtnTitle = isAnyTaskInProgress ? 'New structure set is being generated...' : ''
        
        return (
            <>
            <div className="section-title" onContextMenu={(evt: React.MouseEvent<HTMLDivElement, MouseEvent>) => { this.handleShowStructureSetContextMenu('structure-sets-header-menu', evt); }}>Structure sets</div>
            <Menu id='structure-sets-header-menu' style={{zIndex: 1000}} animation={false} className="structure-set-context-menu">
                {<Item onClick={(event) => this.handleViewImageClick()}>View DICOM tags in console (image)</Item>}
                {allowScanDownload && <Item onClick={() => this.handleDownloadScanClick(false)}>Download scan as a ZIP file</Item>}
                {allowScanDownload && <Item onClick={() => this.handleDownloadScanClick(true)}>Download scan as a ZIP file (anonymized)</Item>}
            </Menu>
            <table className="structure-set-table">
                <thead>
                    <tr>
                        <th/>
                        <th/>
                    </tr>
                </thead>
                <tbody>
                    {structureSets.map(ss => this.renderStructureSetRow(ss))}
                    {this.getContouringTasks().map(ct => (<ContouringRequest contouringTask={ct} key={ct.scanId} dismissContouringTask={this.props.dismissContouringTask} />))}
                    <tr className="new-structure-set-button">
                        <td colSpan={2}>
                            <Button title={newStructureSetBtnTitle} variant="light" className="btn btn-default btn-sm" onClick={this.props.handleNewStructureSetClick} disabled={!vs.canCreateRtstruct || isAnyTaskInProgress}>
                                <NewItemGlyph /> New structure set...
                            </Button>
                        </td>
                    </tr>
                </tbody>
            </table>

            <ModalDialog show={!!modalText} onHide={() => {}}>
                <Modal.Header>
                    <Modal.Title>Please wait</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modalText}</Modal.Body>
            </ModalDialog>

            {ss && (
                <ModalDialog show={this.state.showConfirmExportDialog} onHide={this.handleCloseExportConfirmationDialog}>
                    <Modal.Header>
                        <Modal.Title>Confirm export</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {ss.unsaved ? (
                            <div>Please save your unsaved changes before exporting.</div>
                        ) : (
                            <div>Are you sure you want to export structure set "{ss.dataset.StructureSetLabel}"?</div>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" disabled={ss.unsaved} onClick={this.handleConfirmExport}>Export</Button>
                        <Button variant="light" onClick={this.handleCloseExportConfirmationDialog}>Cancel</Button>
                    </Modal.Footer>
                </ModalDialog>
            )}

            <DeleteStructureSetDialog
                isVisible={this.state.showDeleteStructureSetDialog}
                onClose={this.handleCloseDeleteStructureSetDialog}
                onStructureSetDelete={this.deleteStructureSet}
                structureSet={ss}
            />

            {this.state.backupManagerStructureSet !== undefined && (
                <BackupManager 
                    isVisible={this.state.isBackupManagerVisible} 
                    structureSet={this.state.backupManagerStructureSet} 
                    onClose={this.closeBackupManager}
                    viewerState={this.props.viewerState}
                    latestRestoredBackupFilename={this.state.restoredBackups[this.state.backupManagerStructureSet.structureSetId]}
                    addRestoredBackup={this.addRestoredBackup}
                />
            )}
            
            </>

            
        );
    }
}

export default connect(
    state => Object.assign({}, state),
    sagas.mapDispatchToProps
)(StructureSetTable);
