import './AddMarginDialog.css';
import { MarginOptions, maxMarginMM } from "../../../rtviewer-core/webgl/sdf/margin/margin";
import { Roi } from "../../../dicom/structure-set";
import React from "react";
import { Checkbox } from '../../misc-components';
import { DropdownButton, Dropdown, Button } from 'react-bootstrap';
import { roiCompare } from '../../../helpers/compare';
import { isRutherford } from '../../../environments';

type OwnProps = {
    roi: Roi,
    apply: (opt: MarginOptions) => void,
    cancel: () => void,
}


type OwnState = {
    sourceRoi: Roi,
    targetRoi: Roi,
    errorMessage: string,
    isSymmetrical: boolean,
    isInner: boolean,
    symmetricalMarginStr: string,
    marginCranialStr: string,
    marginCaudalStr: string,
    marginLeftStr: string,
    marginRightStr: string,
    marginAnteriorStr: string, 
    marginPosteriorStr: string,
}

export class AddMarginDialog extends React.Component<OwnProps, OwnState> {

    constructor(props: OwnProps) {
        super(props);

        const margin = 0;
        const marginStr = margin.toString();
        this.state = { 
            sourceRoi: props.roi, 
            targetRoi: props.roi,
            errorMessage: "",
            isSymmetrical: true,
            isInner: false,
            symmetricalMarginStr: marginStr,
            marginCranialStr: marginStr,
            marginCaudalStr: marginStr,
            marginLeftStr: marginStr,
            marginRightStr: marginStr,
            marginAnteriorStr: marginStr,
            marginPosteriorStr: marginStr,
        };
    }

    setSymmetricalMargin = (text: string) => {
        this.setState({
            symmetricalMarginStr: text,
            marginCranialStr: text,
            marginCaudalStr: text,
            marginLeftStr: text,
            marginRightStr: text,
            marginAnteriorStr: text,
            marginPosteriorStr: text,
        })
    }

    handleApply = () => {
        const marginCranial = Number(this.state.marginCranialStr);
        const marginCaudal = Number(this.state.marginCaudalStr);
        const marginLeft = Number(this.state.marginLeftStr);
        const marginRight = Number(this.state.marginRightStr);
        const marginAnterior = Number(this.state.marginAnteriorStr);
        const marginPosterior = Number(this.state.marginPosteriorStr);
        const margins = [marginCranial, marginCaudal, marginLeft, marginRight, marginAnterior, marginPosterior];
        for(let i = 0; i < margins.length; ++i) {
            const m = margins[i];
            if(isNaN(m)) {
                this.setState({errorMessage: "Margin values must be numeric!"});
                return;
            }
            if( m < 0 || m > maxMarginMM) {
                this.setState({errorMessage: "Margin must be between 0 and " + maxMarginMM + " mm!"});
                return;
            }
        }
        if(margins.every(m => m === 0)) {
            this.props.cancel();
            return;
        }
        const opt = new MarginOptions(this.state.sourceRoi, this.state.targetRoi, this.state.isInner, 
            marginLeft, marginRight, marginCranial, marginCaudal, marginAnterior, marginPosterior );
        
        this.props.apply(opt);
    }

    render = () => {
        if(!this.props.roi) return null;
        const ss = this.props.roi.structureSet;
        
        return (
        <div>
            <div className="margin-source-roi">
                <DropdownButton title={"Source structure: "+ this.state.sourceRoi.name} id="margin-source-roi-dropdown" variant="light" >
                    {
                        ss.getRois().filter(roi => roi.sdf).sort(roiCompare).map((roi, index) =>
                        <Dropdown.Item as="button" key={"source-roi-" + index} 
                            onClick={() => this.setState({sourceRoi: roi}) }>
                                {roi.name}
                        </Dropdown.Item>
                    )}
                </DropdownButton>
            </div>
            <div className="margin-target-roi">
                
                <DropdownButton title={"Target structure: " + this.state.targetRoi.name} id="margin-target-roi-dropdown" variant="light" >
                    {
                        ss.getRois().sort(roiCompare).map((roi, index) =>
                        <Dropdown.Item as="button" key={"target-roi-" + index} 
                            onClick={() => this.setState({targetRoi: roi}) }>
                                {roi.name}
                        </Dropdown.Item>
                    )}
                </DropdownButton>
            </div>

            <div className="margin-checkboxes">
                {/* inner margin is temporarily disabled from rutherford due to bugs */}
                {!isRutherford() && (
                    <Checkbox
                        label={"Create inner margin"}
                        isSelected={this.state.isInner}
                        onCheckboxChange={() => this.setState({isInner: !this.state.isInner})}
                        />
                )}
                <Checkbox
                    label={"Create symmetrical margin"}
                    isSelected={this.state.isSymmetrical}
                    onCheckboxChange={() => this.setState({isSymmetrical: !this.state.isSymmetrical})}
                    />
            </div>

            <div className="margin-inputs">
                {this.state.isSymmetrical ? <div>
                    <span className="margin-label">Margin (mm)</span>
                    <span>
                        <input className="margin-input" type="text" 
                        onChange={(event) => this.setSymmetricalMargin(event.target.value)} 
                        value={this.state.symmetricalMarginStr}></input>
                    </span>
                </div> : <div>

                    <div className="margin-row">
                        <span className="margin-label">Cranial (mm)</span>
                        <span>
                            <input className="margin-input" type="text" 
                            onChange={(event) => this.setState({marginCranialStr: event.target.value})} 
                            value={this.state.marginCranialStr}></input>
                        </span>
                    </div>
                    <div className="margin-row">
                        <span className="margin-label">Caudal (mm)</span>
                        <span>
                            <input className="margin-input" type="text" 
                            onChange={(event) => this.setState({marginCaudalStr: event.target.value})} 
                            value={this.state.marginCaudalStr}></input>
                        </span>
                    </div>

                    <div className="margin-row">
                        <span className="margin-label">Left (mm)</span>
                        <span>
                            <input className="margin-input" type="text" 
                            onChange={(event) => this.setState({marginLeftStr: event.target.value})} 
                            value={this.state.marginLeftStr}></input>
                        </span>
                    </div>
                    <div className="margin-row">
                        <span className="margin-label">Right (mm)</span>
                        <span>
                            <input className="margin-input" type="text" 
                            onChange={(event) => this.setState({marginRightStr: event.target.value})} 
                            value={this.state.marginRightStr}></input>
                        </span>
                    </div>

                    <div className="margin-row">
                        <span className="margin-label">Anterior (mm)</span>
                        <span>
                            <input className="margin-input" type="text" 
                            onChange={(event) => this.setState({marginAnteriorStr: event.target.value})} 
                            value={this.state.marginAnteriorStr}></input>
                        </span>
                    </div>
                    <div className="margin-row">
                        <span className="margin-label">Posterior (mm)</span>
                        <span>
                            <input className="margin-input" type="text" 
                            onChange={(event) => this.setState({marginPosteriorStr: event.target.value})} 
                            value={this.state.marginPosteriorStr}></input>
                        </span>
                    </div>
                </div>}

            </div>
            
            <div className="margin-error">{this.state.errorMessage}</div>
            <div className="margin-dialog-buttons">
                <span>
                    <Button variant="light" onClick={() => this.props.cancel()}>Cancel</Button>
                    <Button variant="light" onClick={() => this.handleApply()}>Apply</Button>
                </span>
            </div>
        </div>);
    }
}