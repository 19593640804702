import React from 'react';
import { Row, ButtonToolbar, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { ViewerState } from '../../../rtviewer-core/viewer-state';
import './InfoToolbar.css';


type OwnProps = {
    viewerState: ViewerState,
}

type AllProps = OwnProps;

type OwnState = {
    lineWidth: string,
    lineWidthSelected: string,
}

export enum InfoMenu {
    MeasureLength, IntensityProfile,
}


export class InfoToolbar extends React.Component<AllProps, OwnState> {
    displayName = InfoToolbar.name

    constructor(props: AllProps) {
        super(props);
        let vs = props.viewerState;

        this.state = {
            lineWidth: vs.lineWidth.toString(),
            lineWidthSelected: vs.lineWidthSelected.toString(),
            // defaultZoom: defaultZoom,
        };
    }

    render() {
        const vs = this.props.viewerState;

        return(
            <div>
                <Row className="info-tools toolbar">
                    <ButtonToolbar className="image-toolbar">
                        <ToggleButtonGroup  className="mr-2" type="radio" name="options" value={vs.infoMenuSelection} onChange={(val: InfoMenu) => vs.setInfoMenuSelection(val)}>
                            <ToggleButton variant="light" className="btn btn-default btn-sm" value={InfoMenu.MeasureLength} >Measure Length</ToggleButton>
                            <ToggleButton variant="light" className="btn btn-default btn-sm" value={InfoMenu.IntensityProfile} >Intensity Profile</ToggleButton>
                        </ToggleButtonGroup>
                    </ButtonToolbar>
                </Row>
            </div>
        );
    }
}



