import React from 'react';
import { Modal } from 'react-bootstrap';
import _ from 'lodash';
import { stopKeyboardPropagation } from '../../react-util';

type OwnProps = {
    /** Set to false to NOT prevent keyboard event propagation. Defaults to true. */
    preventPropagation?: boolean,
}

/** Props extracted from react-bootstrap's modal component. */
type ModalProps = React.ComponentProps<typeof Modal>;

type AllProps = OwnProps & ModalProps;

/**
 * This is a wrapper component for React-Bootstrap's modal dialog component. The wrapper ensures that
 * keyboard events won't be propagated outside of modal dialogs, which would almost never be the desired
 * behaviour.
 */
class ModalDialog extends React.PureComponent<AllProps> {

    stopKeyboardPropagation = (e: React.KeyboardEvent) => {
        if (this.props.preventPropagation === undefined || this.props.preventPropagation === true) {
            stopKeyboardPropagation(e);
        }
    }
    
    render() {
        // separate wrapper's internal props from bootstrap modal props
        const { children, preventPropagation, ...modalProps } = this.props;

        return (
            <div onKeyDown={this.stopKeyboardPropagation}>
                <Modal {...modalProps}>
                    {children}
                </Modal>
            </div>
        )
    }
}

export default ModalDialog;
