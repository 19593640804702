import { MouseToolBase, MouseCursor } from "./mouse-tool-base";
import { View, Plane } from "../view";
import { mouseTools } from "./mouse-tools";
import { Roi } from "../../dicom/structure-set";
import * as polygonMath3D from '../../math/polygon-math-3d';
import * as sdfDrawing from "../webgl/sdf/sdf-drawing";
import { BooleanOperator, SdfOperations } from "../webgl/sdf/boolean/sdf-operations";
import { getRoiSdfResolution, Sdf } from "../webgl/sdf/sdf";
import { Propagation } from "../webgl/sdf/propagation/sdf-propagation";

class Line {
    public roi: Roi;
    public plane: Plane;
    public slice: number;
    public pointsMm: number[][];
    public hoverPointMm: number[] | null;
    private epsilonMm = 2; // distance in Mm to the starting point that will close the line

    constructor(roi: Roi, view: View, ptMm: number[]) {
        this.roi = roi;
        this.plane = view.plane;
        this.slice = view.slice;
        this.pointsMm = [ptMm];
        this.hoverPointMm = null;
    }

    hover(ptMm: number[] | null) {
        this.hoverPointMm = ptMm;
    }

    // Can't add a point that would cross current lines
    isOkToAdd(): boolean {
        return true;
        // if(this.isClosing()) return true;
        // if(!this.hoverPointMm) return false;
        // if(this.pointsMm.length < 2) return true;
        // const latestPt = this.pointsMm[this.pointsMm.length - 1];
        // if( polygonMath3D.pointsEqual(this.hoverPointMm, latestPt ) ) return false;
        // const points = this.pointsMm.slice(0, this.pointsMm.length - 1);
        // return polygonMath3D.countLinePolylineIntersections(this.plane, points, latestPt, this.hoverPointMm) === 0;
    }

    isClosing(): boolean {
        if(!this.hoverPointMm) return false;
        if(this.pointsMm.length < 3) return false;
        if(!this.pointsMm.some(pt => !polygonMath3D.arePointsClose(this.plane, pt, this.pointsMm[0], this.epsilonMm))) return false;
        return polygonMath3D.arePointsClose(this.plane, this.hoverPointMm, this.pointsMm[0], this.epsilonMm);
    }

    addPoint(): boolean {
        if(!this.hoverPointMm ) return false;
        let lineReady = this.isClosing();
        let point = lineReady ? this.pointsMm[0] : this.hoverPointMm;
        this.pointsMm.push(point);
        this.hoverPointMm = null;
        return lineReady;
    }
}

export default class LineDrawTool extends MouseToolBase {

    private line: Line | null;
    private drawing = false;

    constructor() {
        super();
        this.line = null;
    }

    handleActivate() {
        this.line = null;
    }

    handleDeactivate() {
        this.line = null;
    }

    handleRoiSelected(roi: Roi | null) {
        if(this.line && this.line.roi !== roi) {
            this.line = null;
        }
    }

    drawOnCanvas(canvas: HTMLCanvasElement, view: View) {
        
        const line = this.line;
        if(!line) return;
        if(line.plane !== view.plane) return;
        if(line.slice !== view.slice) return;
        let ctx = canvas.getContext("2d");
        if(!ctx) return;

        ctx.lineWidth = line.isClosing() ? 2 : 1;

        ctx.strokeStyle = line.roi.rgb();
        let polyMm = line.pointsMm;
        let first = view.getPointInCanvasCoord(canvas, polyMm[0]);
        ctx.beginPath();
        ctx.arc(first[0], first[1], 3*view.viewManager.zooming, 0, 2 * Math.PI);
        ctx.stroke();
        ctx.beginPath();
        let beginPt = view.getPointInCanvasCoord(canvas, polyMm[0]);
        ctx.moveTo(beginPt[0], beginPt[1]);
        for(let iPt = 0; iPt < polyMm.length; ++iPt)
        {
            let p = view.getPointInCanvasCoord(canvas, polyMm[iPt])
            ctx.lineTo( p[0], p[1] );
        }
        if(line.hoverPointMm){
            let p = view.getPointInCanvasCoord(canvas, line.hoverPointMm);
            ctx.lineTo( p[0], p[1] );
        }
        ctx.stroke();
    }

    getMouseCursor(view: View) {
        const vs = view.viewManager.viewerState;
        return vs.selectedRoi ? (vs.erase ? MouseCursor.CrosshairErase : MouseCursor.CrosshairDraw) : MouseCursor.Pointer;
    }

    handleMouseDown(view: View, mousePointMm: number[], mouseButton: number): void {
        if (mouseButton === 0) {
            this.drawing = true;
            let vm = view.viewManager;
            let vs = vm.viewerState;
            let ss = vs.selectedStructureSet;
            if (!ss) return;
            let roi = vs.selectedRoi;
            if (!roi) {
                mouseTools.select.handleMouseDown();
                return;
            }
            if (this.line && this.line.plane !== view.plane) {
                this.line = null;
            }
            if (this.line && this.line.slice !== view.slice) {
                this.line = null;
            }

            if (this.line) {
                if (this.line.isOkToAdd()) {
                    const lineReady = this.line.addPoint();
                    if (lineReady) {
                        vs.undoStack.pushRoiStateBeforeEdit(roi);

                        this.performOperation(view, roi, this.line);
                        ss.unsaved = true;
                        this.line = null;
                    }
                }
            }
            else if (vs.image.isPointIn(mousePointMm)) {
                this.line = new Line(roi, view, mousePointMm);
            }
            vs.notifyListeners();
        }
    }

    handleMouseUp(view: View) {
        this.drawing = false;
    }

    handleHover(view: View, mousePointMm: number[]): void {
        let vm = view.viewManager;
        let vs = vm.viewerState;
        let roi = vs.selectedRoi;
        if(!roi) {
            mouseTools.select.handleHover(view, mousePointMm);
            return;
        }
        if(this.line && this.line.plane !== view.plane) {
            return;
        }
        else if(this.line && this.line.slice !== view.slice) {
            return;
        }
        else if(this.line && !vs.image.isPointIn(mousePointMm)) {
            this.line.hover(null);
        }
        else if(this.line) {
            this.line.hover(mousePointMm);
        }
        vs.notifyListeners();
    }

    handleDrag(view: View, mousePointMm: number[], diff: number[], mouseButton: number): void {
        const vm = view.viewManager;
        if (mouseButton === 0 && this.drawing && this.line) {
            if (this.line.plane !== view.plane || this.line.slice !== view.slice) {
                this.line = null;
                return;
            }
            if (this.line.isOkToAdd()) {
                const lineReady = this.line.addPoint();
                if (lineReady) {
                    const vs = vm.viewerState;
                    const roi = vs.selectedRoi;
                    if (!roi) {
                        mouseTools.select.handleMouseDown();
                        return;
                    }

                    this.performOperation(view, roi, this.line);

                    const ss = vs.selectedStructureSet;
                    if (!ss) return;
                    ss.unsaved = true;
                    this.line = null;
                }
            }
        } else if (mouseButton !== 0) {
            vm.pan(view, diff[0], diff[1]);
        }
    }

    handleMouseLeave(view: View): void {
        if(this.line) {
            this.drawing = false;
            this.line.hover(null);
            view.viewManager.viewerState.notifyListeners();
        }
    }

    handleEsc(view: View) { 
        if(this.line) {
            this.line = null;
            view.viewManager.viewerState.notifyListeners();
        }
    }

    handleAlt(view: View) { 
        const vs = view.viewManager.viewerState;
        vs.setErase(!vs.erase);
    }

    private performOperation(view: View, roi: Roi, line: Line) {
        const vm = view.viewManager;
        const vs = vm.viewerState;

        vs.undoStack.pushRoiStateBeforeEdit(roi);

        const polygonMm = line.pointsMm;
        const drawnSdf = sdfDrawing.polygonToSdf(view, roi, polygonMm);
        const roiSdf = roi.sdf || new Sdf(vm, getRoiSdfResolution(vs.image, roi));
        const sdfOps = new SdfOperations(vm);
        const operator = vs.erase ? BooleanOperator.AND_NOT : BooleanOperator.OR;
        roi.sdf = sdfOps.applyBoolean(roiSdf, drawnSdf, operator, true);
        const changedSliceIds = vs.image.getSliceIdsForArea(drawnSdf.boundingBox);
        roi.setContoursChanged(changedSliceIds);
        new Propagation(vm).propagate(roi);
    }
}