import { FileDownloadResponseModel, ShareClient, ShareDirectoryClient, ShareFileClient, ShareServiceClient } from '@azure/storage-file-share';
import _ from 'lodash';
import { getDateFromTempFileString, getTemporaryFilename } from '../util';
import { AzureFileInfo, AzurePathInfo, AzureShareInfo, AzureStorageAccountInfo, instanceOfAzureStorageAccountInfo } from './azure-files';
import { rtViewerApiClient } from './rtviewer-api-client';


// clean up backup files (.backup) if more than this amount is found for the target file
export const MAX_BACKUPS_TO_KEEP = 10;

// intermediary files (.new files) generally should not be sticking around unless the app crashed or was closed
// during the save operation. cleaning them automatically should be done carefully since their count may
// quickly balloon up if user encounters compounding errors and may end up overwriting their working
// intermediary files with wrong versions
export const MAX_INTERMEDIARY_FILES_TO_KEEP = 15;
const CLEAN_UP_INTERMEDIARY_FILES = true;

export const BACKUP_FILENAME_EXTENSION = '.backup';
const INTERMEDIARY_FILENAME_EXTENSION = '.new';


/**
 * Optional options when initializing an azure file client.
 */
export type AzureFileClientOptions = {
    /**
     * Optional username used in temporary file names and such in saving operations. Default: undefined.
     */
    username: string,
    /**
     * If set to true, don't create temporary and backup files when doing save operations. Default: false.
     */
    skipTemporaryFilesWhenSaving: boolean,
}

export type BackupFile = {
    filename: string;
    createdOn: Date;
    writtenOn: Date;
    lastModified: Date;
}

/**
 * Client class for interacting with azure storages.
 */
export class AzureFileClient {

    public readonly storageAccountName: string;
    public readonly username?: string;
    public readonly skipTemporaryFilesWhenSaving: boolean = false;

    /**
     * Creates a new azure file share client object. Clients are created against
     * a specific storage account.
     * @param azureInfo File client is generated against this azure storage account info.
     * @param options Optional options when initializing an azure file client.
     */
    constructor(azureInfo: AzureStorageAccountInfo, options?: Partial<AzureFileClientOptions>);
    /**
     * Creates a new azure file share client object. Clients are created against
     * a specific storage account.
     * @param storageAccountName File client is generated against azure storage account with this name.
     * @param options Optional options when initializing an azure file client.
     */
    constructor(storageAccountName: string, options?: Partial<AzureFileClientOptions>);
    /**
     * Combined overload constructor for AzureFileClient.
     */
    constructor(azureInfoOrStorageAccountName: AzureStorageAccountInfo | string, options?: Partial<AzureFileClientOptions>) {
        if (instanceOfAzureStorageAccountInfo(azureInfoOrStorageAccountName)) {
            this.storageAccountName = azureInfoOrStorageAccountName.storageAccountName;
        }
        else if (_.isString(azureInfoOrStorageAccountName)) {
            this.storageAccountName = azureInfoOrStorageAccountName;
        }
        else {
            this.throwUnexpectedTypeError(azureInfoOrStorageAccountName);
        }

        // set optional options or use defaults
        if (options) {
            this.username = _.get(options, 'username', this.username);
            this.skipTemporaryFilesWhenSaving = _.get(options, 'skipTemporaryFilesWhenSaving', this.skipTemporaryFilesWhenSaving);
        }
    }

    /**
     * Use this helper function to validate whether a given azurefileinfo or
     * azurepathinfo belongs to the storage account represented by this client
     * object, if relevant.
     */
    public belongsToClient(fileOrPathInfo: AzureFileInfo | AzurePathInfo): boolean {
        return fileOrPathInfo.storageAccountName === this.storageAccountName;
    }

    /**
     * Convenience method for getting an AzureShareInfo object for the storage account that this azure file
     * client object represents.
     * @param shareName Name of the share in the storage account.
     * @returns An AzureShareInfo object representing the share in the storage account.
     */
    public getShare(shareName: string): AzureShareInfo {
        return new AzureShareInfo(this.storageAccountName, shareName);
    }

    /**
     * Convenience method for getting an AzurePathInfo object for the storage account that this azure file
     * client object represents.
     */
    public getPath(shareInfo: AzureShareInfo, path: string): AzurePathInfo;
    /**
     * Convenience method for getting an AzurePathInfo object for the storage account that this azure file
     * client object represents.
     */
    public getPath(shareName: string, path: string): AzurePathInfo;
    /**
     * Combined function overload for getPath.
     */
    public getPath(shareInfoOrShareName: AzureShareInfo | string, path: string): AzurePathInfo {
        if (shareInfoOrShareName instanceof AzureShareInfo) {
            this.ensureSameStorageAccount(shareInfoOrShareName);
            return shareInfoOrShareName.getPath(path);
        }
        else if (_.isString(shareInfoOrShareName)) {
            return new AzurePathInfo(this.storageAccountName, shareInfoOrShareName, path);
        } else {
            this.throwUnexpectedTypeError(shareInfoOrShareName);
        }
    }

    /**
     * Convenience method for getting an AzureFileInfo object for the storage account that this azure file
     * client object represents.
     */
    public getFile(pathInfo: AzurePathInfo, filename: string): AzureFileInfo;
    /**
     * Convenience method for getting an AzureFileInfo object for the storage account that this azure file
     * client object represents.
     */
    public getFile(shareInfo: AzureShareInfo, path: string, filename: string): AzureFileInfo;
    /**
     * Convenience method for getting an AzureFileInfo object for the storage account that this azure file
     * client object represents.
     */
    public getFile(shareName: string, path: string, filename: string): AzureFileInfo;
    /**
     * Combined function overload for getFile.
     */
    public getFile(pathInfoOrShareInfoOrShareName: AzurePathInfo | AzureShareInfo | string, filenameOrPath: string, filename?: string): AzureFileInfo {
        if (pathInfoOrShareInfoOrShareName instanceof AzurePathInfo) {
            this.ensureSameStorageAccount(pathInfoOrShareInfoOrShareName);
            return pathInfoOrShareInfoOrShareName.getFile(filenameOrPath);
        }
        else if (pathInfoOrShareInfoOrShareName instanceof AzureShareInfo && filename !== undefined) {
            this.ensureSameStorageAccount(pathInfoOrShareInfoOrShareName);
            return pathInfoOrShareInfoOrShareName.getFile(filenameOrPath, filename);
        }
        else if (_.isString(pathInfoOrShareInfoOrShareName) && filename !== undefined) {
            return new AzureFileInfo(this.storageAccountName, pathInfoOrShareInfoOrShareName, filenameOrPath, filename);
        }
        else {
            this.throwUnexpectedTypeError(pathInfoOrShareInfoOrShareName);
        }
    }

    /**
     * Returns a list of all the file shares under this storage account. 
     * @returns List of AzureShareInfo for file shares in the storage account.
     */
    public async listFileShares(): Promise<AzureShareInfo[]> {
        try {
            const fileService = await this.getFileService();
            const fileShares: AzureShareInfo[] = [];

            const shareIter = fileService.listShares();
            for await (const share of shareIter) {
                fileShares.push(new AzureShareInfo(this.storageAccountName, share.name));
            }

            return fileShares;
        }
        catch (error) {
            console.error(error);
            throw error;
        }
    }

    /**
     * Returns a list of file info objects under given path in this storage account.
     * @param pathInfo A path info object describing the path that will be listed.
     */
    public async listFiles(pathInfo: AzurePathInfo): Promise<AzureFileInfo[]>;
    /**
     * Returns a list of file info objects under given path in this storage account.
     * @param shareInfo A share info object depicting the share which is being queried for the files.
     * @param path The path in the share from under which the files will be listed.
     */
    public async listFiles(shareInfo: AzureShareInfo, path: string): Promise<AzureFileInfo[]>;
    /**
     * Returns a list of file info objects under given path in this storage account.
     * @param shareName Name of the share which is being queried for the files.
     * @param path The path in the share from under which the files will be listed.
     */
    public async listFiles(shareName: string, path: string): Promise<AzureFileInfo[]>;
    /**
     * Combined function overload for listFiles.
     */
    public async listFiles(pathInfoOrShareInfoOrShareName: AzurePathInfo | AzureShareInfo | string, path?: string): Promise<AzureFileInfo[]> {

        let directoryClient: ShareDirectoryClient;

        try {
            // check which overload we're using
            if (pathInfoOrShareInfoOrShareName instanceof AzurePathInfo) {
                this.ensureSameStorageAccount(pathInfoOrShareInfoOrShareName);
                directoryClient = (await this.getShareClientAsync(pathInfoOrShareInfoOrShareName.fileShareName)).getDirectoryClient(pathInfoOrShareInfoOrShareName.path);
            }
            else if (pathInfoOrShareInfoOrShareName instanceof AzureShareInfo && path !== undefined) {
                this.ensureSameStorageAccount(pathInfoOrShareInfoOrShareName);
                directoryClient = (await this.getShareClientAsync(pathInfoOrShareInfoOrShareName.fileShareName)).getDirectoryClient(path);
            }
            else if (_.isString(pathInfoOrShareInfoOrShareName) && path !== undefined) {
                directoryClient = (await this.getShareClientAsync(pathInfoOrShareInfoOrShareName)).getDirectoryClient(path);
            }
            else {
                this.throwUnexpectedTypeError(pathInfoOrShareInfoOrShareName);
            }

            const files: AzureFileInfo[] = [];

            const dirIter = directoryClient.listFilesAndDirectories();
            for await (const item of dirIter) {
                if (item.kind === "file") {
                    files.push(new AzureFileInfo(this.storageAccountName, directoryClient.shareName, directoryClient.path, item.name));
                }
            }

            return files;
        }
        catch (error) {
            console.error(error);
            throw error;
        }
    }

    /**
     * Creates given directory if it does not already exist. Nested paths that don't yet exist 
     * are supported (for/example/like/this/).
     * @param pathInfo Path info object depicting the path in question.
     */
    public async createDirIfNotExists(pathInfo: AzurePathInfo): Promise<void>;
    /**
     * Creates given directory if it does not already exist. Nested paths that don't yet exist 
     * are supported (for/example/like/this/).
     * @param shareName Name of the share which will contain the path(s).
     * @param fullPath The full path.
     */
    public async createDirIfNotExists(shareName: string, fullPath: string): Promise<void>;
    /**
     * Combined function overload for createDirIfNotExists.
     */
    public async createDirIfNotExists(shareNameOrPathInfo: string | AzurePathInfo, fullPath?: string): Promise<void> {

        let pathInfo: AzurePathInfo;

        // check which overload we're using
        if (shareNameOrPathInfo instanceof AzurePathInfo) {
            this.ensureSameStorageAccount(shareNameOrPathInfo);
            pathInfo = shareNameOrPathInfo;
            pathInfo.storageAccountName = this.storageAccountName;
        }
        else if (_.isString(shareNameOrPathInfo) && fullPath !== undefined) {
            pathInfo = new AzurePathInfo(this.storageAccountName, shareNameOrPathInfo, fullPath);
        }
        else {
            this.throwUnexpectedTypeError(shareNameOrPathInfo);
        }

        try {
            const shareClient = await this.getShareClientAsync(pathInfo.fileShareName);
            if (!await shareClient.exists()) { throw new Error(`Share ${this.storageAccountName}/${pathInfo.fileShareName} does not exist`); }

            // we can return immediately if the full path already exists
            const fullPathClient = shareClient.getDirectoryClient(pathInfo.path);
            if (await fullPathClient.exists()) { return; }

            // otherwise, create each part of the path if necessary
            let path = '';
            const pathSegments = pathInfo.path.split('/');
            for (const pathSegment of pathSegments) {
                path += pathSegment;
                const directoryClient = shareClient.getDirectoryClient(path);

                // NOTE: this will result in a 409 response if the directory already exists. This can be ignored -- the azure
                // library will handle this internally, but it unfortunately does show up in developer console which may cause
                // confusion.
                await directoryClient.createIfNotExists();

                path += '/';
            }
        }
        catch (error) {
            console.error(`An error occurred when calling createDirIfNotExists for ${pathInfo.toString()}:`);
            console.error(error);
            throw error;
        }
    }

    /**
     * Returns false if the specified file, or its path, or its share do not exist in the given azure storage account, true otherwise.
     * @param fileInfo The file which will be queried.
     */
    public async doesFileExist(fileInfo: AzureFileInfo): Promise<boolean>;
    /**
     * Returns false if the specified file, or its path, or its share do not exist in the given azure storage account, true otherwise.
     * @param shareName The share which will be queried.
     * @param path The path(s) which will be queried.
     * @param filename The file name which will be queried.
     */
    public async doesFileExist(shareName: string, path: string, filename: string): Promise<boolean>;
    /**
     * Combined function overload for doesFileExist.
     */
    public async doesFileExist(fileInfoOrShareName: AzureFileInfo | string, path?: string, filename?: string): Promise<boolean> {
        try {

            let fileInfo: AzureFileInfo;

            if (fileInfoOrShareName instanceof AzureFileInfo) {
                this.ensureSameStorageAccount(fileInfoOrShareName);
                fileInfo = fileInfoOrShareName;
            }
            else if (_.isString(fileInfoOrShareName) && path !== undefined && filename !== undefined) {
                fileInfo = new AzureFileInfo(this.storageAccountName, fileInfoOrShareName, path, filename);
            }
            else {
                this.throwUnexpectedTypeError(fileInfoOrShareName);
            }

            const shareClient = await this.getShareClientAsync(fileInfo.fileShareName);
            if (!await shareClient.exists()) { return false; }

            const directoryClient = shareClient.getDirectoryClient(fileInfo.path);
            if (!await directoryClient.exists()) { return false; }

            const fileClient = directoryClient.getFileClient(fileInfo.filename);
            return await fileClient.exists();
        }
        catch (error) {
            console.error(error);
            throw error;
        }
    }

    /**
     * Returns true if the queried path exists in azure file share, false otherwise.
     * @param pathInfo The queried path.
     */
    public async doesDirectoryExist(pathInfo: AzurePathInfo): Promise<boolean>;
    /**
     * Returns true if the queried path exists in azure file share, false otherwise.
     * @param shareName Name of the queried share.
     * @param path The path which will be queried.
     */
    public async doesDirectoryExist(shareName: string, path: string): Promise<boolean>;
    /**
     * Combined function overload for doesDirectoryExist.
     */
    public async doesDirectoryExist(shareNameOrPathInfo: string | AzurePathInfo, path?: string): Promise<boolean> {

        let pathInfo: AzurePathInfo;

        // check which overload we're using
        if (shareNameOrPathInfo instanceof AzurePathInfo) {
            this.ensureSameStorageAccount(shareNameOrPathInfo);
            pathInfo = shareNameOrPathInfo;
        }
        else if (_.isString(shareNameOrPathInfo) && path !== undefined) {
            pathInfo = new AzurePathInfo(this.storageAccountName, shareNameOrPathInfo, path);
        }
        else {
            this.throwUnexpectedTypeError(shareNameOrPathInfo);
        }

        try {
            const shareClient = await this.getShareClientAsync(pathInfo.fileShareName);
            if (!await shareClient.exists()) { return false; }

            const directoryClient = shareClient.getDirectoryClient(pathInfo.path);
            return await directoryClient.exists();
        }
        catch (error) {
            console.error(error);
            throw error;
        }
    }

    /**
     * Returns true if the queried azure share exists, false otherwise.
     * @param shareName Name of the share which will be queried.
     */
    public async doesShareExist(shareName: string): Promise<boolean> {
        try {
            const shareClient = await this.getShareClientAsync(shareName, true);
            return await shareClient.exists();
        }
        catch (error) {
            console.error(error);
            throw error;
        }
    }

    /**
     * Downloads a file from azure file storage into a string.
     * @param fileInfo The file which will be downloaded.
     * @param createNewDefaultFileIfDoesntExist If defined, and if the file doesn't exist, a new file will be created from this object or string (can be empty).
     */
    public async downloadFileToString(fileInfo: AzureFileInfo,
        createNewDefaultFileIfDoesntExist?: string | any): Promise<string>;
    /**
     * Downloads a file from azure file storage into a string.
     * @param pathInfo The path of the file as a path info object.
     * @param filename Name of the file.
     * @param createNewDefaultFileIfDoesntExist If defined, and if the file doesn't exist, a new file will be created from this object or string (can be empty).
     */
    public async downloadFileToString(pathInfo: AzurePathInfo, filename: string,
        createNewDefaultFileIfDoesntExist?: string | any): Promise<string>;
    /**
     * Downloads a file from azure file storage into a string.
     * @param shareInfo The share of the file as a share info object.
     * @param path The path the file is in.
     * @param filename Name of the file.
     * @param createNewDefaultFileIfDoesntExist If defined, and if the file doesn't exist, a new file will be created from this object or string (can be empty).
     */
    public async downloadFileToString(shareInfo: AzureShareInfo, path: string, filename: string,
        createNewDefaultFileIfDoesntExist?: string | any): Promise<string>;
    /**
     * Downloads a file from azure file storage into a string.
     * @param shareName Name of the share.
     * @param path The path the file is in.
     * @param filename Name of the file.
     * @param createNewDefaultFileIfDoesntExist If defined, and if the file doesn't exist, a new file will be created from this object or string (can be empty).
     */
    public async downloadFileToString(shareName: string, path: string, filename: string,
        createNewDefaultFileIfDoesntExist?: string | any): Promise<string>;
    /**
     * Combined function overloads for downloadFileToString.
     */
    public async downloadFileToString(fileOrPathOrShareOrShareName: AzureFileInfo | AzurePathInfo | AzureShareInfo | string,
        filenameOrPathOrCreateNew?: string | any,
        filenameOrCreateNew?: string | any,
        createNew?: string | any): Promise<string> {

        try {

            let shouldCreateNewDefaultFileIfDoesntExist: boolean;
            let newDefaultContents: string | any;
            let fileInfo: AzureFileInfo;

            // figure out which function overload we're using and get a handle to the file
            if (fileOrPathOrShareOrShareName instanceof AzureFileInfo) {
                this.ensureSameStorageAccount(fileOrPathOrShareOrShareName);
                fileInfo = fileOrPathOrShareOrShareName;
                shouldCreateNewDefaultFileIfDoesntExist = filenameOrPathOrCreateNew !== undefined;
                newDefaultContents = filenameOrPathOrCreateNew;
            }
            else if (fileOrPathOrShareOrShareName instanceof AzurePathInfo && _.isString(filenameOrPathOrCreateNew)) {
                this.ensureSameStorageAccount(fileOrPathOrShareOrShareName);
                fileInfo = fileOrPathOrShareOrShareName.getFile(filenameOrPathOrCreateNew);
                shouldCreateNewDefaultFileIfDoesntExist = filenameOrCreateNew !== undefined;
                newDefaultContents = filenameOrCreateNew;
            }
            else if (fileOrPathOrShareOrShareName instanceof AzureShareInfo && _.isString(filenameOrPathOrCreateNew) && _.isString(filenameOrCreateNew)) {
                this.ensureSameStorageAccount(fileOrPathOrShareOrShareName);
                fileInfo = fileOrPathOrShareOrShareName.getFile(filenameOrPathOrCreateNew, filenameOrCreateNew);
                shouldCreateNewDefaultFileIfDoesntExist = createNew !== undefined;
                newDefaultContents = createNew;
            }
            else if (_.isString(fileOrPathOrShareOrShareName) && _.isString(filenameOrPathOrCreateNew) && _.isString(filenameOrCreateNew)) {
                fileInfo = new AzureFileInfo(this.storageAccountName, fileOrPathOrShareOrShareName, filenameOrPathOrCreateNew, filenameOrCreateNew);
                shouldCreateNewDefaultFileIfDoesntExist = createNew !== undefined;
                newDefaultContents = createNew;
            }
            else {
                this.throwUnexpectedTypeError(fileOrPathOrShareOrShareName);
            }

            const fileClient = await this.getFileClientAsync(fileInfo);

            let downloadFileResponse: FileDownloadResponseModel;
            try {
                downloadFileResponse = await fileClient.download();
            }
            catch (error) {
                // do a specific handling for the case where the target file didn't exist
                if (error.message.includes('The specified resource does not exist.')) {
                    if (shouldCreateNewDefaultFileIfDoesntExist) {
                        console.info(`File ${fileInfo.toString()} does not exist -- creating it with supplied default contents.`);
                        const defaultContents = _.isString(newDefaultContents) ? newDefaultContents : JSON.stringify(newDefaultContents);
                        await this.saveToFile(fileInfo, defaultContents);
                        return defaultContents;
                    }
                    else {
                        throw new Error(`File ${fileInfo.toString()} does not exist!`);
                    }
                }
                else {
                    throw error;
                }
            }

            if (!downloadFileResponse.blobBody) { throw new Error(`Download failed for ${fileInfo.toString()}`); }
            const fileContent = await this.blobToString(await downloadFileResponse.blobBody);

            return fileContent;
        }
        catch (error) {
            console.error(error);
            throw error;
        }
    }

    /**
     * Downloads a file from azure file storage into an arraybuffer.
     * @param fileInfo The file which will be downloaded.
     */
    public async downloadArrayBuffer(fileInfo: AzureFileInfo): Promise<ArrayBuffer>;
    /**
     * Downloads a file from azure file storage into an arraybuffer.
     * @param shareName Name of the share.
     * @param path The path the file is in.
     * @param filename The filename of the file.
     */
    public async downloadArrayBuffer(shareName: string, path: string, filename: string): Promise<ArrayBuffer>;
    /**
     * Combined function overload for downloadArrayBuffer.
     */
    public async downloadArrayBuffer(fileInfoOrshareName: AzureFileInfo | string, path?: string, filename?: string): Promise<ArrayBuffer> {

        let fileInfo: AzureFileInfo;

        if (fileInfoOrshareName instanceof AzureFileInfo) {
            this.ensureSameStorageAccount(fileInfoOrshareName);
            fileInfo = fileInfoOrshareName;
        }
        else if (_.isString(fileInfoOrshareName) && path !== undefined && filename !== undefined) {
            fileInfo = new AzureFileInfo(this.storageAccountName, fileInfoOrshareName, path, filename);
        }
        else {
            this.throwUnexpectedTypeError(fileInfoOrshareName);
        }

        try {
            const fileClient = await this.getFileClientAsync(fileInfo);
            const downloadFileResponse = await fileClient.download();

            if (!downloadFileResponse.blobBody) { throw new Error(`Download failed for ${fileInfo.toString()}`); }
            const arrayBuffer = await (await downloadFileResponse.blobBody).arrayBuffer();

            return arrayBuffer;
        }
        catch (error) {
            console.error(`An error occurred when trying to download arraybuffer from ${fileInfo.toString()}:`);
            console.error(error);
            throw error;
        }
    }

    /**
     * Saves supplied string or object into azure file share file as JSON. If the file already exists it will be overwritten.
     * @param fileInfo Azure file info to use for saving.
     * @param content Content to save. Strings are saved as is, objects are converted to JSON.
     */
    public async saveToFile(fileInfo: AzureFileInfo, content: string | any): Promise<void>;
    /**
     * Saves supplied string or object into azure file share file as JSON. If the file already exists it will be overwritten.
     * @param shareName Name of the file share where the file will be saved to.
     * @param path Path in the file share where the file will be saved to. Use an empty string ("") for root.
     * @param filename File name for the file to be saved to.
     * @param content Content to save. Strings are saved as is, objects are converted to JSON.
     */
    public async saveToFile(shareName: string, path: string, filename: string, content: string | any): Promise<void>;
    /**
     * Combined function overload for saveToFile.
     */
    public async saveToFile(fileInfoOrShareName: AzureFileInfo | string, contentOrPath: string | any, filename?: string, content?: string | any): Promise<void> {

        let errorMessageSuffix = '';

        try {
            let fileInfo: AzureFileInfo;
            let contentValue: string | any;

            // get the correct overload
            if (fileInfoOrShareName instanceof AzureFileInfo) {
                this.ensureSameStorageAccount(fileInfoOrShareName);
                fileInfo = fileInfoOrShareName;
                contentValue = contentOrPath;
            } else if (_.isString(fileInfoOrShareName) && _.isString(contentOrPath) && filename !== undefined && content !== undefined) {
                fileInfo = new AzureFileInfo(this.storageAccountName, fileInfoOrShareName, contentOrPath, filename);
                contentValue = content;
            }
            else {
                this.throwUnexpectedTypeError(fileInfoOrShareName);
            }

            errorMessageSuffix = ` to ${fileInfo.toString()}`;
            const contentIsString = _.isString(contentValue);
            const handledContent = contentIsString ? contentValue : JSON.stringify(contentValue, null, 4);
            const blob = new Blob([handledContent]);
            errorMessageSuffix += ` (file size: ${blob.size}, ${contentIsString ? 'string' : 'JSON'})`;

            await this.saveBlob(fileInfo, blob);
        }
        catch (error) {
            console.error(`An error occurred when trying to save string/json file${errorMessageSuffix}`);
            console.error(error);
            throw error;
        }
    }

    /**
     * Uploads a blob to file share.
     * Note: all other file saving operations in AzureFileClient eventually go through this function.
     * @param fileInfo The file where the blob will be saved.
     * @param blob The blob that will be uploaded to azure.
     * @param skipBackups If true the saving operation won't make backups of the target file or use any
     * intermediary files, and will instead save directly to the target file. If false, a backup is taken of 
     * the target file (if it already exists) and the blob will first be saved into an intermediary file in azure
     * before then being copied over the original file. This setting defaults to false if omitted.
     */
    public async saveBlob(fileInfo: AzureFileInfo, blob: Blob, skipBackups?: boolean): Promise<void>;
    /**
     * Uploads a blob to file share.
     * Note: all other file saving operations in AzureFileClient eventually go through this function.
     * @param pathInfo The path where the blob will be saved, as a path info object.
     * @param filename The name of the target file.
     * @param blob The blob that will be uploaded to azure.
     * @param skipBackups If true the saving operation won't make backups of the target file or use any
     * intermediary files, and will instead save directly to the target file. If false, a backup is taken of 
     * the target file (if it already exists) and the blob will first be saved into an intermediary file in azure
     * before then being copied over the original file. This setting defaults to false if omitted.
     */
    public async saveBlob(pathInfo: AzurePathInfo, filename: string, blob: Blob, skipBackups?: boolean): Promise<void>;
    /**
     * Uploads a blob to file share.
     * Note: all other file saving operations in AzureFileClient eventually go through this function.
     * @param shareName The name of the share where the file will be saved.
     * @param path The path where the file will be saved.
     * @param filename The name of the target file.
     * @param blob The blob that will be uploaded to azure.
     * @param skipBackups If true the saving operation won't make backups of the target file or use any
     * intermediary files, and will instead save directly to the target file. If false, a backup is taken of 
     * the target file (if it already exists) and the blob will first be saved into an intermediary file in azure
     * before then being copied over the original file. This setting defaults to false if omitted.
     */
    public async saveBlob(shareName: string, path: string, filename: string, blob: Blob, skipBackups?: boolean): Promise<void>;
    /**
     * Combined function overload for saveBlob.
     */
    public async saveBlob(fileInfoOrPathInfoOrShareName: AzureFileInfo | AzurePathInfo | string,
        blobOrFilenameOrPath: Blob | string,
        filenameOrBlobOrSkipBackups?: string | Blob | boolean,
        skipBackupsOrBlob?: boolean | Blob,
        skipBackups?: boolean): Promise<void> {

        let fileInfo: AzureFileInfo;
        let blobValue: Blob;
        let skipBackupsValue: boolean = false;

        if (fileInfoOrPathInfoOrShareName instanceof AzureFileInfo && blobOrFilenameOrPath instanceof Blob && (filenameOrBlobOrSkipBackups === undefined || _.isBoolean(filenameOrBlobOrSkipBackups))) {
            this.ensureSameStorageAccount(fileInfoOrPathInfoOrShareName);
            fileInfo = fileInfoOrPathInfoOrShareName;
            blobValue = blobOrFilenameOrPath;
            skipBackupsValue = filenameOrBlobOrSkipBackups !== undefined ? filenameOrBlobOrSkipBackups : this.skipTemporaryFilesWhenSaving;
        }
        else if (fileInfoOrPathInfoOrShareName instanceof AzurePathInfo && _.isString(blobOrFilenameOrPath) && filenameOrBlobOrSkipBackups instanceof Blob && (skipBackupsOrBlob === undefined || _.isBoolean(skipBackupsOrBlob))) {
            this.ensureSameStorageAccount(fileInfoOrPathInfoOrShareName);
            fileInfo = fileInfoOrPathInfoOrShareName.getFile(blobOrFilenameOrPath);
            blobValue = filenameOrBlobOrSkipBackups;
            skipBackupsValue = skipBackupsOrBlob !== undefined ? skipBackupsOrBlob : this.skipTemporaryFilesWhenSaving;
        }
        else if (_.isString(fileInfoOrPathInfoOrShareName) && _.isString(blobOrFilenameOrPath) && _.isString(filenameOrBlobOrSkipBackups) && skipBackupsOrBlob instanceof Blob) {
            fileInfo = new AzureFileInfo(this.storageAccountName, fileInfoOrPathInfoOrShareName, blobOrFilenameOrPath, filenameOrBlobOrSkipBackups);
            blobValue = skipBackupsOrBlob;
            skipBackupsValue = skipBackups !== undefined ? skipBackups : this.skipTemporaryFilesWhenSaving;
        }
        else {
            this.throwUnexpectedTypeError();
        }

        const fileClient = await this.getFileClientAsync(fileInfo);

        if (!skipBackupsValue && await fileClient.exists()) {
            // default case: rotate the new file through a temporary file first if the file already exists
            // (temp files aren't used if the target file doesn't already exist)

            const backupFilename = this.getBackupFileName(fileInfo.filename);
            const intermediaryFilename = this.getNewTempName(fileInfo.filename);
            const backupFileInfo = fileInfo.getPath().getFile(backupFilename);
            const intermediaryFileInfo = fileInfo.getPath().getFile(intermediaryFilename);

            // 1. do a new backup of the existing file
            await this.cloneFile(fileInfo, backupFileInfo);

            // 2. save new changes into an intermediary temp file
            await this.saveBlob(intermediaryFileInfo, blobValue, true);

            // 3. move the intermediary file over the actual file
            await this.cloneFile(intermediaryFileInfo, fileInfo);

            // Rest of the operations are fire & forget and can be done
            // asynchronously to speed up letting user get back to the control
            // of UI. It's not a big deal if cleanup is not done or is only 
            // done partially (i.e. because the user closed their browser 
            // in-progress) or if there's access conflicts in the files.
            new Promise(async () => {

                // 4. remove the intermediary file
                await this.deleteFile(intermediaryFileInfo);

                // 5. remove old backups
                await this.cleanBackups(fileInfo, BACKUP_FILENAME_EXTENSION, MAX_BACKUPS_TO_KEEP);

                // 6. clean up any old intermediary files
                if (CLEAN_UP_INTERMEDIARY_FILES) {
                    await this.cleanBackups(fileInfo, INTERMEDIARY_FILENAME_EXTENSION, MAX_INTERMEDIARY_FILES_TO_KEEP);
                }
            });
        } else {
            // simple case: save without temporary files (used always with new files)
            try {
                await fileClient.uploadData(blobValue);
            } catch (error) {
                console.error(`An error occurred when trying to upload blob of size ${blobValue.size} to ${fileInfo.toString()}`);
                console.error(error);
                throw error;
            }
        }
    }

    /**
     * Clones a file within azure storage.
     * NOTE: currently works only within the same storage account.
     * @param source The source file which will be cloned.
     * @param target The target file into which the source file will be cloned to.
     */
    public async cloneFile(source: AzureFileInfo, target: AzureFileInfo): Promise<void> {
        // TODO: currently only works within the same storage account because of the ensureSameStorageAccount
        // asserts in getFileClientAsync, but could be made to work across storage accounts if needed
        try {
            const sourceFileClient = await this.getFileClientAsync(source, true);
            const targetFileClient = await this.getFileClientAsync(target, true);

            const sourceUrl = sourceFileClient.url;
            await targetFileClient.startCopyFromURL(sourceUrl);
        }
        catch (error) {
            console.log(`An error occurred when trying to clone file ${source.toString()} to ${target.toString()}`);
            console.error(error);
            throw error;
        }
    }

    /**
     * Deletes specified file from azure file share. Does nothing if the file does not exist.
     * @param fileInfo The file which will be deleted.
     */
    public async deleteFile(fileInfo: AzureFileInfo): Promise<boolean>;
    /**
     * Deletes specified file from azure file share. Does nothing if the file does not exist.
     * @param pathInfo The path where the file will be deleted from, as a path info object.
     * @param filename The name of the file which will be deleted.
     */
    public async deleteFile(pathInfo: AzurePathInfo, filename: string): Promise<boolean>;
    /**
     * Deletes specified file from azure file share. Does nothing if the file does not exist.
     * @param shareName The name of the share where the file will be deleted from.
     * @param path The path where the file will be deleted from.
     * @param filename The name of the file which will be deleted.
     */
    public async deleteFile(shareName: string, path: string, filename: string): Promise<boolean>;
    /**
     * Combined function overloads for deleteFile.
     */
    public async deleteFile(fileInfoOrPathInfoOrShareName: AzureFileInfo | AzurePathInfo | string, filenameOrPath?: string, filename?: string): Promise<boolean> {
        try {

            let fileInfo: AzureFileInfo;

            if (fileInfoOrPathInfoOrShareName instanceof AzureFileInfo) {
                this.ensureSameStorageAccount(fileInfoOrPathInfoOrShareName);
                fileInfo = fileInfoOrPathInfoOrShareName;
            }
            else if (fileInfoOrPathInfoOrShareName instanceof AzurePathInfo && filenameOrPath !== undefined) {
                this.ensureSameStorageAccount(fileInfoOrPathInfoOrShareName);
                fileInfo = fileInfoOrPathInfoOrShareName.getFile(filenameOrPath);
            }
            else if (_.isString(fileInfoOrPathInfoOrShareName) && filenameOrPath !== undefined && filename !== undefined) {
                fileInfo = new AzureFileInfo(this.storageAccountName, fileInfoOrPathInfoOrShareName, filenameOrPath, filename);
            }
            else {
                this.throwUnexpectedTypeError();
            }

            const fileClient = await this.getFileClientAsync(fileInfo);
            const result = await fileClient.deleteIfExists();
            return result.succeeded;
        }
        catch (error) {
            console.error(error);
            throw error;
        }
    }

    /**
     * Deletes an azure path, all files in it, and all sub-paths under it.
     * @param pathInfo The path which will be deleted recursively, as a path info object.
     */
    public async deleteDirectoryRecursive(pathInfo: AzurePathInfo): Promise<void>;
    /**
     * Deletes an azure path, all files in it, and all sub-paths under it.
     * @param shareName The name of the share where the path is.
     * @param path The path which will be deleted recursively.
     */
    public async deleteDirectoryRecursive(shareName: string, path: string): Promise<void>;
    /**
     * Combined function overload for deleteDirectoryRecursive.
     */
    public async deleteDirectoryRecursive(pathInfoOrShareName: AzurePathInfo | string, path?: string): Promise<void> {

        let pathInfo: AzurePathInfo;

        if (pathInfoOrShareName instanceof AzurePathInfo) {
            this.ensureSameStorageAccount(pathInfoOrShareName);
            pathInfo = pathInfoOrShareName;
        }
        else if (_.isString(pathInfoOrShareName) && path !== undefined) {
            pathInfo = new AzurePathInfo(this.storageAccountName, pathInfoOrShareName, path);
        }
        else {
            this.throwUnexpectedTypeError(pathInfoOrShareName);
        }

        try {
            const shareClient = await this.getShareClientAsync(pathInfo.fileShareName, false);
            const directoryClient = shareClient.getDirectoryClient(pathInfo.path);

            for await (const item of directoryClient.listFilesAndDirectories()) {
                if (item.kind === "directory") {
                    await this.deleteDirectoryRecursive(pathInfo.fileShareName, pathInfo.path + '/' + item.name);
                } else if (item.kind === "file") {
                    const fileClient = directoryClient.getFileClient(item.name);
                    await fileClient.delete();
                }
            }

            await directoryClient.delete();
        }
        catch (error) {
            console.error(`An error occurred when trying to recursively delete directory ${pathInfo.toString()}`);
            console.error(error);
            throw error;
        }
    }

    public async listBackups(fileInfo: AzureFileInfo, includeCurrent: boolean = true): Promise<BackupFile[]> {
        const backups: BackupFile[] = [];

        const filename = fileInfo.filename;

        const shareClient = await this.getShareClientAsync(fileInfo.fileShareName, false);

        if (includeCurrent) {
            // include 'current' file at the start of the backups list
            const currentFileClient = await this.getFileClientAsync(fileInfo);
            const currentFileProperties = await currentFileClient.getProperties();
            backups.push({
                filename: currentFileClient.name,
                createdOn: currentFileProperties.fileCreatedOn || new Date(0),
                writtenOn: currentFileProperties.fileLastWriteOn || new Date(0),
                lastModified: currentFileProperties.lastModified || new Date(0),
            });
        }

        // get matching backups
        const directoryClient = shareClient.getDirectoryClient(fileInfo.path);
        for await (const item of directoryClient.listFilesAndDirectories()) {
            if (item.kind === "file" && item.name.startsWith(filename) && item.name.endsWith(BACKUP_FILENAME_EXTENSION)) {
                const fileClient = directoryClient.getFileClient(item.name);
                const properties = await fileClient.getProperties();
                backups.push({
                    filename: item.name,
                    createdOn: properties.fileCreatedOn || new Date(0),
                    writtenOn: properties.fileLastWriteOn || new Date(0),
                    lastModified: properties.lastModified || new Date(0),
                });
            }
        }

        return backups;
    }


    /**
     * Returns a client object for a storage account.
     */
    protected async getFileService(): Promise<ShareServiceClient> {
        return new ShareServiceClient(`${await this.wrapUrlWithSas(`https://${this.storageAccountName}.file.core.windows.net`)}`);
    }

    private async wrapUrlWithSas(url: string): Promise<string> {
        const sas = await rtViewerApiClient.getSas(this.storageAccountName);
        if (!sas || !_.isString(sas)) {
            const error = "Error: cannot get shared access signature for storage account " + this.storageAccountName;
            throw new Error(error);
        }

        return `${url}${sas}`;
    }

    private async getShareClientAsync(shareName: string, skipExistsCheck: boolean = true): Promise<ShareClient> {
        const fileService = await this.getFileService();
        const shareClient = fileService.getShareClient(shareName);

        if (!skipExistsCheck) {
            if (!await shareClient.exists()) {
                throw new Error(`Share ${shareName} in storage account ${this.storageAccountName} does not exist`);
            }
        }

        return shareClient;
    }

    /**
     * Returns a client object for the given azure file share file.
     * @param skipExistsCheck If false, will do an exists check for the file and throw an error if it does not exist. If true, no check is made.
     */
    private async getFileClientAsync(fileInfo: AzureFileInfo, skipExistsCheck: boolean = true): Promise<ShareFileClient> {

        this.ensureSameStorageAccount(fileInfo);

        try {
            const fileClient = (await this.getShareClientAsync(fileInfo.fileShareName)).getDirectoryClient(fileInfo.path).getFileClient(fileInfo.filename);

            if (!skipExistsCheck) {
                if (!await fileClient.exists()) {
                    throw new Error(`File ${fileInfo.toString()} does not exist`);
                }
            }

            return fileClient;
        }
        catch (error) {
            console.error(error);
            throw error;
        }
    }

    /**
     * Converts a downloaded blob into a string.
     */
    private async blobToString(blob: Blob): Promise<string> {
        return blob.text();
    }

    private throwUnexpectedTypeError(unexpectedObject?: any): never {
        if (unexpectedObject === undefined) {
            throw new Error(`Unexpected arguments`);
        }
        else if (_.isObject(unexpectedObject) && 'constructor' in unexpectedObject) {
            throw new Error(`Unexpected argument of type ${unexpectedObject.constructor.name}`);
        } else {
            throw new Error(`Unexpected argument of type ${typeof unexpectedObject}`);
        }
    }

    /**
     * Throws an error if the given azure info object is configured for a different storage account than this azure file client.
     */
    private ensureSameStorageAccount(azureInfo: AzureStorageAccountInfo) {
        if (azureInfo.storageAccountName !== this.storageAccountName) {
            throw new Error(`Given azure object has different storage account (${azureInfo.storageAccountName}) than the one configured in this azure client object (${this.storageAccountName})`);
        }
    }

    /**
     * Returns a standardized backup filename for an existing file.
     */
    protected getBackupFileName(filename: string): string {
        return `${filename}-${getTemporaryFilename(this.username)}${BACKUP_FILENAME_EXTENSION}`;
    }

    /**
     * Returns a standardized intermediary filename for an existing file.
     */
    protected getNewTempName(filename: string): string {
        return `${filename}-${getTemporaryFilename(this.username)}${INTERMEDIARY_FILENAME_EXTENSION}`;
    }

    /**
     * Cleans existing temporary files matching a given source file, starting from oldest files.
     * @param fileInfo Temporary files for this file will be cleaned in this operation.
     * @param backupExtension The file extension for the targeted temporary files.
     * @param maxBackupsToKeep Maximum amount of temporary files matching the given backupExtension which will be left untouched.
     */
    private async cleanBackups(fileInfo: AzureFileInfo, backupExtension: string, maxBackupsToKeep: number): Promise<void> {
        // 1. get all files from source file directory
        const files = await this.listFiles(fileInfo.getPath());

        // 2. find files matching backup naming pattern
        const backupFiles = files.filter(f => f.filename.startsWith(fileInfo.filename) && f.filename.endsWith(backupExtension));

        // 3. sort the files by date
        const backupFilenamesAndTimestamps: { filename: string, timestamp: number }[] = [];
        for (const backupFile of backupFiles) {

            // try to parse timestamp from filename
            let timestamp = this.tryGetTempFileTimestamp(fileInfo.filename, backupFile.filename);

            if (timestamp === undefined) {
                // if unsuccessful, get the actual timestamp from azure file properties (considerably slower)
                const backupFileClient = await this.getFileClientAsync(backupFile);
                const properties = await backupFileClient.getProperties();
                const lastWriteOn = properties.fileLastWriteOn ? properties.fileLastWriteOn.getTime() : 0;
                const changedOn = properties.fileChangeOn ? properties.fileChangeOn.getTime() : 0;
                timestamp = Math.max(lastWriteOn, changedOn);
            }

            backupFilenamesAndTimestamps.push({ filename: backupFile.filename, timestamp: timestamp });
        }
        const sortedBackupFilenames = _.orderBy(backupFilenamesAndTimestamps, b => b.timestamp, 'desc');

        // 4. select which backups to remove, if any, and do just that
        const backupsToRemove = sortedBackupFilenames.length > maxBackupsToKeep ? sortedBackupFilenames.slice(maxBackupsToKeep) : [];
        for (const backupToRemove of backupsToRemove) {
            const fileInfoToRemove = fileInfo.getPath().getFile(backupToRemove.filename);
            await this.deleteFile(fileInfoToRemove);
        }
    }

    /**
     * Attempts to get a timestamp from a backup filename.
     * @param originalFilename The original source filename.
     * @param backupFilename The name of the backup file from which the timestamp will be parsed.
     * @returns Timestamp as a number if it was parsed successfully, or undefined otherwise.
     */
    private tryGetTempFileTimestamp(originalFilename: string, backupFilename: string): number | undefined {
        if (!backupFilename.includes(originalFilename) || backupFilename.length <= originalFilename.length) {
            // don't attempt to detect a timestamp from a filename that looks unrecogniseable
            return undefined;
        }

        // the second component of the time string in the backup file name,
        // get it and also remove the backup file extension
        const filenameBackupPortion = backupFilename.slice(originalFilename.length);
        const dashedParts = filenameBackupPortion.split('-');
        if (dashedParts.length < 3) {
            return undefined;
        }

        const timeString = `${dashedParts[1]}-${dashedParts[2]}`;
        const parsedTimestamp = getDateFromTempFileString(timeString);

        return parsedTimestamp !== null ? parsedTimestamp.getTime() : undefined;
    }
}
