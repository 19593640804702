// a React component (and supporting classes) for displaying session-only notifications (aka alerts) for the user
// don't use these for anything that should persist across sessions

import React from 'react';
import { connect } from 'react-redux';
import { Alert, Button, Fade, Modal } from 'react-bootstrap';

import * as sagas from '../../store/sagas';
import { StoreState } from '../../store/store';

import './Notifications.css';
import { notificationTypeToBootstrapVariant, SessionNotification } from './models/SessionNotification';
import LinkButton from './LinkButton';
import ModalDialog from './ModalDialog';


type NotificationProps = {
    notification: SessionNotification,
    onDismiss: (notificationId: string) => void,
}

type NotificationState = {
    isDismissing: boolean,
    showDetailedMessageModal: boolean,
}

class Notification extends React.Component<NotificationProps, NotificationState>{

    constructor(props: NotificationProps) {
        super(props);
        this.state = {
            isDismissing: false,
            showDetailedMessageModal: false,
        }
    }

    handleDismissing = () => {
        this.setState({ isDismissing: true });
    }

    handleDismissed = () => {
        // // give the react-bootstrap's own dismiss animation some time to play out before we eradicate this notification from redux store
        // setTimeout(() => this.props.onDismiss(this.props.notification.id), 2000);
        this.props.onDismiss(this.props.notification.id);
    }

    handleShowDetailedMessageModal = () => {
        this.setState({ showDetailedMessageModal: true })
    }

    handleHideDetailedMessageModal = () => {
        this.setState({ showDetailedMessageModal: false })
    }

    render() {

        const isDetailedMessageAvailable = this.props.notification.detailedMessage !== undefined;
        return (
            <>
                <div className="notification">
                    <Fade in={!this.state.isDismissing} onExited={this.handleDismissed}>
                        <Alert className="notification-alert shadow-sm" variant={notificationTypeToBootstrapVariant(this.props.notification.type)} dismissible onClose={this.handleDismissing} >
                            <div className="notification-text-container">
                                <span className="notification-text">{this.props.notification.message}</span>
                                {isDetailedMessageAvailable && (<span className="show-details-link">(<LinkButton onClick={this.handleShowDetailedMessageModal}>show details</LinkButton>)</span>)}
                            </div>
                        </Alert>
                    </Fade>
                </div>
                {isDetailedMessageAvailable && !this.state.isDismissing && (
                    <ModalDialog show={this.state.showDetailedMessageModal}
                        onHide={this.handleHideDetailedMessageModal}>
                        <Modal.Body>
                            {this.props.notification.detailedMessage}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.handleHideDetailedMessageModal}>Close</Button>
                        </Modal.Footer>
                    </ModalDialog>
                )}
            </>
        );
    }
}



type NotificationsListDispatchProps = {
    dismissNotification: (notificationId: string) => void,
}

type AllProps = StoreState & NotificationsListDispatchProps;

class NotificationsList extends React.PureComponent<AllProps>{
    handleDismiss = (notificationId: string) => {
        this.props.dismissNotification(notificationId);
    }

    render() {
        return (
            <div className="notifications-container">
                {this.props.notifications.map((notification: SessionNotification) => (
                    <Notification
                        notification={notification}
                        key={notification.id}
                        onDismiss={this.handleDismiss}
                    />
                ))}
            </div>
        )
    }
}

export default connect(
    state => Object.assign({}, state),
    sagas.mapDispatchToProps
)(NotificationsList);
