import { Matrix3f } from "./Matrix3f";
import { Vector3f } from "./Vector3f";

/**
 * Matrix4f represents a 4x4 matrix <br>
 * where elements are stored in <b>column</b> major order! <br>
 * This is the way OpenGL uses matrices, so it's the more feasible manner for this purpose.<br>
 * <br>
 * For column major matrices the correct order of operations is:<br>
 * <ul>
 * <li>Translation</li>
 * <li>Rotation</li>
 * <li>Scale</li>
 * </ul>
 */
export class Matrix4f {

    public m00 = 1; public m01 = 0; public m02 = 0; public  m03 = 0;
    public m10 = 0; public m11 = 1; public m12 = 0; public  m13 = 0;
    public m20 = 0; public m21 = 0; public m22 = 1; public  m23 = 0;
    public m30 = 0; public m31 = 0; public m32 = 0; public  m33 = 1;

    constructor(m00?: number | Matrix4f | Matrix3f | Array<number>, m01?: number, m02?: number, m03?: number,
        m10?: number, m11?: number, m12?: number, m13?: number,
        m20?: number, m21?: number, m22?: number, m23?: number,
        m30?: number, m31?: number, m32?: number, m33?: number){
        if(m00 !== undefined){
            this.set(m00, m01, m02, m03,
                m10, m11, m12, m13,
                m20, m21, m22, m23,
                m30, m31, m32, m33);
        }
    }

    static identity = new Matrix4f();

    public set(m00: number | Matrix4f | Matrix3f | Array<number>, m01?: number, m02?: number, m03?: number,
        m10?: number, m11?: number, m12?: number, m13?: number,
        m20?: number, m21?: number, m22?: number, m23?: number,
        m30?: number, m31?: number, m32?: number, m33?: number): Matrix4f {
        if(typeof m00 === 'number'){
            if(m01 !== undefined && m02 !== undefined && m03 !== undefined &&
                m10 !== undefined && m11 !== undefined && m12 !== undefined && m13 !== undefined &&
                m20 !== undefined && m21 !== undefined && m22 !== undefined && m23 !== undefined &&
                m30 !== undefined && m31 !== undefined && m32 !== undefined && m33 !== undefined){
                this.m00 = m00;
                this.m01 = m01;
                this.m02 = m02;
                this.m03 = m03;
                this.m10 = m10;
                this.m11 = m11;
                this.m12 = m12;
                this.m13 = m13;
                this.m20 = m20;
                this.m21 = m21;
                this.m22 = m22;
                this.m23 = m23;
                this.m30 = m30;
                this.m31 = m31;
                this.m32 = m32;
                this.m33 = m33;
            }
        } else if(m00 instanceof Matrix4f){
            this.m00 = m00.m00;
            this.m01 = m00.m01;
            this.m02 = m00.m02;
            this.m03 = m00.m03;
            this.m10 = m00.m10;
            this.m11 = m00.m11;
            this.m12 = m00.m12;
            this.m13 = m00.m13;
            this.m20 = m00.m20;
            this.m21 = m00.m21;
            this.m22 = m00.m22;
            this.m23 = m00.m23;
            this.m30 = m00.m30;
            this.m31 = m00.m31;
            this.m32 = m00.m32;
            this.m33 = m00.m33;
        } else if(m00 instanceof Matrix3f) {
            this.m00 = m00.m00;
            this.m01 = m00.m01;
            this.m02 = m00.m02;
            this.m10 = m00.m10;
            this.m11 = m00.m11;
            this.m12 = m00.m12;
            this.m20 = m00.m20;
            this.m21 = m00.m21;
            this.m22 = m00.m22;
        } else if(m00 instanceof Array && m00.length === 16){
            this.m00 = m00[0];
            this.m01 = m00[1];
            this.m02 = m00[2];
            this.m03 = m00[3];
            this.m10 = m00[4];
            this.m11 = m00[5];
            this.m12 = m00[6];
            this.m13 = m00[7];
            this.m20 = m00[8];
            this.m21 = m00[9];
            this.m22 = m00[10];
            this.m23 = m00[11];
            this.m30 = m00[12];
            this.m31 = m00[13];
            this.m32 = m00[14];
            this.m33 = m00[15];
        }
        return this;
    }

    setIdentity(): Matrix4f{
        this.m00 = this.m11 = this.m22 = this.m33 = 1;
        this.m01 = this.m02 = this.m03 = 0;
        this.m10 = this.m12 = this.m13 = 0;
        this.m20 = this.m21 = this.m23 = 0;
        this.m30 = this.m31 = this.m32 = 0;
        return this;
    }

    getArray(): Array<number> {
        let array = new Array<number>(16);
        array[0] = this.m00;
        array[1] = this.m01;
        array[2] = this.m02;
        array[3] = this.m03;
        array[4] = this.m10;
        array[5] = this.m11;
        array[6] = this.m12;
        array[7] = this.m13;
        array[8] = this.m20;
        array[9] = this.m21;
        array[10] = this.m22;
        array[11] = this.m23;
        array[12] = this.m30;
        array[13] = this.m31;
        array[14] = this.m32;
        array[15] = this.m33;
        return array;
    }

    getFloat32Array(): Float32Array {
        let array = new Float32Array(16);
        array[0] = this.m00;
        array[1] = this.m01;
        array[2] = this.m02;
        array[3] = this.m03;
        array[4] = this.m10;
        array[5] = this.m11;
        array[6] = this.m12;
        array[7] = this.m13;
        array[8] = this.m20;
        array[9] = this.m21;
        array[10] = this.m22;
        array[11] = this.m23;
        array[12] = this.m30;
        array[13] = this.m31;
        array[14] = this.m32;
        array[15] = this.m33;
        return array;
    }

    /**
     * A method to get matrice's transpose.
     * @returns this matrix with transpose applied
     */
    transpose(): Matrix4f {
        let array = new Array<number>(16);
        array[0] = this.m00;
        array[1] = this.m10;
        array[2] = this.m20;
        array[3] = this.m30;
        array[4] = this.m01;
        array[5] = this.m11;
        array[6] = this.m21;
        array[7] = this.m31;
        array[8] = this.m02;
        array[9] = this.m12;
        array[10] = this.m22;
        array[11] = this.m32;
        array[12] = this.m03;
        array[13] = this.m13;
        array[14] = this.m23;
        array[15] = this.m33;
        return this.set(array);
    }

    getTranspose(): Matrix4f {
        return new Matrix4f(this.m00, this.m10, this.m20, this.m30,
            this.m01, this.m11, this.m21, this.m31,
            this.m02, this.m12, this.m22, this.m32,
            this.m03, this.m13, this.m23, this.m33);
    }

    /**
     * A method to apply additional translation to this transformation matrix.<br>
     * The new translation is added on top of any previous translation.
     * @param translation Translation to be applied
     * @returns This matrix with additional translation applied
     */
    translate(translation: Vector3f): Matrix4f {
        this.m30 += this.m00 * translation.x + this.m10 * translation.y + this.m20 * translation.z;
        this.m31 += this.m01 * translation.x + this.m11 * translation.y + this.m21 * translation.z;
        this.m32 += this.m02 * translation.x + this.m12 * translation.y + this.m22 * translation.z;
        this.m33 += this.m03 * translation.x + this.m13 * translation.y + this.m23 * translation.z;
        return this;
    }

    public getTranslation(): Vector3f {
        return new Vector3f(this.m30, this.m31, this.m32);
    }

    /**
     * A method to apply additional rotation to this transformation matrix.<br>
     * The new rotation is added on top of any previous rotations.
     * @param angleRad The rotation angle in radians
     * @param axis The axis around which the rotation is performed
     * @returns This matrix with additional rotation applied
     */
    rotate(angleRad: number, axis: Vector3f): Matrix4f {
        if(angleRad === 0.0){
            return this;
        } else{
            const c = Math.cos(angleRad);
            const s = Math.cos(angleRad);
            const oneminusc = 1.0 - c;
            const xy = axis.x * axis.y * oneminusc;
            const yz = axis.y * axis.z * oneminusc;
            const xz = axis.x * axis.z * oneminusc;
            const sinScaled = new Vector3f(axis).scale(s);

            const f00 = axis.x * axis.x * oneminusc + c;
            const f01 = xy + sinScaled.z;
            const f02 = xz - sinScaled.y;

            const f10 = xy - sinScaled.z;
            const f11 = axis.y * axis.y * oneminusc + c;
            const f12 = yz + sinScaled.x;

            const f20 = xz + sinScaled.y;
            const f21 = yz - sinScaled.x;
            const f22 = axis.z * axis.z * oneminusc + c;

            const t00 = this.m00 * f00 + this.m10 * f01 + this.m20 * f02;
            const t01 = this.m01 * f00 + this.m11 * f01 + this.m21 * f02;
            const t02 = this.m02 * f00 + this.m12 * f01 + this.m22 * f02;
            const t03 = this.m03 * f00 + this.m13 * f01 + this.m23 * f02;
            const t10 = this.m00 * f10 + this.m10 * f11 + this.m20 * f12;
            const t11 = this.m01 * f10 + this.m11 * f11 + this.m21 * f12;
            const t12 = this.m02 * f10 + this.m12 * f11 + this.m22 * f12;
            const t13 = this.m03 * f10 + this.m13 * f11 + this.m23 * f12;
            this.m20 = this.m00 * f20 + this.m10 * f21 + this.m20 * f22;
            this.m21 = this.m01 * f20 + this.m11 * f21 + this.m21 * f22;
            this.m22 = this.m02 * f20 + this.m12 * f21 + this.m22 * f22;
            this.m23 = this.m03 * f20 + this.m13 * f21 + this.m23 * f22;
            this.m00 = t00;
            this.m01 = t01;
            this.m02 = t02;
            this.m03 = t03;
            this.m10 = t10;
            this.m11 = t11;
            this.m12 = t12;
            this.m13 = t13;
        }
        return this;
    }

    /**
     * A method to scale matrix by a vector
     * @param scale Vector to scale matrix by
     * @returns This matrix with additional scaling applied.
     */
    scale(scale: Vector3f): Matrix4f {
        this.m00 *= scale.x;
        this.m01 *= scale.x;
        this.m02 *= scale.x;
        this.m03 *= scale.x;
        this.m10 *= scale.y;
        this.m11 *= scale.y;
        this.m12 *= scale.y;
        this.m13 *= scale.y;
        this.m20 *= scale.z;
        this.m21 *= scale.z;
        this.m22 *= scale.z;
        this.m23 *= scale.z;
        return this;
    }

    /**
     * A convenience method that will multiply this matrix by another matrix <br>
     * and store the result inside this one
     * @param right The factor this matrix will be multiplied with
     * @returns This matrix with the multiplication performed
     */
    multiply(right: Matrix4f | Matrix3f): Matrix4f {
        return Matrix4f.multiply(this, right, this);
    }

    /**
     * A static method that will perform matrix multiplication.
     * Multiplication is performed by multiplying left with right and storing the result in dest.
     * If dest is not defined, a brand new destination matrix is created and returned.
     * @param left Matrix to be multiplied
     * @param right The factor the matrix is multiplied by
     * @param dest A destination matrix the result is stored to, or null if new matrix is to be created
     * @returns The destination matrix in which the multiplication result was stored
     */
    static multiply(left: Matrix4f, right: Matrix4f | Matrix3f, dest?: Matrix4f): Matrix4f{
        if(!dest){
            dest = new Matrix4f();
        }
        let array = new Array<number>(16);
        if(right instanceof Matrix4f){
            array[0] = left.m00 * right.m00 + left.m10 * right.m01 + left.m20 * right.m02 + left.m30 * right.m03;
            array[1] = left.m01 * right.m00 + left.m11 * right.m01 + left.m21 * right.m02 + left.m31 * right.m03;
            array[2] = left.m02 * right.m00 + left.m12 * right.m01 + left.m22 * right.m02 + left.m32 * right.m03;
            array[3] = left.m03 * right.m00 + left.m13 * right.m01 + left.m23 * right.m02 + left.m33 * right.m03;
            array[4] = left.m00 * right.m10 + left.m10 * right.m11 + left.m20 * right.m12 + left.m30 * right.m13;
            array[5] = left.m01 * right.m10 + left.m11 * right.m11 + left.m21 * right.m12 + left.m31 * right.m13;
            array[6] = left.m02 * right.m10 + left.m12 * right.m11 + left.m22 * right.m12 + left.m32 * right.m13;
            array[7] = left.m03 * right.m10 + left.m13 * right.m11 + left.m23 * right.m12 + left.m33 * right.m13;
            array[8] = left.m00 * right.m20 + left.m10 * right.m21 + left.m20 * right.m22 + left.m30 * right.m23;
            array[9] = left.m01 * right.m20 + left.m11 * right.m21 + left.m21 * right.m22 + left.m31 * right.m23;
            array[10] = left.m02 * right.m20 + left.m12 * right.m21 + left.m22 * right.m22 + left.m32 * right.m23;
            array[11] = left.m03 * right.m20 + left.m13 * right.m21 + left.m23 * right.m22 + left.m33 * right.m23;
            array[12] = left.m00 * right.m30 + left.m10 * right.m31 + left.m20 * right.m32 + left.m30 * right.m33;
            array[13] = left.m01 * right.m30 + left.m11 * right.m31 + left.m21 * right.m32 + left.m31 * right.m33;
            array[14] = left.m02 * right.m30 + left.m12 * right.m31 + left.m22 * right.m32 + left.m32 * right.m33;
            array[15] = left.m03 * right.m30 + left.m13 * right.m31 + left.m23 * right.m32 + left.m33 * right.m33;
        } else {
            array[0] = left.m00 * right.m00 + left.m10 * right.m01 + left.m20 * right.m02;
            array[1] = left.m01 * right.m00 + left.m11 * right.m01 + left.m21 * right.m02;
            array[2] = left.m02 * right.m00 + left.m12 * right.m01 + left.m22 * right.m02;
            array[3] = left.m03 * right.m00 + left.m13 * right.m01 + left.m23 * right.m02;
            array[4] = left.m00 * right.m10 + left.m10 * right.m11 + left.m20 * right.m12;
            array[5] = left.m01 * right.m10 + left.m11 * right.m11 + left.m21 * right.m12;
            array[6] = left.m02 * right.m10 + left.m12 * right.m11 + left.m22 * right.m12;
            array[7] = left.m03 * right.m10 + left.m13 * right.m11 + left.m23 * right.m12;
            array[8] = left.m00 * right.m20 + left.m10 * right.m21 + left.m20 * right.m22;
            array[9] = left.m01 * right.m20 + left.m11 * right.m21 + left.m21 * right.m22;
            array[10] = left.m02 * right.m20 + left.m12 * right.m21 + left.m22 * right.m22;
            array[11] = left.m03 * right.m20 + left.m13 * right.m21 + left.m23 * right.m22;
            array[12] = left.m30;
            array[13] = left.m31;
            array[14] = left.m32;
            array[15] = left.m33;
        }
        return dest.set(array);
    }

    public add(ref: Matrix4f): Matrix4f {
        this.m00 += ref.m00;
        this.m01 += ref.m01;
        this.m02 += ref.m02;
        this.m03 += ref.m03;
        this.m10 += ref.m10;
        this.m11 += ref.m11;
        this.m12 += ref.m12;
        this.m13 += ref.m13;
        this.m20 += ref.m20;
        this.m21 += ref.m21;
        this.m22 += ref.m22;
        this.m23 += ref.m23;
        this.m30 += ref.m30;
        this.m31 += ref.m31;
        this.m32 += ref.m32;
        this.m33 += ref.m33;
        return this;
    }

    /**
     * private static method that will calculate determinant for a sub 3x3 matrix.
     * @returns determinant calculated for a 3x3 matrix sub-region
     */
    private static determinant3x3(t00: number, t01: number, t02: number,
        t10: number, t11: number, t12: number,
        t20: number, t21: number, t22: number): number {
    return t00 * (t11 * t22 - t12 * t21)
    + t01 * (t12 * t20 - t10 * t22)
    + t02 * (t10 * t21 - t11 * t20);
}

    /**
     * Performs Matrix inversion, stores the inverted version within this matrix and returns reference to this.
     * note: Somewhat heavy operation
     * @returns Reference to this with matrix inversion performed
     */
    public invert(): Matrix4f {
        const determinant = this.getDeterminant();

        if(determinant === 0.0){
            return this;
        }

        const determinant_inv = 1.0/determinant;

        const t00 =  Matrix4f.determinant3x3(this.m11, this.m12, this.m13, this.m21, this.m22, this.m23, this.m31, this.m32, this.m33);
        const t01 = -Matrix4f.determinant3x3(this.m10, this.m12, this.m13, this.m20, this.m22, this.m23, this.m30, this.m32, this.m33);
        const t02 =  Matrix4f.determinant3x3(this.m10, this.m11, this.m13, this.m20, this.m21, this.m23, this.m30, this.m31, this.m33);
        const t03 = -Matrix4f.determinant3x3(this.m10, this.m11, this.m12, this.m20, this.m21, this.m22, this.m30, this.m31, this.m32);
        // second row
        const t10 = -Matrix4f.determinant3x3(this.m01, this.m02, this.m03, this.m21, this.m22, this.m23, this.m31, this.m32, this.m33);
        const t11 =  Matrix4f.determinant3x3(this.m00, this.m02, this.m03, this.m20, this.m22, this.m23, this.m30, this.m32, this.m33);
        const t12 = -Matrix4f.determinant3x3(this.m00, this.m01, this.m03, this.m20, this.m21, this.m23, this.m30, this.m31, this.m33);
        const t13 =  Matrix4f.determinant3x3(this.m00, this.m01, this.m02, this.m20, this.m21, this.m22, this.m30, this.m31, this.m32);
        // third row
        const t20 =  Matrix4f.determinant3x3(this.m01, this.m02, this.m03, this.m11, this.m12, this.m13, this.m31, this.m32, this.m33);
        const t21 = -Matrix4f.determinant3x3(this.m00, this.m02, this.m03, this.m10, this.m12, this.m13, this.m30, this.m32, this.m33);
        const t22 =  Matrix4f.determinant3x3(this.m00, this.m01, this.m03, this.m10, this.m11, this.m13, this.m30, this.m31, this.m33);
        const t23 = -Matrix4f.determinant3x3(this.m00, this.m01, this.m02, this.m10, this.m11, this.m12, this.m30, this.m31, this.m32);
        // fourth row
        const t30 = -Matrix4f.determinant3x3(this.m01, this.m02, this.m03, this.m11, this.m12, this.m13, this.m21, this.m22, this.m23);
        const t31 =  Matrix4f.determinant3x3(this.m00, this.m02, this.m03, this.m10, this.m12, this.m13, this.m20, this.m22, this.m23);
        const t32 = -Matrix4f.determinant3x3(this.m00, this.m01, this.m03, this.m10, this.m11, this.m13, this.m20, this.m21, this.m23);
        const t33 =  Matrix4f.determinant3x3(this.m00, this.m01, this.m02, this.m10, this.m11, this.m12, this.m20, this.m21, this.m22);

        // transpose and divide by the determinant
        this.m00 = t00*determinant_inv;
        this.m11 = t11*determinant_inv;
        this.m22 = t22*determinant_inv;
        this.m33 = t33*determinant_inv;
        this.m01 = t10*determinant_inv;
        this.m10 = t01*determinant_inv;
        this.m20 = t02*determinant_inv;
        this.m02 = t20*determinant_inv;
        this.m12 = t21*determinant_inv;
        this.m21 = t12*determinant_inv;
        this.m03 = t30*determinant_inv;
        this.m30 = t03*determinant_inv;
        this.m13 = t31*determinant_inv;
        this.m31 = t13*determinant_inv;
        this.m32 = t23*determinant_inv;
        this.m23 = t32*determinant_inv;

        return this;
    }

    /**
     * @returns the matrix determinant
     */
    public getDeterminant(): number {
        let f = this.m00
        		* ((this.m11 * this.m22 * this.m33 + this.m12 * this.m23 * this.m31 + this.m13 * this.m21 * this.m32)
                - this.m13 * this.m22 * this.m31 - this.m11 * this.m23 * this.m32 - this.m12 * this.m21 * this.m33);
        f -= this.m01
                * ((this.m10 * this.m22 * this.m33 + this.m12 * this.m23 * this.m30 + this.m13 * this.m20 * this.m32)
                - this.m13 * this.m22 * this.m30 - this.m10 * this.m23 * this.m32 - this.m12 * this.m20 * this.m33);
        f += this.m02
                * ((this.m10 * this.m21 * this.m33 + this.m11 * this.m23 * this.m30 + this.m13 * this.m20 * this.m31)
                - this.m13 * this.m21 * this.m30 - this.m10 * this.m23 * this.m31 - this.m11 * this.m20 * this.m33);
        f -= this.m03
                * ((this.m10 * this.m21 * this.m32 + this.m11 * this.m22 * this.m30 + this.m12 * this.m20 * this.m31)
                - this.m12 * this.m21 * this.m30 - this.m10 * this.m22 * this.m31 - this.m11 * this.m20 * this.m32);
        return f;
    }

    equals(ref: Matrix4f) {
        return this.m00 === ref.m00 && this.m01 === ref.m01 && this.m02 === ref.m02 && this.m03 === ref.m03 &&
            this.m10 === ref.m10 && this.m11 === ref.m11 && this.m12 === ref.m12 && this.m13 === ref.m13 &&
            this.m20 === ref.m20 && this.m21 === ref.m21 && this.m22 === ref.m22 && this.m23 === ref.m23 &&
            this.m30 === ref.m30 && this.m31 === ref.m31 && this.m32 === ref.m32 && this.m33 === ref.m33;
    }
}