import { getLinePolylineIntersections } from "../../../math/polygon-math-3d";
import { Axis, Plane, View } from "../../view";
import { RoiContours, Roi } from "../../../dicom/structure-set";
import { BoundingBox } from "../../../math/bounding-box";
import { Sdf, getRoiSdfResolution } from "./sdf";
import * as shaders from './brush-shaders';
import * as twgl from 'twgl.js';
import { BrushBuffer } from "../../mouse-tools/brush-tool";
import { flattenArray, equal, axisAlignedOrientation } from "../../../util";


export class SdfDrawing {
    gl: any;

    brushCircleProg: twgl.ProgramInfo;
    brushRectProg: twgl.ProgramInfo;

    constructor(gl: any) {
        this.gl = gl;
        this.brushCircleProg = twgl.createProgramInfo(gl, [shaders.SDF_BRUSH_CIRCLE_VS, shaders.SDF_BRUSH_CIRCLE_FS])
        this.brushRectProg = twgl.createProgramInfo(gl, [shaders.SDF_BRUSH_RECT_VS, shaders.SDF_BRUSH_RECT_FS])
    }

    public drawBrushStroke( brushBuffer: BrushBuffer, view: View, pointMm: number[]): void {
        const vm = view.viewManager;
        const vs = vm.viewerState;
        const sdf = brushBuffer.sdf;
        const img = vs.image;
        const gl = view.viewManager.getWebGlContext() as any;
        const radiusMm = view.viewManager.viewerState.brushWidthMm / 2;
        const intersectionThickness = 1.3;

        const fb = gl.createFramebuffer();
        gl.bindFramebuffer(gl.FRAMEBUFFER, fb);
        gl.enable(gl.BLEND);
        if(vs.erase) {
            gl.blendEquation(gl.MAX);
        }
        else {
            gl.blendEquation(gl.MIN);
        }

        const marginBig = radiusMm + sdf.maxDistanceMm / 2;
        const marginSmall = sdf.maxDistanceMm / 2;

        if(view.plane === Plane.Transversal) {

            brushBuffer.drawnBB.expandWithPoint(pointMm[0] - marginBig, pointMm[1] - marginBig, pointMm[2] - marginSmall);
            brushBuffer.drawnBB.expandWithPoint(pointMm[0] + marginBig, pointMm[1] + marginBig, pointMm[2] + marginSmall);
            
            const imgBb = img.getRealBoundingBox();
            const sdfSlice = Math.round( ((imgBb.minK + view.slice * img.kSpacing) - sdf.boundingBox.minK) / img.kSpacing );
            gl.framebufferTextureLayer(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, sdf.data, 0, sdfSlice);
            gl.viewport(0, 0, sdf.size[0], sdf.size[1]);

            const uniforms = {
                u_orientationPatient: axisAlignedOrientation, // img.orientationMatrix,
                u_inverseSizeMM: [
                    1 / sdf.boundingBox.getXSize(),
                    1 / sdf.boundingBox.getYSize(),
                    1 / img.kSpacing
                ],
                u_boundingBoxMin: [
                  sdf.boundingBox.minI,
                  sdf.boundingBox.minJ,
                  img.kMin + view.slice * img.kSpacing
                ],
                u_brushPointMM: pointMm,
                u_brushRadiusMM: radiusMm,
                u_maxDistanceMM: sdf.maxDistanceMm,
                u_inverseMaxDistanceMM: 1 / sdf.maxDistanceMm,
                u_erase: vs.erase ? 1 : 0
            }

            gl.useProgram(this.brushCircleProg.program);
            const arrays = {
                position: Array(6*3).fill(0), // Draw two triangles
              };
            const bufferInfo = twgl.createBufferInfoFromArrays(gl, arrays);
            twgl.setBuffersAndAttributes(gl, this.brushCircleProg, bufferInfo);
            twgl.setUniforms(this.brushCircleProg, uniforms);
            twgl.drawBufferInfo(gl, bufferInfo);
            
        }
        else { // Coronal and sagittal, Draw brush intersections
            for(let slice = 0; slice <= vm.maxSlice[Axis.Z]; ++slice) {
                const zPos = img.kMin + slice * img.kSpacing;
                const zDiff = Math.abs(zPos - pointMm[2]);
                if( zDiff > radiusMm + sdf.maxDistanceMm ) continue;
                const radius2 = Math.sqrt(radiusMm*radiusMm - zDiff*zDiff) // By Pythagorean theorem

                let x1 = 0, x2 = 0, y1 = 0, y2 = 0;
                if(view.plane === Plane.Coronal) {
                    
                    brushBuffer.drawnBB.expandWithPoint(pointMm[0] - marginBig, pointMm[1] - marginSmall, pointMm[2] - marginBig);
                    brushBuffer.drawnBB.expandWithPoint(pointMm[0] + marginBig, pointMm[1] + marginSmall, pointMm[2] + marginBig);

                    x1 = pointMm[0] - radius2;
                    x2 = pointMm[0] + radius2;
                    y1 = pointMm[1] - img.jSpacing * intersectionThickness/2;
                    y2 = pointMm[1] + img.jSpacing * intersectionThickness/2;
                }
                else if(view.plane === Plane.Sagittal) {

                    
                    brushBuffer.drawnBB.expandWithPoint(pointMm[0] - marginSmall, pointMm[1] - marginBig, pointMm[2] - marginBig);
                    brushBuffer.drawnBB.expandWithPoint(pointMm[0] + marginSmall, pointMm[1] + marginBig, pointMm[2] + marginBig);

                    x1 = pointMm[0] - img.iSpacing * intersectionThickness/2;
                    x2 = pointMm[0] + img.iSpacing * intersectionThickness/2;
                    y1 = pointMm[1] - radius2;
                    y2 = pointMm[1] + radius2;
                }

                gl.framebufferTextureLayer(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, sdf.data, 0, slice);
                gl.viewport(0, 0, sdf.size[0], sdf.size[1]);

                const uniforms = {
                    u_orientationPatient:  axisAlignedOrientation, // img.orientationMatrix,
                    u_inverseSizeMM: [
                        1 / sdf.boundingBox.getXSize(),
                        1 / sdf.boundingBox.getYSize(),
                        1 / img.kSpacing
                    ],
                    u_boundingBoxMin: [
                    sdf.boundingBox.minI,
                    sdf.boundingBox.minJ,
                    zPos
                    ],
                    u_maxDistanceMM: sdf.maxDistanceMm,
                    u_inverseMaxDistanceMM: 1 / sdf.maxDistanceMm,
                    u_erase: vs.erase ? 1 : 0,
                    u_x1: x1,
                    u_x2: x2,
                    u_y1: y1,
                    u_y2: y2
                }

                gl.useProgram(this.brushRectProg.program);

                const arrays = {
                    a_point: [
                        x1 - sdf.maxDistanceMm, y1 - sdf.maxDistanceMm, zPos,
                        x2 + sdf.maxDistanceMm, y1 - sdf.maxDistanceMm, zPos,
                        x2 + sdf.maxDistanceMm, y2 + sdf.maxDistanceMm, zPos,
                        x2 + sdf.maxDistanceMm, y2 + sdf.maxDistanceMm, zPos,
                        x1 - sdf.maxDistanceMm, y2 + sdf.maxDistanceMm, zPos,
                        x1 - sdf.maxDistanceMm, y1 - sdf.maxDistanceMm, zPos
                    ], // Draw two triangles
                };

                const bufferInfo = twgl.createBufferInfoFromArrays(gl, arrays);
                twgl.setBuffersAndAttributes(gl, this.brushRectProg, bufferInfo);
                twgl.setUniforms(this.brushRectProg, uniforms);
                twgl.drawBufferInfo(gl, bufferInfo);
            }
        }

        gl.bindFramebuffer(gl.FRAMEBUFFER, null);
        gl.deleteFramebuffer(fb);
        gl.disable(gl.BLEND);
        sdf.propagationPending = true;
    }
}


export const polygonToRoiContours = (view: View, polygonMm: number[][]): RoiContours => {
    const vm = view.viewManager;
    const vs = vm.viewerState;
    const img = vs.image;
    const intersectionThickness = 1.2;
    const roiContours: RoiContours = {};

    const deleteDuplicates = (polygon: number[][]) => {
        const result: number[][] = [];
        polygon.forEach(p => {
            if(!result.some(old => equal(old[0], p[0]) && equal(old[1], p[1]) && equal(old[2], p[2]))) {
                result.push(p);
            }
        });
        return result;
    }

    if(view.plane === Plane.Transversal) {
        const slice = view.slice;
        const sliceId = vs.image.sliceIds[slice];

        roiContours[sliceId] = {polygons: [polygonMm]};
    }
    else if(view.plane === Plane.Coronal) {
        for(let slice = 0; slice <= vm.maxSlice[Axis.Z]; ++slice) {
            const sliceId = vs.image.sliceIds[slice];
            const yMm = img.jMin + view.slice * img.jSpacing;
            const zMm = img.kMin + slice * img.kSpacing;
            // Define crossing line with two points
            const pt1 = [img.iMin - 100, yMm, zMm]
            const pt2 = [img.iMax + 100, yMm, zMm];
            let intersections = getLinePolylineIntersections(view, polygonMm, pt1, pt2); 
            intersections = deleteDuplicates(intersections);
            function compare( a: number[], b: number[] ) {
                if( a[0] < b[0]) return -1;
                return 1;
            }
            intersections.sort(compare);
            if(intersections.length % 2) {
               const error = "Error: odd number of intersections";
                console.log(error, intersections.length);
                console.log(intersections, pt1, pt2);
                continue;
            }
            
            for(let i = 0; i < intersections.length; i += 2){
                const p1 = intersections[i]
                const p2 = intersections[i+1]

                const diff = img.jSpacing * intersectionThickness/2;
                const projectedPolygonMm = [
                    [p1[0], p1[1] - diff, p1[2] ], // top left
                    [p2[0], p2[1] - diff, p2[2] ], // top right
                    [p2[0], p2[1] + diff, p2[2] ], // bottom right
                    [p1[0], p1[1] + diff, p1[2] ], // bottom left
                    [p1[0], p1[1] - diff, p1[2] ], // top left
                ];
                roiContours[sliceId] = roiContours[sliceId] || {};
                let polys: any = roiContours[sliceId].polygons || [];
                polys.push( flattenArray(projectedPolygonMm) );
                roiContours[sliceId].polygons = polys;
                
            }
        }
    }
    else if(view.plane === Plane.Sagittal) {
        for(let slice = 0; slice <= vm.maxSlice[Axis.Z]; ++slice) {
            const sliceId = vs.image.sliceIds[slice];
            const xMm = img.iMin + view.slice * img.iSpacing;
            const zMm = img.kMin + slice * img.kSpacing;
            // Define crossing line with two points
            const pt1 = [xMm, img.jMin - 100, zMm]
            const pt2 = [xMm, img.jMax + 100, zMm];
            let intersections = getLinePolylineIntersections(view, polygonMm, pt1, pt2); 
            intersections = deleteDuplicates(intersections);
            function compare( a: number[], b: number[] ) {
                if( a[1] < b[1]) return -1;
                return 1;
            }
            intersections.sort(compare);
            if(intersections.length % 2) {
                const error = "Error: odd number of intersections";
                console.log(error, intersections.length);
                console.log(intersections, pt1, pt2);
                continue;
            }
            
            for(let i = 0; i < intersections.length; i += 2){
                const p1 = intersections[i]
                const p2 = intersections[i+1]

                const diff = img.iSpacing * intersectionThickness/2;
                const projectedPolygonMm = [
                    [p1[0] - diff, p1[1], p1[2] ], // top left
                    [p1[0] + diff, p1[1], p1[2] ], // top right
                    [p2[0] + diff, p2[1], p2[2] ], // bottom right
                    [p2[0] - diff, p2[1], p2[2] ], // bottom left
                    [p1[0] - diff, p1[1], p1[2] ], // top left
                ];
                roiContours[sliceId] = roiContours[sliceId] || {};
                let polys: any = roiContours[sliceId].polygons || [];
                polys.push( flattenArray(projectedPolygonMm));
                roiContours[sliceId].polygons = polys;
            }
        }
    }
    return roiContours;
}

export const polygonToSdf = ( view: View, roi: Roi, polygonMm: number[][]) : Sdf => {
    
    const vm = view.viewManager;
    const vs = vm.viewerState;
    const img = vs.image;
    const resolution = getRoiSdfResolution(img, roi);
   
    const sdf = new Sdf(vm, resolution);
    let bb = new BoundingBox();
    bb.expandWithPoints(polygonMm);
    sdf.createTexture(bb, true, false);

    const roiContours = polygonToRoiContours(view, polygonMm);
    sdf.addContours(roiContours);
    return sdf;

}