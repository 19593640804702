export class FrameBufferObject {
    private width: number;
    private height: number;
    private gl: WebGL2RenderingContext;
    private originalDimensions?: Int32Array;

    public readonly id: WebGLFramebuffer | null;
    private renderTargets: Map<number, RenderTarget>;

    constructor(gl: WebGL2RenderingContext, width: number, height: number) {
        this.gl = gl;
        this.width = Math.ceil(width);
        this.height = Math.ceil(height);
        this.renderTargets = new Map<number, RenderTarget>();
        this.id = gl.createFramebuffer();
        this.bind();
    }

    public bind(): void {
        this.gl.bindFramebuffer(this.gl.FRAMEBUFFER, this.id);
        this.originalDimensions = this.gl.getParameter(this.gl.VIEWPORT);
        this.gl.viewport(0, 0, this.width, this.height);
    }

    public unbind(): void {
        this.gl.bindFramebuffer(this.gl.FRAMEBUFFER, null);
        if(this.originalDimensions){
            this.gl.viewport(0, 0, this.originalDimensions[2], this.originalDimensions[3]);
        }
    }

    public createTextureAttachment(attachment: number, internalFormat: number, format: number, type: number){
        let old: RenderTarget | undefined;
        if(old = this.renderTargets.get(attachment)){
            old.delete(this.gl);
        }
        let target = new TextureRenderTarget(this.gl, this.width, this.height,
            attachment, internalFormat, format, type);
        this.renderTargets.set(attachment, target);
    }

    public createBufferAttachment(attachment: number, internalFormat: number){
        let target = new BufferRenderTarget(this.gl, this.width, this.height, attachment, internalFormat);
        this.renderTargets.set(attachment, target);
    }

    public createTextureLayer(texture: WebGLTexture | null, layer: number, attachment: number = this.gl.COLOR_ATTACHMENT0){
        if(texture){
            this.gl.framebufferTextureLayer(this.gl.FRAMEBUFFER, attachment, texture, 0, layer);
        } else {
            console.log("Attempting to bind null texture onto a FrameBufferObject");
        }
    }

    public attachTexture(texture: WebGLTexture, attachment?: number){
        if(!attachment){
            attachment = this.gl.COLOR_ATTACHMENT0;
        }
        if(this.renderTargets.has(attachment)){
            console.log("Attempting to add texture attachment to FrameBufferObject on slot " +attachment +" which is already occupied");
        }
        this.gl.framebufferTexture2D(this.gl.FRAMEBUFFER, attachment, this.gl.TEXTURE_2D, texture, 0);
    }

    public getWidth(): number {
        return this.width;
    }

    public getHeight(): number {
        return this.height;
    }

    public bindRenderTarget(attachment: number): boolean {
        let target = this.renderTargets.get(attachment);
        if(target){
            return target.bind(this.gl);
        }
        console.log("Attempting to bind non-existing render target. " +new Error().stack);
        return false;
    }

    public extractRenderTarget(attachment: number): RenderTarget | null{
        let target = this.renderTargets.get(attachment);
        if(target){
            this.renderTargets.delete(attachment);
            return target;
        }
        return null;
    }

    public delete(): void {
        this.renderTargets.forEach(r => {
            r.delete(this.gl);
        });
        this.renderTargets.clear();
        this.gl.deleteFramebuffer(this.id);
    }

    public checkStatus(): boolean {
        let status = this.gl.checkFramebufferStatus(this.gl.FRAMEBUFFER);
        if(status === 0){
            throw new Error("FrameBuffer got an error:\n" +status);
        } else if(status !== this.gl.FRAMEBUFFER_COMPLETE){
            console.log("FrameBuffer status " +status);
        }
        return true;
    }

    public static getBufferSize(gl: WebGL2RenderingContext, width: number, height: number, bytesPerPixel: number): number {
        const alignment = gl.getParameter(gl.UNPACK_ALIGNMENT);
        const rowLength = gl.getParameter(gl.UNPACK_ROW_LENGTH) | width;
        const skipRows = gl.getParameter(gl.UNPACK_SKIP_ROWS);
        const skipPixels = gl.getParameter(gl.UNPACK_SKIP_PIXELS);
        const imageHeight = gl.getParameter(gl.UNPACK_IMAGE_HEIGHT) | height;
        const skipImages = gl.getParameter(gl.UNPACK_SKIP_IMAGES);

        let rowLengthInBytes = rowLength * bytesPerPixel;
        let skipBytes = skipPixels * bytesPerPixel;

        switch(alignment) {
            case 1:
                break;
            case 2:
            case 4:
            case 8:
                let remainder = rowLengthInBytes & (alignment - 1);
                if(remainder > 0) {
                    rowLengthInBytes += alignment - remainder;
                }
                remainder = skipBytes & (alignment - 1);
                if(remainder > 0){
                    skipBytes += alignment - remainder;
                }
                break;
            default:
                console.error("Invalid alignment " +alignment +", must be 2**n (1, 2, 4, 8)");
        }
        return skipBytes + (skipImages - 1) * imageHeight * rowLengthInBytes +
        (skipRows + height - 1) * rowLengthInBytes + width * bytesPerPixel;
    }
}

export abstract class RenderTarget {

    protected id: any;

    protected constructor(id: any){
        this.id = id;
    }

    public getId(): any{
        return this.id;
    }

    public abstract delete(gl: WebGL2RenderingContext): void;

    public abstract bind(gl: WebGL2RenderingContext): boolean;
}

export class BufferRenderTarget extends RenderTarget {

    /**
     * 
     * @param gl 
     * @param width 
     * @param height 
     * @param attachment gl.COLOR_ATTACHMENTi, gl.DEPTH_ATTACHMENT, gl.DEPTH_STENCIL_ATTACHMENT, gl.STENCIL_ATTACHMENT 
     * @param internalFormat
     */
    public constructor(gl: WebGL2RenderingContext, width: number, height: number, attachment: number, internalFormat: number){
        super(gl.createRenderbuffer());
        if(this.id){
            gl.bindRenderbuffer(gl.RENDERBUFFER, this.id);
            gl.renderbufferStorage(gl.RENDERBUFFER, internalFormat, width, height);
            gl.framebufferRenderbuffer(gl.FRAMEBUFFER, attachment, gl.RENDERBUFFER, this.id);
        } else {
            console.error("Failed creating RenderBuffer as FBO's render target");
        }
    }

    public delete(gl: WebGL2RenderingContext): void {
        gl.deleteRenderbuffer(this.id);
    }

    public bind(gl: WebGL2RenderingContext): boolean{
        gl.bindRenderbuffer(gl.RENDERBUFFER, this.id);
        return true;
    }
}

export class TextureRenderTarget extends RenderTarget {

    public constructor(gl: WebGL2RenderingContext, width: number, height: number, attachment: number, internalFormat: number, format: number, type: number) {
        super(gl.createTexture());
        gl.bindTexture(gl.TEXTURE_2D, this.id);
        gl.texImage2D(gl.TEXTURE_2D, 0, internalFormat, width, height, 0, format, type, null);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
        gl.framebufferTexture2D(gl.FRAMEBUFFER, attachment, gl.TEXTURE_2D, this.id, 0);
    }

    public delete(gl: WebGL2RenderingContext): void {
        gl.deleteTexture(this.id);
    }

    public bind(gl: WebGL2RenderingContext): boolean {
        gl.bindTexture(gl.TEXTURE_2D, this.id);
        return true;
    }
}