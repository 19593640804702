// ROI list component 

import React from 'react';
import { Button, DropdownButton, Dropdown, Row, Col,  Modal, Form, SplitButton} from 'react-bootstrap';
import { Menu, Item, Separator, contextMenu, ItemParams } from 'react-contexify';
import 'react-contexify/dist/ReactContexify.min.css';
import { SketchPicker } from 'react-color';
import { connect } from 'react-redux';
import { MdMessage, MdSave } from 'react-icons/md';
import _ from 'lodash';
import { HotKeys } from "react-hotkeys";

import { Checkbox } from '../misc-components';
import { roiCompare, areStringsSameCi } from '../../helpers/compare';
import * as sagas from '../../store/sagas';
import * as structureSet from '../../dicom/structure-set';
import { StructureSetGrading, RoiGrading, GradingMeanings, GradingWorkflowState, DuplicateRoiGradingError, checkForMultipleSameGradeRois, DatasetGradings, getGradingVariant } from '../../datasets/roi-grading';

import { ViewerState } from '../../rtviewer-core/viewer-state';
import { Interpolation } from '../../rtviewer-core/webgl/sdf/interpolation/sdf-interpolation';
import { NewItemGlyph } from '../glyphs';
import { AddMarginDialog } from './dialogs/AddMarginDialog';
import { MarginOptions, MarginOperations } from '../../rtviewer-core/webgl/sdf/margin/margin';
import GradingStateDropdown from './grading/GradingStateDropdown';
import ConfirmGradingSaveDialog from './dialogs/ConfirmGradingSaveDialog';
import { GradingToSave } from '../../datasets/dataset-files';
import { StoreState } from '../../store/store';
import { Dataset } from '../../datasets/dataset';
import ConfirmRoiDeletionDialog from './dialogs/ConfirmRoiDeletionDialog';
import RoiTypeMenu from './RoiTypeMenu';
import ModalDialog from '../common/ModalDialog';

import './ROITable.css';
import { getDateTimeString } from '../../util';

type OwnProps = {
    viewerState: ViewerState,
    structureSet: structureSet.StructureSet | null,
    structureSets: structureSet.StructureSet[],
    grading: StructureSetGrading | null,
    allDatasetGradings: DatasetGradings | null,
    openAddStructuresFromTemplateDialog: () => void,
    onAddRoiClick: (ss: structureSet.StructureSet) => void,
    doGradingSync: (ss: structureSet.StructureSet) => void,
}

type DispatchProps = {
    saveGrading: (structureSetId: string, ssGrading: StructureSetGrading | null, dataset: Dataset, lastUpdated?: string | undefined) => void,
    saveDatasetGradings: (gradingsToSave: GradingToSave[], dataset: Dataset) => void,
    setGradingWithoutSaving: (structureSetId: string, ssGrading: StructureSetGrading | null, dataset: Dataset) => void,
}

type AllProps = StoreState & OwnProps & DispatchProps;

type OwnState = {
    // TODO: all these roiToSomethings could be done with selectedIndices
    roiToRename?: structureSet.Roi | null,
    roiToComment?: structureSet.Roi | null,
    roiForColorSelect?: structureSet.Roi | null,
    comment?: string,
    showCommentModal?: boolean,
    newName?: string,
    roiForMarginTool?: structureSet.Roi | null,
    selectedColor?: any,
    refreshSwitch?: any,
    duplicateGoodGradedRoisErrors: DuplicateRoiGradingError[] | null,
    previousRoiGrading: StructureSetGrading | null,
    roiNumberNeedingConfirmation: string | null,
    showDeleteRoiDialog: boolean,

    /** The list index numbers of ROIs that user has currently selected. 0, 1, or more. */
    selectedIndices: number[],

    /** The last selected ROI item index user actively clicked on. This can include when clicked on with the CTRL modifier, but does
     * NOT include when clicked on with the SHIFT key modifier. This value is used as a starting index when performing mass list
     * selections (e.g. when user continously clicks on the ROI list with shift-clicks) to ensure the selections stay sensible.
     * Initializes and should default to '0' (first item in the list).
     */
    lastIndexClicked: number,

    /** An unfortunate hack we need to keep track when viewerState.selectedRoi is changed. Tracking this is not possible in any other
     * way as viewerState is not an immutable object and it lives outside of React lifecycle.
     */
    previousSelectedRoiInViewerStateHack: structureSet.Roi | null,
    previousSelectedStructureSetInViewerStateHack: structureSet.StructureSet | null,
}

/** keyboard shortcut map for this component */
const keyMap = {
    DELETE_ITEM: ["del"],
    SELECT_ALL: ["ctrl+a"],  
};

enum RoiContextMenuAction { Focus, Rename, Duplicate, CopyToClipboard, ChangeColor, CreateMargin, Interpolate, Delete };
type RoiContextMenuProps = { roi: structureSet.Roi };
type RoiContextMenuData = { action: RoiContextMenuAction };

class ROITable extends React.Component<AllProps, OwnState> {
    displayName = ROITable.name

    roiContextMenuId = "roi-context-menu";

    constructor(props: AllProps) {
        super(props);
        this.render = this.render.bind(this);

        this.state = {
            duplicateGoodGradedRoisErrors: null,
            previousRoiGrading: null,
            roiNumberNeedingConfirmation: null,
            selectedIndices: [],
            showDeleteRoiDialog: false,
            lastIndexClicked: 0,
            previousSelectedRoiInViewerStateHack: null,
            previousSelectedStructureSetInViewerStateHack: null,
         };
    }

    componentDidMount() {
        let vs = this.props.viewerState;
        vs.addListener(this.updateView);
        this.updateView();
    }

    componentWillUnmount() {
        let vs = this.props.viewerState;
        vs.removeListener(this.updateView);
    }

    componentDidUpdate = (prevProps: AllProps, prevState: OwnState) => {
        let viewerStateSelectedRoiWasChanged = false;

        if (this.state.previousSelectedRoiInViewerStateHack !== this.props.viewerState.selectedRoi) {
            // ALWAYS update our viewerState.selectedRoi hack. Flag that selectedRoi change path
            // must be processed.
            viewerStateSelectedRoiWasChanged = true;
            this.setState({
                previousSelectedRoiInViewerStateHack: this.props.viewerState.selectedRoi,
            });
        }

        if (prevProps.structureSet !== this.props.structureSet) {
            // structure set was changed -- clear some structure set specific state data, don't do
            // anything else
            this.setState({
                selectedIndices: [],
                lastIndexClicked: 0,
            });
            return;
        }

        if (viewerStateSelectedRoiWasChanged) {
            // ROI was changed without clicking on the ROI table (e.g. by clicking on the contours)
            // TODO: allow user to ctrl-click multiple ROIs into selection. Currently the selection is just cleared.
            const newSelection: number[] = [];
            let index = 0;
            const { selectedRoi } = this.props.viewerState;
            if (selectedRoi) {
                const roiList = this.getRoiList();
                if (roiList) {
                    index = roiList.findIndex(r => r === selectedRoi);
                    if (index !== -1) {
                        newSelection.push(index);
                    }
                }
            }

            this.setState({
                selectedIndices: newSelection,
                lastIndexClicked: index !== -1 ? index : 0,
            });
        }

        // TODO: is this being called too aggressively?
        this.selectedRoiToViewport();
    }

    static getDerivedStateFromProps = (props: AllProps, state: OwnState): Partial<OwnState> | null => {
        if (state.previousSelectedStructureSetInViewerStateHack !== props.viewerState.selectedStructureSet) {
            // clear current selectedIndices if structure set was changed; also keep track of the previous viewer
            // state selected structure set value
            return { previousSelectedStructureSetInViewerStateHack: props.viewerState.selectedStructureSet, selectedIndices: [] };
        }

        return null;
    }
    
    updateView = () => {
        this.setState({ refreshSwitch: !this.state.refreshSwitch });
    }

    handleSelectRoiClick = (roi: structureSet.Roi, evt: React.MouseEvent<HTMLDivElement, MouseEvent> | null) => {

        const vs = this.props.viewerState;
        vs.setSelectedRoi(roi);

        const ss = roi.structureSet;
        const roiList = this.getRoiList(ss)!;
        const selectedIndex = roiList.indexOf(roi);
        let lastIndexClicked = selectedIndex;
        let newIndices: number[] = [selectedIndex];

        // ctrl-click -> add or remove this single roi from selection
        if (evt && evt.ctrlKey) {
            if (this.state.selectedIndices.includes(selectedIndex)){
                newIndices = _.without(this.state.selectedIndices, selectedIndex);
            } else {
                newIndices = this.state.selectedIndices.concat(selectedIndex);
            }
        }

        // shift-click -> choose a list of elements, either up or down
        if (evt && evt.shiftKey) {
            // the previous item we clicked on is the last item from the current
            // indices selection array
            const previousIndexSelection = this.state.lastIndexClicked;

            // set the new last index clicked as whatever it was previously.
            // this ensures repeated shift-click selections behave well.
            lastIndexClicked = this.state.lastIndexClicked;

            // special case: if we just clicked on the same item again as previously,
            // ensure it's in the list and do nothing else
            if (previousIndexSelection === selectedIndex) {
                if (!_.includes(newIndices, selectedIndex)) { newIndices.push(selectedIndex); }
            }
            else {
                const direction = selectedIndex - previousIndexSelection > 0 ? 1 : -1;

                if (!evt.ctrlKey) {
                    // clear previous selection if ctrl was NOT pressed
                    newIndices = [];
                }

                // always loop from previous selection towards current selection so the current
                // one will be the last one to be added to the new indices selection. duplicates
                // will be removed in the next step.
                newIndices.push(previousIndexSelection);
                let i = previousIndexSelection;
                while (i !== selectedIndex) {
                    i += 1 * direction;
                    newIndices.push(i);
                }

                // remove any duplicates, retain first-in order
                newIndices = _.uniq(newIndices);
            }
        }

        this.setState({ selectedIndices: newIndices, lastIndexClicked: lastIndexClicked, previousSelectedRoiInViewerStateHack: roi, });
    }

    handleFocusToRoiClick = (roi: structureSet.Roi) => {
        let ss = this.props.structureSet as structureSet.StructureSet;
        let vs = this.props.viewerState;
        let vm = vs.viewManager;
        this.handleSelectRoiClick(roi, null);
        if(roi.sdf) {
            vm.focusToRoi(roi.sdf.boundingBox);
        }
        else { // Maybe there are marker points?
            if( ss.contourData[roi.roiNumber] ) {
                const sliceIds = Object.keys(ss.contourData[roi.roiNumber]);
                if(sliceIds && sliceIds.length) vm.scrollToSlice(sliceIds[0]);
            }
        }
    }

    handleRoiVisibleChange = (event: any, roi: structureSet.Roi) => {
        let val = event.target.checked;
        let vs = this.props.viewerState;
        vs.setRoiHidden(roi, !val);
    }

    handleAllRoisVisibleChange = () => {
        const vs = this.props.viewerState;
        const shouldAllRoisBeHidden = vs.getAreAllRoisVisible();
        vs.setAllRoisHidden(shouldAllRoisBeHidden);
    }

    handleGradingUnsureChange = (event: any, roi: structureSet.Roi) => {
        const ss = this.props.structureSet;
        const originalGrading = this.props.grading;
        if(!ss || !originalGrading) {
            event.preventDefault();
            return;
        }

        const newGrading = originalGrading.clone();
        const lastUpdated = getDateTimeString();

        newGrading.rois[roi.roiNumber] = newGrading.rois[roi.roiNumber] || new RoiGrading(roi.name);
        newGrading.rois[roi.roiNumber].unsure = Boolean(event.target.checked);
        newGrading.rois[roi.roiNumber].lastUpdated = lastUpdated;
        this.props.saveGrading(ss.structureSetId, newGrading, this.props.currentWorkState.dataset, lastUpdated);
    }

    handleGradeRoi = (roi: structureSet.Roi, val: any) => {
        const ss = this.props.structureSet;
        const dataset: Dataset | null = this.props.currentWorkState.dataset;
        const originalGrading = this.props.grading;
        if (!ss || !originalGrading || !dataset) { return; }

        const previousRoiGrading = originalGrading.clone();
        const newGrading = originalGrading.clone();
        const lastUpdated = getDateTimeString();

        newGrading.rois[roi.roiNumber] = newGrading.rois[roi.roiNumber] || new RoiGrading(roi.name);
        newGrading.rois[roi.roiNumber].value = val;
        newGrading.rois[roi.roiNumber].valueMeaning = GradingMeanings[val];
        newGrading.rois[roi.roiNumber].lastUpdated = lastUpdated;

        // check that there are no multiple ROIs in this scan that have been graded "1 - Good"
        const datasetGradings = this.props.datasetGradings[dataset.getDatasetId()];
        const structureSets = this.props.structureSets ? this.props.structureSets : null;
        if (datasetGradings && structureSets) {
            const duplicateGoodGradedRoisErrors = checkForMultipleSameGradeRois(ss, newGrading, datasetGradings, structureSets, "1");
            if (duplicateGoodGradedRoisErrors && duplicateGoodGradedRoisErrors.length > 0) {
                // there are, so prevent saving from now and request confirmation from user.
                // store current state so we can force either the save or the revert after user has made their choice.
                // note that this previous state remains applicable only as long as the current structure set context
                // doesn't change -- this should not be an issue, the dialog requiring user confirmation is blocking.
                this.setState({ 
                    previousRoiGrading,
                    duplicateGoodGradedRoisErrors,
                    roiNumberNeedingConfirmation: roi.roiNumber
                });

                // nevertheless, temporary apply the current grading so it shows up correctly in the UI
                this.props.setGradingWithoutSaving(ss.structureSetId, newGrading, dataset);

                return;
            }
        }

        this.props.saveGrading(ss.structureSetId, newGrading, dataset, lastUpdated);
    }
    
    handleConfirmSaveAndObsoleteOldGradingsChange = () => {
        const { duplicateGoodGradedRoisErrors, roiNumberNeedingConfirmation } = this.state;
        const ss = this.props.structureSet;
        const originalGrading = this.props.grading;
        if (!ss || !originalGrading) { return; }

        // we need to keep track of all the gradings we're about to modify so we can save them
        const gradingsToSave: GradingToSave[] = [];

        // only anything to do here if we have the state values
        if (duplicateGoodGradedRoisErrors && roiNumberNeedingConfirmation && Object.keys(originalGrading.rois).includes(roiNumberNeedingConfirmation)) {

            const datasetGradings = this.props.allDatasetGradings;
            const roiBeingChanged = originalGrading.rois[roiNumberNeedingConfirmation];
            const roiGradingDuplicates = duplicateGoodGradedRoisErrors.find(d => d.roiName === roiBeingChanged.roiName);
            if (roiGradingDuplicates) {
                for (const structureSetId of Object.keys(roiGradingDuplicates.duplicateStructureSetLabels)) {
                    if (structureSetId === ss.structureSetId) { 
                        // don't change currently active structure set's grading for this roi
                        continue;
                    }
                    if (datasetGradings && Object.keys(datasetGradings.structureSets).includes(structureSetId)) {
                        const newGradings = datasetGradings.clone();
                        const roi = Object.values(newGradings.structureSets[structureSetId].rois).find(r => areStringsSameCi(r.roiName, roiBeingChanged.roiName));
                        if (roi) {
                            roi.value = "6";
                            roi.valueMeaning = GradingMeanings["6"];
                            const structureSetToSave = this.props.structureSets ? this.props.structureSets.find(ss => ss.structureSetId === structureSetId) : undefined;
                            if (structureSetToSave !== undefined) {
                                gradingsToSave.push({ssId: structureSetToSave.structureSetId, ssGrading: newGradings.structureSets[structureSetId]});
                            }
                        }
                    }
                }
            }
        }

        // finally, always include the current structure set and its grading
        gradingsToSave.push({ssId: ss.structureSetId, ssGrading: originalGrading});

        this.props.saveDatasetGradings(gradingsToSave, this.props.currentWorkState.dataset)
        this.setState({ previousRoiGrading: null, duplicateGoodGradedRoisErrors: null, roiNumberNeedingConfirmation: null });
    }

    handleCancelGradingChange = () => {
        const { previousRoiGrading } = this.state;
        const ss = this.props.structureSet;
        const dataset: Dataset | null = this.props.currentWorkState.dataset;

        if (!previousRoiGrading || !ss || !dataset) { return; }

        // revert to previous grading
        this.props.setGradingWithoutSaving(ss.structureSetId, previousRoiGrading, dataset);

        this.setState({ previousRoiGrading: null, duplicateGoodGradedRoisErrors: null, roiNumberNeedingConfirmation: null });
    }

    handleCommentChanged = (event: any) => {
        const comment = event.target.value;
        this.setState({ comment: comment });
    }

    handleCloseCommentModal = () => {
        const ss = this.props.structureSet;
        const originalGrading = this.props.grading;
        if (!ss || !originalGrading) { return; }

        const newGrading = originalGrading.clone();
        const lastUpdated = getDateTimeString();
        
        const comment = this.state.comment || undefined;
        if (this.state.roiToComment) {
            const roi = this.state.roiToComment;
            newGrading.rois[roi.roiNumber] = newGrading.rois[roi.roiNumber] || new RoiGrading(roi.name);
            newGrading.rois[roi.roiNumber].comment = comment;
            newGrading.rois[roi.roiNumber].lastUpdated = lastUpdated;
        }
        else {
            newGrading.comment = comment;
        }
        this.props.saveGrading(ss.structureSetId, newGrading, this.props.currentWorkState.dataset, lastUpdated);
        this.setState({ showCommentModal: false });
    }
    
    handleShowCommentModal = (roi: structureSet.Roi | null) => {
        const g = this.props.grading;
        if(!g) { return; }

        let comment = '';
        if (roi) {
            if(g.rois[roi.roiNumber]){
                comment = g.rois[roi.roiNumber].comment || '';
            }
        }
        else {
            if(g.comment) {
                comment = g.comment;
            }
        } 
        this.setState({ showCommentModal: true, roiToComment: roi, comment: comment });
    }

    handleCancelCommentModal = () => {
        this.setState({ showCommentModal: false });
    }

    handleGradingStateChange = (workflowState: GradingWorkflowState) => {
        const ss = this.props.structureSet;
        const originalGrading = this.props.grading;
        if (!ss || !originalGrading) { return; }

        const newGrading = originalGrading.clone();
        newGrading.workflowState = workflowState;
        this.props.saveGrading(ss.structureSetId, newGrading, this.props.currentWorkState.dataset);
    }

    handleRenameRoiClick = (roi: structureSet.Roi) => {
        this.setState({roiToRename: roi, newName: roi.name}, function(){
            const arr = document.getElementsByClassName("roi-name-edit");
            (arr[arr.length - 1] as HTMLElement).focus();
        });
    }

    handleDuplicateRoiClick = (roi: structureSet.Roi) => {
        const vs = this.props.viewerState;
        const vm = vs.viewManager;
        const ss = vs.selectedStructureSet;
        if (ss) {
            ss.duplicateRoi(vm, roi);
            vs.notifyListeners();
        }
    }

    handleCopyToClipboard = () => {
        const vs = this.props.viewerState;
        const ss = vs.selectedStructureSet;
        const roiList = this.getRoiList();
        if (ss && roiList) {
            const rois = this.state.selectedIndices.map(i => roiList[i]);
            vs.setCopiedRois(rois);
        }
    }

    handleInterpolateRoiClick = (roi: structureSet.Roi) => {
        const vs = this.props.viewerState;
        const vm = vs.viewManager;
        const ss = vs.selectedStructureSet as structureSet.StructureSet;
        if (!ss) { return; }

        const interpolation = new Interpolation(vm);
        document.body.style.cursor = 'wait';
        ss.modalMessage = structureSet.StructureSetModalMessages.Interpolating;
        vs.notifyListeners();

        setTimeout(function(){ 
            interpolation.interpolate(ss as structureSet.StructureSet, roi);
            document.body.style.cursor = 'default';
            ss.modalMessage = null;
            vs.notifyListeners();
         }, 200);   
    }

    handleChangeRoiColorClick = (roi: structureSet.Roi) => {
        const arr = roi.color;
        const color = {r: arr[0], g: arr[1], b: arr[2]};
        this.setState({roiForColorSelect: roi, selectedColor: color});
    }

    /** deletes whatever the current selection of ROIs is */
    handleRoiDeletion = () => {
        const vs = this.props.viewerState;
        const ss = vs.selectedStructureSet;
        const roiList = this.getRoiList();
        if (ss && roiList) {
            const roiNumbers = this.state.selectedIndices.map(i => roiList[i].roiNumber);
            ss.deleteRois(roiNumbers);
            vs.setSelectedRoi(null);
            this.props.doGradingSync(ss);
            vs.roisChanged(ss);
        }
        this.setState({ selectedIndices: [] });
        this.handleCloseDeleteRoiDialog();
    }

    handleRoiNameChanged = (event: any) => {
        this.setState({newName: event.target.value})
    }

    handleRoiNameKeyPress = (event: any, roi: structureSet.Roi) => {
        const vs = this.props.viewerState;
        const ss = this.props.structureSet as structureSet.StructureSet;
        if (event.keyCode === 13){// Enter
            roi.name = event.target.value.trim();
            this.props.doGradingSync(ss);
            vs.roisChanged(ss);
            this.setState({roiToRename: null});
        }
        else if (event.keyCode === 27)  {// Esc
            this.setState({roiToRename: null});
        }
    }

    handleRoiNameEditFinished = (roi: structureSet.Roi) => {
        const vs = this.props.viewerState;
        const ss = this.props.structureSet as structureSet.StructureSet;
        if (this.state.newName) {
            roi.name = this.state.newName.trim();
            this.props.doGradingSync(ss);
            vs.roisChanged(ss);
        }
        this.setState({roiToRename: null});
    }

    handleSelectColor = (color: any) => {
        this.setState({selectedColor: color});
    }

    handleCloseColorModal = () => {
        const vs = this.props.viewerState;

        const ss = vs.selectedStructureSet;
        const roi = this.state.roiForColorSelect;
        const rgb = this.state.selectedColor.rgb;
        if (ss && roi && rgb) {
            roi.color = [rgb.r, rgb.g, rgb.b];
            ss.unsaved = true;
            roi.unsaved = true;
            vs.setSelectedRoi(roi);
            vs.notifyListeners();
        }
        this.setState({roiForColorSelect: null});
    }

    handleStartMarginDialog = (roi: structureSet.Roi) => {
        if (!roi.sdf) {
            alert(roi.name + " does not have contours!");
        }
        else {
            this.setState({roiForMarginTool: roi});
        }
    }

    handleMarginApply = (opt: MarginOptions) => {
        const vs = this.props.viewerState;
        const vm = vs.viewManager;
        const ss = opt.targetRoi.structureSet;

        document.body.style.cursor = 'wait';
        ss.modalMessage = structureSet.StructureSetModalMessages.CalculatingMargin;
        vs.notifyListeners();
        this.setState({roiForMarginTool: null});

        setTimeout(function(){ 
            new MarginOperations(vm).addMargin(opt);
            document.body.style.cursor = 'default';
            ss.modalMessage = null;
            vs.notifyListeners();
         }, 200);
    }

    handleChangeRoiInterpretedType = (roi: structureSet.Roi, roiType: string) => {
        const vs = this.props.viewerState;
        const ss = vs.selectedStructureSet;
        if (ss && Object.values(ss.rois).includes(roi)) {
            roi.interpretedType = roiType;
            ss.unsaved = true;
            roi.unsaved = true;
            vs.notifyListeners();
        }
    }
    
    handleAddRoiClick = () => {
        const vs = this.props.viewerState;
        const ss = vs.selectedStructureSet;
        if (!ss) { return; }
        this.props.onAddRoiClick(ss);
    }

    handleAddRoisFromTemplateOpenModalClick = () => {
        this.props.openAddStructuresFromTemplateDialog();
    }

    handleDeleteRoiDialogClick = () => {
        const vs = this.props.viewerState;
        const ss = vs.selectedStructureSet as structureSet.StructureSet;
        const canEdit = vs.canEdit && ss.canEdit();

        if (canEdit && this.state.selectedIndices.length > 0) {
            this.setState({ showDeleteRoiDialog: true });
        }
    }

    handleCloseDeleteRoiDialog = () => {
        this.setState({ showDeleteRoiDialog: false });
    }

    handleShortcutDelete = () => {
        this.handleDeleteRoiDialogClick();
    }

    handleShortcutSelectAll = (keyEvent?: KeyboardEvent) => {

        // stop ctrl+a from selecting everything
        if (keyEvent) {
            keyEvent.preventDefault();
        }

        const roiList = this.getRoiList() || [];

        // add all indices from 0 to roiList.length to array
        const list = roiList.map((_, i) => i);
        this.setState({ selectedIndices: list });
    }

    handleShowRoiContextMenu = (roi: structureSet.Roi, evt: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        contextMenu.show({ id: this.roiContextMenuId, props: { roi: roi }, event: evt });
    }

    handleRoiContextMenuItemClick = ({ props, data }: ItemParams<RoiContextMenuProps, RoiContextMenuData>) => {
        if (!props || !data) {
            // do nothing if we somehow get undefined props or data
            return;
        }

        const { roi } = props;
        const { action } = data;

        switch (action) {
            case RoiContextMenuAction.Focus:
                this.handleFocusToRoiClick(roi);
                break;
            case RoiContextMenuAction.Rename:
                this.handleRenameRoiClick(roi);
                break;
            case RoiContextMenuAction.Duplicate:
                this.handleDuplicateRoiClick(roi);
                break;
            case RoiContextMenuAction.CopyToClipboard:
                this.handleCopyToClipboard();
                break;
            case RoiContextMenuAction.ChangeColor:
                this.handleChangeRoiColorClick(roi);
                break;
            case RoiContextMenuAction.CreateMargin:
                this.handleStartMarginDialog(roi);
                break;
            case RoiContextMenuAction.Interpolate:
                this.handleInterpolateRoiClick(roi);
                break;
            case RoiContextMenuAction.Delete:
                this.handleDeleteRoiDialogClick();
                break;
            default:
                if (_.has(RoiContextMenuAction, action)) {
                    throw new Error(`Unimplemented ROI context menu action "${RoiContextMenuAction[action]}"`);
                } else {
                    throw new Error(`Undefined ROI context menu action "${action}"`);
                }
        }
    }

    /** Returns a sorted list of structures (ROIs) from either the given structure set or from current
     *  viewer-state selected structure set.
     */
    getRoiList = (structureSet: structureSet.StructureSet | undefined = undefined): structureSet.Roi[] | null => {
        let ss: structureSet.StructureSet;
        if (structureSet) { 
            ss = structureSet;
        }
        else {
            const vs = this.props.viewerState;
            const ssOrNull = vs.selectedStructureSet;
            if (!ssOrNull) { return null; }
            ss = ssOrNull;
        }
        return Object.keys(ss.rois).map(roiNr => ss.rois[roiNr]).sort(roiCompare);
    }

    selectedRoiToViewport = () => {
        const selectedRoiItem = document.getElementsByClassName("selected-roi")[0];
        const roiPanel = document.getElementsByClassName("left-bottom-panel")[0];
        if (!selectedRoiItem) { return; }
        const selectionRect = selectedRoiItem.getBoundingClientRect();
        const roiPanelRect = roiPanel.getBoundingClientRect();

        if (selectionRect.top < roiPanelRect.top ) {
            roiPanel.scrollTop -= (roiPanelRect.top - selectionRect.top);
        }
        else if (selectionRect.bottom > document.documentElement.clientHeight) {
            roiPanel.scrollTop += (selectionRect.bottom - document.documentElement.clientHeight);
        }
    }

    getGrading(grading: StructureSetGrading, roi: structureSet.Roi): RoiGrading {
        const roiGrading = grading.rois[roi.roiNumber];
        return roiGrading || new RoiGrading(roi.name);
    }

    renderRoiName(roi: structureSet.Roi, canEdit: boolean) {
        if (this.state.roiToRename === roi) {
            return (
                <input className="roi-name-edit" type="text" value={this.state.newName}
                    onChange={(event) => this.handleRoiNameChanged(event)}
                    onKeyDown={(event) => this.handleRoiNameKeyPress(event, roi)}
                    onBlur={() => this.handleRoiNameEditFinished(roi)} />
            );
        }

        return (
            <div>
                <div onDoubleClick={() => canEdit && this.handleRenameRoiClick(roi)}>
                        {roi.unsaved ? <b>{roi.name}</b> : <>{roi.name}</>}
                </div>
            </div>
        );
    }

    /** keyboard shortcut handlers */
    handlers = {
        DELETE_ITEM: this.handleShortcutDelete, 
        SELECT_ALL: this.handleShortcutSelectAll,   
    }

    render() {
        const vs = this.props.viewerState;
        const grading = this.props.grading;
        const ssOrNull = vs.selectedStructureSet;
        if (!ssOrNull) { return null; }
        const ss = ssOrNull as structureSet.StructureSet;
        const canEditRtstruct = vs.canEdit && ss.canEdit();  // true -> RTSTRUCT can be edited (i.e. add/rename ROIs, add/alter contours), false -> no changes to RTSTRUCT allowed

        // show a different ROI context menu if we have multiple selections
        const multipleRoisSelected = this.state.selectedIndices.length > 1;

        // are modifications allowed when a single ROI is selected?
        const singleRoiEditsAllowed = canEditRtstruct && !multipleRoisSelected;

        const roiList = this.getRoiList(ss)!;
        const singleSelectedRoi = this.state.selectedIndices.length === 1 ? roiList[this.state.selectedIndices[0]].name : undefined;

        const gradingWorkflowState = grading ? grading.workflowState : GradingWorkflowState.Undefined;
        const isGradingSheetCurrentlySaving = this.props.isSavingGradings;

        // add or change current item to selection on right-click unless we're shift/ctrl-clicking or we have 2 or more selections already
        const shouldNotSelectOnRightClick = (evt: React.MouseEvent<HTMLDivElement, MouseEvent>) => this.state.selectedIndices.length > 1 || evt.shiftKey || evt.ctrlKey;

        return (
            <>
            <div className="vertical-scrollable">
                <HotKeys keyMap={keyMap} handlers={this.handlers}>
                    <table className="roi-table">
                        <thead>
                            <tr>
                                <th className="visibility-column"><Checkbox
                                    label={""}
                                    isSelected={vs.getAreAllRoisVisible()}
                                    onCheckboxChange={this.handleAllRoisVisibleChange}
                                    />
                                </th>
                                <th className="color-column"/>
                                <th className="roi-name-column"/>
                                {grading && (
                                    <th className="grading-column">
                                        <div className="grading-sheet-header">
                                            <div className="title">Grading: </div>

                                            <Button variant={grading.comment ? "outline-danger" : "outline-secondary"}
                                                size="sm"
                                                title="Overall comment"
                                                onClick={() => { this.handleShowCommentModal(null) }}
                                                className="grading-button icon-button"
                                            ><MdMessage /></Button>

                                            <div
                                                className={`grading-sheet-saving-icon ${isGradingSheetCurrentlySaving ? 'pop-in' : 'pop-out'}`}
                                                title={isGradingSheetCurrentlySaving ? "Auto-saving grading sheet..." : ""}>
                                                <MdSave />
                                            </div>
                                        </div>
                                        <GradingStateDropdown gradingWorkflowState={gradingWorkflowState} onChange={this.handleGradingStateChange} disabled={!vs.canEdit}/>
                                    </th>
                                )
                                }
                            </tr>
                        </thead>
                        <tbody>
                        { roiList.map((roi, index, rois) => ( 
                                <tr className={`roi-item ${this.state.selectedIndices.includes(index) ? "selected-roi" : ""} ${index + 1 === rois.length && "last-roi-row"}`} 
                                        key={roi.roiNumber}
                                        onContextMenu={(evt: React.MouseEvent<HTMLDivElement, MouseEvent>) => { 
                                            !shouldNotSelectOnRightClick(evt) && this.handleSelectRoiClick(roi, evt);
                                            this.handleShowRoiContextMenu(roi, evt);
                                        }}>
                                    <td className="visibility-column">
                                        <Checkbox
                                            label={""}
                                            isSelected={!( vs.hiddenRois[ss.structureSetId] && vs.hiddenRois[ss.structureSetId].has(roi.roiNumber)) }
                                            onCheckboxChange={(evt) => { this.handleRoiVisibleChange(evt, roi)}}/>
                                    </td>
                                    <td className="color-column"><div className="color-square" style={{backgroundColor: ss.rois[roi.roiNumber].rgb()}}
                                            title="Click to focus on structure"
                                            onClick={() => this.handleFocusToRoiClick(roi) }
                                            onDoubleClick={() => { if (canEditRtstruct) { this.handleChangeRoiColorClick(roi); }}}/></td>
                                    <td className="roi-name-column" title={ss.rois[roi.roiNumber].name}
                                            onClick={(evt) => { this.handleSelectRoiClick(roi, evt)}}>{this.renderRoiName(ss.rois[roi.roiNumber], canEditRtstruct)}</td>
                                    {grading ? 
                                        <td className="grading-column">
                                            <Row>
                                                <Col sm="3">
                                                    <DropdownButton title={this.getGrading(grading, roi).value} id={"grading-value-" + roi.roiNumber}
                                                            disabled={!vs.canEdit}
                                                            variant={getGradingVariant(this.getGrading(grading, roi).value)}
                                                            size="sm"
                                                            className="grading-button">
                                                    {
                                                        Object.keys(GradingMeanings).map((val) =>
                                                        <Dropdown.Item as="button" key={val} 
                                                            onClick={() => this.handleGradeRoi(roi, val)}> {`${val}: ${GradingMeanings[val]}`}
                                                        </Dropdown.Item>
                                                    )}
                                                    </DropdownButton>
                                                </Col>
                                                <Col sm="2" className="grading-section-column">
                                                    <Button variant={this.getGrading(grading, roi).comment ? "outline-danger" : "outline-secondary"}
                                                    size="sm"
                                                    title="Comment"
                                                    onClick={() => this.handleShowCommentModal(roi)}
                                                    className="grading-button icon-button"
                                                    ><MdMessage /></Button>
                                                </Col>
                                                <Col sm="4" className="grading-section-column">
                                                    <div className="grading-button unsure">
                                                        <Checkbox
                                                        disabled={!vs.canEdit}
                                                        label={"Unsure"}
                                                        isSelected={this.getGrading(grading, roi).unsure}
                                                        onCheckboxChange={(evt) => { this.handleGradingUnsureChange(evt, roi)}}/>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </td> 
                                        : null 
                                    }
                                </tr>
                        )) }
                            {canEditRtstruct &&
                                (<tr className="new-roi-button">
                                    <td colSpan={grading ? 4 : 3}>
                                        <SplitButton
                                            id="add-roi-buttons"
                                            variant="light" 
                                            title={(<span><NewItemGlyph /> Add structure</span>)} 
                                            onClick={this.handleAddRoiClick}
                                            >
                                            <Dropdown.Item onClick={this.handleAddRoisFromTemplateOpenModalClick}>Add structures from template...</Dropdown.Item>
                                        </SplitButton>
                                    </td>
                                </tr>)
                            }
                        </tbody>
                    </table>
                </HotKeys>

                <ModalDialog show={this.state.showCommentModal} onHide={this.handleCancelCommentModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>{this.state.roiToComment ? "Comment on " + this.state.roiToComment.name : "Overall comment"}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                        <Form.Group controlId="exampleForm.ControlTextarea1">
                            <Form.Control as="textarea" rows={5} value={this.state.comment} onChange={this.handleCommentChanged} disabled={!vs.canEdit} />
                        </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={this.handleCloseCommentModal} disabled={!vs.canEdit}>Save changes</Button>
                        <Button variant="outline-secondary" onClick={this.handleCancelCommentModal}>Cancel</Button>
                    </Modal.Footer>
                </ModalDialog>

                <ModalDialog show={Boolean(this.state.roiForColorSelect)} onHide={this.handleCloseColorModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Choose color</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <SketchPicker
                        color={ this.state.selectedColor }
                        onChangeComplete={ this.handleSelectColor }
                    />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={this.handleCloseColorModal}>OK</Button>
                    </Modal.Footer>
                </ModalDialog>

                <ModalDialog show={Boolean(this.state.roiForMarginTool)} onHide={() => this.setState({roiForMarginTool: null})}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add margin</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <AddMarginDialog 
                            roi={this.state.roiForMarginTool as structureSet.Roi}
                            cancel={() => this.setState({roiForMarginTool: null})}
                            apply={this.handleMarginApply}
                        />
                    </Modal.Body>
                </ModalDialog>

                <ModalDialog backdrop="static" show={!!this.state.duplicateGoodGradedRoisErrors && this.state.duplicateGoodGradedRoisErrors.length > 0}
                    onHide={() => {}}>
                    <Modal.Header>
                        <Modal.Title>Duplicate structures graded "1: {GradingMeanings["1"]}"</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ConfirmGradingSaveDialog
                            errors={this.state.duplicateGoodGradedRoisErrors}
                            currentStructureSet={ss}
                            currentGrading={grading}
                            roiNumberNeedingConfirmation={this.state.roiNumberNeedingConfirmation}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={this.handleConfirmSaveAndObsoleteOldGradingsChange}>Confirm grading change</Button>
                        <Button variant="outline-secondary" onClick={this.handleCancelGradingChange}>Cancel</Button>
                    </Modal.Footer>
                </ModalDialog>

                <ConfirmRoiDeletionDialog
                    isVisible={this.state.showDeleteRoiDialog}
                    onClose={this.handleCloseDeleteRoiDialog}
                    handleRoiDeletion={this.handleRoiDeletion}
                    selectedIndices={this.state.selectedIndices}
                    roiList={roiList}
                />
            </div>

            <Menu id={this.roiContextMenuId} style={{ zIndex: 1000 }} animation={false} className="roi-context-menu">
                {singleSelectedRoi && (<>
                    <Item disabled={true} className="context-menu-header"><b>{singleSelectedRoi}</b></Item>
                    <Separator /></>
                )}
                {!multipleRoisSelected && <Item onClick={this.handleRoiContextMenuItemClick} data={{ action: RoiContextMenuAction.Focus }}>Focus on structure</Item>}
                {singleRoiEditsAllowed && <Item onClick={this.handleRoiContextMenuItemClick} data={{ action: RoiContextMenuAction.Rename }}>Rename</Item>}
                {singleRoiEditsAllowed && <Item onClick={this.handleRoiContextMenuItemClick} data={{ action: RoiContextMenuAction.Duplicate }}>Duplicate</Item>}
                {<Item onClick={this.handleRoiContextMenuItemClick} data={{ action: RoiContextMenuAction.CopyToClipboard }}>Copy to clipboard</Item>}
                {singleRoiEditsAllowed && <Item onClick={this.handleRoiContextMenuItemClick} data={{ action: RoiContextMenuAction.ChangeColor }}>Change color...</Item>}
                {!multipleRoisSelected && <RoiTypeMenu roi={vs.selectedRoi} onChangeRoiType={this.handleChangeRoiInterpretedType} canEdit={singleRoiEditsAllowed} /> }
                {singleRoiEditsAllowed && <Separator />}
                {singleRoiEditsAllowed && <Item onClick={this.handleRoiContextMenuItemClick} data={{ action: RoiContextMenuAction.CreateMargin }}>Create margin...</Item>}
                {singleRoiEditsAllowed && <Item onClick={this.handleRoiContextMenuItemClick} data={{ action: RoiContextMenuAction.Interpolate }}>Interpolate</Item>}
                {singleRoiEditsAllowed && <Separator />}
                {canEditRtstruct && <Item onClick={this.handleRoiContextMenuItemClick} data={{ action: RoiContextMenuAction.Delete }}>Delete</Item>}
            </Menu>
            </>
        );
    }
}

export default connect(
    state => Object.assign({}, state),
    sagas.mapDispatchToProps
)(ROITable);
