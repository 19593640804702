import { getRtViewerBackendClient } from "./auth";
import { AzureShareInfo } from "./azure-files";

const BatchApiUrlBase = '/api/batchjob';
const BatchApiBatchJobUrl = BatchApiUrlBase + '/batchjob';
const BatchApiAllBatchJobsUrl = BatchApiUrlBase + '/batchjobs';

export class BatchRequest {
    public storageAccountName: string;
    public fileShareName: string;
    public images: ImageRequest[];
    public rtStructs: RTStructRequest[];
    public batchDescription?: string | null; // Can be set or left undefined. Can include e.g. a string created from the filter settings used to search the dataset.
    constructor (fileShare: AzureShareInfo,
        images: ImageRequest[],
        rtStructs: RTStructRequest[],
        batchDescription?: string) {
            this.storageAccountName = fileShare.storageAccountName;
            this.fileShareName = fileShare.fileShareName;
            this.images = images;
            this.rtStructs = rtStructs;
            this.batchDescription = batchDescription !== undefined ? batchDescription : null;
    }
}

export class ImageRequest {
    public patientId: string;
    public seriesInstanceUid: string;
    public folderPath: string;
    public autoContourAction: string | null; // same as action parameter in contouring api. Null when only image export is requested, without contouring.

    constructor(patientId: string,
        seriesInstanceUid: string,
        folderPath: string,
        autoContourAction: string | null) {
            this.patientId = patientId;
            this.seriesInstanceUid = seriesInstanceUid;
            this.folderPath = folderPath;
            this.autoContourAction = autoContourAction;
    }
}

export class RTStructRequest {
    public patientId: string;
    public sopInstanceUid: string;
    public folderPath: string;

    constructor(patientId: string,
        sopInstanceUid: string,
        folderPath: string) {
            this.patientId = patientId;
            this.sopInstanceUid = sopInstanceUid;
            this.folderPath = folderPath;
    }
}

export class BatchJobStatus {
    public batchId: string;
    public user: string;
    public batchDescription?: string | null;
    public finished: boolean;
    public timeStampStarted: number; // unix timestamp in milliseconds
    public timeStampFinished: number; // unix timestamp in milliseconds
    public imageExportsDone: number;
    public imageExportsTotal: number;
    public rtStructExportsDone: number;
    public rtStructExportsTotal: number;
    public contouringsDone: number;
    public contouringsTotal: number;
    public errors: string[];
    public sourceStorageAccountName: string;
    public sourceFileShareName: string;
    public targetStorageAccountName: string;
    public targetFileShareName: string;
    public targetPath: string;

    public constructor() { // TypeScript requires constructor to set all members...
        this.batchId = "";
        this.user = "";
        this.finished = false;
        this.timeStampStarted = 0;
        this.timeStampFinished = 0;
        this.imageExportsDone = 0;
        this.imageExportsTotal = 0;
        this.rtStructExportsDone = 0;
        this.rtStructExportsTotal = 0;
        this.contouringsDone = 0;
        this.contouringsTotal = 0;
        this.errors = [];
        this.sourceStorageAccountName = "";
        this.sourceFileShareName = "";
        this.targetStorageAccountName = "";
        this.targetFileShareName = "";
        this.targetPath = "";
    }
}

export class BatchClient {

    // Returns batchId
    public static async createBatchJob(request: BatchRequest): Promise<string> {
        const url = BatchApiBatchJobUrl;
        const json = JSON.stringify(request);
        const options = {
            body: json,
            config: {
                headers: {
                    "Content-Type": "application/json",
                },
            },
        }

        const client = getRtViewerBackendClient();

        return new Promise(async (resolve, reject) => {
            const response = await client.post(url, options);
            if(response.status === 200) {
                const batchId = await response.text();
                resolve(batchId);
            }
            else {
                const error = "createBatchJob, HTTP error status: " + response.status;
                console.log( error );
                reject(error);
            }
        });
    }

    public static async getBatchJobs(): Promise<BatchJobStatus[]> {
        const url = BatchApiAllBatchJobsUrl;
        const client = getRtViewerBackendClient();

        return new Promise(async (resolve, reject) => {
            const response = await client.fetch(url);
            if(response.status === 200) {
                const statuses = await response.json() as BatchJobStatus[];
                resolve(statuses);
            }
            else {
                const error = "getBatchJobs, HTTP error status: " + response.status;
                console.log( error );
                reject(error);
            }
        });
    }

    public static async getBatchJob(batchId: string): Promise<BatchJobStatus | null> {
        const url = BatchApiBatchJobUrl + '?batchJobId=' + batchId;
        const client = getRtViewerBackendClient();

        return new Promise(async (resolve, reject) => {
            const response = await client.fetch(url);
            if(response.status === 404) {
                console.log("Batch job not found! batchId: " + batchId);
                resolve(null);
            }
            else if(response.status === 200) {
                const batchStatus = await response.json() as BatchJobStatus;
                resolve(batchStatus);
            }
            else {
                const error = "getBatchJob, HTTP error status: " + response.status;
                console.log( error );
                reject(error);
            }
        });
    }



}
