import React from "react";
import { Button, Modal } from "react-bootstrap";
import { HotKeys } from "react-hotkeys";

import * as structureSet from "../../../dicom/structure-set";
import ModalDialog from "../../common/ModalDialog";


type OwnProps = {
    isVisible: boolean,
    onClose: () => void,
    handleRoiDeletion: () => void,
    selectedIndices: number[],
    roiList: structureSet.Roi[],
};

class ConfirmRoiDeletionDialog extends React.PureComponent<OwnProps> {

    handleClose = () => {
        this.props.onClose();
    }

    handleConfirmDeletion = () => {
        this.props.handleRoiDeletion();
    }

    getMessage = () => {
        const names: string[] = [];
        for (const i of this.props.selectedIndices) {
            const roi = this.props.roiList[i];
            if (roi) { names.push(roi.name); }
        }

        const label = names.join(', ');

        if (names.length > 1) {
            return `Are you sure you want to delete following structures: ${label}?`;
        } else {
            return `Are you sure you want to delete structure ${label}?`;
        }
    }

    keyMap = {
        CONFIRM_DIALOG: ["Enter"],
        CANCEL_DIALOG: ["esc"],
    };

    handlers = {
        CONFIRM_DIALOG: this.handleConfirmDeletion,
        CANCEL_DIALOG: this.handleClose,
    };

    render() {
        if (this.props.selectedIndices.length < 1) {
            return null;
        }

        const isDeletingMultiple = this.props.selectedIndices.length > 1;

        return (
            <HotKeys keyMap={this.keyMap} handlers={this.handlers}>
                <ModalDialog
                    size='lg'
                    show={this.props.isVisible}
                    onHide={this.handleClose}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{isDeletingMultiple ? 'Confirm structure deletions' : 'Confirm structure deletion'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>
                            {this.getMessage()}
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={this.handleConfirmDeletion}>{isDeletingMultiple ? 'Delete structures' : 'Delete structure'}</Button>
                        <Button variant="light" onClick={this.handleClose}>Cancel</Button>
                    </Modal.Footer>
                </ModalDialog>
            </HotKeys>
        );
    }


}

export default ConfirmRoiDeletionDialog;
