import {Uniform} from './uniforms/Uniform';

export class ShaderProgram {

    protected gl: WebGL2RenderingContext;
    protected programID?: WebGLProgram;

    constructor(gl: WebGL2RenderingContext, vertexContent: string, fragmentContent: string, ...inVariables: string[]){
        this.gl = gl;
        let vertexShaderID = this.loadShader(vertexContent, gl.VERTEX_SHADER);
        let fragmentShaderID = this.loadShader(fragmentContent, gl.FRAGMENT_SHADER);

        this.programID = gl.createProgram() || undefined;
        if(!this.programID){
            throw new Error("Couldn't get valid shader program ID");
        }
        gl.attachShader(this.programID, vertexShaderID);
        gl.attachShader(this.programID, fragmentShaderID);

        this.bindAttributes(inVariables);

        gl.linkProgram(this.programID);

        if(!gl.getProgramParameter(this.programID, gl.LINK_STATUS)){
            const info = gl.getProgramInfoLog(this.programID);
            throw new Error("Could not compile program\n\n" +info);
        }
    }

    public bindAttributes(inVariables: string[]): void{
        if(this.programID){
            for(let i = 0; i < inVariables.length; ++i){
                this.gl.bindAttribLocation(this.programID, i, inVariables[i]);
            }
        }
    }

    public start(): void {
        if(this.programID){
            this.gl.useProgram(this.programID);
        }
    }

    public stop(): void {
        this.gl.useProgram(null);
    }

    public storeUniformLocations(...uniforms: Uniform[]): void{
        if(this.programID){
            for(let i = 0; i < uniforms.length; ++i){
                uniforms[i].storeUniformLocation(this.programID);
            }
            this.gl.validateProgram(this.programID);
        }
    }

    protected loadShader(shaderContent: string, shaderType: number): WebGLShader{
        const shaderID = this.gl.createShader(shaderType);
        if(shaderID){
            this.gl.shaderSource(shaderID, shaderContent);
            this.gl.compileShader(shaderID);
        } else {
            throw new Error("Couldn't get valid shader ID");
        }

        const error: string | null = this.gl.getShaderInfoLog(shaderID);
        if(error && error.length > 0){
            console.error(new Error().stack);
            throw error;
        }

        return shaderID;
    }

    public delete(): void {
        if(this.programID){
            this.gl.deleteProgram(this.programID);
        }
    }
}