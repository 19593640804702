import React from 'react';
import { connect } from 'react-redux';
import * as sagas from '../../store/sagas';
import AzureFileLoaderLiveReview from './AzureFileLoaderLiveReview';
import RTViewer from '../rtviewer/RTViewer';
import { StoreState } from '../../store/store';
import { ErrorBoundary } from '../ErrorBoundary';
import AuthenticatedPage from '../AuthenticatedPage';
import Workspace from '../../store/workspace';

type OwnProps = {}

type DispatchProps = {
    setCurrentWorkspace(workspace: Workspace): void,
    setLiveReviewQueries: (urlSearchParams: URLSearchParams) => boolean,
}

type RouterProps = {
    location: any;
}

type AllProps = OwnProps & StoreState & DispatchProps & RouterProps;

type OwnState = {
    scanId?: string | null,
    anyValidQueries: boolean,
}

export class LiveReviewPage extends React.Component<AllProps, OwnState> {
    displayName = LiveReviewPage.name

    constructor(props: AllProps) {
        super(props);

        this.props.setCurrentWorkspace(Workspace.LiveReview);
        const urlSearchParams = new URLSearchParams(this.props.location.search);
        const anyValidQueries = this.props.setLiveReviewQueries(urlSearchParams);

        this.state = { anyValidQueries };
    }

    handleBack = () => {
        this.setState({ scanId: null });
    }

    viewImage = (scanId: string) => {
        this.setState({ scanId: scanId });
    }

    render() {
        if (!this.state.anyValidQueries) {
            return (<p><b> Invalid live review link</b></p>);
        }

        let scanId = this.state.scanId;

        return (
            <AuthenticatedPage>
                <div className="container-fluid annotation-container">
                    {scanId ?
                        <ErrorBoundary>
                            <RTViewer scanId={scanId} canEdit={true} canCreateRtstruct={true} handleBack={this.handleBack} />
                        </ErrorBoundary>
                        : <AzureFileLoaderLiveReview viewImage={this.viewImage}></AzureFileLoaderLiveReview>}
                </div>
            </AuthenticatedPage>
        );
    }
}

export default connect(
    state => Object.assign({}, state),
    sagas.mapDispatchToProps
)(LiveReviewPage);



