import produce from 'immer';
import _ from 'lodash';

import { DownloadTask, UploadTask, TransferredFile, TransferredFileType, LocalFileProgress, isLocalFileProgress } from "./file-transfer-task";
import { ContouringTask, ContouringTaskState } from './contouring-task';
import { getDurationString } from '../util';
import { differenceInSeconds } from 'date-fns';
import { AppVersionInfo } from './app-version-info';
import { AppAuthStatesCollection, LogInProcessState } from './auth-state';
import { User } from './user';
import { AzureDownloadError, LoginError, ApiDownloadError } from './errors';
import { instanceOfAzureFileShareRequest, instanceOfApiFileShareRequest } from './requests';
import { ReceivedApiFile, ReceivedAzureFile } from './received-files';
import { Dataset } from '../datasets/dataset';
import { GradingToSave, LockAction, ScanLockRequests, ScanLocks } from '../datasets/dataset-files';
import WorkState from './work-state';
import { DatasetGradings } from '../datasets/roi-grading';
import { StructureSet } from '../dicom/structure-set';
import { SessionNotification } from '../components/common/models/SessionNotification';
import { RTViewerDisplayVersion } from '../environments';
import { FileLoadError } from './file-load-error';
import { backendTierAppAuths } from '../web-apis/auth';
import { UserStorageAccountAccess } from '../web-apis/user-access';
import { DatasetPatientCategoryCollection } from '../web-apis/dataset-patient-categories';
import Workspace from './workspace';

export const initializeApp = 'INITIALIZE_APP';

export const addAuthState = 'ADD_AUTH_STATE';
export const startLogin = 'START_LOGIN';
export const startLogout = 'START_LOGOUT';
export const setAuthStateAsRequired = 'SET_AUTH_STATE_AS_REQUIRED';
export const requestLogOut = 'REQUEST_LOGOUT';
export const setAuthStateAsLoggedIn = 'SET_AUTH_STATE_AS_LOGGED_IN';
export const setUserDetails = 'SET_USER_DETAILS';
export const setUserPermissions = 'SET_USER_PERMISSIONS';

export const initializeAnnotationPage = 'INITIALIZE_ANNOTATION_PAGE';
export const setIsAnnotationPageReady = 'SET_IS_ANNOTATION_PAGE_READY';
export const setUserStorageAccess = 'SET_USER_STORAGE_ACCESS';
export const setDatasetCategories = 'SET_DATASET_CATEGORIES';

export const receiveDownloadCreated = 'RECEIVE_DOWNLOAD_CREATED'; // Create an empty download task
export const receiveDownloadAddFile = 'RECEIVE_DOWNLOAD_ADD_FILE'; // Add file to a download task
export const deleteDownloadType = 'DELETE_DOWNLOAD';
export const receiveDownloadErrorType = 'RECEIVE_DOWNLOAD_ERROR';
export const receiveFileDownloadedType = 'RECEIVE_FILE_DOWNLOADED';

export const receiveUploadCreated = 'RECEIVE_UPLOAD_CREATED';
export const receiveUploadAddFile = 'RECEIVE_UPLOAD_ADD_FILE';
export const deleteUploadType = 'DELETE_UPLOAD';
export const receiveUploadErrorType = 'RECEIVE_UPLOAD_ERROR';
export const receiveFileUploadedType = 'RECEIVE_FILE_UPLOADED';

export const receiveSliceType = 'RECEIVE_IMAGE_SLICE';
export const receiveFullImageType = 'RECEIVE_FULL_IMAGE';

export const receiveStructureSetType = 'RECEIVE_STRUCTURE_SET';
export const deleteStructureSetType = 'DELETE_STRUCTURE_SET';
export const seenStructureSetType = 'SEEN_STRUCTURE_SET';
export const storeOriginalStructureSet = 'STORE_ORIGINAL_STRUCTURE_SET';
export const fileLoadError = 'FILE_LOAD_ERROR';
export const clearFileLoadErrors = 'CLEAR_FILE_LOAD_ERRORS';

export const updateContouringTaskType = 'UPDATE_CONTOURING_TASK';
export const dismissContouringTaskType = 'DISMISS_CONTOURING_TASK';

export const unloadScanType = 'UNLOAD_SCAN';
export const unloadStructureSetType = 'UNLOAD_STRUCTURE_SET';
export const unloadAllContouringTasksType = 'UNLOAD_ALL_CONTOURING_TASKS';

export const setBatchJobPaneVisibilityType = 'SET_BATCH_JOB_PANE_VISIBILITY';
export const receiveStartWatchingBatchJobsType = 'RECEIVE_START_WATCHING_BATCH_JOBS';
export const receiveBatchJobsStatusType = 'RECEIVE_BATCH_JOBS_STATUS';
export const batchJobRequestLockType = 'BATCH_JOB_REQUEST_LOCK';
export const batchJobRequestSuccessType = 'BATCH_JOB_REQUEST_SUCCESS';
export const batchJobRequestFailureType = 'BATCH_JOB_REQUEST_FAILURE';

export const setUserSettingsDialogVisibilityType = 'SET_USER_SETTINGS_DIALOG_VISIBILITY';
export const setUserSettingBackend = 'SET_USER_SETTING_BACKEND';
export const setUserSettingPatientInfoVisibility = 'SET_USER_SETTING_PATIENT_INFO_VISIBILITY';

export const setHelpDialogVisibility = 'SET_HELP_DIALOG_VISIBILITY';

export const addNotificationType = 'ADD_NOTIFICATION';
export const removeNotificationType = 'REMOVE_NOTIFICATION';

export const receiveStartFetchingStructureTemplatesType = 'RECEIVE_START_FETCHING_STRUCTURE_TEMPLATES';
export const setStructureTemplatesType = 'SET_STRUCTURE_TEMPLATES';

export const receiveAppVersionInfo = 'RECEIVE_APP_VERSION_INFO';
export const snoozeNewVersionAlert = 'SNOOZE_NEW_VERSION_ALERT';

export const setLiveReviewQueries = 'SET_LIVE_REVIEW_QUERIES';
export const setLiveReviewIsDownloading = 'SET_LIVE_REVIEW_IS_DOWNLOADING';
export const liveReviewDownloadKey = 'LIVE_REVIEW_DOWNLOAD';
export const setLiveReviewErrorMessage = 'SET_LIVE_REVIEW_ERROR_MESSAGE';

// requestDownloadDataset payload: { azureShare: AzureShareInfo, reloadMetaFiles: boolean, datasetId: string }
export const requestDownloadDataset = 'REQUEST_DOWNLOAD_DATASET';
export const startDatasetDownload = 'START_DATASET_DOWNLOAD';
export const finishDatasetDownload = 'FINISH_DATASET_DOWNLOAD';
export const addDataset = 'ADD_DATASET';

export const requestDatasetLock = 'REQUEST_DATASET_LOCK';
export const startDatasetLockRequest = 'START_DATASET_LOCK_REQUEST';
export const setDatasetLock = 'SET_DATASET_LOCK';
export const undoDatasetLockRequest = 'UNDO_DATASET_LOCK_REQUEST';

export const setDatasetGradings = 'SET_DATASET_GRADINGS';
export const startDatasetGradingSave = 'START_DATASET_GRADINGS_SAVE';
export const debouncedFinishDatasetGradingSave = 'DEBOUNCED_FINISH_DATASET_GRADINGS_SAVE';
export const finishDatasetGradingSave = 'FINISH_DATASET_GRADINGS_SAVE';
export const syncStructureSetGrading = 'SYNC_STRUCTURE_SET_GRADING';
export const saveStructureSetGrading = 'SAVE_STRUCTURE_SET_GRADING';
export const saveDatasetGradings = 'SAVE_DATASET_GRADINGS';
export const setStructureSetGrading = 'SET_STRUCTURE_SET_GRADING';
export const reloadDatasetGradings = 'RELOAD_DATASET_GRADINGS';
export const clearModifiedDatasetGradings = 'CLEAR_MODIFIED_DATASET_GRADINGS';

export const requestImageAndStructureSetDownload = 'REQUEST_IMAGE_AND_STRUCTURE_SET_DOWNLOAD';

export const startRestoringBackup = 'START_RESTORING_BACKUP';
export const finishRestoringBackup = 'FINISH_RESTORING_BACKUP';

export const requestSaveScanToUserDisk = 'REQUEST_SAVE_SCAN_TO_USER_DISK';

export const loadAnnotationQuery = 'LOAD_ANNOTATION_QUERY';

export const setLoginError = 'SET_LOGIN_ERROR';

export const setCurrentWorkState = 'SET_CURRENT_WORK_STATE';
export const updateCurrentWorkState = 'UPDATE_CURRENT_WORK_STATE';

export const setCurrentWorkspace = 'SET_CURRENT_WORKSPACE';

export const setLabeling = 'SET_LABELING';

export const resetLocalFileProgress = 'RESET_LOCAL_FILE_PROGRESS';
export const setLocalFileProgress = 'SET_LOCAL_FILE_PROGRESS;'

// TODO: this MUST be made strongly-typed to make interacting with the store easier and safer, preferrably sooner than later
export type StoreState = {
    appAuthStatesCollection?: any,
    user?: any,

    /** True if annotation page is ready to be used, false or undefined if not */
    isAnnotationPageReady?: boolean,

    // TODO: when re-implementing in rtviewer3 use a flat hierarchy instead!
    // (or better yet: just integrate this already into browsable data within backend)
    userStorageAccess?: { [storageName: string]: UserStorageAccountAccess },

    /** Dataset patients and their dataset categories ("split categories") */
    datasetCatagories?: DatasetPatientCategoryCollection,

    scans?: any,
    // original DICOM structure sets we've downloaded
    originalStructureSets?: any;    // { [structureSetId: string]: { file: ArrayBuffer, filename: string } }
    downloads?: any,
    uploads?: any,
    contouringTasks?: any,
    batchJobs?: any,
    notifications?: any,
    newStructureSets?: any,
    isUserSettingsDialogVisible?: any,
    isHelpDialogVisible?: any,
    isBatchJobPaneVisible?: any,
    isBatchJobRequestLocked?: any,
    structureTemplates?: any,
    appVersion?: any,
    isNewAppVersionAvailable?: any,
    newVersionAlertSnoozedSince?: any,
    liveReviewQueries?: any,
    isDownloadingLiveReviewFiles?: any,
    liveReviewErrorMessage?: string,
    loginError?: any,
    datasetsDownloading?: any,
    datasets?: any,
    // dataset locks by dataset id
    datasetLocks?: any, // { [datasetId: string]: ScanLocks }
    // dataset lock requests by dataset id
    lockRequests?: any, // { [datasetId: string]: ScanLockRequest }
    // dataset gradings by dataset id
    datasetGradings?: any,  // { [datasetId: string]: DatasetGradings }
    isSavingGradings?: any,
    /* we need to keep track of which gradings user has modified in order to properly sync up during a delayed save operation **/
    modifiedDatasetGradings?: any, // { [datasetId: string]: GradingToSave[] }
    // current work set and its state in RTViewer
    currentWorkState?: any,
    currentWorkspace?: Workspace,
    fileLoadErrors?: FileLoadError[],
    localFileProgress?: LocalFileProgress,
    isRestoringBackup?: boolean,
}

export const initialState: StoreState = {
    appAuthStatesCollection: new AppAuthStatesCollection(),
    user: new User(),
    isAnnotationPageReady: false,
    datasetCatagories: {},
    scans: {},
    originalStructureSets: {},
    downloads: {},
    uploads: {},
    contouringTasks: {},
    batchJobs: [],
    notifications: [],
    newStructureSets: [],
    isUserSettingsDialogVisible: false,
    isHelpDialogVisible: false,
    isBatchJobPaneVisible: false,
    isBatchJobRequestLocked: false,
    structureTemplates: [],
    appVersion: null,
    isNewAppVersionAvailable: false,
    newVersionAlertSnoozedSince: null,
    liveReviewQueries: { queryV1: null, queryV2: null },
    isDownloadingLiveReviewFiles: false,
    liveReviewErrorMessage: undefined,
    loginError: null,
    datasetsDownloading: {},
    datasets: {},
    datasetLocks: {},
    lockRequests: {},
    datasetGradings: {},
    isSavingGradings: false,
    modifiedDatasetGradings: {},
    currentWorkState: new WorkState(null, null, false, false),
    currentWorkspace: Workspace.None,
    fileLoadErrors: [],
    localFileProgress: undefined,
    isRestoringBackup: false,
};

export const reducer = (state: any, action: any) => {
    state = state || initialState;
    //  console.log(action.type);
    //  console.log(action);

    return produce(state, (draft: StoreState) => {

        if (action.type === addAuthState) {
            const { authState } = action;
            (draft.appAuthStatesCollection as AppAuthStatesCollection).addAuthState(authState);
        }

        if (action.type === startLogin) {
            const { appAuthName } = action;
            const authState = (draft.appAuthStatesCollection as AppAuthStatesCollection).getAppAuthState(appAuthName);
            if (authState && authState.logInProcessState !== LogInProcessState.LoggedIn) {
                authState.logInProcessState = LogInProcessState.LoggingInProgress;
            }
        }

        if (action.type === startLogout) {
            const { appAuthName } = action;
            const authState = (draft.appAuthStatesCollection as AppAuthStatesCollection).getAppAuthState(appAuthName);
            if (authState) {
                authState.isLogoutInProgress = true;
            }
        }

        if (action.type === setAuthStateAsRequired) {
            const { appAuthName } = action;
            (draft.appAuthStatesCollection as AppAuthStatesCollection).setAuthStateAsRequired(appAuthName);
        }

        if (action.type === setAuthStateAsLoggedIn) {
            const { appAuthName } = action;
            (draft.appAuthStatesCollection as AppAuthStatesCollection).setAuthStateAsLoggedIn(appAuthName);
        }

        if (action.type === setUserDetails) {
            const { username, email, permissions } = action;
            (draft.user as User).setBasicDetails(username, email);
            (draft.user as User).setPermissions(permissions);
        }

        if (action.type === setUserPermissions) {
            const { permissions } = action;
            (draft.user as User).setPermissions(permissions);
        }

        if (action.type === setIsAnnotationPageReady) {
            const { isAnnotationPageReady }: { isAnnotationPageReady: boolean } = action;
            draft.isAnnotationPageReady = isAnnotationPageReady;
        }

        if (action.type === setUserStorageAccess) {
            const { userStorageAccess }: { userStorageAccess: UserStorageAccountAccess[] } = action;
            draft.userStorageAccess = {};
            for (const s of userStorageAccess) {
                draft.userStorageAccess[s.name] = s;
            }
        }

        if (action.type === setDatasetCategories) {
            const { datasetCategories }: { datasetCategories: DatasetPatientCategoryCollection } = action;
            draft.datasetCatagories = datasetCategories;
        }

        if (action.type === receiveDownloadCreated) {
            const key = action.downloadKey;
            draft.downloads[key] = new DownloadTask();
        }

        if (action.type === receiveUploadCreated) {
            const { scanId } = action;
            draft.uploads[scanId] = new UploadTask();
            draft.contouringTasks[scanId] = new ContouringTask(scanId);
        }

        if (action.type === receiveDownloadAddFile) {
            const { request } = action;

            let filename: string;
            let key: string;

            if (instanceOfAzureFileShareRequest(request)) {
                filename = request.fileInfo.filename;
                key = request.downloadKey || request.fileInfo.filename.toString();
            }
            else if (instanceOfApiFileShareRequest(request)) {
                filename = request.path;
                key = request.downloadKey || request.path;
            }
            else {
                const errorMsg = `Invalid request object received`;
                console.error(errorMsg);
                console.log(action);
                throw new Error(errorMsg);
            }

            const download: DownloadTask = draft.downloads[key] || new DownloadTask();
            download.files[filename] = new TransferredFile();
            draft.downloads[key] = download;
        }

        if (action.type === receiveUploadAddFile) {
            const { filename, scanId } = action;
            const upload = draft.uploads[scanId];// || new UploadTask();
            if (upload === undefined) { console.error(`Expected upload task for scanId ${scanId} to exist!`); }

            const tf = new TransferredFile();
            tf.scanId = scanId;
            tf.fileType = TransferredFileType.ImageSlice;
            upload.files[filename] = tf;

            const contouringTask = draft.contouringTasks[scanId];
            contouringTask.totalImagesToUpload = Object.keys(upload.files).length;
            if (contouringTask.contouringState === ContouringTaskState.NotStarted) {
                contouringTask.uploadStartTime = new Date();
                contouringTask.contouringState = ContouringTaskState.UploadingFiles;
            }
        }

        if (action.type === deleteDownloadType) {
            draft.downloads[action.downloadKey] = undefined;
        }

        if (action.type === deleteUploadType) {
            draft.uploads[action.scanId] = undefined;
        }

        if (action.type === receiveFileDownloadedType) {

            const { receivedFile } = action;

            let key: string;
            let filename: string;

            if (receivedFile instanceof ReceivedAzureFile) {
                key = receivedFile.downloadKey || receivedFile.fileInfo.toString();
                filename = receivedFile.fileInfo.filename;
            }
            else if (receivedFile instanceof ReceivedApiFile) {
                key = receivedFile.downloadKey || receivedFile.path;
                filename = receivedFile.path;
            }
            else {
                const errorMsg = `Invalid ReceivedFile object received`;
                console.error(errorMsg);
                console.log(action);
                throw new Error(errorMsg);
            }

            const { scanId, structureSetId } = receivedFile;

            const download: DownloadTask = draft.downloads[key];
            let received = 0;
            const filenames = Object.keys((draft.downloads[key] as DownloadTask).files);
            filenames.forEach(f => {
                if (download.files[f].ready || f === filename) {
                    received++;
                }
            });

            const file = download.files[filename];
            file.ready = true;
            file.scanId = scanId;
            if (structureSetId) {
                file.fileType = TransferredFileType.StructureSet;
                file.structureSetId = structureSetId;
            } else {
                file.fileType = TransferredFileType.ImageSlice;
            }

            download.ready = (received === filenames.length);
            download.progressPercentage = received * 100 / filenames.length;
            // download.files[filename] = file;

            draft.downloads[key] = download;
        }

        if (action.type === receiveFileUploadedType) {

            const key = action.scanId;
            const filename = action.filename;

            const upload: UploadTask = draft.uploads[key];

            // ignore everything else here if the upload has already failed
            if (!upload.failed) {
                let sentCount = 0;
                const filenames = Object.keys(upload.files);

                upload.files[filename].ready = true;
                filenames.forEach(fn => {
                    if (upload.files[fn].ready) {
                        sentCount++;
                    }
                })
                upload.ready = (sentCount === filenames.length);
                upload.progressPercentage = sentCount * 100 / filenames.length;

                const contouringTask: ContouringTask = draft.contouringTasks[key];
                if (contouringTask) {
                    contouringTask.contouringState = upload.ready ? ContouringTaskState.PollingForResults : ContouringTaskState.UploadingFiles;
                    contouringTask.imagesUploaded = sentCount;
                    contouringTask.totalImagesToUpload = filenames.length;
                    if (upload.ready && !contouringTask.uploadFinishTime) { contouringTask.uploadFinishTime = new Date(); }
                }
            }
        }

        if (action.type === receiveDownloadErrorType) {
            const { error } = action;

            let key: string;

            if (error instanceof AzureDownloadError) {
                key = error.downloadKey || error.azureInfo.toString();
            }
            else if (error instanceof ApiDownloadError) {
                key = error.downloadKey || error.path;
            }
            else {
                const errorMsg = `Invalid DownloadError object received`;
                console.error(errorMsg);
                console.log(action);
                throw new Error(errorMsg);
            }

            const download: DownloadTask = draft.downloads[key] || new DownloadTask();
            download.failed = true;
            download.error = error.error;
            draft.downloads[key] = download;
        }

        if (action.type === receiveUploadErrorType) {
            const key = action.scanId;
            const error = action.error;

            const upload: UploadTask = draft.uploads[key];

            // if this upload has already failed, don't bother overwriting it
            if (!upload.failed) {
                upload.failed = true;
                upload.error = error;
            }
            // set any possible contouring task to 'error' state (but don't overwrite any existing errors)
            const contouringTask: ContouringTask = draft.contouringTasks[key];
            if (contouringTask && contouringTask.contouringState !== ContouringTaskState.Error) {
                contouringTask.contouringState = ContouringTaskState.Error;
                contouringTask.errorMessage = action.error;
                console.log(action.error);
                contouringTask.uploadFinishTime = new Date();
            }
        }

        if (action.type === receiveSliceType) {
            const { imageSlice, arrayBuffer, scanId, filename } = action;
            const slice = { imageSlice: imageSlice, arrayBuffer: arrayBuffer, filename: filename };
            const modality = imageSlice.modality;
            const seriesDescription = imageSlice.seriesDescription;

            // overwrite scan values with whatever we just parsed from the slice
            // this means that in cases of erroneously configured slices the last one
            // parsed will control these values
            const scan = draft.scans[scanId] || {};
            scan.scanId = scanId;
            scan.modality = modality;
            scan.seriesDescription = seriesDescription;
            scan.slices = scan.slices || {};
            scan.slices[imageSlice.sopInstanceUID] = slice;

            draft.scans[scanId] = scan;
        }

        if (action.type === receiveFullImageType) {
            const { image } = action;
            const scan = draft.scans[image.scanId] || {};
            scan.scanId = image.scanId;
            scan.modality = image.modality;
            scan.seriesDescription = image.seriesDescription;
            scan.image = image;
            draft.scans[image.scanId] = scan;
        }

        if (action.type === unloadScanType) {
            draft.scans[action.scanId] = null;
        }

        if (action.type === unloadStructureSetType) {
            const scan = draft.scans[action.scanId];
            if (scan) {
                scan.structureSets[action.structureSetId] = null;
            }
        }

        if (action.type === unloadAllContouringTasksType) {
            draft.contouringTasks = {};
        }

        if (action.type === receiveStructureSetType) {
            const ss = action.structureSet;
            const scanId = ss.scanId;
            const ssId = ss.structureSetId;

            if (!draft.scans[scanId]) {
                draft.scans[scanId] = {};
            }

            if (!draft.scans[scanId].structureSets) {
                draft.scans[scanId].structureSets = {};
            }

            draft.scans[scanId].structureSets[ssId] = ss;

            if (draft.contouringTasks[scanId]) {
                const task = draft.contouringTasks[scanId];
                task.contouringState = ContouringTaskState.Success;
                task.taskFinishTime = new Date();
                if (task.uploadStartTime && task.uploadFinishTime) {
                    const taskTimeLogMessage = `Auto-contouring finished in ${getDurationString(differenceInSeconds(task.taskFinishTime, task.uploadFinishTime))}`
                        + ` (total task time: ${getDurationString(differenceInSeconds(task.taskFinishTime, task.uploadStartTime))})`;
                    console.log(taskTimeLogMessage);
                }

                if (task.matchingStructureSetIds === null) {
                    task.matchingStructureSetIds = [];
                }
                task.matchingStructureSetIds.push(ssId);

                // if the received structure set was previously a contouring task, mark it as a new structure set
                if (draft.newStructureSets.indexOf(ssId) === -1) {
                    draft.newStructureSets.push(ssId);
                }
            }
        }

        if (action.type === deleteStructureSetType) {
            const { structureSet } = action;
            const scanId = structureSet.scanId;
            const ssIdToDelete = structureSet.structureSetId;

            if (draft.scans[scanId] !== undefined && draft.scans[scanId].structureSets[ssIdToDelete] !== undefined) {
                delete draft.scans[scanId].structureSets[ssIdToDelete];
            }
        }

        if (action.type === seenStructureSetType) {
            const ssId = action.structureSetId;
            const index = draft.newStructureSets.indexOf(ssId);
            if (index > -1) {
                draft.newStructureSets.splice(index, 1);
            }
        }

        if (action.type === storeOriginalStructureSet) {
            const { structureSetId, file, filename }: { structureSetId: string, file: ArrayBuffer, filename: string } = action;
            draft.originalStructureSets[structureSetId] = { file, filename };
        }

        if (action.type === updateContouringTaskType) {
            const { scanId, newContouringState, errorMessage } = action;
            const contouringTask = draft.contouringTasks[scanId];

            contouringTask.contouringState = newContouringState;

            if (errorMessage) { contouringTask.errorMessage = errorMessage; }

            if (newContouringState === ContouringTaskState.Success || newContouringState === ContouringTaskState.Failed || newContouringState === ContouringTaskState.Error) {
                contouringTask.taskFinishTime = new Date();
            }
        }

        if (action.type === dismissContouringTaskType) {
            const { scanId } = action;
            const contouringTask = draft.contouringTasks[scanId];
            if (contouringTask) { contouringTask.isDismissed = true }
        }

        if (action.type === setUserSettingsDialogVisibilityType) {
            draft.isUserSettingsDialogVisible = action.value as boolean;
        }

        if (action.type === setUserSettingBackend) {
            const { backend } = action;
            draft.user.currentBackend = backend;
        }

        if (action.type === setUserSettingPatientInfoVisibility) {
            const { showPatientInfo } = action;
            draft.user.showPatientInfo = showPatientInfo;
        }

        if (action.type === setHelpDialogVisibility) {
            draft.isHelpDialogVisible = action.value as boolean;
        }

        if (action.type === setBatchJobPaneVisibilityType) {
            draft.isBatchJobPaneVisible = action.value as boolean;
        }

        if (action.type === receiveBatchJobsStatusType) {
            draft.batchJobs = action.batchJobs;
        }

        if (action.type === batchJobRequestLockType) {
            draft.isBatchJobRequestLocked = action.lock;
        }

        if (action.type === addNotificationType) {
            const newNotification: SessionNotification = action.notification;

            // add or update the notification
            const existingNotificationIndex = draft.notifications.findIndex((n: SessionNotification) => n.id === newNotification.id);
            if (existingNotificationIndex !== -1) {
                draft.notifications.splice(existingNotificationIndex, 1, newNotification);
            } else {
                draft.notifications.push(action.notification);
            }
        }

        if (action.type === removeNotificationType) {
            const index = draft.notifications.findIndex((n: SessionNotification) => n.id === action.notificationId);
            if (index !== -1) {
                draft.notifications.splice(index, 1);
            }
        }

        if (action.type === setStructureTemplatesType) {
            draft.structureTemplates = action.structureTemplates;
        }

        if (action.type === receiveAppVersionInfo) {
            const appVersionInfo = action.appVersionInfo as AppVersionInfo;
            if (draft.appVersion === null) {
                /** Print current version to developer console on receive to make debugging easier. */
                console.log(`RTViewer version: ${appVersionInfo.toString()}, display version: ${RTViewerDisplayVersion}`);
                console.log(appVersionInfo);
                draft.appVersion = appVersionInfo;

                // set correct version to app auths
                Object.values(backendTierAppAuths).forEach(appAuth => appAuth.setAppVersion(appVersionInfo));
            } else {
                if (!(draft.appVersion as AppVersionInfo).isEqual(appVersionInfo)) {
                    draft.isNewAppVersionAvailable = true;
                }
            }
        }

        if (action.type === snoozeNewVersionAlert) {
            draft.newVersionAlertSnoozedSince = new Date();
        }

        if (action.type === setLiveReviewIsDownloading) {
            const { isDownloadingLiveReviewFiles } = action;
            draft.isDownloadingLiveReviewFiles = isDownloadingLiveReviewFiles || false;
        }

        if (action.type === setLiveReviewQueries) {
            const { queryV1, queryV2 } = action;
            draft.liveReviewQueries.queryV1 = queryV1;
            draft.liveReviewQueries.queryV2 = queryV2;
        }

        if (action.type === setLiveReviewErrorMessage) {
            const { errorMessage }: { errorMessage: string | undefined } = action;
            draft.liveReviewErrorMessage = errorMessage;
        }

        if (action.type === setLoginError) {
            const { loginError }: { loginError: LoginError | null } = action;
            draft.loginError = loginError;
        }

        // ** DATASETS**

        if (action.type === startDatasetDownload) {
            const { datasetId }: { datasetId: string } = action;
            draft.datasetsDownloading[datasetId] = true;
        }

        if (action.type === finishDatasetDownload) {
            const { datasetId }: { datasetId: string } = action;
            draft.datasetsDownloading[datasetId] = false;
        }

        if (action.type === addDataset) {
            const { dataset, overrideGradings }: { dataset: Dataset, overrideGradings: boolean } = action;
            const datasetId = dataset.getDatasetId();
            draft.datasets[datasetId] = dataset;

            // also save gradings if the override flag is on
            if (overrideGradings) {
                draft.datasetGradings[datasetId] = dataset.metaFiles.gradings;
            }
        }

        if (action.type === startDatasetLockRequest) {
            const { datasetId, seriesId, lockAction }: { datasetId: string, seriesId: string, lockAction: LockAction } = action;
            let datasetRequests: ScanLockRequests = draft.lockRequests[datasetId];
            if (datasetRequests === undefined) {
                draft.lockRequests[datasetId] = {};
                datasetRequests = draft.lockRequests[datasetId];
            }
            datasetRequests[seriesId] = lockAction;
        }

        if (action.type === setDatasetLock) {
            const { datasetId, scanLocks, clearRequests }: { datasetId: string, scanLocks: ScanLocks, clearRequests?: boolean } = action;

            // override all existing locks with received ones for this dataset
            draft.datasetLocks[datasetId] = scanLocks;

            // clear fulfilled lock requests for this dataset
            if (clearRequests) {
                const requests: ScanLockRequests = draft.lockRequests[datasetId];
                if (requests) {
                    const seriesIds = Object.keys(requests);
                    for (let i = 0; i < seriesIds.length; i++) {
                        const seriesId = seriesIds[i];
                        if ((requests[seriesId] === LockAction.Lock && scanLocks[seriesId])
                            || (requests[seriesId] === LockAction.Unlock && scanLocks[seriesId] !== draft.user.username)) {
                            delete draft.lockRequests[datasetId][seriesId];
                        }
                    }
                }
            }
        }

        if (action.type === undoDatasetLockRequest) {
            const { datasetId, seriesId }: { datasetId: string, seriesId: string } = action;
            let datasetRequests: ScanLockRequests = draft.lockRequests[datasetId];
            if (datasetRequests && datasetRequests[seriesId]) {
                delete draft.lockRequests[datasetId][seriesId]
            }
        }

        if (action.type === setCurrentWorkState) {
            const { workState }: { workState: WorkState } = action;
            draft.currentWorkState = workState;
        }

        if (action.type === setCurrentWorkspace) {
            const { workspace }: { workspace: Workspace } = action;
            draft.currentWorkspace = workspace;
        }

        if (action.type === updateCurrentWorkState) {
            const { type, ...updatedWorkStateProps } = action;
            const workState = draft.currentWorkState;
            for (const key in updatedWorkStateProps) {
                if (key in workState) {
                    workState[key] = updatedWorkStateProps[key];
                }
            }
        }

        if (action.type === setDatasetGradings) {
            const { datasetId, datasetGradings }: { datasetId: string, datasetGradings: DatasetGradings } = action;
            draft.datasetGradings[datasetId] = datasetGradings;
        }

        if (action.type === startDatasetGradingSave) {
            const { gradingsToSave, dataset }: { gradingsToSave: GradingToSave[], dataset: Dataset } = action;
            const datasetId = dataset.getDatasetId();
            const allGradingsToSave: GradingToSave[] = draft.modifiedDatasetGradings[datasetId] || [];

            draft.isSavingGradings = true;

            for (const gradingToSave of gradingsToSave) {
                const gradingChangeToSameStructureSet = allGradingsToSave.find(g => g.ssId === gradingToSave.ssId);
                if (gradingChangeToSameStructureSet) {
                    // remove previous modification to same structure set
                    _.pull(allGradingsToSave, gradingChangeToSameStructureSet);
                }
                allGradingsToSave.push(gradingToSave);
            }
            draft.modifiedDatasetGradings[datasetId] = allGradingsToSave;
        }

        if (action.type === finishDatasetGradingSave) {
            const { wasSuccessful, gradingsToSave, dataset }: { wasSuccessful: boolean, gradingsToSave: GradingToSave[], dataset: Dataset } = action;
            draft.isSavingGradings = false;

            // clear modified gradings if save was successful, assuming there's been no new changes since
            if (wasSuccessful) {
                const datasetId = dataset.getDatasetId();
                const currentModifiedGradings: GradingToSave[] = draft.modifiedDatasetGradings[datasetId] || [];
                if (_.isEqual(gradingsToSave, currentModifiedGradings)) {
                    delete draft.modifiedDatasetGradings[datasetId];
                }
            }
        }

        if (action.type === clearModifiedDatasetGradings) {
            draft.modifiedDatasetGradings = {};
        }

        /**
         * Synchronizes a structure set's matching grading sheet, if any, to match
         * the current ROI set in the structure set.
         */
        if (action.type === syncStructureSetGrading) {
            const { structureSet, dataset }: { structureSet: StructureSet, dataset: Dataset } = action;

            const datasetId = dataset.getDatasetId();
            const gradings: DatasetGradings | undefined = draft.datasetGradings[datasetId];
            if (gradings) { // Update gradings for the structure set
                const ssGrading = gradings.structureSets[structureSet.structureSetId];
                if (ssGrading) {
                    const ssRoiNrs = Object.keys(structureSet.rois);
                    let gradingRoiNrs = Object.keys(ssGrading.rois);

                    // 1. Delete gradings if ROI is deleted
                    const deletedRoiNrs = gradingRoiNrs.filter(nr => !ssRoiNrs.includes(nr));
                    for (let i = 0; i < deletedRoiNrs.length; ++i) {
                        delete ssGrading.rois[deletedRoiNrs[i]];
                    }
                    // 2. Make sure ROI names are up-to-date in the grading
                    gradingRoiNrs = Object.keys(ssGrading.rois);
                    for (let i = 0; i < gradingRoiNrs.length; ++i) {
                        const roiNr = gradingRoiNrs[i];
                        ssGrading.rois[roiNr].roiName = structureSet.rois[roiNr].name;
                    }
                }
            }
        }

        if (action.type === setStructureSetGrading) {
            const { gradingsToSave, dataset }: { gradingsToSave: GradingToSave[], dataset: Dataset } = action;

            const datasetId = dataset.getDatasetId();
            let gradings: DatasetGradings | undefined = draft.datasetGradings[datasetId];
            if (gradings === undefined) {
                gradings = new DatasetGradings();
                draft.datasetGradings[datasetId] = gradings;
            }

            for (const gradingToSave of gradingsToSave) {
                const { ssGrading, ssId } = gradingToSave;
                if (ssGrading) {
                    gradings.structureSets[ssId] = ssGrading;
                }
                else if (!ssGrading && gradings.structureSets[ssId]) {
                    delete gradings.structureSets[ssId];
                }
            }
        }

        if (action.type === fileLoadError) {
            const { errorMessage, fileName }: { errorMessage: string, fileName: string } = action;
            const newFileLoadError: FileLoadError = { errorMessage, fileName };
            draft.fileLoadErrors!.push(newFileLoadError);
        }

        if (action.type === clearFileLoadErrors) {
            draft.fileLoadErrors = [];
        }

        if (action.type === resetLocalFileProgress) {
            draft.localFileProgress = undefined;
        }

        if (action.type === setLocalFileProgress) {
            const localFileProgress = { progress: action.progress, total: action.total };
            if (isLocalFileProgress(localFileProgress)) {
                draft.localFileProgress = localFileProgress;
            }
        }

        if (action.type === startRestoringBackup) {
            draft.isRestoringBackup = true;
        }

        if (action.type === finishRestoringBackup) {
            draft.isRestoringBackup = false;
        }

    });
};
