import * as storage from '../local-storage';
import { AvailableModels } from './contouring-options';
import { StructureTemplate } from './structure-template';
import { isDemo, isLNDemo } from '../environments';
import { UserPermissions } from './user-permissions';
import { getRtViewerBackendClient } from './auth';
import { User } from '../store/user';

const ApiRoot = '/api';
const ContouringOptionsUrl = `${ApiRoot}/contouringoptions/contouringoptions`;
const StructureTemplatesUrl = `${ApiRoot}/structuretemplates`;
const UserPermissionsUrl = `${ApiRoot}/permissions`;
const SasUrl = `${ApiRoot}/sas/`;
const UserAccessUrl = `${ApiRoot}/sas/access`;

const DatasetApiRoot = '/datasets';
const DatasetSplitsUrl = `${DatasetApiRoot}/splits`;


class RTViewerAPIClient {

    // TODO: separate these user specific properties from the client functions below
    // NOTE: see User.tsx -- that one's supposed to be the single source of truth for user information.
    public username: string;
    public email: string;
    public permissions: UserPermissions;

    constructor() {
        this.username = "default-user";
        this.email = "";
        this.permissions = new UserPermissions(false);
    }

    public isMVisionUser() {
        // TODO: handle this with RTViewer config user group settings
        return this.email.includes("@mvision.ai") && !this.email.includes("demo");
    }

    // update the redux store user
    // NOTE: see User.tsx -- that one's supposed to be the single source of truth for user information.
    public updateUser(user: User) {
        this.username = user.username;
        this.email = user.email;
        this.permissions = user.permissions;
    }


    // API CALL METHODS
    // TODO: separate these from the rtviewer user class above
    public async getAvailableModels(): Promise<AvailableModels> {

        const client = getRtViewerBackendClient();
        const url = ContouringOptionsUrl;

        const response = await client.fetch(url);
        if (response.status !== 200) {
            console.log(response);
            throw new Error("Invalid model options: " + response.status);
        }
        const options = await response.json();
        options.available_models = [];
        options.backend_models = [];
        return options as AvailableModels;
    }

    public async getStructureTemplates(): Promise<StructureTemplate[]> {
        const url = StructureTemplatesUrl;
        const client = getRtViewerBackendClient();

        const response = await client.fetch(url);
        if (response.status !== 200) {
            console.error("Could not fetch structure templates: " + response.status)
            return [];
        }
        const templateData = await response.json();

        // convert incoming template data into StructureTemplate objects
        // TODO: RUTHERFORD: sort these into rutherford and non-rutherford ones
        const templates: StructureTemplate[] = [];
        templateData.forEach((t: any) => templates.push(new StructureTemplate(t)));

        // validate & sort templates
        // 1. every template must have a unique name
        // 2. template name must not be an empty string
        // 3. every roi in a template must have a unique title
        // 4. roi title must not be an empty string
        // -> if any of these conditions does not pass, print an error and remove the template(s) from the list
        const uniqueTemplateNames: { [name: string]: number } = {};
        let invalidTemplates: StructureTemplate[] = [];

        templates.forEach(t => {
            const name = t.name;
            if (name === '') {
                console.error('Structure template has an empty string for its name!');
                invalidTemplates.push(t);
            }

            if (uniqueTemplateNames.hasOwnProperty(name)) {
                uniqueTemplateNames[name] += 1;
            } else {
                uniqueTemplateNames[name] = 1;
            }

            const uniqueRoiTitles: { [name: string]: number } = {};
            t.templateRois.forEach(r => {
                const title = r.title;
                if (title === '') {
                    console.error(`Structure for Structure template ${name} has an empty string for its name!`);
                    invalidTemplates.push(t);
                }

                if (uniqueRoiTitles.hasOwnProperty(title)) {
                    uniqueRoiTitles[title] += 1;
                } else {
                    uniqueRoiTitles[title] = 1;
                }
            });

            Object.entries(uniqueRoiTitles).forEach(
                ([key, value]) => {
                    if (value > 1) {
                        console.error(`Structure ${key} has been configured ${value} times in template ${name} -- all structures must have unique names.`);
                        invalidTemplates.push(t);
                    }
                }
            );

            // while we're here, sort the ROIs alphabetically
            t.templateRois.sort((a, b) => a.title.localeCompare(b.title));
        });

        Object.entries(uniqueTemplateNames).forEach(
            ([key, value]) => {
                if (value > 1) {
                    console.error(`${value} templates have the same name '${key}' -- all templates must have unique names.`);
                    const duplicateNameTemplates = templates.filter(t => t.name === key);
                    invalidTemplates = invalidTemplates.concat(duplicateNameTemplates);
                }
            }
        );

        // remove invalid templates & sort the ones left
        const sortedTemplates = templates.filter(t => !invalidTemplates.includes(t)).sort((a, b) => a.name.localeCompare(b.name));

        return sortedTemplates;
    }

    public async getAnnotationStorages() {
        const url = SasUrl + "annotationstorages";
        const client = getRtViewerBackendClient();
        const response = await client.fetch(url);
        const names = await response.json();
        return names.sort();
    }


    public async getAllStorages() {
        const url = SasUrl + "allstorages";
        const client = getRtViewerBackendClient();
        const response = await client.fetch(url);
        const names = await response.json();
        return names.sort();
    }

    // Returns a cached sas if found (from local storage)
    public async getSas(storageAccountName: string) {
        const cachedSas = storage.getSas(storageAccountName);
        if (cachedSas) {
            return cachedSas;
        }
        const url = SasUrl + storageAccountName;
        const client = getRtViewerBackendClient();
        const response = await client.fetch(url);
        if (response.status === 200) {
            const sas = await response.text();
            storage.setSas(storageAccountName, sas);
            return sas;
        }
        console.log("Failed to get SAS from the server. Status code: " + response.status);
    }

    public async getAuthenticatedUser() { // Call this only once! Then, use sasClient.username 
        const url = SasUrl + 'email';
        const client = getRtViewerBackendClient();

        const email = ((await client.quickFetch(url, null, { asText: true })) as string).toLowerCase().trim();
        if (email === '{"detail":"unauthorized"}') {
            const errorMessage = 'Did not receive correct username -- please take a screenshot of developer console contents (F12) and send it to dev team for further assistance!';
            alert(errorMessage);
            throw new Error(errorMessage);
        }

        const username = email.replace("@mvision.ai", "")
        const permissions = await this.getUserPermissions();

        return { username: username, email: email, permissions: permissions };
    }

    public async getUserPermissions(): Promise<UserPermissions> {
        const url = UserPermissionsUrl;
        const client = getRtViewerBackendClient();
        const response = await client.fetch(url);
        const permissionsData = await response.json();
        return new UserPermissions(permissionsData);
    }

    /** Fetch user access data from backend (e.g. which annotation datasets current user has access to) */
    public async getUserAccess(): Promise<any> {
        const url = UserAccessUrl;
        const client = getRtViewerBackendClient();
        const response = await client.fetch(url);
        const accessData = await response.json();
        return accessData;
    }

    /** Fetch dataset categories ("split categories") for dataset patients from backend */
    public async getDatasetCategories(): Promise<any> {
        const url = DatasetSplitsUrl;
        const client = getRtViewerBackendClient();
        const response = await client.fetch(url);
        const accessData = await response.json();
        return accessData;
    }
}

// NOTE: see User.tsx -- that one's supposed to be the single source of truth for user information.
export const rtViewerApiClient = new RTViewerAPIClient();
