import React from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { RouteComponentProps } from 'react-router';

import * as sagas from '../../store/sagas';
import DatasetBrowser from './DatasetBrowser';
import RTViewer from '../rtviewer/RTViewer';
import { StoreState } from '../../store/store';
import { ErrorBoundary } from '../ErrorBoundary';
import GlobalToolbar from '../common/GlobalToolbar';
import AnnotationQuery from './models/AnnotationQuery';
import { DatasetImage } from '../../datasets/dataset-image';
import { Dataset } from '../../datasets/dataset';
import WorkState from '../../store/work-state';
import { DownloadTask } from '../../store/file-transfer-task';
import SplashScreen from '../splash-screen/SplashScreen';
import Workspace from '../../store/workspace';

import './AnnotationPage.css';
import { NotificationType, SessionNotification } from '../common/models/SessionNotification';

type OwnProps = {}

type DispatchProps = {
    setCurrentWorkspace(workspace: Workspace): void,
    setCurrentWorkState(workState: WorkState | null): void,
    loadAnnotationQuery(query: AnnotationQuery): void,
    addNotification(notification: SessionNotification, delayInMilliseconds?: number): void,
    reloadDatasetGradings(dataset: Dataset): void,
    initializeAnnotationPage(): void,
}

type AllProps = OwnProps & StoreState & DispatchProps & RouteComponentProps;

type OwnState = {
    user?: string,
    datasetImage: DatasetImage | null,
    canEdit: boolean,
    isAutoLoading: boolean,
    autoLoadProgress?: number,
}

class AnnotationPage extends React.Component<AllProps, OwnState> {
    displayName = AnnotationPage.name

    constructor(props: AllProps) {
        super(props);

        this.props.setCurrentWorkspace(Workspace.Annotation);
        this.props.initializeAnnotationPage();

        let isAutoLoading = false;

        try {
            const annotationQuery = AnnotationQuery.fromQueryParameter(this.props.location.search);
            if (annotationQuery) {
                isAutoLoading = true;
                this.autoload(annotationQuery);
            } else {
                // reset an invalid query parameter at load at this point
                this.setUrl();
            }
        }
        catch (err) {
            const errorMessage = 'An error occurred when trying to initialize page.';
            this.props.setCurrentWorkState(new WorkState(null, null, false, false, null, err.message || errorMessage));
            this.props.addNotification(new SessionNotification(Date.now().toString(), errorMessage, NotificationType.Error, err.message || undefined));
        }

        this.state = { canEdit: false, datasetImage: null, isAutoLoading: isAutoLoading, };
    }

    componentDidUpdate = (prevProps: AllProps) => {
        // update URL if current work state has changed, unless the work state is in an error state
        if (this.props.currentWorkState !== prevProps.currentWorkState && this.props.currentWorkState && !this.props.currentWorkState.hasError()) {
            this.setUrl();
        }

        // update autoload download progress & open RTViewer if auto-loading has finished
        if (this.state.isAutoLoading && (this.props.currentWorkState as WorkState).hasWork()) {
            const workState: WorkState = this.props.currentWorkState;
            const downloadKey = workState.datasetImage!.downloadKey;
            const download: DownloadTask = this.props.downloads![downloadKey];

            if (download && download.ready) {
                this.finalizeAutoLoad(workState);
            }
        }
    }

    /**
     * Set current work state into URL's query (search) parameters,
     * or remove any existing query parameters if there is no current
     * work.
     */
    setUrl = () => {
        // currently only set URL based on current work state, whether it's
        // a scan loaded into rtviewer or a dataset listing opened on the
        // annotation page (this component)
        const workState: WorkState = this.props.currentWorkState;
        const query = workState.getAnnotationQuery();
        if (query) {
            this.props.history.push({ search: query.getQueryParameters() });
            return;
        }
    }

    autoload = (annotationQuery: AnnotationQuery) => {
        this.props.loadAnnotationQuery(annotationQuery);
    }

    /**
     * Returns current progress (0-100) of autoload download, or undefined
     * if not relevant.
     */
    getAutoLoadProgress = (): number | undefined => {
        const workState: WorkState = this.props.currentWorkState;
        if (workState.hasWork()) {
            const downloadKey = workState.datasetImage!.downloadKey;
            const download: DownloadTask = this.props.downloads![downloadKey];

            if (download) {
                return parseInt(download.progressPercentage.toString(), 10);
            }
        }

        return undefined;
    }

    /**
     * Validates that given workstate looks ok for annotation work. Returns a new, fixed workstate
     * if not, or null if the workstate was ok.
     */
    validateWorkState = (workState: WorkState): WorkState | null => {
        if (workState.canEdit && workState.datasetImage!.seriesId.length > 64) {
            alert("Image's SeriesInstanceUID length exceeds 64 characters. Editing is disabled!");
            return new WorkState(workState.datasetImage, workState.dataset, false, false);
        }

        return null;
    }

    handleViewImage = (datasetImage: DatasetImage, dataset: Dataset, canEdit: boolean) => {
        const workState = new WorkState(datasetImage, dataset, canEdit, canEdit);
        const fixedWorkState = this.validateWorkState(workState);

        // change current work state & reload dataset gradings when we're changing the image we're viewing
        this.props.setCurrentWorkState(fixedWorkState ? fixedWorkState : workState);
        this.props.reloadDatasetGradings(dataset);

        this.viewImage(workState);
    }

    finalizeAutoLoad = (workState: WorkState) => {
        const fixedWorkState = this.validateWorkState(workState);
        if (fixedWorkState) {
            this.props.setCurrentWorkState(fixedWorkState);
        }

        this.viewImage(fixedWorkState ? fixedWorkState : workState);
    }

    viewImage = (workState: WorkState) => {
        this.setState({ datasetImage: workState.datasetImage, canEdit: workState.canEdit, isAutoLoading: false, autoLoadProgress: undefined });
    }

    handleBack = () => {
        this.props.setCurrentWorkState(null);
    }

    render = () => {
        const { currentWorkState }: { currentWorkState?: WorkState } = this.props;
        const { isAutoLoading } = this.state;
        const datasetImage = currentWorkState ? currentWorkState.datasetImage : null;
        const isImageMetadataLoaded = datasetImage !== null;
        const hasErrored = currentWorkState ? currentWorkState.hasError() : false;

        // automatically navigate datasetbrowser to the dataset from current work
        // state if we're coming in from a direct URL (i.e. we're auto-loading)
        const navigateToWorkState = isAutoLoading ? currentWorkState : undefined;

        // show splash screen if we're auto-loading a scan (and not a dataset page index)
        const showSplashScreen = !hasErrored && isAutoLoading && currentWorkState && !currentWorkState.isDatasetIndex;

        // show rtviewer if we're done with auto-loading and the splash screen (if applicable) 
        // and related scan dataset data is loaded in
        const showRTViewer = !showSplashScreen && !hasErrored && isImageMetadataLoaded && !isAutoLoading;

        // always default to showing the dataset browser if we've errored (or also in other fall-back cases)
        // TODO: will this cause problems if we set work state to error while already IN rtviewer?
        const showDatasetBrowser = !showSplashScreen && !showRTViewer;

        let canEdit = currentWorkState ? currentWorkState.canEdit : false;
        let canCreateRtstruct = currentWorkState ? currentWorkState.canCreateRtstruct : false;
        let scanId = isImageMetadataLoaded ? datasetImage!.seriesId : "";
        if (scanId.length > 64) {
            canEdit = false;
            canCreateRtstruct = false;
            scanId = scanId.substring(0, 64);
        }


        return (
            <>
                {this.props.isAnnotationPageReady && (
                    <div className="container-fluid annotation-container">

                        {showSplashScreen && (
                            <SplashScreen
                                isVisible={true}
                                showProgress={true}
                                progressNow={this.getAutoLoadProgress()}
                                patientId={(this.props.currentWorkState as WorkState).getWorkName()}
                            />
                        )}

                        <div className={showDatasetBrowser ? 'visible' : 'invisible'} >
                            <Row>
                                <Col>
                                    <DatasetBrowser
                                        viewImage={this.handleViewImage} isVisible={!isImageMetadataLoaded} initialWorkState={navigateToWorkState}
                                    />
                                </Col>
                                <GlobalToolbar isEmbedded={false} />
                            </Row>
                        </div>


                        {showRTViewer ?
                            <ErrorBoundary>
                                <RTViewer scanId={scanId}
                                    datasetImage={datasetImage!} canEdit={canEdit} canCreateRtstruct={canCreateRtstruct} handleBack={this.handleBack} />
                            </ErrorBoundary>
                            : null}
                    </div>
                )
                }
            </>
        );
    }
};

export default connect(
    state => Object.assign({}, state),
    sagas.mapDispatchToProps
)(AnnotationPage);
