import { SDFGenerator } from './sdf-generator';
import * as sdfTexture from './sdf-texture';
import { Image } from '../../../dicom/image';
import { RoiContours, Roi, Polygon, Point } from '../../../dicom/structure-set';
import { BoundingBox } from '../../../math/bounding-box';
import { ViewManager } from '../../view-manager';
//@ts-ignore
import * as marchingSquares from 'marchingsquares';
import { Propagation } from './propagation/sdf-propagation';
import { flattenArray, deepCopy } from '../../../util';
import { FrameBufferObject } from '../objects/FrameBufferObject';

export function getRoiSdfResolution(img: Image, roi: Roi) {
    const spacing = Math.max(img.iSpacing, img.jSpacing);

    // Using smaller resolution for body doesn't seem to be a good idea.
    // What would we do when they draw on coronal/sagittal planes. Allow only drawing on every second slice?
    return spacing;// roi.isBody() ? 2*spacing : spacing;
}

function getMaxDistanceMm(img: Image): number {

    // Good 8-bit max distance is probably 16 mm. Here we calculate a distance
    // where the angular resolution of surface normals is balanced.
    // (Really should use plane separation, not Dicom slice thickness, if they
    // differ.)
    const bitDepth = 8;
    return Math.sqrt(
        (1 << (bitDepth - 1)) *
        img.kSpacing *
        Math.max(img.iSpacing, img.jSpacing)
    );
}

export class Sdf {
    viewManager: ViewManager;
    data: WebGLTexture | null;
    /**
     * size is the size of the sdf in pixels
     */
    size: number[];
    /**
     * boundingBox contains the coordinates in mm
     */
    boundingBox: BoundingBox;
    /**
     * millimeters per pixel
     */
    resolutionMm: number;
    maxDistanceMm: number;
    propagationPending: boolean; // Propagation needed before SDF can be interpolated

    constructor(vm: ViewManager, resolutionMm: number) {
        this.viewManager = vm;
        this.data = null;
        this.size = [0,0,0];
        this.boundingBox = new BoundingBox();
        this.maxDistanceMm = getMaxDistanceMm(vm.image);
        this.resolutionMm = resolutionMm;
        this.propagationPending = false;
    }

    createTexture(bb: BoundingBox, addMargin: boolean, isRGBA: boolean) {
        bb = bb.copy();
        let vm = this.viewManager;
        let gl = vm.getWebGlContext();
        if(addMargin){
            const margin = this.maxDistanceMm / 2;
            bb = bb.withMargin(margin, margin, 0.5 * vm.image.kSpacing);
            bb.roundToFullPixels(vm.image);
        }
        
        this.boundingBox = bb;
        this.size = [
            Math.round(bb.getXSize() / this.resolutionMm),
            Math.round(bb.getYSize() / this.resolutionMm),
            Math.round(bb.getZSize() / vm.image.kSpacing)
        ];
        
        this.data = sdfTexture.createSDFTexture(gl, this.size, isRGBA);
        this.clearTexture();
    }

    clearTexture() {
        let gl = this.viewManager.getWebGlContext();
        const fb = gl.createFramebuffer();
        gl.bindFramebuffer(gl.FRAMEBUFFER, fb);
        gl.clearColor(1.0, 0.0, 0.0, 0.0);
        for(let z = 0; z < this.size[2]; ++z) {
            gl.framebufferTextureLayer(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, this.data, 0, z);
            //TODO: Would it be better to just delete the texture completely?
            gl.clear(gl.COLOR_BUFFER_BIT);
        }
        gl.bindFramebuffer(gl.FRAMEBUFFER, null);
        gl.deleteFramebuffer(fb);
    }

    clearSlice(sliceId: string) {
        const vm = this.viewManager;
        const img = vm.image;
        const imgBb = img.getRealBoundingBox();
        const i = img.sliceIds.findIndex(id => id === sliceId);
        if(i === -1) return;
        const z = i - Math.round( (this.boundingBox.minK - imgBb.minK) / img.kSpacing );
        let gl = this.viewManager.getWebGlContext();
        const fb = gl.createFramebuffer();
        gl.bindFramebuffer(gl.FRAMEBUFFER, fb);
        gl.framebufferTextureLayer(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, this.data, 0, z);

        gl.clearColor(1.0, 1.0, 1.0, 1.0);
        gl.clear(gl.COLOR_BUFFER_BIT);
        gl.bindFramebuffer(gl.FRAMEBUFFER, null);
        gl.deleteFramebuffer(fb);
    }

    addContours(roiContours: RoiContours) {
        const vm = this.viewManager;
        const img = vm.image;
        const imgBb = img.getRealBoundingBox();

        const bb = this.boundingBox // roi.boundingBox with added margin
        const gl = vm.getWebGlContext();
        gl.viewport(0, 0, this.size[0], this.size[1]);
        const sdfGenerator = new SDFGenerator(vm, img);
        const fb = gl.createFramebuffer();
        gl.bindFramebuffer(gl.FRAMEBUFFER, fb);
        sdfGenerator.begin()

        let firstSliceIndex = Math.round( ( bb.minK - imgBb.minK) / img.kSpacing );
        // console.log([Math.round( ( bb.minI - imgBb.minI) / img.iSpacing ), Math.round( ( bb.minJ - imgBb.minJ) / img.jSpacing ), firstSliceIndex, 1])
        for(let z = 0; z < this.size[2]; ++z) {
            
            let sliceIndex = firstSliceIndex + z;
            let zPos = img.kMin + sliceIndex * img.kSpacing;

            gl.framebufferTextureLayer(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, this.data, 0, z);
            gl.clearColor(1.0, 1.0, 1.0, 1.0); //TODO: Could possibly be performed outside the loop
            gl.clear(gl.COLOR_BUFFER_BIT);

            if(sliceIndex < 0) continue;
            if(sliceIndex >= img.sliceIds.length) continue;
            let sliceId = img.sliceIds[sliceIndex];
            // console.log("z: " + z + " : " + zPos + " : " + this.size + ' : '+ sliceId)
            if(roiContours[sliceId] ) {
                let polygons = roiContours[sliceId].polygons || [];
                let contours: number[][] = [];
                for(let j = 0; j < polygons.length; ++j) {
                    let arr = flattenArray( polygons[j] );
                    if(arr.length > 9) contours.push(arr); // Ignore contours with less than 3 points
                }
                if(contours.length) {
                    // Force z coordinates on the slice plane
                    for(let i = 0; i < contours.length; ++i) {
                        for(let j = 0; j < contours[i].length; ++j) {
                            if(j%3 === 2) {
                                contours[i][j] = zPos;  // TODO: bug: for non-axis aligned scans, k-th dim will have multiple values, so this does not work
                            }
                        }
                    }
                    const buf = sdfGenerator.prepareContours(contours);
                    sdfGenerator.drawBuffer(this.maxDistanceMm, buf, zPos, bb, this.size);
                }
            }
        }
        sdfGenerator.end();
        gl.bindFramebuffer(gl.FRAMEBUFFER, null);
        gl.deleteFramebuffer(fb);
    }

    getContours(roi: Roi, roiContours: RoiContours): RoiContours {
        let gl = this.viewManager.getWebGlContext();
        const bb = this.boundingBox;
        const vm = this.viewManager;
        const img = vm.image;
        const imgBb = img.getRealBoundingBox();

        let r1=0, r2=0, r3=0, r4=0;
        
        if(this.propagationPending) {
            const pg = new Propagation(this.viewManager);
            pg.propagate(roi);
        }

        const result: RoiContours = deepCopy(roiContours);
        const fb = gl.createFramebuffer();
        gl.bindFramebuffer(gl.FRAMEBUFFER, fb);
        let firstSliceIndex = Math.round( ( bb.minK - imgBb.minK) / img.kSpacing );

        for(let z = 0; z < this.size[2]; ++z) {

            const t1 = new Date().getTime();

            const sliceIndex = firstSliceIndex + z;
            const sliceId = img.sliceIds[sliceIndex];

           if(!roi.contoursChangedInAllSlices && !roi.contoursChangedInSlices.includes(sliceId)) continue;

            const polyArray: Polygon[] = [];
            result[sliceId] = {"polygons": polyArray};
            const zPos = img.kMin + sliceIndex * img.kSpacing;
            gl.framebufferTextureLayer(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, this.data, 0, z);
            
            let pixels = new Uint8Array(4 * this.size[0] * this.size[1]);
            gl.readPixels(0, 0, this.size[0], this.size[1], gl.RGBA, gl.UNSIGNED_BYTE, pixels);

            const t2 = new Date().getTime();

            const data: number[][] = [];
            for(let y = 0; y < this.size[1]; ++y) {
                const row: number[] = [];
                for(let x = 0; x < this.size[0]; ++x) {
                    const r = pixels[y*this.size[0]*4 + x*4];
                    row.push(r);
                }
                data.push(row);
            }

            const t3 = new Date().getTime();

            const threshold = 127.5;
            const options = {};
            const polygons = marchingSquares.isoLines(data, threshold, options)

            const t4 = new Date().getTime();

            polygons.forEach((poly: number[][]) => {
                let polygon: Point[] = [];
                poly.forEach((coord: number[]) => {
                    const pt = [
                        bb.minI + ( coord[0] * (this.size[0] ) / (this.size[0] - 1 ) * img.iSpacing),
                        bb.minJ + ( coord[1] * (this.size[1] ) / (this.size[1] - 1 ) * img.jSpacing),
                        zPos
                    ];
                    polygon.push(pt);
                })
                
                const triangleAreaTreshold = Math.min( 0.1 * polygon.length / 200, 0.8 );

                // Apply Visvalingam & Whyatt Line Simplification Algorithm
                let keptRatio = 0;
                let iterations = 0;
                while(keptRatio < 0.97) {
                    const newPoly: Point[] = [];
                    for(let i = 0; i < polygon.length; i += 2) {
                        newPoly.push(polygon[i]);
                        if(i === polygon.length - 1) break;
                        if(i === polygon.length - 2) {
                            newPoly.push(polygon[i + 1]);
                            break;
                        }
                        // calculate the area of the triangle formed by three consecutive points in the polygon
                        // A = | (Ax * (By - Cy) + Bx * (Cy - Ay) + Cx * (Ay - By)) / 2 |;
                        if ( Math.abs( 
                                ( polygon[i][0] * (polygon[i+1][1] - polygon[i+2][1]) 
                                + polygon[i+1][0] * (polygon[i+2][1] - polygon[i][1]) 
                                + polygon[i+2][0] * (polygon[i][1] - polygon[i+1][1]) 
                                ) / 2 ) > triangleAreaTreshold ) {
                            newPoly.push(polygon[i + 1]);
                        }
                    }
                    keptRatio = newPoly.length / polygon.length;
                    polygon = newPoly;
                    iterations++;
                    
                }
               // console.log("keptRatio: " + keptRatio + ", iterations: " + iterations);
               
                polyArray.push(polygon);
            })

            const t5 = new Date().getTime();



            r1 += (t2-t1);
            r2 += (t3-t2);
            r3 += (t4-t3);
            r4 += (t5-t4);

            roi.contoursChangedInSlices = roi.contoursChangedInSlices.filter(sId => sId !== sliceId);
        }

        // console.log("Read pixels: " + r1);
        // console.log("Input array: " + r2);
        // console.log("marching squares: " + r3)
        // console.log("contourData: " + r4)
        // console.log("Total: " + (new Date().getTime() - start))

        gl.bindFramebuffer(gl.FRAMEBUFFER, null);
        gl.deleteFramebuffer(fb);
        return result;
    }

    // For mouse select tool
    distanceToContour(ptMM: number[] ): number {
   
        let bb = this.boundingBox;
        let vm = this.viewManager;
        let img = vm.image;

        if(!bb.isPointInside(ptMM[0], ptMM[1], ptMM[2])) return 9999;
        
        let x = Math.round(  (ptMM[0] - bb.minI - 0.5*img.iSpacing) / img.iSpacing );
        let y = Math.round(  (ptMM[1] - bb.minJ - 0.5*img.jSpacing) / img.jSpacing );
        let z = Math.round(  (ptMM[2] - bb.minK - 0.5*img.kSpacing) / img.kSpacing );

        
        let gl = vm.getWebGlContext();
        let fb = gl.createFramebuffer();
        gl.bindFramebuffer(gl.FRAMEBUFFER, fb);

        gl.framebufferTextureLayer(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, this.data, 0, z);
        let pixels = new Uint8Array(4);
        gl.readPixels(x, y, 1, 1, gl.RGBA, gl.UNSIGNED_BYTE, pixels);
        let dist = Math.abs( pixels[0] - 127.5 );
        
        gl.bindFramebuffer(gl.FRAMEBUFFER, null);
        gl.deleteFramebuffer(fb);
        return dist;
    }

    // Retuns slice indices.
    findSlicesWithContours(): number[] {

        const img = this.viewManager.image;
        const imgBb = img.getRealBoundingBox();
        const step = Math.floor(this.maxDistanceMm * 2 / this.resolutionMm) - 1;
        const result: number[] = [];
        let vm = this.viewManager;
        let gl = vm.getWebGlContext();
        let fb = gl.createFramebuffer();
        gl.bindFramebuffer(gl.FRAMEBUFFER, fb);
        let counter = 0;

        //TODO: Would most likely be way better to read all pixels at once and then loop those
        const investigateSlice = (z: number) => {
            gl.framebufferTextureLayer(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, this.data, 0, z);
            for(let x = 0; x < this.size[0]; ++x) {
                if( ( x % step !== 0) && ( x !== 0 ) && ( x !==this.size[0] - 1 ) ) continue;
                for(let y = 0; y < this.size[1]; ++y) {
                    if( ( y % step !== 0) && ( y !== 0 ) && ( y !==this.size[1] - 1 ) ) continue;
                    counter++;
                    let pixels = new Uint8Array(4);
                    gl.readPixels(x, y, 1, 1, gl.RGBA, gl.UNSIGNED_BYTE, pixels);
                    const r = pixels[0];
                    if(r !== 255) {
                        const imgZ = z + Math.round( (this.boundingBox.minK - imgBb.minK) / img.kSpacing );
                        result.push(imgZ);
                        return;
                    }
                }
            }
        }

        for(let z = 0; z < this.size[2]; ++z) {
            investigateSlice(z);
        }

        gl.bindFramebuffer(gl.FRAMEBUFFER, null);
        gl.deleteFramebuffer(fb);
        //console.log("checked " + counter + " pixels out of " + this.size[0] * this.size[1] * this.size[2] );
        return result;
    }
}

