import React from 'react';
import { IconContext } from 'react-icons';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import './InfoText.css';

type OwnProps = {
    title?: string,
};

/**
 * UI component for emphasizing that a DOM element (works best with just a block of text) has an important tooltip available.
 */
class InfoText extends React.PureComponent<OwnProps>{
    render() {
        return (<IconContext.Provider value={{ className: "info-text-icon" }}>
            <span className="info-text" title={this.props.title}>{this.props.children} <IoIosInformationCircleOutline /></span>
        </IconContext.Provider>);
    }
}

export default InfoText;
