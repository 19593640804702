import React from 'react';
import { Form, Badge, Table, Button, Col } from 'react-bootstrap';
import { readString } from 'react-papaparse';
import { IoIosCheckmarkCircleOutline, IoIosCloseCircleOutline } from 'react-icons/io';

import './ModelMappingPage.css';


const SUPPORTED_DICOM_ATTRIBUTES = ['PatientSex', 'ProtocolName', 'SeriesDescription', 'SpecificCharacterSet', 'BodyPartExamined', 'Modality'];
const SUPPORTED_MODEL_NAMES = ["headneckln_ct", "headneck_ct", "malepelvisln_ct", "malepelvis_ct",
    "breastln_ct", "breast_ct", "abdomen_ct", "brain_ct", "femalepelvis_ct", "breastabdomen_ct",
    "breastlnabdomen_ct"];
const MODEL_NAME = ['ModelName', 'AE Title of MVision model', 'MVision model'];
const SUBSCRIPTION = ['Subscription'];
const DEFAULT_SUBSCRIPTION = 'default';
const ALL_SUPPORTED_HEADERS = SUPPORTED_DICOM_ATTRIBUTES.concat(MODEL_NAME).concat(SUBSCRIPTION);
const DEFAULT_NON_LN_MODELS = [
    {
        "BodyPart": "brain",
        "Modality": "ct",
        "ModelName": "brain_ct",
        "Subscription": "default"
    },
    {
        "BodyPart": "headneck",
        "Modality": "ct",
        "ModelName": "headneck_ct",
        "Subscription": "default"
    },
    {
        "BodyPart": "breast",
        "Modality": "ct",
        "ModelName": "breast_ct",
        "Subscription": "default"
    },
    {
        "BodyPart": "abdomen",
        "Modality": "ct",
        "ModelName": "abdomen_ct",
        "Subscription": "default"
    },
    {
        "BodyPart": "malepelvis",
        "Modality": "ct",
        "ModelName": "malepelvis_ct",
        "Subscription": "default"
    },
    {
        "BodyPart": "femalepelvis",
        "Modality": "ct",
        "ModelName": "femalepelvis_ct",
        "Subscription": "default"
    }
];

const DEFAULT_LN_MODELS = [
    {
        "BodyPart": "headneckln",
        "Modality": "ct",
        "ModelName": "headneckln_ct",
        "Subscription": "default"
    },
    {
        "BodyPart": "breastln",
        "Modality": "ct",
        "ModelName": "breastln_ct",
        "Subscription": "default"
    },
    {
        "BodyPart": "malepelvisln",
        "Modality": "ct",
        "ModelName": "malepelvisln_ct",
        "Subscription": "default"
    },
];



class ModelMappingHeader {
    // what's the matching column in CSV?
    csvIndex: number;
    // what's the dicom attribute? use null if not relevant
    dicomAttribute: string | null;
    // does this header denote the used model?
    isModelName: boolean;
    // does this header denote the used subscription name?
    isSubscription: boolean;

    constructor(csvIndex: number, dicomAttribute: string | null, isModelName = false, isSubscription = false) {
        this.csvIndex = csvIndex;
        this.dicomAttribute = dicomAttribute;
        this.isModelName = isModelName;
        this.isSubscription = isSubscription;
    }
}


type OwnState = {
    parsedCsv: any;
    defaultSubscription: string,
    json: string,
    includeBasicModels: boolean,
    includeBasicLNModels: boolean,
}

export class ModelMappingPage extends React.Component<{}, OwnState> {

    constructor(props: any) {
        super(props);

        this.state = {
            parsedCsv: null,
            defaultSubscription: DEFAULT_SUBSCRIPTION,
            json: '',
            includeBasicModels: true,
            includeBasicLNModels: true,
        };
    }

    showError = (errorMsg: any) => {
        alert(errorMsg);
    }

    handleChange = (evt: any) => {
        const value: any = evt.target.value;
        if (!!value && value.toString()) {
            const results = readString(value.toString());
            this.setState({ parsedCsv: results });
            console.log(results);
        } else {
            this.setState({ parsedCsv: null });
        }
    }

    isCsvOk = (): boolean | null => {
        const { parsedCsv } = this.state;

        if (parsedCsv === null) {
            return null;;
        } else if (parsedCsv.errors && parsedCsv.errors.length === 0) {
            return true;
        } else {
            return false;
        }
    }

    handleGenerateJsonClick = () => {
        const { parsedCsv } = this.state;

        let modelSelectionEntries: any = [];

        // get the fields we want to use (i.e. headers)
        let index = -1;
        const headers: ModelMappingHeader[] = [];
        for (const h of parsedCsv.data[0]) {
            index++;
            const header = (h as string).trim();

            if (!header) {
                //ignore empty elements
                continue;
            }

            const matchingDicomAttribute = SUPPORTED_DICOM_ATTRIBUTES.indexOf(header);
            if (matchingDicomAttribute !== -1) {
                // found a matching supported header!
                headers.push(new ModelMappingHeader(index, SUPPORTED_DICOM_ATTRIBUTES[matchingDicomAttribute]));
                continue;
            }

            // no supported matching dicom attribute was found, check if this is the model name column instead
            if (MODEL_NAME.includes(header)) {
                headers.push(new ModelMappingHeader(index, null, true));
                continue;
            }

            // check if this is the subscription name column
            if (SUBSCRIPTION.includes(header)) {
                headers.push(new ModelMappingHeader(index, null, false, true));
                continue;
            }
        }


        // get the values
        const modelNameHeader = headers.find(h => h.isModelName);
        if (!modelNameHeader) { throw new Error('No model name specified in the provided CSV data!'); }

        const subscriptionHeader = headers.find(h => h.isSubscription);
        if (!subscriptionHeader && !this.state.defaultSubscription) { throw new Error('No subscription name specified in the provided CSV data, nor is a default subscription set!'); }

        const content = parsedCsv.data.slice(1);
        for (const entry of content) {
            let modelSelectionEntry: any = {};
            let dicomAttributes: any = {};
            let dicomAttributeCount = 0;

            const modelName = entry[modelNameHeader.csvIndex.toString()];
            const subscription = subscriptionHeader ? entry[subscriptionHeader.csvIndex.toString()] : this.state.defaultSubscription;

            modelSelectionEntry["ModelName"] = modelName;
            modelSelectionEntry["Subscription"] = subscription;

            // gather dicom attributes
            for (const header of headers.filter(h => !h.isModelName && !h.isSubscription)) {
                const dicomAttributeValue = (entry[header.csvIndex.toString()] as string).trim();
                if (dicomAttributeValue) {
                    dicomAttributes[header.dicomAttribute as string] = dicomAttributeValue;
                    dicomAttributeCount++;
                }
            }

            if (dicomAttributeCount > 0) {
                modelSelectionEntry["DicomAttributes"] = dicomAttributes;
            }

            modelSelectionEntries.push(modelSelectionEntry);
        }

        if (this.state.includeBasicModels) {
            modelSelectionEntries = modelSelectionEntries.concat(DEFAULT_NON_LN_MODELS);
        }

        if (this.state.includeBasicLNModels) {
            modelSelectionEntries = modelSelectionEntries.concat(DEFAULT_LN_MODELS);
        }

        // convert to json
        const jsonString = JSON.stringify({ "Models": modelSelectionEntries }, null, 2);
        console.log(jsonString);

        this.setState({ json: jsonString });
    }

    handleSaveJsonToFile = () => {
        const blob = new Blob([this.state.json], { type: 'text/plain' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "model-selection.json";
        a.click();
    }

    renderCsvState = () => {
        const isCsvOk = this.isCsvOk();

        if (isCsvOk === null) {
            return (<span><Badge variant="secondary">Incomplete</Badge></span>);
        } else if (isCsvOk === true) {
            return (<span><Badge variant="success">Ok!</Badge></span>);
        } else {
            return (<span><Badge variant="danger">Faulty</Badge></span>);
        }
    }

    renderCsvTable = () => {
        const { parsedCsv } = this.state;

        if (!parsedCsv || !parsedCsv.data || parsedCsv.data.length < 2) {
            return null;
        }

        const headers = parsedCsv.data[0];
        const content = parsedCsv.data.slice(1);
        const modelNameRow = (headers as string[]).findIndex(h => MODEL_NAME.includes(h.trim()));
        const supportedHeaders = (headers as string[]).map(h => ALL_SUPPORTED_HEADERS.includes(h.trim()));

        return (
            <div>
                <div>This is how the CSV was parsed -- make sure this looks OK:</div>
                <div>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                {headers.map((h: any, i: number) => (<th key={i.toString()} className={supportedHeaders[i] ? undefined : 'unsupported-header'}>{h}</th>))}
                            </tr>
                        </thead>
                        <tbody>
                            {content.map((row: any, i: number) =>
                            (<tr key={i.toString()}>
                                {row.map((item: any, i: number) => {
                                    const isModelNameRow = i === modelNameRow;
                                    const isModelSupported = isModelNameRow && SUPPORTED_MODEL_NAMES.includes((item as string).trim().toLowerCase());
                                    const modelNameSupportedStyle = isModelNameRow ? isModelSupported ? 'supported-model-name' : 'unsupported-model-name' : '';
                                    const columnSupportedStyle = supportedHeaders[i] ? undefined : 'unsupported-column';
                                    const columnStyle = `${modelNameSupportedStyle} ${columnSupportedStyle}`

                                    const modelNameSupportTooltip = isModelNameRow ? isModelSupported ? 'This contouring model is known to be supported.' : 'This contouring model might not be supported.' : undefined;
                                    const columnSupportedTooltip = columnSupportedStyle ? "This column is not a supported model selection attribute. It won't be included in the generated json." : undefined;
                                    const tooltip = !modelNameSupportTooltip && !columnSupportedTooltip ? undefined : `${modelNameSupportTooltip || ''} ${columnSupportedTooltip || ''}`;

                                    return (
                                        <td key={i.toString()} title={tooltip} className={columnStyle}>
                                            <span>{item}</span>&nbsp;{isModelNameRow ? isModelSupported ? <IoIosCheckmarkCircleOutline /> : <IoIosCloseCircleOutline /> : null}
                                        </td>)
                                })}
                            </tr>)
                            )}
                        </tbody>
                    </Table>
                </div>
            </div>
        );
    }

    render() {
        return (
            <div className="model-mapping-page">
                <div>Paste your CSV here:</div>
                <Form>
                    <Form.Group>
                        <Form.Control as="textarea" rows={20} className="csv-paste-area" onChange={this.handleChange} />
                    </Form.Group>
                </Form>
                <div>Current CSV looks: {this.renderCsvState()}</div>
                {this.isCsvOk() === true && (
                    <div>
                        <div>{this.renderCsvTable()}</div>
                        <div>
                            <Form>
                                <Form.Row>
                                    <Col xs="auto">
                                        <Button variant="primary" onClick={this.handleGenerateJsonClick}>Generate model selection JSON file!</Button>
                                    </Col>
                                    <Col xs="auto">
                                        <Form.Check
                                            type="checkbox"
                                            id="include_basic_models"
                                            label="Include basic models"
                                            checked={this.state.includeBasicModels}
                                            onChange={() => this.setState({ includeBasicModels: !this.state.includeBasicModels })} />
                                    </Col>
                                    <Col xs="auto">
                                        <Form.Check
                                            type="checkbox"
                                            id="include_basic_ln_models"
                                            label="Include basic LN models"
                                            checked={this.state.includeBasicLNModels}
                                            onChange={() => this.setState({ includeBasicLNModels: !this.state.includeBasicLNModels })} />
                                    </Col>
                                </Form.Row>
                            </Form>
                        </div>
                    </div>)
                }
                {this.state.json && (
                    <div>
                        <div className="generated-options">
                            <span>Generated JSON:</span>
                            <span><Button onClick={() => { navigator.clipboard.writeText(this.state.json) }}>Copy to clipboard</Button></span>
                            <span><Button onClick={this.handleSaveJsonToFile}>Save as file...</Button></span>
                        </div>
                        <pre className="generated-json">{this.state.json}</pre>
                    </div>
                )}
            </div>
        );
    }
}

export default ModelMappingPage;


