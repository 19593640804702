/** Current workspace of the application */
enum Workspace {
    /** No workspace is set */
    None = 'NoWorkspace',

    /** Local workspace, for working with local files */
    Local = 'LocalWorkspace',

    /** Annotation workspace */
    Annotation = 'AnnotationWorkspace',

    /** Livereview workspace */
    LiveReview = 'LiveReviewWorkspace'
}

export default Workspace;
