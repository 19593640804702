/* a pre-styled react-select dropdown component */

import React from 'react';
import Select from 'react-select';

// import './StyledDropdown.css';

type OwnProps = {
    // allow input styles to be combined with this component's preset styles
    styles?: any,
    // indexer to let any other props through
    [x: string]: any,
};

export default class StyledDropdown extends React.PureComponent<OwnProps> {

    // override some styles of react-select
    static selectStyles = {
        container: (provided: any) => ({
            ...provided,
            display: "inline-block",
            minWidth: "220px"
        }),
        control: (provided: any) => ({
            ...provided,
            minHeight: "unset"
        }),
        indicatorsContainer: (provided: any) => ({
            ...provided,
            height: "unset"
        }),
        clearIndicator: (provided: any) => ({
            ...provided,
            padding: "0 5px"
        }),
        dropdownIndicator: (provided: any) => ({
            ...provided,
            padding: "0 5px"
        })
    };

    render() {
        const { styles, ...rest } = this.props;
        const combinedStyles = styles ? Object.assign({}, StyledDropdown.selectStyles, styles) : StyledDropdown.selectStyles;

        return (
            <span className="dropdown">
                <Select
                    styles={combinedStyles}
                    {...rest}
                />
            </span>);
    }
}