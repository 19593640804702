export const MARGIN_SWEEP_Z_VS = `#version 300 es
#define POSITION_LOCATION 0
#define TEXCOORD_LOCATION 1

precision highp float;
precision highp int;

layout(location = POSITION_LOCATION) in vec2 position;
layout(location = TEXCOORD_LOCATION) in vec2 in_texcoord;

out vec2 v_texcoord_2D;
out vec3 v_texcoord1;
out vec3 v_texcoord2;
out vec3 v_texcoord3;
out vec3 v_texcoord4;
uniform mat4 orientation1;
uniform mat4 orientation2;
uniform mat4 orientation3;
uniform mat4 orientation4;

void main()
{
    v_texcoord_2D = vec2(in_texcoord.x, 1.0 - in_texcoord.y);
    v_texcoord1 = (orientation1 * vec4(in_texcoord, 0.0, 1.0)).stp;
    v_texcoord2 = (orientation2 * vec4(in_texcoord, 0.0, 1.0)).stp;
    v_texcoord3 = (orientation3 * vec4(in_texcoord, 0.0, 1.0)).stp;
    v_texcoord4 = (orientation4 * vec4(in_texcoord, 0.0, 1.0)).stp;
    gl_Position = vec4(position, 0.0, 1.0);
}
`;

export const MARGIN_SWEEP_Z_FS = `#version 300 es
precision highp float;
precision highp int;

precision highp sampler2D;
precision highp sampler3D;
uniform sampler3D textureOrig;
uniform sampler2D textureBuffer; // Image slice after the previous iteration of z-sweep

in vec2 v_texcoord_2D;
in vec3 v_texcoord1;
in vec3 v_texcoord2;
in vec3 v_texcoord3;
in vec3 v_texcoord4;
uniform float distancePixels1;
uniform float distancePixels2;  
uniform float z1MarginPixels;
uniform float z2MarginPixels;
uniform float interpolationWeight1;
uniform float interpolationWeight2;
uniform float isInnerMargin; // 0.0 = NO, 1.0 = YES

out vec4 color;

void main()
{
    bool isInner = isInnerMargin > 0.5;

    vec4 curr = texture(textureBuffer, v_texcoord_2D );
    float r = curr.r; 
    float g = curr.g;
    float b = curr.b;
    float a = curr.a;
    

    if(interpolationWeight1 >= 0.0) {
        
        vec4 sample1 = (interpolationWeight1 == 0.0) 
            ? texture( textureOrig, v_texcoord1 )
            : mix( 
                texture(textureOrig, v_texcoord1 ), 
                texture(textureOrig, v_texcoord2 ), 
                interpolationWeight1);

        float a1 = a;
        if ((isInner && r <= 0.5 && sample1.r >= 0.5) ||
           (!isInner && r >= 0.5 && sample1.r <= 0.5) ) {
            a1 = distancePixels1 / z1MarginPixels;

            if(sample1.g * sample1.g + sample1.b * sample1.b + a1 * a1 < 1.001) {
                r = isInner ? max(r, sample1.r) : min(r, sample1.r);
                g = sample1.g;
                b = sample1.b;
                a = a1;
            } 
        }



    } 

    if(interpolationWeight2 >= 0.0) {
        
        vec4 sample2 = (interpolationWeight2 == 0.0) 
            ? texture( textureOrig, v_texcoord3 )
            : mix( 
                texture(textureOrig, v_texcoord3 ), 
                texture(textureOrig, v_texcoord4 ), 
                interpolationWeight2);

        float a2 = a;
        if ((isInner && curr.r <= 0.5 && sample2.r >= 0.5) ||
           (!isInner && curr.r >= 0.5 && sample2.r <= 0.5) ) {
            a2 = distancePixels2 / z2MarginPixels;

            if(sample2.g * sample2.g + sample2.b * sample2.b + a2 * a2 < 1.001) {
                r = isInner ? max(r, sample2.r) : min(r, sample2.r);
                g = sample2.g;
                b = sample2.b;
                a = a == 0.0 ? a2 : min(a, a2);
            } 
        }


    } 

    color = vec4(r, g, b, a);
}
`;