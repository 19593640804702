import './LocalPage.css';
import React from 'react';
import { Button, Alert, ProgressBar, Carousel } from 'react-bootstrap';
import { connect } from 'react-redux';
import * as sagas from '../../store/sagas';
import RTViewer from '../rtviewer/RTViewer';
import { StoreState } from '../../store/store';
import { ErrorBoundary } from '../ErrorBoundary';

import { isDemo, RTViewerDisplayVersion } from '../../environments';
import { rtViewerApiClient } from '../../web-apis/rtviewer-api-client';
import GlobalToolbar from '../common/GlobalToolbar';
import { ServiceAgreementSummary } from '../common/ServiceAgreement';
import LandingPageLayout from '../LandingPageLayout';
import Workspace from '../../store/workspace';

type OwnProps = {}
type DispatchProps = {
    setCurrentWorkspace(workspace: Workspace): void,
    unloadScan(scanId: string): void,
    unloadStructureSet(scanId: string, structureSetId: string): void,
    storeLocalScan(files: File[], cbReturnId: (scanId: string) => void): void,
    storeLocalStructureSet(file: File, currentScanId: string, cbReturnId: (scanId: string, ssId: string) => void): void,
    clearFileLoadErrors(): void,
}

type AllProps = OwnProps & StoreState & DispatchProps;
type OwnState = {
    showRTViewer: boolean,
    immediatelyOpenScan: boolean,
    scanId?: string | null,
    structureSetId?: string | null,
    slideIndex: number;
}

const PREVENT_OPEN_IF_ERRORS = true;

type CarouselItem = { url: string, captionHeader?: string, captionText?: string };

const carouselItems: CarouselItem[] = [
    { url: '/img/carousel01.png', captionHeader: 'An image caption can go here', captionText: 'A description of the image can be put here, but it\'s optional' },
    { url: '/img/carousel02.png' }
];


export class LocalPage extends React.Component<AllProps, OwnState> {
    displayName = LocalPage.name

    constructor(props: AllProps) {
        super(props);

        this.props.setCurrentWorkspace(Workspace.Local);

        this.state = { showRTViewer: false, immediatelyOpenScan: true, slideIndex: 0 };
    }

    openScan = (scanId: string) => {
        this.setState({ scanId: scanId });
        if (this.state.immediatelyOpenScan && (!this.props.fileLoadErrors || this.props.fileLoadErrors.length === 0)) {
            this.setState({ showRTViewer: true });
        }
    }

    openStructureSet = (scanId: string, structureSetId: string) => {
        if (this.state.scanId !== scanId) {
            alert("Structure set doesn't belong to current image");
        }
        else {
            this.setState({ structureSetId: structureSetId });
        }
    }

    handleImageFileChange = (e: any) => {
        this.props.clearFileLoadErrors();
        if (this.state.scanId) {
            this.props.unloadScan(this.state.scanId);
        }
        e.persist();
        this.setState({ scanId: null, structureSetId: null }, () => {
            let files = [];
            for (let i = 0; i < e.target.files.length; i++) {
                files.push(e.target.files[i])
            }
            if (files.length > 0) {
                this.props.storeLocalScan(files, this.openScan);
            }
        });
    }

    handleStructureSetFileChange = (e: any) => {
        if (!this.state.scanId) return;
        let scanId: string = this.state.scanId;

        if (this.state.structureSetId) {
            this.props.unloadStructureSet(scanId, this.state.structureSetId);
        }
        e.persist();
        this.setState({ structureSetId: null }, () => {
            this.props.storeLocalStructureSet(e.target.files[0], scanId, this.openStructureSet);
        })
    }


    handleShowRTViewerClick = () => {
        this.setState({ showRTViewer: true });
    }

    handleBack = () => {
        this.setState({ showRTViewer: false });
    }

    handleImmediatelyOpenChange = () => {
        this.setState({ immediatelyOpenScan: !this.state.immediatelyOpenScan });
    }

    handleChangeSlide = (nextSlideIndex: number) => {
        this.setState({ slideIndex: nextSlideIndex });
    }

    renderFileForm = (isVisible: boolean) => {

        const { fileLoadErrors, localFileProgress } = this.props;

        const isOpenPrevented = !PREVENT_OPEN_IF_ERRORS || (fileLoadErrors !== undefined && fileLoadErrors.length > 0);

        // by hiding instead of not rendering the file form we're able to save the state of the browser's built-in
        // file load forms, allowing us to show the number and names of files loaded in the UI after user presses
        // back on the main rtviewer view
        const hiddenStyle = isVisible ? '' : 'hide-file-form';

        const currentSlide = carouselItems[this.state.slideIndex];

        return (

            <>
                <LandingPageLayout
                    className={hiddenStyle}
                    toolbar={(
                        <div className="global-toolbar-for-local">
                            <GlobalToolbar isEmbedded={true} />
                        </div>
                    )}
                >

                    <p><b>RTViewer</b> can be used to view DICOM images and auto-generate structure sets.</p>

                    {isDemo() && (<p>Images are anonymized before sending them to the delineation service.</p>)}

                    <p className="local-page-not-for-clinical">Not for clinical use!</p>

                    <div className="image-form">
                        <p >1. Select DICOM image files (CT/MR):</p>
                        <div> <label><input name="OpenFileSelection" type="checkbox"
                            checked={this.state.immediatelyOpenScan} onChange={this.handleImmediatelyOpenChange} /> Immediately Open Selected Scan</label></div> <br />
                        <input type="file" ref="imageInput" onChange={this.handleImageFileChange} multiple />

                        {localFileProgress && (<div className="scan-progress-bar"><ProgressBar variant="info" now={localFileProgress.progress} max={localFileProgress.total} /></div>)}

                    </div>
                    {this.state.scanId ? <div className="rtstruct-form"><p>2. Select DICOM structure set files (optional):</p>
                        <input type="file" ref="structureSetInput" onChange={this.handleStructureSetFileChange} /></div> : null
                    }
                    {this.state.scanId && !isOpenPrevented ?
                        <Button variant="info" className="open-rtviewer-button btn btn-default  " onClick={this.handleShowRTViewerClick}>Open RTViewer</Button>
                        : null
                    }
                    <br />

                    {fileLoadErrors && fileLoadErrors.length > 0 && (
                        <Alert variant="danger" className="file-load-errors-alert">
                            <Alert.Heading>Errors occurred when trying to load following files:</Alert.Heading>
                            {fileLoadErrors.map(e => (
                                <div key={e.fileName} className="file-load-errors">
                                    <div className="filename"><b>{e.fileName}</b></div>
                                    <div className="error-message">{e.errorMessage}</div>
                                </div>
                            ))}
                        </Alert>
                    )}

                    <ServiceAgreementSummary />

                    <div className="version-number">{"Version: " + RTViewerDisplayVersion}</div>

                </LandingPageLayout>
            </>
        );
    }

    render() {
        const user = rtViewerApiClient.username;
        const scanId = this.state.scanId;
        const canEdit = !isDemo() ||
            (user === "demo-strang1" || user === "demo-strang2" || user === "demo-berlin1");

        return (
            <>
                {this.renderFileForm(!(this.state.showRTViewer && scanId))}
                {this.state.showRTViewer && scanId &&
                    (<ErrorBoundary>
                        <RTViewer scanId={scanId} canEdit={canEdit} canCreateRtstruct={true} hideSaveButtons={true} handleBack={this.handleBack} />
                    </ErrorBoundary>)}
            </>
        );
    }
}

export default connect(
    state => Object.assign({}, state),
    sagas.mapDispatchToProps
)(LocalPage);
