import React from 'react';
import { Modal, Row, Col, Button, Form, Spinner } from 'react-bootstrap';
import { PredictionModel } from '../../web-apis/contouring-options';
import ModelSelect from '../common/ModelSelect';
import BatchJobSelectionCounts from './BatchJobSelectionCounts';

import './BatchJobDialog.css';
import { BatchJobOperation } from './dataset-table/DatasetPage';
import { DatasetImage } from '../../datasets/dataset-image';
import { DatasetStructureSet } from '../../datasets/dataset-structure-set';
import ModalDialog from '../common/ModalDialog';

type OwnProps = {
    isVisible: boolean,
    onClose: () => void,
    requestBatchOperation: (contouringAction: string, description?: string) => Promise<string>,
    selectedPatientsCount: number,
    selectedImagesCount: number,
    selectedStructureSetsCount: number,
    selectedAutoContours: number,
    defaultDescription: string,
    isBatchJobRequestInProgress: boolean,
    getCurrentSelection: () => BatchJobSelection[],
}

type OwnState = {
    selectedPredictionModel: PredictionModel | undefined,
    description: string,
    isModelOptionValid: boolean,
    isTextEntryOptionValid: boolean,
    currentSelection: BatchJobSelection[],
}

export class BatchJobSelection {
    image: DatasetImage;
    structs: DatasetStructureSet[];
    operation: BatchJobOperation;

    constructor(image: DatasetImage, structs: DatasetStructureSet[], operation: BatchJobOperation) {
        this.image = image;
        this.structs = structs;
        this.operation = operation;
    }

    static sortSelectionItems = (item1: BatchJobSelection, item2: BatchJobSelection) => {
        const idComparison = item1.image.patientId.localeCompare(item2.image.patientId);
        if (idComparison === 0) {
            return item1.image.seriesDescription.localeCompare(item2.image.seriesDescription);
        } else {
            return idComparison;
        }
    }
}

class BatchJobDialog extends React.Component<OwnProps, OwnState>{

    constructor(props: OwnProps) {
        super(props);
        this.state = {
            selectedPredictionModel: undefined,
            description: '',
            isModelOptionValid: true,
            isTextEntryOptionValid: true,
            currentSelection: [],
        };
    }

    componentDidUpdate(prevProps: OwnProps) {
        if (this.props.isVisible && !prevProps.isVisible) {
            // it's ok to collect the current selections only when the dialog has just become visible since the user can't edit the selections anyway when
            // the dialog is blocking the rest of the ui
            this.collectSelection();
        }
    }

    resetValidation = () => {
        this.setState({
            isModelOptionValid: true,
            isTextEntryOptionValid: true,
        });
    }

    collectSelection = () => {
        this.setState({ currentSelection: this.props.getCurrentSelection().sort(BatchJobSelection.sortSelectionItems) });
    }

    handleShow = () => {
        this.setState({ description: this.props.defaultDescription });
    }

    handleClose = () => {
        this.props.onClose();
    }

    handleModelChange = (model: PredictionModel | undefined) => {
        this.resetValidation();
        this.setState({ selectedPredictionModel: model });
    }

    handleDescriptionChange = (evt: any) => {
        this.setState({ description: evt.target.value });
    }

    handleExecuteBatchJob = () => {

        // validate current options before proceeding
        const model = this.state.selectedPredictionModel;
        const anyAutoContours = this.props.selectedAutoContours > 0

        // validate that if auto-contouring is set, a model must be selected
        if (anyAutoContours && !model) {
            this.setState({ isModelOptionValid: false });
            return;
        }

        // // validate that if auto-contouring is set to text entry, then there must actually be some text
        // if (anyAutoContours && model
        //     && model.label === customModelLabel
        //     && model.action.length < 3) {
        //     this.setState({ isTextEntryOptionValid: false })
        //     return;
        // }

        this.resetValidation();

        const contouringAction = model ? model.name : "";
        const batchRequest = this.props.requestBatchOperation(contouringAction, this.state.description);

        batchRequest.then(msg => {
            this.handleClose();
        }, msg => {
        });
    }

    renderSelectedImage = (selection: BatchJobSelection) => {
        const { image, structs, operation } = selection;
        const isAutoContour = operation === BatchJobOperation.AutoContourAndExport;
        const batchOpStyle = isAutoContour ? 'auto-contouring-selected' : '';
        return (
            <Row className="selection-row" key={image.seriesId}>
                <Col lg={3} className="patient-id">{image.patientId}</Col>
                <Col lg={4} className="image-description">{image.seriesDescription}</Col>
                <Col lg={3} className="structure-sets">{structs.map(s => (<div key={s.sopId}>{s.label}</div>))}</Col>
                <Col lg={2} className={`batch-operation ${batchOpStyle}`}>{isAutoContour ? 'Auto-contour' : 'Export only'}</Col>
            </Row>
        );
    }

    render() {
        const { currentSelection } = this.state;
        const anyAutoContours = this.props.selectedAutoContours > 0;
        const isInProgress = this.props.isBatchJobRequestInProgress;

        let modelSelectValidation = {};
        if (!this.state.isModelOptionValid) {
            modelSelectValidation = {
                control: (provided: any) => ({
                    ...provided,
                    borderColor: "#ee892c"
                }),
            }
        }

        return (
            <ModalDialog
                className="batch-job-dialog"
                size="lg"
                show={this.props.isVisible}
                onHide={this.handleClose}
                onEnter={this.handleShow}>
                <Modal.Header closeButton>
                    <Modal.Title>Batch contour and export</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="instructions">This dialog lets you export and optionally auto-contour multiple images and structure sets in one batch job. The batch job will be queued and you can follow its progress.</div>
                    <Row>
                        <Col>
                            <Form.Label>Optional description</Form.Label>
                            <Form.Control as="textarea" rows={2} value={this.state.description} onChange={this.handleDescriptionChange} />
                        </Col>
                    </Row>
                    {anyAutoContours && (
                        <Row className="prediction-model-selector">
                            <Col lg={6}>
                                <div>Select prediction model for the entire batch job</div>
                            </Col>
                            <Col className="option-right-side">
                                <ModelSelect
                                    onChange={this.handleModelChange}
                                    currentPredictionModel={this.state.selectedPredictionModel}
                                    styles={modelSelectValidation}
                                />
                                {(!this.state.isModelOptionValid) && (<span className="invalid-prediction-model">Please select a prediction model.</span>)}
                                {(!this.state.isTextEntryOptionValid) && (<span className="invalid-prediction-model">Please enter the name of the prediction model you want to use.</span>)}
                            </Col>
                        </Row>
                    )}
                    <div className="confirmation-instructions">Confirm your batch job/export selections below:</div>
                    <div className="selection-counts">
                        <BatchJobSelectionCounts 
                            selectedPatientsCount={this.props.selectedPatientsCount} 
                            selectedImagesCount={this.props.selectedImagesCount} 
                            selectedStructureSetsCount={this.props.selectedStructureSetsCount}
                            selectedAutoContourOperations={this.props.selectedAutoContours} />
                    </div>
                    {currentSelection.length > 0 && (
                        <div className="batch-job-selections">
                            {currentSelection.map(selectedImage => this.renderSelectedImage(selectedImage))}
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={this.handleExecuteBatchJob} disabled={isInProgress}>
                        {isInProgress && (<Spinner as="span" size="sm" animation="border"></Spinner>)}
                        {' Execute this batch job'}
                    </Button>
                    <Button variant="outline-secondary" onClick={this.handleClose} disabled={isInProgress}>Cancel</Button>
                </Modal.Footer>
            </ModalDialog>
        );
    }
}

export default BatchJobDialog;
