import { ShaderProgram } from './ShaderProgram';
import { VAO } from '../objects/VertexArrayObject';
import { Sdf } from '../sdf/sdf';
import { SdfSlice } from '../sdf/sdf-slice';
import { Vector3f } from '../../../math/Vector3f';
import { Matrix4f } from '../../../math/Matrix4f';
import { UniformMatrix } from './uniforms/UniformMatrix';

export class SliceToSdfRenderer {

    private gl: WebGL2RenderingContext;
    private shader: SliceToSdfShader;
    private quadVAO: VAO;

    constructor(gl: WebGL2RenderingContext) {
        this.gl = gl;
        this.shader = new SliceToSdfShader(gl);
        this.quadVAO = new VAO(gl);
        this.quadVAO.bind();
        this.quadVAO.createAttribute(0, this.gl.FLOAT, [-1.0, 1.0, -1.0, -1.0, 1.0, 1.0, 1.0, -1.0], 2);
        this.quadVAO.unbind();
    }

    public render(sdf: Sdf, slice: SdfSlice): void {
        this.shader.start();

        let transformationMatrix = new Matrix4f();
        if(slice.boundingBox){
            const scale = new Vector3f(slice.boundingBox.getXSize() / sdf.boundingBox.getXSize(),
            slice.boundingBox.getYSize() / sdf.boundingBox.getYSize(), 1.0);
            const sliceCenter = slice.boundingBox.getCenter();
            const sdfCenter = sdf.boundingBox.getCenter();
            const translation = new Vector3f(2.0*(sliceCenter.x - sdfCenter.x) / sdf.boundingBox.getXSize(),
            2.0*(sliceCenter.y - sdfCenter.y) / sdf.boundingBox.getYSize(), 0.0);
            transformationMatrix.translate(translation);
            transformationMatrix.scale(scale);
        }
        this.shader.transformationMatrix.loadMatrix(transformationMatrix);
        this.quadVAO.bind(0);
        this.gl.bindTexture(this.gl.TEXTURE_2D, slice.data);
        this.gl.drawArrays(this.gl.TRIANGLE_STRIP, 0, 4);
        this.quadVAO.unbind(0);
        this.shader.stop();
    }

    public delete(): void{
        this.shader.delete();
        this.quadVAO.delete();
    }
}

export class SliceToSdfShader extends ShaderProgram {

    private static VERTEX_CONTENT = `#version 300 es
    
    precision highp float;
    
    in vec2 position;

    out vec2 pass_textureCoordinates;
    
    uniform mat4 transformationMatrix;

    void main() {
        gl_Position = transformationMatrix * vec4(position, 0.0, 1.0);
        pass_textureCoordinates = vec2((position.x+1.0)/2.0, (position.y+1.0)/2.0);
    }
    `;
    private static FRAGMENT_CONTENT = `#version 300 es
    
    precision highp float;
    
    in vec2 pass_textureCoordinates;

    out vec4 color;

    uniform sampler2D sampler;

    void main() {
        //vec4 color = texture(sampler, pass_textureCoordinates);
        //gl_FragColor = vec4(color.r*0.5, color.g, color.b, color.a);
        color = texture(sampler, pass_textureCoordinates);
    }
    `;

    public transformationMatrix: UniformMatrix;

    constructor(gl: WebGL2RenderingContext){
        super(gl, SliceToSdfShader.VERTEX_CONTENT, SliceToSdfShader.FRAGMENT_CONTENT, "position");
        this.transformationMatrix = new UniformMatrix(gl, "transformationMatrix");
        this.storeUniformLocations(this.transformationMatrix);
        //this.zCoordinate = new UniformFloat(gl, "zCoordinate");
        //super.storeUniformLocations(this.zCoordinate);
    }
}