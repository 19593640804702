import React from "react";
import { Button, Modal } from "react-bootstrap";

import * as structureSet from "../../../dicom/structure-set";
import ModalDialog from "../../common/ModalDialog";



type OwnProps = {
    isVisible: boolean;
    onClose: () => void;
    onStructureSetDelete: (ss: structureSet.StructureSet) => void;
    structureSet: structureSet.StructureSet | null;

};

type OwnState = {};

class DeleteStructureSetDialog extends React.Component<OwnProps, OwnState> {
    constructor(props: OwnProps) {
        super(props);

        this.state = {};
    }

    handleClose = () => {
        this.props.onClose();
    };

    handleConfirmDelete = () => {
        if (this.props.structureSet) {
            this.props.onStructureSetDelete(this.props.structureSet);
        }
    }

    render() {
        const { structureSet } = this.props;

        if (structureSet === null) {
            return null;
        }

        const label = structureSet.dataset.StructureSetLabel;

        return (
            <ModalDialog
                size='lg'
                show={this.props.isVisible}
                onHide={this.handleClose}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Confirm structure set deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to delete structure set "{label}"?</p>
                </Modal.Body>
                <Modal.Footer>

                    <Button
                        variant="danger"
                        onClick={this.handleConfirmDelete}
                    >
                        Delete
                    </Button>
                    <Button
                        variant="light"
                        onClick={this.handleClose}
                    >
                        Cancel
                    </Button>
                </Modal.Footer>
            </ModalDialog>
        );
    }
}

export default DeleteStructureSetDialog;
