import { AppVersionInfo } from "../store/app-version-info";

// RTViewer functions that don't use a backend API
const versionFile = '/version.json';

export class RTViewerWebClient {

    public static async getAppVersionInfo(): Promise<AppVersionInfo> {
        const url = versionFile;

        return new Promise(async (resolve, reject) => {
            const response = await fetch(url, { cache: 'no-store' });
            if (response.status === 200) {
                const versionJson = await response.json();
                if ('commit' in versionJson && 'date' in versionJson && 'buildEnv' in versionJson) {
                    resolve(new AppVersionInfo(versionJson.commit, versionJson.date, versionJson.buildEnv));
                } else {
                    const error = "Received version.json has invalid properties";
                    console.log(error);
                    console.log(versionJson);
                    reject(error);
                }
            }
            else {
                const error = "Could not get version.json, HTTP error status: " + response.status;
                console.log(error);
                reject(error);
            }
        });
    }
}