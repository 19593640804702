import _ from "lodash";
import AppAuth, { BackendFetchOptions } from "./app-auth";
import { Backend } from "./backends";



export default class BackendClient {

    backend: Backend;
    appAuth: AppAuth;
    name: string;
    url: string;

    constructor(backend: Backend, appAuth: AppAuth) {
        this.backend = backend;
        this.name = backend.name;
        this.url = backend.url;
        this.appAuth = appAuth;
    }

    async fetch(partialUrl: string, httpRequestOptions: any = undefined, backendFetchOptions: Partial<BackendFetchOptions> = {}, printAccessToken: boolean = false): Promise<Response> {
        const fullUrl = this.backend.url + partialUrl;
        return await this.appAuth.fetch(fullUrl, httpRequestOptions, backendFetchOptions, printAccessToken);
    }

    async get(partialUrl: string, httpRequestOptions: any = undefined, backendFetchOptions: Partial<BackendFetchOptions> = {}): Promise<Response> {
        const optionsWithGetMethod = _.set(httpRequestOptions || {}, 'method', 'get');
        return await this.fetch(partialUrl, optionsWithGetMethod, backendFetchOptions);
    }

    async post(partialUrl: string, httpRequestOptions: any = undefined, backendFetchOptions: Partial<BackendFetchOptions> = {}): Promise<Response> {
        const optionsWithPostMethod = _.set(httpRequestOptions || {}, 'method', 'post');
        return await this.fetch(partialUrl, optionsWithPostMethod, backendFetchOptions);
    }

    async quickFetch(partialUrl: string, httpRequestOptions: any, backendFetchOptions: Partial<BackendFetchOptions> = {}): Promise<any> {
        return this.fetch(partialUrl, httpRequestOptions, backendFetchOptions, true)
            .then(async response => backendFetchOptions.asText ? await response.text() : await response.json())
            .catch(error => {
                const errorMessage = `Error calling quickFetch on ${partialUrl} (${this.name}):`;
                console.log(errorMessage);
                console.log(error);
                throw new Error(errorMessage);
            });
    }
}