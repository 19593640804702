import React from "react";
import { StructureSet } from "../../../dicom/structure-set";
import { Button, Modal, Spinner, ListGroup, ButtonGroup, Alert } from 'react-bootstrap';
import { IoIosCopy, IoIosCloudDownload, IoMdCheckmark } from 'react-icons/io';
import { connect } from "react-redux";

import './BackupManager.css';
import ModalDialog from "../../common/ModalDialog";
import { AzureFileClient, BackupFile, BACKUP_FILENAME_EXTENSION } from "../../../web-apis/azure-file-client";
import { AzureFileInfo } from "../../../web-apis/azure-files";
import { StoreState } from '../../../store/store';
import * as sagas from '../../../store/sagas';
import { ViewerState } from "../../../rtviewer-core/viewer-state";

export type LatestRestoredBackups = {
    [structureSetId: string]: string; // ssId: filename
}

type OwnProps = {
    structureSet: StructureSet;
    isVisible: boolean;
    onClose: () => void,
    viewerState: ViewerState,
    latestRestoredBackupFilename: string | undefined,
    addRestoredBackup: (structureSetId: string, filename: string) => void,
}

type OwnState = {
    isLoadingBackups: boolean;
    backupFiles: BackupFile[];
    refreshSwitch: boolean;
    currentlyRestoringBackupId: string | undefined;
}

type DispatchProps = {
    restoreStructureSetBackup: (backupAzureFile: AzureFileInfo, originalStructureSet: StructureSet, cbReturnId: (structureSet: StructureSet) => void, isDuplicating: boolean) => Promise<void>,
}

type AllProps = OwnProps & StoreState & DispatchProps;

// current restore action state for a specific backup file
enum RestoreState {
    Idle, RestoringThis, RestoringOther
}


class BackupManager extends React.Component<AllProps, OwnState> {

    constructor(props: AllProps) {
        super(props);

        this.state = {
            isLoadingBackups: true,
            backupFiles: [],
            refreshSwitch: false,
            currentlyRestoringBackupId: undefined,
        };
    }

    async componentDidMount() {
        this.props.viewerState.addListener(this.updateView);

        // start loading backups asynchronously
        if (this.props.structureSet.azureFileInfo) {
            const fileClient = new AzureFileClient(this.props.structureSet.azureFileInfo.storageAccountName);
            const backups = await fileClient.listBackups(this.props.structureSet.azureFileInfo);
            this.setState({ isLoadingBackups: false, backupFiles: backups });
        }
    }

    componentWillUnmount() {
        this.props.viewerState.removeListener(this.updateView);
    }

    updateView = () => {
        this.setState({ refreshSwitch: !this.state.refreshSwitch });
    }

    handleCloseModal = () => {
        if (!this.props.isRestoringBackup) {
            this.props.onClose();
        }
    }

    handleSwitchToBackup = (backupFile: BackupFile, isCurrent: boolean = false) => {
        this.restoreBackup(backupFile, isCurrent, false);
    }

    handleDuplicateBackup = (backupFile: BackupFile, isCurrent: boolean = false) => {
        this.restoreBackup(backupFile, isCurrent, true);
    }

    restoreBackup = (backupFile: BackupFile, isCurrent: boolean, isDuplicating: boolean) => {
        const { structureSet, viewerState } = this.props;

        if (this.props.isRestoringBackup) {
            console.warn('Already in progress of restoring a backup');
            return;
        }

        if (!structureSet.azureFileInfo) {
            throw new Error('File has no azure entry');
        }

        const backupFileInfo = isCurrent ? structureSet.azureFileInfo : structureSet.azureFileInfo.getPath().getFile(backupFile.filename);

        this.setState({ currentlyRestoringBackupId: backupFile.filename });

        this.props.restoreStructureSetBackup(backupFileInfo, structureSet,
            (structureSet) => {
                viewerState.setSelectedStructureSet(structureSet, viewerState.image);
                this.setState({ currentlyRestoringBackupId: undefined });
                if (!isDuplicating) { this.props.addRestoredBackup(structureSet.structureSetId, backupFile.filename); }
            }, isDuplicating);
    }

    render() {
        const { isVisible, isRestoringBackup, latestRestoredBackupFilename } = this.props;
        const { backupFiles, currentlyRestoringBackupId } = this.state;

        // first file (if any) of the backups is the current file (i.e. latest save)
        const currentFile = backupFiles[0];
        const backups = backupFiles.slice(1).sort((a, b) => +b.createdOn - +a.createdOn);

        return (
            <>
                <ModalDialog show={isVisible} onHide={this.handleCloseModal} size="xl">
                    <Modal.Header>
                        <Modal.Title>Backup Manager</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{this.state.isLoadingBackups ? (
                        <div className="loading-backups">
                            <span className="backup-loading-spinner"><Spinner as="span" size="sm" animation="border"></Spinner> </span>
                            <span className="backup-loading-text"> Loading available backups...</span>
                        </div>
                    ) : (
                            <div>
                                <div>
                                    <Alert variant="danger">Restoring a backup will replace the current selected structure set with a structure set from the backup. <b>Any unsaved changes are lost.</b></Alert>
                                    <Alert variant="warning">This is an experimental feature. If you encounter any errors please contact RTViewer support or the #rtviewer-users slack channel!</Alert>
                                    <Alert variant="warning">Note: annotation gradings are <b>not</b> adjusted, they will remain as they are in current latest save and must be changed, fixed or restored manually!</Alert>
                                </div>
                                <div><h4>Available backups for this structure set ({this.props.structureSet.label})</h4></div>
                                <div>
                                    <ListGroup>
                                        <BackupItem
                                            backupFile={currentFile}
                                            isCurrent
                                            onSwitchToBackup={this.handleSwitchToBackup}
                                            onDuplicateBackup={this.handleDuplicateBackup}
                                            restoreState={isRestoringBackup ? currentFile.filename === currentlyRestoringBackupId ? RestoreState.RestoringThis : RestoreState.RestoringOther : RestoreState.Idle}
                                            latestRestoredBackupFilename={latestRestoredBackupFilename}
                                        />
                                        {backups.map(b => <BackupItem
                                            key={b.filename}
                                            backupFile={b}
                                            onSwitchToBackup={this.handleSwitchToBackup}
                                            onDuplicateBackup={this.handleDuplicateBackup}
                                            restoreState={isRestoringBackup ? b.filename === currentlyRestoringBackupId ? RestoreState.RestoringThis : RestoreState.RestoringOther : RestoreState.Idle}
                                            latestRestoredBackupFilename={latestRestoredBackupFilename}
                                        />)}
                                    </ListGroup>
                                </div>
                            </div>
                        )}
                    </Modal.Body>
                    <Modal.Footer><Button onClick={this.handleCloseModal} disabled={isRestoringBackup}>Close</Button></Modal.Footer>
                </ModalDialog>
            </>
        );
    }
}

export default connect(
    state => Object.assign({}, state),
    sagas.mapDispatchToProps
)(BackupManager);




/** Attempt to get the name of the person who made this save */
const getBackupBy = (backupFile: BackupFile): string => {
    const suffixIndex = backupFile.filename.lastIndexOf(BACKUP_FILENAME_EXTENSION);
    const dividerIndex = backupFile.filename.indexOf('__');

    if (dividerIndex !== -1 && suffixIndex !== -1) {
        return backupFile.filename.substring(dividerIndex + 2, suffixIndex);
    }

    return 'N/A';
}

class BackupItem extends React.PureComponent<{
    backupFile: BackupFile,
    isCurrent?: boolean,
    restoreState: RestoreState,
    onSwitchToBackup: (backupFile: BackupFile, isCurrent?: boolean) => void,
    onDuplicateBackup: (backupFile: BackupFile, isCurrent?: boolean) => void,
    latestRestoredBackupFilename: string | undefined
}> {

    handleSwitchToBackupClick = () => {
        this.props.onSwitchToBackup(this.props.backupFile, this.props.isCurrent);
    }

    handleDuplicateBackupClick = () => {
        this.props.onDuplicateBackup(this.props.backupFile, this.props.isCurrent);
    }

    render() {
        const { backupFile, isCurrent, restoreState, latestRestoredBackupFilename } = this.props;

        const isAlreadyRestored = latestRestoredBackupFilename === backupFile.filename;

        const title = isCurrent ? `Current latest saved version, saved on ${backupFile.lastModified.toLocaleString()}`
            : `Backup on ${backupFile.createdOn.toLocaleString()} by ${getBackupBy(backupFile)}`;

        return (
            <ListGroup.Item className="backup-item">
                <div className="restored-checkmark">{isAlreadyRestored && (<IoMdCheckmark />)}</div>
                <div className="backup-file-name">{title}</div>
                <div className="backup-file-actions">
                    {restoreState === RestoreState.RestoringThis ? (
                        <div className="loading-backups">
                            <span className="backup-loading-spinner"><Spinner as="span" size="sm" animation="border"></Spinner> </span>
                            <span className="backup-loading-text"> Restoring backup...</span>
                        </div>
                    ) : (
                            <ButtonGroup>
                                <Button variant="outline-primary" onClick={this.handleSwitchToBackupClick} disabled={isAlreadyRestored || restoreState !== RestoreState.Idle}>{isAlreadyRestored ? <IoMdCheckmark /> : <IoIosCloudDownload />} {isCurrent ? 'Use latest saved file' : 'Switch to this backup'}</Button>
                                <Button variant="outline-primary" onClick={this.handleDuplicateBackupClick} disabled={restoreState !== RestoreState.Idle}><IoIosCopy /> Duplicate as new structure set</Button>
                            </ButtonGroup>
                        )}
                </div>
            </ListGroup.Item>
        )
    }
}

