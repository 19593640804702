export const SDF_BRUSH_CIRCLE_VS = `
#version 300 es

precision highp float;


// Inverse of the size of the plane bounding box in mm. From DICOM attributes:
//
// 0: 1 / (PixelSpacing[1] * (Columns - 1))
// 1: 1 / (PixelSpacing[0] * (Rows - 1))
// 2: 1 / SliceThickness (or calculated plane separation)
//
// Note the inverted pixel spacing - this way it's consistent with orientation,
// unlike in DICOM.
uniform vec3 u_inverseSizeMM;
uniform vec3 u_boundingBoxMin;
uniform vec3 u_brushPointMM;

// Specifies the distance map range. Smaller = faster and better subpixel
// accuracy, but limits outline width and is worse for interpolation.
uniform float u_maxDistanceMM;

uniform float u_brushRadiusMM;

// Input in patient coordinates
in vec3 a_point;

// Outputs are in image plane coordinates in mm.
// FIXME: this requires high precision - calculate in javascript or with highp
flat out vec2 v_center;
out vec2 v_coord;
out float maxDistanceMM;
out float brushRadiusMM;

void main()
{

  // For fragment shader, we pass image plane coordinates in mm.
  // For WebGL, we pass clip space (normalized) coordinates.

  // Pass center point.
  vec3 planar =  (u_brushPointMM - u_boundingBoxMin);
  v_center = planar.xy;

  // Pass vertices of the bounding rectangle as TRIANGLES.
  const vec3 vert_offset[6] = vec3[6](
    vec3(-1.0, -1.0, 0.0),
    vec3( 1.0, -1.0, 0.0),
    vec3( 1.0,  1.0, 0.0),

    vec3( 1.0,  1.0, 0.0),
    vec3(-1.0,  1.0, 0.0),
    vec3(-1.0, -1.0, 0.0)
  );
  vec3 coord = planar + vert_offset[gl_VertexID % 6] * (u_brushRadiusMM + u_maxDistanceMM);
  v_coord = coord.xy;

  // Normalize vertex to clip space for rasterization.
  gl_Position = vec4(2.0 * (coord * u_inverseSizeMM - 0.5), 1.0);
    maxDistanceMM = u_maxDistanceMM;
    brushRadiusMM = u_brushRadiusMM;
}
`

export const SDF_BRUSH_CIRCLE_FS = `
#version 300 es

precision mediump float;

// See the vertex shader.
uniform float u_inverseMaxDistanceMM;
uniform float u_erase;
in float maxDistanceMM;
in float brushRadiusMM;

// FIXME: this requires high precision - calculate in javascript or with highp
flat in vec2 v_center;
in vec2 v_coord;

// Alpha will be from 0 to 127 and should be blended with gl.MIN.
out vec4 outColor;

void main()
{
  float dist = distance(v_coord, v_center);

  float v = (dist < brushRadiusMM) ?
     0.5 - 0.5 * clamp( ( brushRadiusMM - dist ) * u_inverseMaxDistanceMM, 0.0, 1.0 )
   : 0.5 + 0.5 * clamp( ( dist - brushRadiusMM ) * u_inverseMaxDistanceMM, 0.0, 1.0 );

  if(u_erase > 0.5) {
    v = 1.0 - v;
  } 
  
  outColor = vec4(v, v, v, 1.0);
}
`

export const SDF_BRUSH_RECT_VS = `
#version 300 es

precision highp float;


// Inverse of the size of the plane bounding box in mm. From DICOM attributes:
//
// 0: 1 / (PixelSpacing[1] * (Columns - 1))
// 1: 1 / (PixelSpacing[0] * (Rows - 1))
// 2: 1 / SliceThickness (or calculated plane separation)
//
// Note the inverted pixel spacing - this way it's consistent with orientation,
// unlike in DICOM.
uniform vec3 u_inverseSizeMM;
uniform vec3 u_boundingBoxMin;

// Specifies the distance map range. Smaller = faster and better subpixel
// accuracy, but limits outline width and is worse for interpolation.
uniform float u_maxDistanceMM;

// Input in patient coordinates
in vec3 a_point;

// Outputs are in image plane coordinates in mm.
// FIXME: this requires high precision - calculate in javascript or with highp
flat out vec2 v_center;
out vec2 v_coord;
out float maxDistanceMM;

void main()
{

  v_coord = a_point.xy;

  vec3 planar =  (a_point - u_boundingBoxMin);
  gl_Position = vec4(2.0 * (planar * u_inverseSizeMM - 0.5), 1.0);

  maxDistanceMM = u_maxDistanceMM;
}
`

export const SDF_BRUSH_RECT_FS = `
#version 300 es

precision mediump float;

uniform float u_inverseMaxDistanceMM;
uniform float u_erase;
uniform float u_x1;
uniform float u_x2;
uniform float u_y1;
uniform float u_y2;
in float maxDistanceMM;
in vec2 v_coord;
out vec4 outColor;

void main()
{
  float v = 1.0;

  // 1. Above y1
  if(v_coord.y < u_y1) {
    if(v_coord.x < u_x1) {
      vec2 p = vec2(u_x1, u_y1);
      float dist = distance(v_coord, p);
      v = 0.5 + 0.5 * clamp( dist * u_inverseMaxDistanceMM, 0.0, 1.0 );
    }
    else if(v_coord.x <= u_x2) {
      float dist = u_y1 - v_coord.y;
      v = 0.5 + 0.5 * clamp( dist * u_inverseMaxDistanceMM, 0.0, 1.0 );
    }
    else {
      vec2 p = vec2(u_x2, u_y1);
      float dist = distance(v_coord, p);
      v = 0.5 + 0.5 * clamp( dist * u_inverseMaxDistanceMM, 0.0, 1.0 );
    }
  }

  // 2. Between y1 and y2
  else if(v_coord.y <= u_y2) {
    if(v_coord.x < u_x1) {
      float dist = u_x1 - v_coord.x;
      v = 0.5 + 0.5 * clamp( dist * u_inverseMaxDistanceMM, 0.0, 1.0 );
    }
    else if(v_coord.x <= u_x2) {
      float dist1 = v_coord.x - u_x1;
      float dist2 = u_x2 - v_coord.x;
      float dist3 = v_coord.y - u_y1;
      float dist4 = u_y2 - v_coord.y;
      float dist = min(min(dist1, dist2), min(dist3, dist4));
      v = 0.5 - 0.5 * clamp( dist * u_inverseMaxDistanceMM, 0.0, 1.0 );
    }
    else {
      float dist = v_coord.x - u_x2;
      v = 0.5 + 0.5 * clamp( dist * u_inverseMaxDistanceMM, 0.0, 1.0 );
    }
  } 

  // 3. Below y2
  else {
    if(v_coord.x < u_x1) {
      vec2 p = vec2(u_x1, u_y2);
      float dist = distance(v_coord, p);
      v = 0.5 + 0.5 * clamp( dist * u_inverseMaxDistanceMM , 0.0, 1.0 );
    }
    else if(v_coord.x <= u_x2) {
      float dist = v_coord.y - u_y2;
      v = 0.5 + 0.5 * clamp( dist * u_inverseMaxDistanceMM , 0.0, 1.0 );
    }
    else {
      vec2 p = vec2(u_x2, u_y2);
      float dist = distance(v_coord, p);
      v = 0.5 + 0.5 * clamp( dist * u_inverseMaxDistanceMM , 0.0, 1.0 );
    }
  }

  if(u_erase > 0.5) {
    v = 1.0 - v;
  } 
  outColor = vec4(v, v, v, 1.0);
}
`
