import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom'
import createSagaMiddleware from 'redux-saga'
import { applyMiddleware, createStore } from 'redux';

import * as Store from './store/store';
import rootSaga from './store/sagas'
import { UnsupportedBrowserPage } from './components/UnsupportedBrowserPage';
import App from './App';

const sagaMiddleware = createSagaMiddleware()
const store = createStore(
  Store.reducer,
  Store.initialState,
  applyMiddleware(sagaMiddleware)
)
sagaMiddleware.run(rootSaga)

const rootElement = document.getElementById('root');

// 1. Unsupported browser notification when even this script is not run properly
const el = document.getElementById("unsupported-browser-notification");
if (el) el.remove();

// 2. Unsupported browser notification when this script is run properly but webgl2 not supported
if (!document.createElement('canvas').getContext('webgl2')) {
  ReactDOM.render(<UnsupportedBrowserPage></UnsupportedBrowserPage>, rootElement);
}
else {
  // service workers are not always available, e.g. when in firefox private mode
  if ('serviceWorker' in navigator) {
    // This is a nuclear option for removing any existing service workers.
    // They're currently more trouble than they're worth.
    navigator.serviceWorker.getRegistrations().then(function (registrations) {
      for (let registration of registrations) {
        registration.unregister()
      }
    });
  }

  const AppProvider = () => (
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  );

  ReactDOM.render(<AppProvider />, document.getElementById("root"));
}





