// Toolbar above the dicom viewer grid

import React from 'react';
import { Button, ButtonGroup, ButtonToolbar, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { StoreState } from '../../../store/store';
import { connect } from 'react-redux';
import * as sagas from '../../../store/sagas';
import { WindowLevelToolbar } from './WindowLevelToolbar';
import ContouringToolbar  from './ContouringToolbar';
import { ViewerState } from '../../../rtviewer-core/viewer-state';

import './MainToolbar.css';
import { PreferenceToolbar } from './PreferenceToolbar';
import { InfoToolbar } from './InfoToolbar';
import GlobalToolbar from '../../common/GlobalToolbar';
import { stopKeyboardPropagation } from '../../../react-util';

type OwnProps = {
    viewerState: ViewerState,
}

type DispatchProps = {
}

type AllProps = OwnProps & StoreState & DispatchProps;

type OwnState = {
    refreshSwitch?: any,
}

export enum MainMenu {
    Select, WindowLevel, Contouring, Preferences, Info,
}

class MainToolbar extends React.Component<AllProps, OwnState> {
    displayName = MainToolbar.name

    constructor(props: AllProps) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.props.viewerState.addListener(this.updateView);
    }

    componentWillUnmount() {
        this.props.viewerState.removeListener(this.updateView);
    }
    
    updateView = () => {
        this.setState({refreshSwitch: !this.state.refreshSwitch});
    }

    handleKeyDown = (e: React.KeyboardEvent) => {
        // prevent keystrokes from falling through any text fields in the toolbar
        stopKeyboardPropagation(e);
    }

    render() {
        let vs = this.props.viewerState;
        let ss = vs.selectedStructureSet;
        const canEdit = (vs.canEdit && ss && ss.canEdit());
        let vm = vs.viewManager;
        let menu = vs.mainMenuSelection;

        function reset() {
            vs.resetWindowLevel();
            vm.resetViews();
        }

        return (
            <div className="main-toolbar" onKeyDown={this.handleKeyDown}>
                <div className="toolbar">
                    <div className="toolbar-top">
                        <ButtonToolbar className="image-toolbar">
                            <ToggleButtonGroup className="mr-2" type="radio" name="options" value={menu} onChange={(value: MainMenu) => vs.setMainMenuSelection(value)}>
                                <ToggleButton variant="light" className="btn btn-default btn-sm" value={MainMenu.Select}>Pan/Select</ToggleButton>
                                <ToggleButton variant="light" className="btn btn-default btn-sm" value={MainMenu.WindowLevel}>WW/WC</ToggleButton>
                                { canEdit ? <ToggleButton variant="light" className="btn btn-default btn-sm" value={MainMenu.Contouring}>Contouring</ToggleButton> : null}
                                {/*<ToggleButton variant="light" className="btn btn-default btn-sm" value={MainMenu.Contouring}>Contouring</ToggleButton>*/}
                                <ToggleButton variant="light" className="btn btn-default btn-sm" value={MainMenu.Preferences}>Preferences</ToggleButton>
                                <ToggleButton variant="light" className="btn btn-default btn-sm" value={MainMenu.Info}>InfoTools</ToggleButton>
                                {/* <ToggleButton variant="light" className="btn btn-default btn-sm" value={MainMenu.Info}>Info</ToggleButton> */}
                            </ToggleButtonGroup>
                            <ButtonGroup className="mr-2">
                                <Button variant="light" className="btn btn-default btn-sm" onClick={reset}>Reset</Button>
                            </ButtonGroup>
                            <span className="disclaimer-not-for-clinical-use">Not for clinical use!</span>
                        </ButtonToolbar>

                        <ButtonToolbar className="global-context-buttons float-right">
                            <GlobalToolbar isEmbedded={true} />
                        </ButtonToolbar>
                    </div>

                    <div className="tool-area">
                        {menu === MainMenu.WindowLevel && <WindowLevelToolbar viewerState={vs} />}
                        {menu === MainMenu.Preferences && <PreferenceToolbar viewerState={vs} />}
                        {menu === MainMenu.Contouring && <ContouringToolbar viewerState={vs} />}
                        {menu === MainMenu.Info && <InfoToolbar viewerState={vs} />}
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    state => Object.assign({}, state),
    sagas.mapDispatchToProps
)(MainToolbar);