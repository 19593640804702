import { DatasetGradings, StructureSetGrading } from "./roi-grading";
import { AzureFileClient } from "../web-apis/azure-file-client";
import { getDateTimeString, notEmpty } from "../util";
import { AzureFileInfo, AzureShareInfo } from "../web-apis/azure-files";
import { GradingToSave } from "./dataset-files";

// Backup functionality for critical json files. (used just for grading sheets as of now,
// plenty of other critical files are being automatically backed up during azure file
// operations -- see azure-file-client.tsx -> saveBlob())

const BACKUP_STORAGE = "rtviewerbackup";
const GRADINGS_BACKUP_SHARE = "gradings";


export async function backupGradings(gradings: DatasetGradings, sourceShare: AzureShareInfo, user: string, gradingsJustSaved: GradingToSave[]): Promise<void> {
    const storage = BACKUP_STORAGE;
    const shareName = GRADINGS_BACKUP_SHARE;
    const path = sourceShare.toString();

    // try to get the latest lastUpdated time string from the collated grading updates, or generate it right now
    const nonNullSsGradings = gradingsJustSaved.flatMap(g => g.ssGrading).filter(notEmpty);
    const lastUpdatedGrading: StructureSetGrading | undefined = nonNullSsGradings.sort().slice(-1)[0];
    const date = lastUpdatedGrading ? lastUpdatedGrading.lastUpdated : getDateTimeString();

    const filename = `${date}-${user}.json`;
    const fileInfo = new AzureFileInfo(storage, shareName, path, filename);
    const content = JSON.stringify(gradings, null, 4);

    try {
        const client = new AzureFileClient(storage, { username: user, skipTemporaryFilesWhenSaving: true });
        await client.createDirIfNotExists(fileInfo.getPath());
        await client.saveToFile(fileInfo, content);
    }
    catch (err) {
        console.error(`An error occurred when trying to back up gradings file ${fileInfo.toString()}:`);
        console.log(err);
        throw err;
    }
}
