import _ from "lodash"

/** User has access to a storage account with this name. May include further details 
 * by only allowing certain fileshares. */
export type UserStorageAccountAccess = {
    /** Name of the storage account user has access to. */
    name: string;
    /** File shares under this storage account that user has access to, or 'null' for all. */
    fileShares: UserFileShareAccess[] | null;
}

/** User has access to a file share (under a storage account) with this name. May include further 
 * details by only allowing certain patient IDs. */
export type UserFileShareAccess = {
    /** Name of the file share user has access to. */
    name: string;
    /** Patient IDs under this file share that user has access to, or 'null' for all. */
    patientIds: UserPatientIdAccess[] | null;
}

/** User has access to a patient (under a file share and a storage account) with this Patient ID ('name'). 
 * May include further details by only allowing certain series UIDs. */
export type UserPatientIdAccess = {
    /** Patient ID of the patient user has access to. [sic] */
    name: string;
    /** Series UIDs under this patient that user has access to, or 'null' for all. */
    seriesUids: string[] | null;
}

/** Convert JSON from backend to view models */
export const convertUserAccessJsonToViewModels = (json: any): UserStorageAccountAccess[] => {
    const userAccess: UserStorageAccountAccess[] = [];

    if (_.isArray(json)) {
        for (const storageAccount of json) {
            if (_.isString(storageAccount['name'])) {
                const storageAccountVm: UserStorageAccountAccess = { name: storageAccount['name'], fileShares: null };
                userAccess.push(storageAccountVm);

                if (_.isArray(storageAccount['file_shares'])) {
                    storageAccountVm.fileShares = [];
                    for (const fileShare of storageAccount['file_shares']) {
                        if (_.isString(fileShare['name'])) {
                            const fileShareVm: UserFileShareAccess = { name: fileShare['name'], patientIds: null };
                            storageAccountVm.fileShares.push(fileShareVm);

                            if (_.isArray(fileShare['patient_ids'])) {
                                fileShareVm.patientIds = [];
                                for (const patientId of fileShare['patient_ids']) {
                                    const patientIdVm: UserPatientIdAccess = { name: patientId['name'], seriesUids: null }
                                    fileShareVm.patientIds.push(patientIdVm);

                                    if (_.isArray(patientId['series_uids']) && patientId['series_uids'].every(sUid => _.isString(sUid))) {
                                        patientIdVm.seriesUids = patientId['series_uids'];
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    return userAccess;
}
