import React from 'react';
import { Modal, Button, Alert } from 'react-bootstrap';
import { StoreState } from '../../../store/store';
import { connect } from 'react-redux';
import { ContouringTask, ContouringTaskState } from '../../../store/contouring-task';
import { differenceInSeconds } from 'date-fns';
import { getHumanReadableDurationString, notEmpty } from '../../../util';
import { StructureSet, saveStructureSetOnUserDevice } from '../../../dicom/structure-set';
import { ViewerState } from '../../../rtviewer-core/viewer-state';
import { BsDownload } from 'react-icons/bs';


import './FinishedAutoContouringDialog.css';
import { IconContext } from 'react-icons';
import _ from 'lodash';
import { User } from '../../../store/user';
import { isDemo } from '../../../environments';


// FinishedAutoContouringDialog is a component mainly for the Import/Export UI, but can also be enabled in RTViewer.
// It is disabled for now, but can be enabled by setting the constant below to true.
const IS_FINISHED_AUTO_CONTOURING_DIALOG_ENABLED = false;

type OwnProps = {
    show: boolean,
    onHide: () => void,
    scanId: string,
    structureSets: StructureSet[];
    vs: ViewerState;
}

type DispatchProps = {
}

type AllProps = OwnProps & StoreState & DispatchProps;

class FinishedAutoContouringDialog extends React.Component<AllProps> {

    constructor(props: AllProps) {
        super(props);
    }

    render() {
        const { show, onHide, scanId, contouringTasks, structureSets, vs, appVersion } = this.props;

        if (!IS_FINISHED_AUTO_CONTOURING_DIALOG_ENABLED) {
            return null;
        }

        const task: ContouringTask | undefined = contouringTasks[scanId];
        if (task === undefined) {
            // nothing to show if we have no contouring task
            return null;
        }

        const finishedStructureSets = task.matchingStructureSetIds !== null ? task.matchingStructureSetIds.map(id => structureSets.find(ss => ss.structureSetId === id)) : undefined;
        const originalDicomFiles: { file: ArrayBuffer, filename: string, ssId: string }[] | undefined = finishedStructureSets ? finishedStructureSets.filter(notEmpty).map(ss => ({ ssId: ss.structureSetId, ...(this.props.originalStructureSets[ss.structureSetId])})) : undefined;
        const areOriginalDicomsAvailable = originalDicomFiles !== undefined && finishedStructureSets !== undefined && originalDicomFiles.length === finishedStructureSets.length;
        const allowStructureSetDownload = areOriginalDicomsAvailable && (this.props.user && (this.props.user as User).permissions.allowStructureSetDownload) || isDemo();

        const taskFailed = task.contouringState !== ContouringTaskState.Success;
        const taskSucceeded = task.contouringState === ContouringTaskState.Success && finishedStructureSets !== undefined;

        const taskTimeInSeconds = task.taskFinishTime !== null && task.uploadFinishTime !== null ? getHumanReadableDurationString(differenceInSeconds(task.taskFinishTime, task.uploadFinishTime)) : undefined;
        const totalTaskTimeInSeconds = task.taskFinishTime !== null && task.uploadStartTime !== null ? getHumanReadableDurationString(differenceInSeconds(task.taskFinishTime, task.uploadStartTime)) : undefined;
        const timesAreAvailable = taskTimeInSeconds !== undefined && totalTaskTimeInSeconds !== undefined;
        const buildId = appVersion.commit;

        return (
            <Modal show={show} onHide={onHide} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Segmentation finished</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        {taskFailed && (
                            <div>
                                <Alert variant="danger" className="file-load-errors-alert">
                                    <Alert.Heading>Segmentation was unsuccessful:</Alert.Heading>
                                    <div className="file-load-errors">
                                        <div>{task.errorMessage}</div>
                                    </div>
                                </Alert>
                                <div>See more information about this error by opening the detailed view from the left-side panel.</div>
                            </div>
                        )}
                        {taskSucceeded && (
                            <div className="segmentation-succeeded">
                                {timesAreAvailable && (<div>Segmentation was completed in {taskTimeInSeconds} (total process time {totalTaskTimeInSeconds}).</div>)}
                                {allowStructureSetDownload && finishedStructureSets && originalDicomFiles && finishedStructureSets.map(ss => ss && (
                                    <div key={ss.structureSetId}>
                                        <IconContext.Provider value={{ size: '1.3em', style: { marginLeft: '5px' } }}>
                                            <Button onClick={() => saveStructureSetOnUserDevice(ss, vs, buildId, originalDicomFiles.find(o => o.ssId === ss.structureSetId))}>Download structure set {ss.label} <BsDownload /></Button>
                                        </IconContext.Provider>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={onHide}>Close</Button>
                </Modal.Footer>

            </Modal>
        );
    }
}

export default connect(
    state => Object.assign({}, state),
    // sagas.mapDispatchToProps
)(FinishedAutoContouringDialog);
