import React from 'react';
import { ButtonToolbar, ButtonGroup, Dropdown, Button } from 'react-bootstrap';

import './BatchJobToolbar.css';
import TriStateCheckbox, { TriStateCheckboxChecked } from '../common/TriStateCheckbox';
import BatchJobSelectionCounts from './BatchJobSelectionCounts';
import { BatchJobOperation } from './dataset-table/DatasetPage';


class BatchJobToolbar extends React.PureComponent<{
    checkAllPatients: (setToChecked: TriStateCheckboxChecked) => void,
    checkAllImages: (setToChecked: TriStateCheckboxChecked) => void,
    checkAllStructureSets: (setToChecked: TriStateCheckboxChecked) => void,
    checkEverything: (setToChecked: TriStateCheckboxChecked) => void,
    checkStructureSetsForSelectedImages: (setToChecked: TriStateCheckboxChecked) => void,
    setAllBatchOperations: (setToOperation: BatchJobOperation) => void,
    isEverythingChecked: TriStateCheckboxChecked,
    areAllPatientsChecked: TriStateCheckboxChecked,
    areAllImagesChecked: TriStateCheckboxChecked,
    areAllStructureSetsChecked: TriStateCheckboxChecked,
    selectedPatientsCount: number,
    selectedImagesCount: number,
    selectedStructureSetsCount: number,
    onExportButtonClick: () => void,
}> {

    getIsEverythingChecked = () => {
        return this.props.isEverythingChecked;
    }

    handleCheckEverything = () => {
        const setToCheckedState = this.getIsEverythingChecked() === TriStateCheckboxChecked.Unchecked ? TriStateCheckboxChecked.Checked : TriStateCheckboxChecked.Unchecked;
        this.props.checkEverything(setToCheckedState);
    }

    handleSelectAllPatients = (event: any) => {
        this.props.checkAllPatients(TriStateCheckboxChecked.Checked);
    }

    handleUnselectAllPatients = (event: any) => {
        this.props.checkAllPatients(TriStateCheckboxChecked.Unchecked);
    }

    handleSelectAllImages = (event: any) => {
        this.props.checkAllImages(TriStateCheckboxChecked.Checked);
    }

    handleUnselectAllImages = (event: any) => {
        this.props.checkAllImages(TriStateCheckboxChecked.Unchecked);
    }

    handleSelectAllStructureSets = (event: any) => {
        this.props.checkAllStructureSets(TriStateCheckboxChecked.Checked);
    }

    handleUnselectAllStructureSets = (event: any) => {
        this.props.checkAllStructureSets(TriStateCheckboxChecked.Unchecked);
    }

    handleSelectAllStructureSetsForSelectedImages = () => {
        this.props.checkStructureSetsForSelectedImages(TriStateCheckboxChecked.Checked);
    }

    handleUnselectAllStructureSetsForSelectedImages = () => {
        this.props.checkStructureSetsForSelectedImages(TriStateCheckboxChecked.Unchecked);
    }

    handleExportButtonClick = () => {
        this.props.onExportButtonClick();
    }

    handleSetAllBatchOperationsToExportOnly = () => {
        this.props.setAllBatchOperations(BatchJobOperation.Export);
    }

    handleSetAllBatchOperationsToAutoContourAndExport = () => {
        this.props.setAllBatchOperations(BatchJobOperation.AutoContourAndExport);
    }


    render() {
        const {
            areAllPatientsChecked, areAllImagesChecked, areAllStructureSetsChecked,
            selectedPatientsCount, selectedImagesCount, selectedStructureSetsCount
        } = this.props;
        const totalCounts = selectedPatientsCount + selectedImagesCount + selectedStructureSetsCount;
        return (
            <div className="batch-job-toolbar">
                <ButtonToolbar className="toolbar">
                    <Dropdown as={ButtonGroup} size="sm" variant="light">
                        <Button variant="light" onClick={this.handleCheckEverything}>
                            <div className="tri-state-select-all">
                                <TriStateCheckbox id="batch-job-toolbar-select-all" onCheckboxChange={this.handleCheckEverything} checked={this.getIsEverythingChecked()} >Select all</TriStateCheckbox>
                            </div>
                        </Button>
                        <Dropdown.Toggle split id="selection-dropdown-split" variant="light" />
                        <Dropdown.Menu>
                            {areAllPatientsChecked !== TriStateCheckboxChecked.Checked && (<Dropdown.Item onClick={this.handleSelectAllPatients}>Select all patients</Dropdown.Item>)}
                            {areAllPatientsChecked !== TriStateCheckboxChecked.Unchecked && (<Dropdown.Item onClick={this.handleUnselectAllPatients}>Unselect all patients</Dropdown.Item>)}
                            {areAllImagesChecked !== TriStateCheckboxChecked.Checked && (<Dropdown.Item onClick={this.handleSelectAllImages}>Select all images</Dropdown.Item>)}
                            {areAllImagesChecked !== TriStateCheckboxChecked.Unchecked && (<Dropdown.Item onClick={this.handleUnselectAllImages}>Unselect all images</Dropdown.Item>)}
                            {areAllStructureSetsChecked !== TriStateCheckboxChecked.Checked && (<Dropdown.Item onClick={this.handleSelectAllStructureSets}>Select all structure sets</Dropdown.Item>)}
                            {areAllStructureSetsChecked !== TriStateCheckboxChecked.Unchecked && (<Dropdown.Item onClick={this.handleUnselectAllStructureSets}>Unselect all structure sets</Dropdown.Item>)}
                            <Dropdown.Item onClick={this.handleSelectAllStructureSetsForSelectedImages}>Select all structure sets for selected images</Dropdown.Item>
                            <Dropdown.Item onClick={this.handleUnselectAllStructureSetsForSelectedImages}>Unselect all structure sets for selected images</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>

                    <Dropdown>
                        <Dropdown.Toggle variant="light" id="batch-job-set-all-dropdown-split" disabled={totalCounts === 0}>
                            Set all batch operations...
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={this.handleSetAllBatchOperationsToExportOnly}>Set all batch operations to export only</Dropdown.Item>
                            <Dropdown.Item onClick={this.handleSetAllBatchOperationsToAutoContourAndExport}>Set all batch operations to auto-contour and export</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>

                    <Button
                        className="batch-export-button"
                        disabled={totalCounts === 0}
                        variant="outline-primary"
                        onClick={this.handleExportButtonClick}
                        title={totalCounts === 0 ? 'Select images and/or structure sets to enable batch contour/export' : 'Perform a batch run of contouring and/or export for the selected images and structure sets'}>
                        Batch contour/export...
                    </Button>
                    {totalCounts > 0 && (
                        <BatchJobSelectionCounts selectedPatientsCount={selectedPatientsCount} selectedImagesCount={selectedImagesCount} selectedStructureSetsCount={selectedStructureSetsCount} />
                    )}
                </ButtonToolbar>
            </div>
        )
    }
}

export default BatchJobToolbar;
