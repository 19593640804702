import _ from "lodash";
import React from "react";
import { Button, Modal } from "react-bootstrap";
import ReactMarkdown from "react-markdown";
import { connect } from "react-redux";
import remarkGfm from "remark-gfm";

import { getAppName, } from "../../../environments";
import * as sagas from '../../../store/sagas';
import { StoreState } from "../../../store/store";
import { User } from "../../../store/user";
import ModalDialog from "../ModalDialog";


import './HelpDialog.css';

type OwnProps = {}

type DispatchProps = {
    setHelpDialogVisibility(value: boolean): void,
}

type AllProps = OwnProps & StoreState & DispatchProps;

class HelpDialog extends React.PureComponent<AllProps> {

    handleClose = () => {
        this.props.setHelpDialogVisibility(false);
    }

    render() {
        const isVisible = this.props.isHelpDialogVisible;

        const user = this.props.user as User;
        const permissions = user.permissions;

        const markdown = `
## Supported hotkeys for ${getAppName()}

### All scan views

| Hotkey             | Explanation |
| ------------------ | ----------- |
| Mouse wheel        | Scroll the image stack up or down. |
| Up or down arrow   | Scroll the image stack up or down. |
| Ctrl + mouse wheel | Zoom the view in or out. |
| Ctrl + Up or down arrow | Zoom the view in or out. |
| Esc                | Cancel current tool action. |
| Ctrl + Z           | Undo previous action, if possible. |
| Ctrl + Y           | Redo previous action, if possible.
${(process.env.NODE_ENV === 'development') && permissions.allowDebugMode && `| D                  | Toggle debug view. |`}


### Pan/Select tool

| Hotkey                       | Explanation |
| ---------------------------- | ----------- |
| Click and drag               | Pan the view. |
| Click on any contour outline | Select the structure. |

### Windowing (WW/WC) tool

| Hotkey                       | Explanation |
| ---------------------------- | ----------- |
| Click and drag left or right | Adjust the window width value. |
| Click and drag up or down    | Adjust the window center value. |

### Line draw tool

| Hotkey             | Explanation |
| ------------------ | ----------- |
| Click              | Set a line point. |
| Click and drag     | Draw a continous line. |
| Alt                | Toggle between drawing and erasing. |
| Esc                | Cancel current line. |

### Brush tool

| Hotkey              | Explanation |
| ------------------- | ----------- |
| Click (and drag)    | Draw with the brush tool. |
| Shift + mouse wheel | Adjust brush size. |
| Alt                 | Toggle between drawing and erasing. |
| Esc                 | Cancel current brush drawing. |

### Info tools

| Hotkey             | Explanation |
| ------------------ | ----------- |
| Esc                | Remove all active info tools. |

### Structures list

| Hotkey            | Explanation |
| ----------------- | ----------- |
| Ctrl/shift-click  | Select multiple structures. |
| Ctrl + A          | Select all structures. |
| Ctrl + C          | Copy the contour from current SLICE to clipboard. |
| Ctrl + V          | Paste a contour from clipboard to current slice. |
| Del               | Delete selected structure(s). |

`;

        return (
            <ModalDialog
                show={isVisible}
                onHide={this.handleClose}
                size="lg">

                <Modal.Header closeButton>
                    <Modal.Title>{getAppName()} help</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ReactMarkdown remarkPlugins={[remarkGfm]} children={markdown} className="help-dialog-markdown" />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={this.handleClose}>Close</Button>
                </Modal.Footer>
            </ModalDialog>
        );
    }
}

export default connect(
    state => Object.assign({}, state),
    sagas.mapDispatchToProps,
)(HelpDialog);
