import React from 'react';
import { connect } from 'react-redux';
import { Modal, Form, Col, Button } from 'react-bootstrap';
import produce from 'immer';

import * as sagas from '../../../store/sagas';
import { StoreState } from '../../../store/store';

import './AddStructuresFromTemplateDialog.css';
import { StructureTemplate, TemplateRoi } from '../../../web-apis/structure-template';
import { ViewerState } from '../../../rtviewer-core/viewer-state';
import ListSelect from '../../common/ListSelect';
import StructureTemplateCheckList from './StructureTemplateCheckList';
import { StructureSet } from '../../../dicom/structure-set';
import ModalDialog from '../../common/ModalDialog';

type OwnProps = {
    viewerState: ViewerState,
    isVisible: boolean,
    onClose: () => void,
    selectedStructureSet: StructureSet | null,
    doGradingSync: (ss: StructureSet) => void,
}

type DispatchProps = {
    loadStructureTemplates(): void,
}

type AllProps = OwnProps & StoreState & DispatchProps;

type OwnState = {
    currentTemplateName: string | undefined,
    checkedTemplateRois: string[],
}

class AddStructuresFromTemplateDialog extends React.Component<AllProps, OwnState> {

    constructor(props: AllProps) {
        super(props);
        this.state = { currentTemplateName: undefined, checkedTemplateRois: [] };
    }

    componentDidMount() {
        // don't bother loading structure template if we're now allowed to edit in the first place
        if (this.props.viewerState.canEdit) {
            this.props.loadStructureTemplates();
        }
    }

    // gets a list of ROI templates that would not cause duplicate names with the ROIs already in current structure set
    getAllowedTemplateRois = (selectedStructureSet: StructureSet | null, template: StructureTemplate | undefined) => {
        if (!template) {
            return [];
        }

        const existingRoiNames = selectedStructureSet ? selectedStructureSet.getRois().map(r => r.name) : [];
        return template.templateRois.filter(r => !existingRoiNames.includes(r.title));
    };

    addRoisFromTemplateToSelectedStructureSet = (roiTemplatesToApply: TemplateRoi[]) => {
        const { selectedStructureSet, viewerState } = this.props;

        if (!selectedStructureSet || roiTemplatesToApply.length === 0) {
            return;
        }

        roiTemplatesToApply.forEach(r => {
            // don't add ROIs that would result in duplicate ROI names
            if (selectedStructureSet.getRois().some(existingRoi => existingRoi.name === r.title)) {
                return;
            }

            const roi = selectedStructureSet.addRoi(r.title, r.color, r.volumeType);
            viewerState.setRoiHidden(roi, false);
        })

        this.props.doGradingSync(selectedStructureSet);
        viewerState.roisChanged(selectedStructureSet);
        this.setState({ checkedTemplateRois: [] });
    }

    handleShow = () => {
        // clear any previous checkbox selections when (re-)opening the modal
        this.setState({ checkedTemplateRois: [] });
    }

    handleClose = () => {
        this.props.onClose();
    }

    handleApply = () => {
        const currentTemplate: StructureTemplate = this.props.structureTemplates.find((t: StructureTemplate) => t.name === this.state.currentTemplateName);
        if (!currentTemplate) {
            return;
        }

        const templateRoisToApply = currentTemplate.templateRois.filter(r => this.state.checkedTemplateRois.includes(r.title));
        this.addRoisFromTemplateToSelectedStructureSet(templateRoisToApply);
    }

    handleTemplateChange = (newTemplateName: string) => {
        if (this.state.currentTemplateName !== newTemplateName) {
            this.setState({ currentTemplateName: newTemplateName, checkedTemplateRois: [] });
        }
    }

    // handles toggling of template ROI checkboxes
    handleCheckTemplateRoi = (templateRoiTitle: string) => {
        this.setState(produce((draft: OwnState) => {
            const index = draft.checkedTemplateRois.indexOf(templateRoiTitle);
            if (index === -1) {
                draft.checkedTemplateRois.push(templateRoiTitle)
            } else {
                draft.checkedTemplateRois.splice(index, 1);
            }
        }));
    }

    // handles checking or unchecking all template ROIs at once
    handleCheckAllTemplateRois = () => {
        const { selectedStructureSet, structureTemplates } = this.props;
        this.setState(produce((draft: OwnState) => {

            // don't do anything if no template is selected
            if (!draft.currentTemplateName) {
                return;
            }

            const currentTemplate: StructureTemplate = structureTemplates.find((t: StructureTemplate) => t.name === draft.currentTemplateName);
            const allowedTemplateRois = this.getAllowedTemplateRois(selectedStructureSet, currentTemplate);

            const areAllRoisSelected = draft.checkedTemplateRois.length === allowedTemplateRois.length;
            if (areAllRoisSelected) {
                draft.checkedTemplateRois = [];
            } else {
                draft.checkedTemplateRois = allowedTemplateRois.map(r => r.title);
            }
        }));
    }

    render() {
        const { selectedStructureSet } = this.props;
        const templates: StructureTemplate[] = this.props.structureTemplates;
        const templateOptions = templates.map((t) => ({ title: t.name, value: t.name }));

        const currentTemplate = templates.find(t => t.name === this.state.currentTemplateName);
        const allTemplateRois = currentTemplate !== undefined ? currentTemplate.templateRois : undefined;
        const allowedTemplateRois = this.getAllowedTemplateRois(selectedStructureSet, currentTemplate);

        return (
            <ModalDialog
                show={this.props.isVisible}
                onEnter={this.handleShow}
                onHide={this.handleClose}
                size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Add structures from template</Modal.Title>
                </Modal.Header>
                <Modal.Body className="add-rois-from-template">
                    <Form>
                        <Form.Group>
                            <Form.Row>
                                <Col lg={4}>
                                    <div className="section-title">Templates</div>
                                    <ListSelect options={templateOptions} onChange={this.handleTemplateChange} value={this.state.currentTemplateName} maxSize={18} />
                                </Col>
                                <Col lg={8} className="checklist-section">
                                    <div className="section-title">Template structures</div>
                                    <StructureTemplateCheckList templateRois={allTemplateRois} checkedTemplateRois={this.state.checkedTemplateRois}
                                        allowedTemplateRois={allowedTemplateRois} onRoiCheckChange={this.handleCheckTemplateRoi} onCheckAllRois={this.handleCheckAllTemplateRois} />
                                </Col>
                            </Form.Row>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={this.handleApply} disabled={this.state.checkedTemplateRois.length === 0}>Add selected structures to current structure set</Button>
                    <Button variant="outline-secondary" onClick={this.handleClose}>Close</Button>
                </Modal.Footer>
            </ModalDialog>
        );
    }
}

export default connect(
    state => Object.assign({}, state),
    sagas.mapDispatchToProps,
)(AddStructuresFromTemplateDialog);
