import * as poly2d from './polygon-math-2d';
import { Plane, View } from '../rtviewer-core/view';



export function getLinePolylineIntersections(view: View, polyline: number[][], pt1: number[], pt2: number[]): number[][] {

    let pl = polyline.map(p => pointTo2dPoint(view.plane, p));
    let p1 = pointTo2dPoint(view.plane, pt1);
    let p2 = pointTo2dPoint(view.plane, pt2);
    return poly2d.getLinePolylineIntersections(pl, p1, p2).map(p => pointTo3dPoint(view, p));
}

export function countLinePolylineIntersections(plane: Plane, polyline: number[][], pt1: number[], pt2: number[]): number {
    let pl = polyline.map(p => pointTo2dPoint(plane, p));
    let p1 = pointTo2dPoint(plane, pt1);
    let p2 = pointTo2dPoint(plane, pt2);
    return poly2d.getLinePolylineIntersections(pl, p1, p2).length;
}


export function arePointsClose(plane: Plane, pt1: number[], pt2: number[], epsilon: number) : boolean {
    const p1 = pointTo2dPoint(plane, pt1);
    const p2 = pointTo2dPoint(plane, pt2);
    return poly2d.arePointsClose(p1, p2, epsilon);
}

export function pointsEqual(pt1: number[], pt2: number[]): boolean {
    return pt1[0] === pt2[0]
        && pt1[1] === pt2[1]
        && pt1[2] === pt2[2];
}

// 3D point to 2D point
function pointTo2dPoint(plane: Plane, pt: number[]): number[] {

    if(plane === Plane.Transversal) {
        return [pt[0], pt[1]];
    }
    else if(plane === Plane.Coronal) {
        return [pt[0], pt[2] ];
    }
    else if (plane === Plane.Sagittal) {
        return [pt[1], pt[2]];
    }
    else{
        throw new Error("Unknown plane");
    }
}

// 2D point to 3D point
function pointTo3dPoint(view: View, pt: number[]): number[] {
    const img = view.viewManager.image;
    if(view.plane === Plane.Transversal) {
        const zMm = img.kMin + view.slice * img.kSpacing;
        return [pt[0], pt[1], zMm];
    }
    else if(view.plane === Plane.Coronal) {
        const yMm = img.jMin + view.slice * img.jSpacing;
        return [pt[0], yMm, pt[1] ];
    }
    else if (view.plane === Plane.Sagittal) {
        const xMm = img.iMin + view.slice * img.iSpacing;
        return [xMm, pt[0], pt[1]];
    }
    else{
        throw new Error("Unknown plane");
    }
}

export function circleToPolygon(plane: Plane, origin: number[], r: number, edges: number): number[][] {

    function getPoint(angle: number): number[] {

        if(plane === Plane.Transversal) {
            let x = origin[0] + r * Math.cos(angle);
            let y = origin[1] + r * Math.sin(angle);
            let z = origin[2]; 
            return [x, y, z];
        }
        else if(plane === Plane.Coronal) {
            let x = origin[0] + r * Math.cos(angle)
            let y = origin[1] 
            let z = origin[2] + r * Math.sin(angle); 
            return [x, y, z];
        }
        else if(plane === Plane.Sagittal) {
            let x = origin[0];
            let y = origin[1] + r * Math.cos(angle); 
            let z = origin[2] + r * Math.sin(angle); 
            return [x, y, z];
        }
        else {
            throw new Error("Unknown plane!");
        }
    }

    let poly = [];
    for(let angle = 0; angle < 2*Math.PI; angle += 2*Math.PI/edges) {
        poly.push(getPoint(angle));
    }
    poly.push(poly[0]);//close the circle
    return poly;
}

// export function diff(pt1: number[], pt2: number[]) {
//     return [
//         pt1[0] - pt2[0],
//         pt1[1] - pt2[1],
//         pt1[2] - pt2[2]
//     ];
// }

// export function add(pt1: number[], diff: number[]){
//     return [
//         pt1[0] + diff[0],
//         pt1[1] + diff[1],
//         pt1[2] + diff[2]
//     ]
// }