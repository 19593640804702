// Downloading, uploading, parsing and unparsing dataset CSV files and lock files in the root of dataset Azure fileshare ...

import * as Papa from 'papaparse';

import { DatasetGradings, StructureSetGrading, ParsedDatasetGradings, importParsedGradings } from './roi-grading';
import { sleep } from '../util';
import * as guid from '../dicom/guid';
import { AzureFileInfo, AzurePathInfo, AzureShareInfo } from '../web-apis/azure-files';
import { AzureFileClient } from '../web-apis/azure-file-client';
import { Dataset } from './dataset';
import { DatasetImage } from './dataset-image';
import { DatasetMetaFiles } from './dataset-metafiles';
import Immerable from '../store/immerable';
import { User } from '../store/user';
import { isRutherford } from '../environments';

const LOCK_FILE_NAME = "scan-locks.json";
const EDITOR_FILE_NAME = "editors.json";
const GRADING_FILE_NAME = "gradings.json";
const DATASET_FILE_NAME = "rtviewer_info.csv";
const EXPORT_DESTINATION_FILE_NAME = "destination.json";
const ROOT_PATH = "";

const MUTEX_LOCK_TIMEOUT_IN_MILLISECONDS = 30 * 1000;
const MUTEX_LOCK_RETRY_IN_MILLISECONDS = 1000;

export const DEBOUNCE_GRADINGS_SAVE_MS = 2000;


export class RoiMapping extends Immerable {
    public originalName: string;
    public standardName: string;

    constructor(originalName: string, standardName: string) {
        super();

        this.originalName = originalName;
        this.standardName = standardName;
    }
}

export enum LockAction {
    Lock, Unlock
}

// File that contains info about locked scans (seriesId vs name of the person who has the lock)
export type ScanLocks = { [seriesId: string]: string }

// type of scan lock that the current client user is requesting (locking or unlocking)
export type ScanLockRequests = { [seriesId: string]: LockAction }

// A structure set id and its grading, if any, that should get saved
export type GradingToSave = { ssId: string, ssGrading: StructureSetGrading | null };

// File that contains some editing history
export type EditorInfo = { [patientId: string]: { [structureSetId: string]: { [user: string]: number } } }; // patientId -> structureSetId -> user -> timestamp

// Backend export task metadata
export type ExportTask = { "app_id": string, "app_name": string, "client_id": string };

// File containing backend export tasks
export type ExportDestination = { [patientId: string]: { [seriesUid: string]: ExportTask } };


export function getStructureSetFileInfo(fileShare: AzureShareInfo, patientId: string,
    frameOfReferenceUid: string, seriesId: string, sopId: string): AzureFileInfo {

    const path = patientId + '/' + frameOfReferenceUid + '/RTSTRUCT-' + seriesId;
    const filename = sopId + ".dcm.gz";
    return new AzureFileInfo(fileShare, path, filename);
}

export function getUserCanEdit(user: User, locks: ScanLocks, seriesId: string) {
    return locks[seriesId] ? locks[seriesId] === user.username : false;
}

export function getUserCanCreateRtstruct(user: User, locks: ScanLocks, seriesId: string) {
    return getUserCanEdit(user, locks, seriesId);
}

// Throws exceptions on error, otherwise returns new Dataset instance
export function parseDatasetCSV(csvString: string, datasetFile: AzureFileInfo, datasetId?: string) {

    const csv = Papa.parse(csvString);

    function validateDatasetCSV(csvObj: any) {
        if (!csvObj || !csvObj.errors || !csvObj.data || csvObj.data.length === 0) return false;
        if (csvObj.errors.length > 0) return false;
        if (csvObj.data[0].length !== 17) return false;
        return true;
    }

    if (!validateDatasetCSV(csv)) {
        console.log(csvString, csv);
        throw new Error("Invalid CSV file " + datasetFile.toString());
    }

    const dataset = new Dataset(csv, datasetFile, datasetId);
    return dataset;
}


// Download image specific locks (in scan-locks.json)
export async function downloadDatasetLocks(shareInfo: AzureShareInfo): Promise<ScanLocks> {
    const azureFileClient = new AzureFileClient(shareInfo);
    const lockFile = azureFileClient.getFile(shareInfo, ROOT_PATH, LOCK_FILE_NAME);
    const defaultContentsIfFileDoesntExist = {};
    const lockFileJson = await azureFileClient.downloadFileToString(lockFile, defaultContentsIfFileDoesntExist);

    try {
        const datasetLocks = JSON.parse(lockFileJson);
        return datasetLocks as ScanLocks;
    }
    catch (error) {
        console.log(`An error occurred when trying to parse JSON from ${lockFile}`);
        console.error(error);
        throw error;
    }
}

/**
 * Set image specific lock (in locks.json).
 */
export async function setDatasetLock(dataset: Dataset, seriesId: string, username: string, lockAction: LockAction): Promise<ScanLocks> {
    const azureFileClient = new AzureFileClient(dataset.datasetFile.storageAccountName, { skipTemporaryFilesWhenSaving: true });

    const lockFile = azureFileClient.getFile(dataset.datasetFile.fileShareName, ROOT_PATH, LOCK_FILE_NAME);
    const locksJson = await azureFileClient.downloadFileToString(lockFile);
    const locks = JSON.parse(locksJson) as ScanLocks;

    let changesNeedSaving = false;

    if (lockAction === LockAction.Lock) {
        // Turn lock on
        if (locks[seriesId] === username) {
            // Nothing to change
            changesNeedSaving = false;
        }
        else if (locks[seriesId]) {
            alert(locks[seriesId] + " already has locked the series!");
            changesNeedSaving = false;
        }
        else {
            locks[seriesId] = username;
            changesNeedSaving = true;
        }
    } else {
        // Turn lock off
        // I'm commenting this code out to allow 'force unlock' button for Jarkko&Jani only
        // TODO: tie force unlock to user rights ('canForceUnlock')

        //if(locks[seriesId] === username ) {
        delete locks[seriesId];
        changesNeedSaving = true;
        //}
        //else {
        // User doesn't have the lock anymore. Nothing to change
        //return false;
        //}
    }

    if (changesNeedSaving) {
        try {
            await azureFileClient.saveToFile(lockFile, locks);
        }
        catch (err) {
            alert("Failed creating a lock! Refresh the page and try again");
            throw err;
        }
    }

    return locks;
}


export async function downloadAllStandardRois(azureShare: AzureShareInfo): Promise<string[]> {
    // TODO: what's the motivation for this function?   
    // TODO: add real functionality
    return new Promise(function (resolve, reject) {
        const DUMMY = ['AdrenalGland_L', 'AdrenalGland_R', 'A_Aorta', 'Duodenum', 'Esophagus', 'Gallbladder', 'InferiorVenaCava', 'Kidney_L', 'Kidney_R', 'Liver', 'Pancreas', 'PortalSplenicVein', 'Spleen', 'Stomach'];
        resolve(DUMMY);
    });
}

export async function downloadExportDestinationFile(azureShare: AzureShareInfo): Promise<ExportDestination> {
    const azureFileClient = new AzureFileClient(azureShare);

    const defaultContentsIfFileDoesntExist = {};
    const exportDestinationFile = azureShare.getFile(ROOT_PATH, EXPORT_DESTINATION_FILE_NAME);
    const exportDestinationJson = await azureFileClient.downloadFileToString(exportDestinationFile, defaultContentsIfFileDoesntExist);
    const exportDestination = JSON.parse(exportDestinationJson) as ExportDestination;
    return exportDestination;
}

export async function downloadGradings(azureShare: AzureShareInfo): Promise<DatasetGradings> {
    const azureFileClient = new AzureFileClient(azureShare);

    const gradingsFile = azureFileClient.getFile(azureShare, ROOT_PATH, GRADING_FILE_NAME);
    const defaultContentsIfFileDoesntExist = new DatasetGradings();
    const gradingsJson = await azureFileClient.downloadFileToString(gradingsFile, defaultContentsIfFileDoesntExist);

    // we need to use a separate conversion function here as just using 'as' to convert parsed JSON to
    // an object won't hydrate the object properly with its inherited class details
    const datasetGradings = importParsedGradings(JSON.parse(gradingsJson) as ParsedDatasetGradings);

    return datasetGradings;
}

/**
 * Save structure set gradings into the dataset gradings file. Any existing gradings for this structure set
 * are overwritten. Other gradings in the file are retained as is.
 * @param dataset Dataset target of these grading sheet modifications.
 * @param gradingsToSave The modified gradings that will be saved to the file.
 * @param username The name of the user who initialized the gradings save operation.
 * @returns the new modified gradings sheet for the entire dataset.
 */
export async function saveGradings(dataset: Dataset, gradingsToSave: GradingToSave[], username: string): Promise<DatasetGradings> {
    const azureFileClient = new AzureFileClient(dataset.datasetFile.storageAccountName, { username: username });
    const share = azureFileClient.getShare(dataset.datasetFile.fileShareName)

    // get latest version of the gradings file from share
    const gradings = await downloadGradings(share);

    // inject the gradings-to-be-saved into the fetched gradings object
    for (const gradingToSave of gradingsToSave) {
        const { ssGrading, ssId } = gradingToSave;
        if (ssGrading) {
            gradings.structureSets[ssId] = ssGrading;
        }
        else if (!ssGrading && gradings.structureSets[ssId]) {
            // grading object is null --> user deleted it
            delete gradings.structureSets[ssId];
        }
    }

    // save the modified gradings file to share
    const gradingsFile = share.getFile(ROOT_PATH, GRADING_FILE_NAME);
    await azureFileClient.saveToFile(gradingsFile, gradings);

    // return the modified gradings object
    return gradings;
}



export async function downloadEditorInfo(share: AzureShareInfo): Promise<EditorInfo> {
    const azureFileClient = new AzureFileClient(share);

    const defaultContentsIfFileDoesntExist = {};
    const editorInfoFile = share.getFile(ROOT_PATH, EDITOR_FILE_NAME);
    const editorInfoJson = await azureFileClient.downloadFileToString(editorInfoFile, defaultContentsIfFileDoesntExist);
    const editorInfo = JSON.parse(editorInfoJson) as EditorInfo;
    return editorInfo;
}

export async function setStructureSetsEdited(datasetImage: DatasetImage, dataset: Dataset, structureSetIds: string[], username: string) {
    const patientId = datasetImage.patientId;
    const share = dataset.datasetFile.getShare();

    const editorInfo = await downloadEditorInfo(share);

    const timestamp = Math.floor(Date.now());
    editorInfo[patientId] = editorInfo[patientId] || {};
    for (let i = 0; i < structureSetIds.length; ++i) {
        const ssId = structureSetIds[i];
        editorInfo[patientId][ssId] = editorInfo[patientId][ssId] || {};
        editorInfo[patientId][ssId][username] = timestamp;
    }

    const editorFile = share.getFile(ROOT_PATH, EDITOR_FILE_NAME);
    try {
        const azureFileClient = new AzureFileClient(share, { username });
        azureFileClient.saveToFile(editorFile, editorInfo);
    }
    catch (error) {
        alert(`Failed saving editor info ${editorFile.toString()}`);
        throw error;
    }
}

// Mutex for editing rtviewer_info.csv and scan-locks.json. When the promise resolves (with lockId), modify
// the csv file or scan-locks.json and immediately release the lock calling leaveCSV mutex with the lockId.
// Nothing slow (e.g. dicom uploading) should happen in between!
// NOTE! This concept is different from the scan-locks file that prevents multiple users interacting with
// the same scans.
export async function enterCSVMutex(dataset: Dataset): Promise<string> {
    const myStamp = Date.now();
    const prefix = "lock_";
    const mutexId = prefix + guid.newGuid() + "." + myStamp;
    const path = new AzurePathInfo(dataset.datasetFile.getShare(), ROOT_PATH);
    const lockFile = path.getFile(mutexId);
    const azureFileClient = new AzureFileClient(dataset.datasetFile.storageAccountName, { skipTemporaryFilesWhenSaving: true });

    try {
        // Step 1. Create a new request file for the current user containing timestamp in milliseconds
        await azureFileClient.saveToFile(lockFile, '');

        // // TODO: is this first sleep necessary anymore with the new azure fileshare library?
        // await sleep(MUTEX_LOCK_RETRY_IN_MILLISECONDS);

        let failedAtLeastOnceTryingToGetMutexLock = false;

        // True if ok to proceed (current user's request is the only one or the earliest one)
        const compareRequests = async (): Promise<boolean> => {
            const files = await azureFileClient.listFiles(path);
            for (const fileInfo of files) {
                if (fileInfo.filename.startsWith(prefix) && fileInfo.filename !== mutexId) {
                    const fileNameSegments = fileInfo.filename.split(".");
                    const fileStamp = parseInt(fileNameSegments[fileNameSegments.length - 1]);
                    if (fileStamp + (MUTEX_LOCK_TIMEOUT_IN_MILLISECONDS + 2000) < Date.now()) {
                        // ignore and delete requests older than timeout. If found, those files are probably leftovers of some failure case
                        try {
                            await azureFileClient.deleteFile(fileInfo);
                        }
                        catch (error) {
                            console.error(`An error occurred when trying to delete an old mutex lock file (${fileInfo.toString()})`);
                            console.log(error);
                        }
                    } else {
                        if (fileStamp <= myStamp) {
                            return false;
                        }
                    }
                }
            }

            return true;
        };

        // Step 2. wait until we get the oldest lock access to the file or until timeout
        while (!await compareRequests()) {

            console.log(`Failed getting mutex lock. Trying again in ${MUTEX_LOCK_RETRY_IN_MILLISECONDS} ms.`);
            failedAtLeastOnceTryingToGetMutexLock = true;
            await sleep(MUTEX_LOCK_RETRY_IN_MILLISECONDS);

            if (Date.now() > myStamp + MUTEX_LOCK_TIMEOUT_IN_MILLISECONDS) {
                await azureFileClient.deleteFile(lockFile);
                throw new Error("Mutex lock timeout");
            }
        }

        if (failedAtLeastOnceTryingToGetMutexLock) {
            console.log("Mutex lock acquired!");
        }
        return mutexId;
    }
    catch (error) {
        console.error("Error in acquiring mutex lock!");
        console.log(error);

        // try to delete the lock file we just possibly created
        try {
            await azureFileClient.deleteFile(lockFile);
        }
        catch (error) {
            console.error(`An error occurred when trying to clean up mutex lock file (${lockFile.toString()})`);
            throw error;
        }

        throw error;
    }

}



// Mutex for editing csv and json files
export async function leaveCSVMutex(datasetTable: Dataset, mutexId: string) {
    const path = new AzurePathInfo(datasetTable.datasetFile.getShare(), ROOT_PATH);
    const azureFileClient = new AzureFileClient(path);

    await azureFileClient.deleteFile(path, mutexId);
    // console.log("Mutex lock released");
}


/**
 * Load annotation dataset from azure
 * @param azureShare azure share info to the dataset location that will be loaded
 * @param reloadMetaFiles If true, metadata files are loaded and replaced. If false, metadata file entries in the dataset are left as null.
 * @param datasetId Optional specific dataset ID that will be given to the downloaded dataset. If not specified, one will be generated based on the azure share location.
 */
export async function downloadDatasetInfo(azureShare: AzureShareInfo, reloadMetaFiles: boolean, datasetId?: string): Promise<Dataset> {
    const azureFileClient = new AzureFileClient(azureShare);
    const datasetFile = azureShare.getFile(ROOT_PATH, DATASET_FILE_NAME);

    let fileData: string;
    try {
        fileData = await azureFileClient.downloadFileToString(datasetFile);
    }
    catch (error) {
        if (error.message.includes('does not exist')) {
            throw new Error(`Selected fileshare (${azureShare.toString()}) is not a valid dataset. Fileshare root must contain ${datasetFile.filename}`);
        } else {
            throw error;
        }
    }

    const dataset = parseDatasetCSV(fileData, datasetFile, datasetId);

    if (reloadMetaFiles) {
        const editorInfo = await downloadEditorInfo(azureShare);
        const gradings = await downloadGradings(azureShare);
        const allAllowedRoiNames = await downloadAllStandardRois(azureShare);
        const exportDestination = isRutherford() ? await downloadExportDestinationFile(azureShare) : null;
        dataset.metaFiles = new DatasetMetaFiles(gradings, allAllowedRoiNames, editorInfo, exportDestination);
    }

    return dataset;
}

export async function saveDatasetImage(datasetImage: DatasetImage, dataset: Dataset, username: string | undefined) {
    const dtOld = dataset;
    const accountName = dtOld.datasetFile.storageAccountName;
    const share = new AzureShareInfo(accountName, dtOld.datasetFile.fileShareName);
    const csvFile = share.getFile(ROOT_PATH, dtOld.datasetFile.filename);
    const metaFiles = dtOld.metaFiles;
    const azureFileClient = new AzureFileClient(accountName, { username });

    const dtNew = await downloadDatasetInfo(share, false);
    dtNew.metaFiles = metaFiles;

    const newImages = [];
    for (let i = 0; i < dtNew.images.length; ++i) {
        const image = dtNew.images[i];
        if (image.seriesId === datasetImage.seriesId) {
            newImages.push(datasetImage);
        }
        else {
            newImages.push(image);
        }
    }

    dtNew.images = newImages;

    const csvText = dtNew.serializeImageList();
    await azureFileClient.saveToFile(csvFile, csvText);
}
