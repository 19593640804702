import React from 'react';
import { Modal, Button, Form, Alert } from 'react-bootstrap';
import { GradingWorkflowState, gradingWorkflowStatesInOrder, GradingMeanings } from '../../datasets/roi-grading';
import InfoText from '../common/InfoText';
import StyledDropdown from '../common/StyledDropdown';
import { Checkbox } from '../misc-components';
import GradingState from '../rtviewer/grading/GradingState';
import ModalDialog from '../common/ModalDialog';

import './FilterDialog.css';

type OwnProps = {
    isVisible: boolean,
    onHide: () => void,
    filterText: string,
    isFilterCaseSensitive: boolean,
    isMatchWholeWordOn: boolean,
    isFilterUseOriginalOn: boolean,
    filteredGradingStateOptions: any[],
    filteredByGrades: any[],
    onFilterTextChange: (filter: string) => void,
    onFilterCaseSensitivityChange: (isCaseSensitivityOn: boolean) => void,
    onFilterMatchWholeWordChange: (isMatchWholeWordOn: boolean) => void,
    onFilterUseOriginalChange: (isUseOriginalOn: boolean) => void,
    onGradingStateOptionsChange: any,
    onfilteredByGradesChange: any,
    roiNamesOriginal: string | null,
    roiNamesStandard: string | null,
    filteredByGradesOptions: any[]
}

type OwnState = {
}

const NO_NAMES_AVAILABLE = '(no names available)';

// these are the grading state options user can select for filtering
// remove the 'undefined' option from the list of allowed workflow state filters
const availableGradingStateOptions = gradingWorkflowStatesInOrder.filter(gws => gws !== GradingWorkflowState.Undefined)
    .map(gws => ({ value: gws, label: (<GradingState gradingWorkflowState={gws} />) }));

const gradesForFiltering = Object.values(GradingMeanings).map(gws => ({ value: gws, label: (<span className='grading-state'>{gws}</span>) }));

class FilterDialog extends React.Component<OwnProps, OwnState>{

    handleOnEntered() {
        // focus on the filter text area when the modal is opened
        const filterTextAreaDom = document.getElementById('filter-text-area');
        if (filterTextAreaDom) { filterTextAreaDom.focus(); }
    }

    handleTextFilterChanged(event: any) {
        this.props.onFilterTextChange(event.target.value);
    }

    handleFilterCaseSensitiveChanged = (event: any) => {
        this.props.onFilterCaseSensitivityChange(event.target.checked);
    }

    handleFilterMatchWholeWordChanged = (event: any) => {
        this.props.onFilterMatchWholeWordChange(event.target.checked);
    }

    handleFilterUseOriginalChanged = (event: any) => {
        this.props.onFilterUseOriginalChange(event.target.checked);
    }

    handleFilterGradingStatesChanged = (filteredStateOptions: any) => {
        this.props.onGradingStateOptionsChange(filteredStateOptions);
    }

    handleFilterByGradesChanged = (grades: any) => {
        this.props.onfilteredByGradesChange(grades)
    }

    render() {
        const { isVisible, onHide, filterText, isFilterCaseSensitive, isMatchWholeWordOn, isFilterUseOriginalOn, roiNamesOriginal, roiNamesStandard, filteredGradingStateOptions, filteredByGrades, filteredByGradesOptions } = this.props;
        return (
            <ModalDialog show={isVisible} onHide={onHide} onEntered={this.handleOnEntered}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit filter</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="filter-instruction">
                        <p>Filter by ROI name, patient id, frame of reference uid, series uid, series description, structure set series id, structure set sop id, approval status or label. Separate values using newline or comma (,)</p>
                        <p>Note: Use double quotes to search with exact ROI name. For example, "ctv" will find only 'ctv' and not 'ctv a' and 'ctv b'</p>
                    </div>
                    <Form>
                        <Form.Group>
                            <Form.Control id="filter-text-area" as="textarea" rows={12} cols={100} type="text" value={filterText} onChange={this.handleTextFilterChanged.bind(this)} />
                        </Form.Group>
                    </Form>

                    <div className="filter-options">
                        <Checkbox
                            label={"Case sensitive"}
                            isSelected={isFilterCaseSensitive}
                            onCheckboxChange={this.handleFilterCaseSensitiveChanged.bind(this)} />
                        <Checkbox
                            label="Match whole word"
                            title="If this is unchecked then text filters are considered wildcards, e.g. 'jar' matches 'ajar' and 'jarring'"
                            isSelected={isMatchWholeWordOn}
                            onCheckboxChange={this.handleFilterMatchWholeWordChanged.bind(this)} />
                        <div className="form-check">
                            <label>
                                <input
                                    type="checkbox"
                                    name={"checkbox-standard-original"}
                                    checked={isFilterUseOriginalOn}
                                    onChange={this.handleFilterUseOriginalChanged.bind(this)}
                                    className="form-check-input" />Filter by <InfoText title={roiNamesOriginal || NO_NAMES_AVAILABLE}>original ROI names</InfoText> instead of <InfoText title={roiNamesStandard || NO_NAMES_AVAILABLE}>standard ROI names</InfoText>
                            </label>
                        </div>
                        <div>
                            <label>Filter by segmentation work state:
                                <div>
                                    <StyledDropdown
                                        isClearable={true}
                                        isMulti={true}
                                        options={availableGradingStateOptions}
                                        onChange={this.handleFilterGradingStatesChanged.bind(this)}
                                        value={filteredGradingStateOptions}
                                    />
                                </div>
                            </label>
                        </div>
                        <div>
                            <label>Filter by structure grades:
                                <div>
                                    <StyledDropdown
                                        isClearable={true}
                                        isMulti={true}
                                        options={gradesForFiltering}
                                        onChange={this.handleFilterByGradesChanged.bind(this)}
                                        value={filteredByGradesOptions}
                                    />
                                </div>
                            </label>
                            {filteredByGrades.length > 0 && filterText.length > 0 && (
                                <Alert variant="info">Note: text filter is only applied to ROI names when filtering by grades!</Alert>
                            )}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={onHide}> OK </Button>
                </Modal.Footer>
            </ModalDialog>
        );
    }
}

export default FilterDialog;
