import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { ViewerState } from '../../../rtviewer-core/viewer-state';

import './PreferenceToolbar.css';
import { Checkbox } from '../../misc-components';


type OwnProps = {
    viewerState: ViewerState,
}

type AllProps = OwnProps;

type OwnState = {
    lineWidth: string,
    lineWidthSelected: string,
}


export class PreferenceToolbar extends React.Component<AllProps, OwnState> {
    displayName = PreferenceToolbar.name

    constructor(props: AllProps) {
        super(props);
        let vs = props.viewerState;

        this.state = {
            lineWidth: vs.lineWidth.toString(), 
            lineWidthSelected: vs.lineWidthSelected.toString(),
           // defaultZoom: defaultZoom,
        };
    }


    handleLineWidthChanged = (event: any) => {
        let val = event.target.value;
        this.setState({lineWidth: val});
        this.props.viewerState.setLineWidth(parseFloat(val))
    }

    handleLineWidthSelectedChanged = (event: any) => {
        let val = event.target.value;
        this.setState({lineWidthSelected: val});
        this.props.viewerState.setLineWidthSelected(parseFloat(val))
    }

    // handleDefaultZoomChanged = (event: any) => {
    // }

    render() {
        const vs = this.props.viewerState;

        return (
            <Row className="preferences"> 
            <Col sm="3">
                <Row className="pref-item">
                    <div className="pref-label" title="Line width">Line width:</div>
                    <input className="pref-value" type="text" value={this.state.lineWidth} onChange={this.handleLineWidthChanged} />
                </Row>
                <Row className="pref-item">
                    <div className="pref-label" title="Line width (selected structure)">Line width (selected):</div>
                    <input className="pref-value" type="text" value={this.state.lineWidthSelected} onChange={this.handleLineWidthSelectedChanged} />
                </Row>
            </Col>
            <Col sm="9">
                {/* <Row className="pref-item">
                    <div className="pref-label" title="Default zoom (%)">Default zoom (%):</div>
                    <input className="pref-value" type="text" value={this.state.defaultZoom} onChange={this.handleDefaultZoomChanged} />
                </Row> */}
                <Checkbox
                        label={"Focus to the cursor point when zooming with mouse wheel"}
                        isSelected={vs.focusWhenMouseZooming}
                        onCheckboxChange={(evt: any) => vs.setFocusWhenMouseZooming(evt.target.checked)}/>
            </Col>
        </Row>
        );
    }
}



