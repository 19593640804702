import { Dataset } from "../../../datasets/dataset";
import { DatasetImage } from "../../../datasets/dataset-image";
import { DatasetStructureSet } from "../../../datasets/dataset-structure-set";
import { convertWorkflowStateToText } from "../../../datasets/roi-grading";
import Immerable from "../../../store/immerable";
import DatasetFilter, { FilterOperator } from "./DatasetFilter";

// this file contains abstraction classes for handling basic DICOM patient/image/structure set objects
// across annotation pages

export class PagePatientCollection extends Immerable {
    patients: PagePatient[];

    constructor(patients: PagePatient[] = []) {
        super();

        this.patients = patients;
    }

    addPatient(newItem: PagePatient | DatasetImage) {
        if (newItem instanceof PagePatient) {
            this.patients.push(newItem);
        } else if (newItem instanceof DatasetImage) {
            let matchingPatient = this.patients.find(p => p.id === newItem.patientId);
            if (matchingPatient === undefined) {
                matchingPatient = new PagePatient(newItem.patientId);
                this.patients.push(matchingPatient);
            }
            matchingPatient.addImage(newItem);
        }
    }

    sort() {
        const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });
        this.patients.sort((a: PagePatient, b: PagePatient) => collator.compare(a.id, b.id));
    }

    getPatients(returnAll: boolean = false): PagePatient[] {
        return returnAll ? this.patients : this.patients.filter(pp => !pp.isFilteredOut);
    }

    setPatients(patients: PagePatient[]) {
        this.patients = patients;
    }

    filter(filter?: DatasetFilter, dataset?: Dataset) {
        this.patients.forEach(pp => pp.filter(filter, dataset));
    }
}

export class PagePatient extends Immerable {
    id: string;
    images: PageImage[];
    isFilteredOut: boolean;
    showFilteredOutImages: boolean;

    constructor(id: string, images: DatasetImage[] = []) {
        super();

        this.id = id;
        this.images = [];
        images.forEach(i => this.images.push(new PageImage(i)));
        this.sortImages();
        this.isFilteredOut = false;
        this.showFilteredOutImages = false;
    }

    addImage(image: DatasetImage) {
        this.images.push(new PageImage(image));
        this.sortImages();
    }

    sortImages() {
        this.images.sort(compareImages);
    }

    getImages(): PageImage[] {
        return this.showFilteredOutImages ? this.images : this.isFilteredOut ? [] : this.images.filter(pi => !pi.isFilteredOut);
    }

    hasMoreImages(): boolean {
        return !this.showFilteredOutImages && this.images.some(pi => pi.isFilteredOut);
    }

    filter(filter?: DatasetFilter, dataset?: Dataset) {
        this.images.forEach(pi => pi.filter(filter, dataset));
        if (this.images.every(pi => pi.isFilteredOut)) {
            this.isFilteredOut = true;
        } else {
            this.isFilteredOut = false;
        }
    }
}

export class PageImage extends Immerable {
    seriesId: string;
    image: DatasetImage;
    structureSets: PageStructureSet[];
    isFilteredOut: boolean;
    showFilteredOutStructureSets: boolean;

    constructor(image: DatasetImage) {
        super();

        this.image = image;
        this.seriesId = image.seriesId;
        this.structureSets = [];
        image.structureSets.forEach(ss => this.structureSets.push(new PageStructureSet(ss)));
        this.sortStructureSets();

        this.isFilteredOut = false;
        this.showFilteredOutStructureSets = false;
    }

    sortStructureSets() {
        this.structureSets.sort(compareStructureSets);
    }

    getStructureSets() {
        return this.showFilteredOutStructureSets ? this.structureSets : this.structureSets.filter(pss => !pss.isFilteredOut);
    }

    hasMoreStructureSets(): boolean {
        return !this.showFilteredOutStructureSets && this.structureSets.some(pss => pss.isFilteredOut);
    }

    filter(filter?: DatasetFilter, dataset?: Dataset) {

        const unfilterEverything = () => {
            // remove any currently applied filters
            this.isFilteredOut = false;
            this.structureSets.forEach(pss => pss.isFilteredOut = false);
        }

        if (filter === undefined) {
            unfilterEverything();
            return;
        }

        if (dataset !== undefined && dataset.getDatasetId() !== this.image.datasetId) {
            throw new Error(`Wrong dataset provided for filter: image has dataset ID: ${this.image.datasetId}, actual dataset ID is: ${dataset.getDatasetId()}`);
        }

        const joinQuoted = (vals: string[]) => { return vals.map(val => "\"" + val + "\"").join(",") };

        const scanData = [
            this.image.patientId,
            this.image.modality,
            this.image.frameOfReferenceUid,
            this.image.seriesId,
            this.image.seriesDescription,
            this.image.pixelDataCharacteristics,
            this.image.patientExam,
            this.image.modalitySpecific,
            this.image.implementationSpecific];

        const includes = (str: string, subStr: string): boolean => {
            if (!filter.caseSensitive) {
                str = str.toLowerCase();
                subStr = subStr.toLowerCase();
            }
            return str.includes(subStr);
        }

        const apply = (strs: string[], scanData: string[] = [], roiNames: string[] = []): boolean => {
            if (filter.matchWholeWord) {
                let searchStrings = strs.concat(scanData, roiNames);
                if (!filter.caseSensitive) {
                    searchStrings = searchStrings.map(s => s.toLowerCase());
                }

                const filterValues = filter.caseSensitive ? filter.values : filter.values.map(v => v.toLowerCase());

                if (filter.operator === FilterOperator.And) {
                    return filterValues.every(v => searchStrings.includes(v));
                }
                else if (filter.operator === FilterOperator.Or) {
                    return filterValues.some(v => searchStrings.includes(v));
                }
                else {
                    throw new Error("Unknown filter operator");
                }
            } else {
                const scanStrings = joinQuoted(scanData);
                const roiString = joinQuoted(roiNames);
                const str = joinQuoted(strs.concat(scanStrings, roiString));

                if (filter.operator === FilterOperator.And) {
                    for (let j = 0; j < filter.values.length; ++j) {
                        const value = filter.values[j];
                        if (!includes(str, value)) {
                            return false;
                        }
                    }
                    return true;
                }
                else if (filter.operator === FilterOperator.Or) {
                    for (let j = 0; j < filter.values.length; ++j) {
                        const value = filter.values[j];
                        if (includes(str, value)) {
                            return true;
                        }
                    }
                    return false;
                }
                else {
                    throw new Error("Unknown filter operator");
                }
            }
        }

        const areGradingStatesBeingFiltered = filter.filterGradingStates.length > 0;
        const areGradesBeingFiltered = filter.filteredByGrades.length > 0;
        if (!areGradingStatesBeingFiltered && !areGradesBeingFiltered && apply(scanData)) {
            // Positive result with image parameters, all structure sets will be kept.
            // Skip this section if workflow states or grades are being filtered.
            unfilterEverything();
            return;
        }
        else {
            this.structureSets.forEach((pss: PageStructureSet) => {

                let include = false;

                // STEP 1: do standard filtering based on filter values

                // if there's no text to filter by, include all structure sets and move on
                if (filter.values.length === 0) {
                    include = true;
                }
                else {
                    const roiNames = filter.useOriginalRoiNames ? pss.structureSet.roiMappings.map(rm => rm.originalName) : pss.structureSet.roiMappings.map(rm => rm.standardName);

                    // add grading state to potential filter values. use both human-readable and JSON values.
                    let gradingText = '';
                    if (dataset !== undefined) {
                        const gradings = dataset.metaFiles.gradings;
                        const ssGrading = gradings ? gradings.structureSets[pss.sopId] : null;
                        gradingText = (ssGrading && ssGrading.workflowState) ? ssGrading.workflowState.toString() + convertWorkflowStateToText(ssGrading.workflowState) : '';
                    }

                    include = apply([
                        pss.structureSet.approvalStatus,
                        pss.structureSet.label,
                        pss.structureSet.sopId,
                        `bestMatch=${(pss.structureSet.bestMatch ? "true" : "false")}`,
                        pss.structureSet.scanId,
                        pss.structureSet.seriesId,
                        gradingText],
                        scanData,
                        roiNames);
                }

                // STEP 2: do filtering based on grading states (if supplied)
                if (include && areGradingStatesBeingFiltered && dataset !== undefined) {
                    const gradings = dataset.metaFiles.gradings;
                    const ssGrading = gradings ? gradings.structureSets[pss.sopId] : null;
                    include = !!ssGrading && filter.filterGradingStates.includes(ssGrading.workflowState);
                }

                // STEP 3: do filtering based on grades (if supplied)
                if (include && areGradesBeingFiltered && dataset !== undefined) {
                    const gradings = dataset.metaFiles.gradings;
                    const ssGrading = gradings ? gradings.structureSets[pss.sopId] : null;

                    if (filter.values.length > 0) {
                        // if we have any text filters set then filter grades against those results
                        const gradesWithMatchingRoiNames = !!ssGrading ? Object.values(ssGrading.rois)
                            .filter(r => {
                                const roiName = filter.caseSensitive ? r.roiName : r.roiName.toLocaleLowerCase();
                                for (const v of filter.values) {
                                    const filterValue = filter.caseSensitive ? v : v.toLocaleLowerCase();
                                    if (filter.matchWholeWord ? roiName === filterValue : roiName.includes(filterValue)) {
                                        return true;
                                    }
                                }

                                return false;
                            })
                            : [];
                        include = gradesWithMatchingRoiNames.some(roiGrade => filter.filteredByGrades.includes(roiGrade.valueMeaning));
                    } else {
                        // otherwise do a more generic grade filtering
                        include = !!ssGrading && Object.values(ssGrading.rois).some(roiGrade => filter.filteredByGrades.includes(roiGrade.valueMeaning));
                    }
                }

                // filter the structure set in or out depending on the inclusion result
                pss.isFilteredOut = !include;
            });
        }

        // check if this image should be filtered out
        if (this.structureSets.every(ss => ss.isFilteredOut)) {
            this.isFilteredOut = true;
        }
    }
}

export class PageStructureSet extends Immerable {
    sopId: string;
    structureSet: DatasetStructureSet;
    isFilteredOut: boolean;

    constructor(structureSet: DatasetStructureSet) {
        super();

        this.structureSet = structureSet;
        this.sopId = structureSet.sopId;
        this.isFilteredOut = false;
    }
}

function compareImages(first: PageImage, second: PageImage) {
    const a = first.image;
    const b = second.image;
    if (a.patientId.localeCompare(b.patientId) === -1) return -1;
    if (a.patientId.localeCompare(b.patientId) === 1) return 1;
    if (a.modality.localeCompare(b.modality) === -1) return -1;
    if (a.modality.localeCompare(b.modality) === 1) return 1;
    if (a.seriesDescription.localeCompare(b.seriesDescription) === -1) return -1;
    if (a.seriesDescription.localeCompare(b.seriesDescription) === 1) return 1;
    if (a.seriesId.localeCompare(b.seriesId) === -1) return -1;
    return 1;
}

function compareStructureSets(a: PageStructureSet, b: PageStructureSet) {
    return (a.structureSet.label.localeCompare(b.structureSet.label, undefined, { 'sensitivity': 'base' }));
}
