import Immerable from "../store/immerable";
import { DatasetStructureSet } from "./dataset-structure-set";

export class DatasetImage extends Immerable {

    public patientId: string;
    public modality: string;
    public frameOfReferenceUid: string;
    public seriesId: string;
    public seriesDescription: string;
    public structureSets: DatasetStructureSet[];
    public pixelDataCharacteristics: string;
    public patientExam: string;
    public modalitySpecific: string;
    public implementationSpecific: string;
    public otherStructureSets: DatasetStructureSet[] // Structure sets that are related in the CSV (because the same frame of reference) but that we don't want to show in the UI for now
    public datasetId: string;
    public downloadKey: string;
    public path: string;

    constructor(datasetId: string, patientId: string, modality: string, frameOfReferenceUid: string, seriesId: string,
        seriesDescription: string, pixelDataCharacteristics: string, patientExam: string, modalitySpecific: string, implementationSpecific: string) {
        super();

        this.patientId = patientId;
        this.modality = modality;
        this.frameOfReferenceUid = frameOfReferenceUid;
        this.seriesId = seriesId;
        this.seriesDescription = seriesDescription;
        this.structureSets = [];
        this.otherStructureSets = [];
        this.pixelDataCharacteristics = pixelDataCharacteristics;
        this.patientExam = patientExam;
        this.modalitySpecific = modalitySpecific;
        this.implementationSpecific = implementationSpecific;
        this.datasetId = datasetId;
        this.downloadKey = "dataset-download-" + datasetId + '/' + seriesId;
        this.path = patientId + '/' + frameOfReferenceUid + '/' + modality + '-' + seriesId;
    }
}
