import { View } from "../view";
import { Roi } from "../../dicom/structure-set";
import { ViewManager } from "../view-manager";

export enum MouseCursor {
    Default,
    Pointer,
    Crosshair,
    CrosshairDraw,
    CrosshairErase,
    Pencil,
    Eraser,
}

export class MouseToolBase {
    public intensityProfile: number[] = []  // todo: saving some data here for now. find a better place to save it
    public distanceMM: number = 0.0  // todo: saving some data here for now. find a better place to save it

    handleActivate(vm: ViewManager) {}

    handleDeactivate() {}

    handleRoiSelected(roi: Roi | null) {}

    drawOnCanvas(canvas: HTMLCanvasElement, view: View) {}

    getMouseCursor(view: View) {return MouseCursor.Default}
    
    // Mouse interactions
    
    handleClick(view: View, mousePointMm: number[]): void {}
    
    handleMouseDown(view: View, mousePointMm: number[], mouseButton: number): void {}

    handleMouseUp(view: View, mousePointMm: number[], mouseButton: number): void {}

    handleHover(view: View, mousePointMm: number[]): void {}

    handleDrag(view: View, mousePointMm: number[], diff: number[], mouseButton: number): void {}

    handleDragRelease(view: View): void {}

    handleMouseLeave(view: View): void {}

    // Wheel and key interactions

    // Tool overrides default scrolling/zooming functionality when returns true
    // both scroll wheel and up&down keys
    handleScroll(view: View, up: boolean, ctrlKey: boolean, shiftKey: boolean): boolean { return false; } 

    handleEsc(view: View) { }

    handleAlt(view: View) { }
}