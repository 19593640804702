import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import * as sagas from '../../store/sagas';
import { StoreState } from '../../store/store';
import { decompressGZip } from '../../store/gzip'
import './ColorExtractPage.css';
import { dicomToStructureSet, StructureSet, Roi } from '../../dicom/structure-set';

type OwnProps = {}
type DispatchProps = {
    storeLocalStructureSet(file: File, currentScanId: string, cbReturnId: (scanId: string, ssId: string) => void, cbError: (message: string) => void): void,
}
type AllProps = OwnProps & StoreState & DispatchProps;
type OwnState = {
    rois?: Roi[] | null,
}


export class ColorExtractPage extends React.Component<AllProps, OwnState> {
    displayName = ColorExtractPage.name

    constructor(props: AllProps) {
        super(props);

        this.state = {};
    }

    showError = (errorMsg: any) => {
        alert(errorMsg);
    }

    handleStructureSetFileChange = (e: any) => {
        e.persist();
        const file = e.target.files[0];
        const that = this;
        const reader = new FileReader();
        reader.onload = () => {
            let arrayBuffer = reader.result as ArrayBuffer;
            if (file.name.toLowerCase().endsWith('.gz')) arrayBuffer = decompressGZip(arrayBuffer)
            const ss = dicomToStructureSet(arrayBuffer);
            if (ss) {
                that.generateStructureSetListing(ss);
            }
            else {
                alert("Invalid DICOM structure set");
            }
        }
        reader.readAsArrayBuffer(file);
    }

    generateStructureSetListing = (ss: StructureSet) => {
        let rois = ss.getRois();

        function compare(a: Roi, b: Roi) {
            if (a.name < b.name) {
                return -1;
            }
            if (a.name > b.name) {
                return 1;
            }
            return 0;
        }
        rois = rois.sort(compare);
        this.setState({ rois: rois });
    }

    render() {
        if (this.state.rois)
            return (<div className="result-listing">

                {this.state.rois.map(roi =>
                    <p key={roi.roiNumber}>
                        {"name: " + roi.name}
                        <br />
                        {`color: [${roi.color[0]}, ${roi.color[1]}, ${roi.color[2]}]`}
                        <br />
                    </p>)}

            </div>);
        return (
            <Col>
                <Row>
                    <Col>
                        <Row><p className="use-margin">Select structure set file to extract ROI names/colors from:</p></Row>
                        <input type="file" ref="structureSetInput" onChange={this.handleStructureSetFileChange} />
                    </Col>
                </Row>
            </Col>
        );
    }
}

export default connect(
    state => Object.assign({}, state),
    sagas.mapDispatchToProps
)(ColorExtractPage);



