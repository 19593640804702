export const MARGIN_SWEEP_Y_VS = `#version 300 es
#define POSITION_LOCATION 0
#define TEXCOORD_LOCATION 1

precision highp float;
precision highp int;

layout(location = POSITION_LOCATION) in vec2 position;
layout(location = TEXCOORD_LOCATION) in vec2 in_texcoord;

out vec2 v_texcoord;

void main()
{
    v_texcoord = vec2(in_texcoord.x, 1.0 - in_texcoord.y);
    gl_Position = vec4(position, 0.0, 1.0);
}
`;

export const MARGIN_SWEEP_Y_FS = `#version 300 es
precision highp float;
precision highp int;

precision highp sampler2D;


uniform sampler2D textureOrig; // Original image slice before any application of y-sweep
uniform sampler2D textureBuffer; // Image slice after the previous iteration of y-sweep

uniform vec2 textureSize;
uniform float distancePixels1;
uniform float distancePixels2; 
uniform float maxDistancePixels;

uniform float y1MarginPixels;
uniform float y2MarginPixels;
uniform float isInnerMargin; // 0.0 = NO, 1.0 = YES

in vec2 v_texcoord;

out vec4 color;

bool isIn(vec2 pixel) {
    return pixel.y >= 0.0 && pixel.y < textureSize.y;
}

void main()
{
    bool isInner = isInnerMargin > 0.5;
    float inv = isInner ? -1.0 : 1.0;
    vec2 pixel = v_texcoord * textureSize;
    vec4 curr = texture(textureBuffer, v_texcoord);
    float r = curr.r; 
    float g = curr.g;
    float b = curr.b;
    
    if(distancePixels1 > 0.0 )  {
        vec2 pixel1 = pixel + vec2(0.0, -1.0 * inv * distancePixels1);
        if(isIn(pixel1)) {
            vec4 sample1 = texture(textureOrig, pixel1 / textureSize );
            float b1 = (distancePixels1 - abs(sample1.r - 0.5) * maxDistancePixels ) / y1MarginPixels;
            if (   (isInner && r <= 0.5 && sample1.r >= 0.5) ||
                    (!isInner && r >= 0.5 && sample1.r <= 0.5) ) {

                        if( curr.b == 0.0 && sample1.g * sample1.g + b1 * b1 < 1.0001 ) {
                            r = isInner ? max(r, sample1.r) : min(r, sample1.r);
                            g = sample1.g;
                            b = b1;
                        }
                        
            }


        }
    }
    
    if(distancePixels2 > 0.0) {
        vec2 pixel2 = pixel + vec2(0.0, 1.0 * inv * distancePixels2);
        if(isIn(pixel2)) {
            vec4 sample2 = texture(textureOrig, pixel2 / textureSize );
            float b2 = b;
            if (   (isInner && curr.r <= 0.5 && sample2.r >= 0.5) ||
                   (!isInner && curr.r >= 0.5 && sample2.r <= 0.5) ) {
                    b2 = (distancePixels2 - abs(sample2.r - 0.5) * maxDistancePixels ) / y2MarginPixels;

                    if( curr.b == 0.0 && sample2.g * sample2.g + b2 * b2 < 1.0001) {
                        r = isInner ? max(r, sample2.r) : min(r, sample2.r);
                        g = sample2.g;
                        b = b == 0.0 ? b2 : min(b, b2);
                    }
            }

        }
    }

    color = vec4(r, g, b, 0.0);
}
`;