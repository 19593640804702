import { number } from "mathjs";
import { Matrix3f } from "./Matrix3f";
import { Matrix4f } from "./Matrix4f";
import { Vector2f } from "./Vector2f";

export class Vector3f {

    /**
     * To guarantee these are never changed, we do it like this.
     */
    static get XAxis(): Vector3f {
        return new Vector3f(1.0, 0.0, 0.0);
    }

    static get YAxis(): Vector3f {
        return new Vector3f(0.0, 1.0, 0.0);
    }

    static get ZAxis(): Vector3f {
        return new Vector3f(0.0, 0.0, 1.0);
    }

    public x!: number;
    public y!: number;
    public z!: number;

    constructor(x?: number | Vector3f | Array<number>, y?: number, z?: number) {
        if(x !== undefined){
            this.set(x, y, z);
        } else {
            this.x = this.y = this.z = 0;
        }
    }

    set(x: number | Vector3f | Array<number> | {x: number, y: number, z: number},
        y?: number, z?: number): Vector3f {
        if(typeof x === 'number'){
            if(y !== undefined && z !== undefined){
                this.x = x;
                this.y = y;
                this.z = z;
            }
        } else if(x instanceof Vector3f){
            this.x = x.x;
            this.y = x.y;
            this.z = x.z;
        } else if(x instanceof Array){
            if(x.length === 3){
                this.x = x[0];
                this.y = x[1];
                this.z = x[2];
            }
        } else {
            this.x = x.x;
            this.y = x.y;
            this.z = x.z;
        }
        return this;
    }

    add(x: number, y: number, z: number): Vector3f;

    add(ref: Vector3f): Vector3f;

    add(ref: Vector3f | number, y?: number, z?: number): Vector3f {
        if(ref instanceof Vector3f){
            this.x += ref.x;
            this.y += ref.y;
            this.z += ref.z;
        } else {
            this.x += ref;
            this.y += y as number;
            this.z += z as number;
        }
        return this;
    }

    subtract(ref: Vector3f): Vector3f;

    subtract(x: number, y: number, z: number): Vector3f;

    /**
     * Method that will mutate this object by subtracting values on a per-component basis.
     * @param ref Vector to subtract or x value to subtract from the x-component
     * @param y value to subtract from y-component
     * @param z value to subtract from z-component
     * @returns this value with subtraction applied
     */
    subtract(ref: Vector3f | number, y?: number, z?: number): Vector3f {
        if(ref instanceof Vector3f){
            this.x -= ref.x;
            this.y -= ref.y;
            this.z -= ref.z;
        } else {
            this.x -= ref;
            this.y -= y as number;
            this.z -= z as number;
        }
        return this;
    }

    scale(scale: number): Vector3f {
        this.x *= scale;
        this.y *= scale;
        this.z *= scale;
        return this;
    }

    getLength(): number {
        return Math.sqrt(this.x * this.x + this.y * this.y + this.z * this.z);
    }

    getSquaredLength(): number {
        return this.x * this.x + this.y * this.y + this.z * this.z;
    }

    absolutize(): Vector3f {
        if(this.x < 0.0){
            this.x = -this.x;
        }
        if(this.y < 0.0){
            this.y = -this.y;
        }
        if(this.z < 0.0){
            this.z = -this.z;
        }
        return this;
    }

    isAxisAligned(): boolean {
        let normalized = new Vector3f(this).normalize();
        if(Math.abs(Vector3f.dotProduct(normalized, Vector3f.XAxis)) === 1.0){
            return true;
        } else if(Math.abs(Vector3f.dotProduct(normalized, Vector3f.YAxis)) === 1.0){
            return true;
        } else {
            return Math.abs(Vector3f.dotProduct(normalized, Vector3f.ZAxis)) === 1.0;
        }
    }

    /**
     * Rounds each component to nearest integer
     * @returns this with rounding applied
     */
    round(): Vector3f {
        this.x = Math.round(this.x);
        this.y = Math.round(this.y);
        this.z = Math.round(this.z);
        return this;
    }

    /**
     * Normalizes this vector, maintaining it's direction information but setting it's length to 1.
     * @returns this vector with normalization applied.
     */
    normalize(): Vector3f {
        const length = this.getLength();
        return this.scale(1/length);
    }

    /**
     * Inverts each component of this vector.
     * Resulting in the same vector pointing in the opposite direction.
     * @returns this vector with inversion applied
     */
    invert(): Vector3f {
        if(this.x !== 0.0){
            this.x = -this.x;
        }
        if(this.y !== 0.0){
            this.y = -this.y;
        }
        if(this.z !== 0.0){
            this.z = -this.z;
        }
        return this;
    }

    static dotProduct(a: Vector3f, b: Vector3f): number {
        return a.x * b.x + a.y * b.y + a.z * b.z;
    }

    static crossProduct(a: Vector3f, b: Vector3f): Vector3f {
        return new Vector3f(a.y * b.z - a.z * b.y, a.z * b.x - a.x * b.z, a.x * b.y - a.y * b.x);
    }

    /**
     * Method to apply transformation matrice's transformation to this vector.<br>
     * This method mutates this vector.
     * @param matrix The transformation matrix to be applied
     * @returns this Vector3f with transformed values
     */
    public applyTransformation(matrix: Matrix4f | Matrix3f): Vector3f {
        if(matrix instanceof Matrix4f){
            const x = matrix.m00 * this.x + matrix.m10 * this.y + matrix.m20 * this.z + matrix.m30;
            const y = matrix.m01 * this.x + matrix.m11 * this.y + matrix.m21 * this.z + matrix.m31;
            const z = matrix.m02 * this.x + matrix.m12 * this.y + matrix.m22 * this.z + matrix.m32;
            return this.set(x, y, z);
        } else {
            const x = matrix.m00 * this.x + matrix.m10 * this.y + matrix.m20;
            const y = matrix.m01 * this.x + matrix.m11 * this.y + matrix.m21;
            return this.set(x, y, this.z);
        }
    }

    getArray(): number[] {
        return [this.x, this.y, this.z];
    }

    /**
     * Method to compare Vector3f to any other object type
     * @param ref Reference to object vector should be compared to
     * @returns true if objects are equal, false otherwise
     */
    public equals(ref: any): boolean {
        if(ref instanceof Vector3f || {x: number, y: number, z: number}){
            if(ref.x === this.x && ref.y === this.y && ref.z === this.z){
                return true;
            }
        }
        return false;
    }

    multiply(vector: Vector3f): Vector3f {
        this.x *= vector.x;
        this.y *= vector.y;
        this.z *= vector.z;
        return this;
    }

    /**
     * Divides each component with it's counterpart.
     * x is divided by vector.x, y is divided by vector.y and z is divided by vector.z.
     * @param vector Vector whose components are denominators.
     * @returns this vector with division applied
     */
    divide(vector: Vector3f): Vector3f {
        this.x /= vector.x;
        this.y /= vector.y;
        this.z /= vector.z;
        return this;
    }

    /**
     * Returns vector2f of this vector's x- & y-values.
     * Note! Value returned is a copy, meaning that changes are not reflected.
     */
    public get xy(): Vector2f {
        return new Vector2f(this.x, this.y);
    }

    /**
     * Returns vector2f of this vector's x- & z-values.
     * Note! Value returned is a copy, meaning that changes are not reflected.
     */
    public get xz(): Vector2f {
        return new Vector2f(this.x, this.z);
    }

    /**
     * Returns vector2f of this vector's y- & z-values.
     * Note! Value returned is a copy, meaning that changes are not reflected.
     */
    public get yz(): Vector2f {
        return new Vector2f(this.y, this.z);
    }

    /**
     * Method to get the axis this vector is closest to align with.
     * The axis sign is also correct
     */
    public closestAxis(): Vector3f {
        if(Math.abs(this.x) > Math.abs(this.y)){
            if(Math.abs(this.x) > Math.abs(this.z)){
                return new Vector3f(Math.sign(this.x), 0, 0).normalize();
            }
        } else if(Math.abs(this.y) > Math.abs(this.z)){
            return new Vector3f(0, Math.sign(this.y), 0).normalize();
        }
        return new Vector3f(0, 0, Math.sign(this.z)).normalize();
    }
}