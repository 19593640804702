import React from 'react';

import './StructureTemplateCheckList.css';
import { TemplateRoi } from '../../../web-apis/structure-template';
import { Checkbox } from '../../misc-components';

export default class StructureTemplateCheckList extends React.PureComponent<{
    templateRois: TemplateRoi[] | undefined,
    checkedTemplateRois: string[],
    allowedTemplateRois: TemplateRoi[],
    onRoiCheckChange: (checkedRoiTitle: string) => void,
    onCheckAllRois: () => void,
}> {

    handleCheckboxChange = (event: any, templateRoi: TemplateRoi) => {
        event.stopPropagation();
        this.props.onRoiCheckChange(templateRoi.title);
    }

    handleCheckAllRois = () => {
        this.props.onCheckAllRois();
    }

    render() {
        const { templateRois, checkedTemplateRois, allowedTemplateRois } = this.props;

        // template rois have not been set -> render an empty element
        if (templateRois === undefined) {
            return (<div />);
        }

        // template rois have been set, but it's an empty array -> render a message
        if (templateRois.length === 0) {
            return (<div>Current template has no structures.</div>);
        }

        const allRoisChecked = allowedTemplateRois.length === 0 ? false : checkedTemplateRois.length === allowedTemplateRois.length;

        return (
            <table className="structure-template-table">
                <thead>
                    <tr>
                        <th><Checkbox label="" isSelected={allRoisChecked} onCheckboxChange={this.handleCheckAllRois} /></th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {templateRois.map(r => {
                        const isThisRoiAllowed = allowedTemplateRois.includes(r);

                        const rowOptions: any = {};
                        const checkboxOptions: any = {};
                        const colorSquareOptions: any = {};
                        const roiNameOptions: any = {};

                        if (isThisRoiAllowed) {
                            colorSquareOptions.className = 'color-square';
                            colorSquareOptions.style = { backgroundColor: r.rgb() };
                            roiNameOptions.className = 'roi-name-column';
                            checkboxOptions.isSelected = checkedTemplateRois.includes(r.title);
                            checkboxOptions.onClick = (e: any) => this.handleCheckboxChange(e, r);
                        } else {
                            colorSquareOptions.className = 'color-square empty';
                            rowOptions.title = 'This structure already exists in current structure set';
                            roiNameOptions.className = 'roi-name-column not-allowed';
                            checkboxOptions.isSelected = false;
                        }

                        return (
                            <tr key={r.title} onClick={(e: any) => this.handleCheckboxChange(e, r)} {...rowOptions}>
                                <td><Checkbox
                                    label=""
                                    onCheckboxChange={() => { }}
                                    disabled={!isThisRoiAllowed}
                                    {...checkboxOptions}
                                /></td>
                                <td><div {...colorSquareOptions} /></td>
                                <td {...roiNameOptions}>{r.title}{r.volumeType && ` (${r.volumeType})` }</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        );
    }
}