import React from 'react';
import { Badge } from 'react-bootstrap';
import { FaCrown, FaRobot, FaHospitalAlt, FaDiceD6 } from "react-icons/fa";
import { BiTestTube } from "react-icons/bi";
import { IoMdPerson } from "react-icons/io";

import { DatasetPatientCategory } from '../../../web-apis/dataset-patient-categories';

import './DatasetCategory.css';

type OwnProps = {
    category: DatasetPatientCategory;
}

const getHumanReadableNameForCategory = (category: DatasetPatientCategory): string => {
    switch (category) {
        case DatasetPatientCategory.Unused:
            return 'Unused';
        case DatasetPatientCategory.Training:
            return 'Training';
        case DatasetPatientCategory.GoldenData:
            return 'Golden Data';
        case DatasetPatientCategory.ManualValidation:
            return 'Manual Validation';
        case DatasetPatientCategory.ClinicalValidation:
            return 'Clinical Validation';
        case DatasetPatientCategory.AutomaticValidation:
            return 'Automatic Validation';
        default:
            return category as string;
    }
}

const getBadgeVariantForCategory = (category: DatasetPatientCategory):
    'primary'
    | 'secondary'
    | 'success'
    | 'danger'
    | 'warning'
    | 'info'
    | 'light'
    | 'dark' => {
    switch (category) {
        case DatasetPatientCategory.Unused:
            return 'light';
        case DatasetPatientCategory.Training:
            return 'secondary';
        case DatasetPatientCategory.GoldenData:
            return 'warning';
        case DatasetPatientCategory.ManualValidation:
            return 'info';
        case DatasetPatientCategory.ClinicalValidation:
            return 'info';
        case DatasetPatientCategory.AutomaticValidation:
            return 'info';
        default:
            return 'light';
    }
}

const getIconForCategory = (category: DatasetPatientCategory): React.ReactNode => {
    switch (category) {
        case DatasetPatientCategory.Unused:
            return null;
        case DatasetPatientCategory.Training:
            return <FaDiceD6 title="Training" />;
        case DatasetPatientCategory.GoldenData:
            return <FaCrown title="Golden" />;
        case DatasetPatientCategory.ManualValidation:
            return <><BiTestTube title="Validation" /><IoMdPerson title="Manual" /></>;
        case DatasetPatientCategory.ClinicalValidation:
            return <><BiTestTube title="Validation" /><FaHospitalAlt title="Clinical" /></>;
        case DatasetPatientCategory.AutomaticValidation:
            return <><BiTestTube title="Validation" /><FaRobot title="Automatic" /></>;
        default:
            return null;
    }
}

/** A component for displaying a dataset patient category tag. */
class DatasetCategory extends React.PureComponent<OwnProps> {
    render() {
        const { category } = this.props;

        // don't display anything for 'unused' patients
        if (category === DatasetPatientCategory.Unused) {
            return null;
        }

        return (
            <div className="dataset-category">
                <Badge variant={getBadgeVariantForCategory(category)} className="dataset-badge">
                    <span className="category-icon">{getIconForCategory(category)}</span>
                    <span className="category-text">{getHumanReadableNameForCategory(category)}</span>
                </Badge>
            </div>
        );
    }
}

export default DatasetCategory;