
// Keys for window.localStorage. Stores data locally with no expiration date

import { differenceInHours, fromUnixTime, getUnixTime } from "date-fns";
import { SERVICE_DEMO_AGREEMENT_UPDATED } from "./components/common/ServiceAgreement";
import { DefaultWindowLevelPresets } from "./rtviewer-core/window-level";

export const LineWidth = "LINE_WIDTH_";
export const LineWidthSelected = "LINE_WIDTH_SELECTED_";
export const FocusWhenMouseZooming ="FOCUS_WHEN_MOUSE_ZOOMING";
export const DefaultZoom = "DEFAULT_IMAGE_VIEWER_ZOOM_PERCENT";
export const ContouringClientId = "CONTOURING_CLIENT_ID";
const StorageAccountSasDict = 'STORAGE_ACCOUNT_SAS_DICT';
const WindowLevelPresetsOld = "WINDOW_LEVEL_PRESETS_7";
const WindowLevelPresets = "WINDOW_LEVEL_PRESETS_8";
const AgreedToServiceTermsOn = "AGREED_TO_SERVICE_TERMS_ON";
const EnableConcurrentUploads = "ENABLE_CONCURRENT_UPLOADS";

const SAS_EXPIRATION_TIMEOUT_IN_MS = 59 * 60 * 1000; // 59min in milliseconds

function getSasDict(): any {
    let keyStr = localStorage.getItem(StorageAccountSasDict);
    return keyStr ? JSON.parse(keyStr) : {};
}

function setSasDict(dict: any): void {
    localStorage.setItem(StorageAccountSasDict, JSON.stringify(dict));
}

// Get stored shared access signature for a storage account. Returns null if sas not found or is older than 59 mins
export function getSas(storageAccountName: string) {
    let sasDict = getSasDict();
    let obj = sasDict[storageAccountName];
    let now = Math.floor(Date.now());
    let millis = SAS_EXPIRATION_TIMEOUT_IN_MS; // 59min in milliseconds
    return (obj && obj.stamp >= now - millis) ? obj.sas : null;
}

// Store shared access signature for a storage account. Sas will be usable for the next 59 mins.
export function setSas(storageAccountName: string, sas: string): void {
    let sasDict = getSasDict();
    sasDict[storageAccountName] = {
        stamp: Math.floor(Date.now()),
        sas: sas
    };
    setSasDict(sasDict);
}

export function getWindowLevelPresets(): any {
    let str = localStorage.getItem(WindowLevelPresets);
    let result = str ? JSON.parse(str) : null;
    // return result;

    // Temporary "migration code", todo: remove soon
    if(result == null) {
        let oldStr = localStorage.getItem(WindowLevelPresetsOld);
        let olds = oldStr ? JSON.parse(oldStr) : null;
        if(olds) {
            result = olds;
            Object.keys(DefaultWindowLevelPresets).forEach(key => {
                if(!result[key]) {
                    result[key] = DefaultWindowLevelPresets[key];
                }
            });
            setWindowLevelPresets(result);
        }
    }
    return result;
}

export function setWindowLevelPresets(presets: any): void {
    localStorage.setItem(WindowLevelPresets, JSON.stringify(presets));
    // TODO: remove these soon:
    localStorage.setItem("WINDOW_LEVEL_PRESETS_6", JSON.stringify(presets));
    localStorage.setItem("WINDOW_LEVEL_PRESETS_7", JSON.stringify(presets));
    localStorage.setItem("WINDOW_LEVEL_PRESETS_8", JSON.stringify(presets));
}

export function getHasAgreedToServiceTerms(): boolean {
    const value = localStorage.getItem(AgreedToServiceTermsOn) || '0';
    let parsedTimestamp: number;
    let parsedDate: Date;
    try {
        parsedTimestamp = parseInt(value);
        // saved as unix timestamp -- without milliseconds
        parsedDate = fromUnixTime(parsedTimestamp);
    }
    catch (err) {
        return false;
    }

    // allow users through if they agreed to ANY terms of service less than 24 h ago to avoid a case 
    // where a bug or a time zone issue might block them from progressing past the modal agreement dialog
    const isAgreementLessThan24HoursOld = Math.abs(differenceInHours(parsedDate, new Date())) < 24;

    return isAgreementLessThan24HoursOld || parsedTimestamp > SERVICE_DEMO_AGREEMENT_UPDATED;
}

export function setHasAgreedToServiceTerms(hasAgreed: boolean) {
    // save as unix timestamp -- without milliseconds
    localStorage.setItem(AgreedToServiceTermsOn, hasAgreed ? getUnixTime((new Date())).toString() : '0');
}

export function getAreConcurrentUploadsEnabled(): boolean {
    const value = localStorage.getItem(EnableConcurrentUploads) || true.toString();
    return value === true.toString();
}

export function setAreConcurrentUploadsEnabled(isConcurrencyEnabled: boolean) {
    // save as unix timestamp -- without milliseconds
    localStorage.setItem(EnableConcurrentUploads, isConcurrencyEnabled.toString());
}
