// Parent class for all classes that are being used with immer (i.e. basically put into redux store).
// This allows Immer to make these classses immutable when put inside a store or state.

// Classes extending from Immerable should serialize easily (i.e. all their properties shold be basic 
// javascript constructs or other Immerable classes).

import { immerable } from "immer";
import _ from "lodash";

export default class Immerable {
  // let's immer know that this class (or any derivatives) is compatible with immer
  [immerable] = true;

  /**
   * Returns a deep copy of this object. The returned object is mutable.
   */
  clone() {
    return _.cloneDeep(this);
  }
}