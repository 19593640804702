import {Image} from '../dicom/image';
import { Vector3f } from './Vector3f';


const defaultVal = 999999;

export class BoundingBox {
    // todo: allow points outside image coordinate bounds, will be useful when doing the registrations
    minI: number;
    maxI: number;
    minJ: number;
    maxJ: number;
    minK: number;
    maxK: number;
    constructor() {
        this.minI = defaultVal;
        this.minJ = defaultVal;
        this.minK = defaultVal;
        this.maxI = -1 * defaultVal;
        this.maxJ = -1 * defaultVal;
        this.maxK = -1 * defaultVal;
    }
    isEmpty() {
        return this.minI === defaultVal;
    }
    copy() {
        let bb = new BoundingBox();
        bb.minI = this.minI;
        bb.maxI = this.maxI;
        bb.minJ = this.minJ;
        bb.maxJ = this.maxJ;
        bb.minK = this.minK;
        bb.maxK = this.maxK;
        return bb;
    }
    expandWithPoint(i: number, j: number, k: number) : void {
        this.minI = Math.min(this.minI, i);
        this.minJ = Math.min(this.minJ, j);
        this.minK = Math.min(this.minK, k);
        this.maxI = Math.max(this.maxI, i);
        this.maxJ = Math.max(this.maxJ, j);
        this.maxK = Math.max(this.maxK, k);
        // console.log([this.minI, this.maxI, this.minJ, this.maxJ, this.minK, this.maxK])
    }
    expandWithPoints(points: number[][]) {
        points.forEach(p => this.expandWithPoint(p[0], p[1], p[2]));
    }
    
    resetToImageDimensions(img: Image) {
        this.expandWithPoint(img.topLeft[0], img.topLeft[1], img.topLeft[2]);
        this.expandWithPoint(img.bottomRight[0], img.bottomRight[1], img.bottomRight[2]);
        this.doHalfPixelCorrection(img);
    }

    cropToImageBorder(img: Image) {
        this.minI = Math.max(this.minI, img.iMin);
        this.maxI = Math.min(this.maxI, img.iMax);
        this.minJ = Math.max(this.minJ, img.jMin);
        this.maxJ = Math.min(this.maxJ, img.jMax);
        this.minK = Math.max(this.minK, img.kMin);
        this.maxK = Math.min(this.maxK, img.kMax);
    }

    cropToImageBorderMM(img: Image) {
        this.minI = Math.max(this.minI, 0.);
        this.maxI = Math.min(this.maxI, img.iSizeMM);
        this.minJ = Math.max(this.minJ, 0.);
        this.maxJ = Math.min(this.maxJ, img.jSizeMM);
        this.minK = Math.max(this.minK, 0.);
        this.maxK = Math.min(this.maxK, img.kSizeMM);
    }

    roundToFullPixels(img: Image) {
        this.minI = this.minI - (this.minI - img.iMin) % img.iSpacing;
        this.minJ = this.minJ - (this.minJ - img.jMin) % img.jSpacing;
        this.minK = this.minK - (this.minK - img.kMin) % img.kSpacing;
        this.maxI = this.maxI - (this.maxI - img.iMin) % img.iSpacing + img.iSpacing;
        this.maxJ = this.maxJ - (this.maxJ - img.jMin) % img.jSpacing + img.jSpacing;
        this.maxK = this.maxK - (this.maxK - img.kMin) % img.kSpacing + img.kSpacing;
        this.doHalfPixelCorrection(img);
    }
    
    // Since image corners are defined by the centers of the corner pixels, we need to have a bounding box
    // around all image pixels to be able to align sdf pixels exactly with the image pixels.
    doHalfPixelCorrection(img: Image) {
        this.minI = this.minI - img.iSpacing * 0.5;
        this.minJ = this.minJ - img.jSpacing * 0.5;
        this.minK = this.minK - img.kSpacing * 0.5;
        this.maxI = this.maxI + img.iSpacing * 0.5;
        this.maxJ = this.maxJ + img.jSpacing * 0.5;
        this.maxK = this.maxK + img.kSpacing * 0.5;
    }

    isPointInside(i: number, j: number, k: number) : boolean {
        return i >= this.minI
        && i <= this.maxI
        && j >= this.minJ
        && j <= this.maxJ
        && k >= this.minK
        && k <= this.maxK;
    }
    contains(bb: BoundingBox) {
        return bb.minI >= this.minI
        && bb.maxI <= this.maxI
        && bb.minJ >= this.minJ
        && bb.maxJ <= this.maxJ
        && bb.minK >= this.minK
        && bb.maxK <= this.maxK
    }
    withMargin(i: number, j: number, k: number) : BoundingBox {
        let bb = new BoundingBox();
        bb.minI = this.minI - i;
        bb.minJ = this.minJ - j;
        bb.minK = this.minK - k;
        bb.maxI = this.maxI + i;
        bb.maxJ = this.maxJ + j;
        bb.maxK = this.maxK + k;
        return bb;
    }
    union(other: BoundingBox) : BoundingBox {
        let bb = new BoundingBox();
        bb.minI = Math.min(this.minI, other.minI);
        bb.minJ = Math.min(this.minJ, other.minJ);
        bb.minK = Math.min(this.minK, other.minK);
        bb.maxI = Math.max(this.maxI, other.maxI);
        bb.maxJ = Math.max(this.maxJ, other.maxJ);
        bb.maxK = Math.max(this.maxK, other.maxK);
        return bb;
    }
    intersection(other: BoundingBox) : BoundingBox {
        let bb = new BoundingBox();
        bb.minI = Math.max(this.minI, other.minI);
        bb.minJ = Math.max(this.minJ, other.minJ);
        bb.minK = Math.max(this.minK, other.minK);
        bb.maxI = Math.min(this.maxI, other.maxI);
        bb.maxJ = Math.min(this.maxJ, other.maxJ);
        bb.maxK = Math.min(this.maxK, other.maxK);
        return bb;
    }
    getXSize(): number {
        return Math.max(this.maxI - this.minI, 0);
    }
    getYSize(): number {
        return Math.max(this.maxJ - this.minJ, 0);
    }
    getZSize(): number {
        return Math.max(this.maxK - this.minK, 0);
    }
    getVolume(): number {
        return (this.getXSize() || 1)
            * (this.getYSize() || 1)
            * (this.getZSize() || 1);
    }

    getCenter(): Vector3f {
        return new Vector3f((this.maxI + this.minI) * 0.5,
        (this.maxJ + this.minJ) * 0.5,
        (this.maxK + this.minK) * 0.5);
    }
}