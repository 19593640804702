import { BoundingBox } from "../../../math/bounding-box";
import { ViewManager } from "../../view-manager";
import * as sdfTexture from './sdf-texture';

// 2D sdf used in margin tool etc
export class SdfSlice {
    viewManager: ViewManager;
    data: WebGLTexture | null;
    size: number[];
    boundingBox?: BoundingBox;
    resolutionMm: number;
    maxDistanceMm: number;

    constructor(vm: ViewManager, resolutionMm: number, maxDistanceMm: number) {
        this.viewManager = vm;
        this.data = null;
        this.size = [0,0];
        this.maxDistanceMm = maxDistanceMm;
        this.resolutionMm = resolutionMm;
    }

    setBoundingBox(boundingBox: BoundingBox) {
        this.boundingBox = boundingBox;
    }

    getBoundingBox(): BoundingBox | null {
        return this.boundingBox || null;
    }

    createTexture(width: number, height: number) {
        let vm = this.viewManager;
        let gl = vm.getWebGlContext();
        this.size = [
            Math.round(width),
            Math.round(height)
        ];
        this.data = sdfTexture.createSdfSliceTexture(gl, this.size);
        this.clearTexture();
    }

    clearTexture() {
        let gl = this.viewManager.getWebGlContext();
        const fb = gl.createFramebuffer();
        gl.bindFramebuffer(gl.FRAMEBUFFER, fb);
        gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, this.data, 0);
        gl.clearColor(0.0, 0.0, 0.0, 0.0);
        gl.clear(gl.COLOR_BUFFER_BIT);
        
        gl.bindFramebuffer(gl.FRAMEBUFFER, null);
        gl.deleteFramebuffer(fb);
    }
    
}