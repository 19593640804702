import React from 'react';
import { Modal, Form, Col, Button } from 'react-bootstrap';

import './NewStructureSetDialog.css';
import ModelSelect from '../common/ModelSelect';
import { PredictionModel } from '../../web-apis/contouring-options';
import { isRutherford } from '../../environments';
import ModalDialog from '../common/ModalDialog';

type OwnProps = {
    isVisible: boolean,
    isAutoContouringInProgress: boolean,
    onNewStructureCreation: (selectedNewStructureSetOption: NewStructureSetOption, contouringAction: string, emptyStructureSetName?: string) => void,
    onClose: () => void,
}

type OwnState = {
    selectedNewStructureSetOption: NewStructureSetOption,
    selectedPredictionModel: PredictionModel | undefined,
    isModelOptionValid: boolean,
    isTextEntryOptionValid: boolean,
    isAutoContourOptionInProgressValid: boolean,
    emptyStructureSetName: string,
}

export enum NewStructureSetOption {
    NoSelection, FromScratch, AutoContour
}

class NewStructureSetDialog extends React.Component<OwnProps, OwnState> {
    constructor(props: OwnProps) {
        super(props);
        const defaultNewStructureSetOption = isRutherford() ? NewStructureSetOption.FromScratch : NewStructureSetOption.AutoContour;
        this.state = {
            selectedNewStructureSetOption: defaultNewStructureSetOption,
            selectedPredictionModel: undefined,
            isModelOptionValid: true,
            isTextEntryOptionValid: true,
            isAutoContourOptionInProgressValid: true,
            emptyStructureSetName: "MVisionAI",
        };
    }

    resetValidation = () => {
        this.setState({
            isModelOptionValid: true,
            isTextEntryOptionValid: true,
            isAutoContourOptionInProgressValid: true,
        });
    }

    handleCreateNewStructureSetClick = () => {
        // validate current options:
        const model = this.state.selectedPredictionModel;

        // validate that if auto-contouring is set, a model must be selected
        if (this.state.selectedNewStructureSetOption === NewStructureSetOption.AutoContour && !model) {
            this.setState({ isModelOptionValid: false });
            return;
        }

        // // validate that if auto-contouring is set to text entry, then there must actually be some text
        // if (this.state.selectedNewStructureSetOption === NewStructureSetOption.AutoContour
        //     && model && model.label === customModelLabel
        //     && model.action.length < 3) {
        //     this.setState({ isTextEntryOptionValid: false })
        //     return;
        // }

        // validate that if auto-contouring is already in-progress, we can't start another one
        if (this.props.isAutoContouringInProgress && this.state.selectedNewStructureSetOption === NewStructureSetOption.AutoContour) {
            this.setState({ isAutoContourOptionInProgressValid: false });
            return;
        }



        this.resetValidation();

        // proceed if everything looks good
        // delay the the activation a bit so the dialog has time to close before possible auto-contouring is enabled. this will avoid
        // a case where any "auto-contouring is already in-progress" warnings quickly flash on the dialog that's already closing
        const action = model ? model.name : "";

        const emptyStructureSetName = this.state.selectedNewStructureSetOption === NewStructureSetOption.FromScratch ? this.state.emptyStructureSetName : undefined;
        setTimeout(() => { this.props.onNewStructureCreation(this.state.selectedNewStructureSetOption, action, emptyStructureSetName); }, 100);
        this.handleClose();
    }

    handleClose = () => {
        this.props.onClose();
    }

    handleOptionChange = (selectedNewStructureSetOption: NewStructureSetOption) => {
        let removeModelValidation = {};
        if (selectedNewStructureSetOption !== NewStructureSetOption.AutoContour) {
            removeModelValidation = { isModelOptionValid: true, isTextEntryOptionValid: true, isAutoContourOptionInProgressValid: true };
        }
        this.setState({ selectedNewStructureSetOption, ...removeModelValidation });
    }

    handleEmptyStructureSetNameChange = (evt: any) => {
        this.setState({ emptyStructureSetName: evt.target.value });
    }

    handleEmptyInputOnClick = () => {
        this.setState({ emptyStructureSetName: "" });
    }

    handleModelChange = (model: PredictionModel | undefined) => {
        this.setState({ selectedPredictionModel: model, isModelOptionValid: true });
        this.selectAutoContouring();
    }

    isEmptyCreateStructureSetClickDisabled = (): boolean => {
        if (this.state.selectedNewStructureSetOption === NewStructureSetOption.FromScratch) {
            if (this.state.emptyStructureSetName === "") {
                return true;
            }
        }
        return false;
    }

    selectAutoContouring = () => {
        this.setState({ selectedNewStructureSetOption: NewStructureSetOption.AutoContour });
        this.setState({ emptyStructureSetName: "" });
    }

    render() {
        let modelSelectValidation = {};
        if (!this.state.isModelOptionValid) {
            modelSelectValidation = {
                control: (provided: any) => ({
                    ...provided,
                    borderColor: "#ee892c"
                }),
            }
        }

        let autoContouringInProgressWarningStyle = 'autocontour-in-progress';
        if (!this.state.isAutoContourOptionInProgressValid) { autoContouringInProgressWarningStyle += ' autocontour-not-available' }

        return (
            <ModalDialog
                size="lg"
                show={this.props.isVisible}
                onHide={this.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>New structure set</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="new-structure-set-radio-options">

                            {/* autocontouring is disabled in rutherford mode */}
                            {!isRutherford() && (
                                <Form.Row>
                                    <Col lg={5}>
                                        <Form.Check
                                            type="radio"
                                            label="Auto-segment a new structure set for this image using a specific prediction model"
                                            checked={this.state.selectedNewStructureSetOption === NewStructureSetOption.AutoContour}
                                            onChange={() => this.handleOptionChange(NewStructureSetOption.AutoContour)}
                                            id="radio-new-ss-as-autogenerated"
                                            disabled={this.props.isAutoContouringInProgress}
                                        />
                                    </Col>
                                    <Col className="option-right-side" lg={7}>
                                        <ModelSelect
                                            onChange={this.handleModelChange}
                                            onMenuOpen={this.selectAutoContouring}
                                            currentPredictionModel={this.state.selectedPredictionModel}
                                            styles={modelSelectValidation}
                                            isDisabled={this.props.isAutoContouringInProgress}
                                        />
                                        {(!this.state.isModelOptionValid) && (<span className="invalid-prediction-model">Please select a prediction model.</span>)}
                                        {(!this.state.isTextEntryOptionValid) && (<span className="invalid-prediction-model">Please enter the name of the prediction model you want to use.</span>)}
                                        {(this.props.isAutoContouringInProgress) && (<span className={autoContouringInProgressWarningStyle}>Auto-segmentation is already in progress. Please wait for the current one to finish.</span>)}
                                    </Col>

                                </Form.Row>
                            )}


                            <Form.Row>
                                <Col>
                                    <Form.Check
                                        type="radio"
                                        label="Create an empty structure set"
                                        checked={this.state.selectedNewStructureSetOption === NewStructureSetOption.FromScratch}
                                        onChange={() => this.handleOptionChange(NewStructureSetOption.FromScratch)}
                                        id="radio-new-ss-from-scratch"
                                    />
                                </Col>
                                {this.state.selectedNewStructureSetOption === NewStructureSetOption.FromScratch && (
                                    <Col className="option-right-side" lg={7}>
                                    <input type="text"
                                        className="empty-structure-set-name"
                                        value={this.state.emptyStructureSetName}
                                        onClick={this.handleEmptyInputOnClick}
                                        onChange={this.handleEmptyStructureSetNameChange}
                                        />
                                </Col>)}
                            </Form.Row>
                        </Form.Group>
                    </Form>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={this.handleCreateNewStructureSetClick} disabled={this.isEmptyCreateStructureSetClickDisabled()}>Create structure set</Button>
                    <Button variant="outline-secondary" onClick={this.handleClose}>Cancel</Button>
                </Modal.Footer>
            </ModalDialog>
        );
    }
}

export default NewStructureSetDialog;
