import './LoggedOutPage.css';
import React from 'react';
import { Button, Alert, ProgressBar, Carousel, Container, Row } from 'react-bootstrap';
import LandingPageLayout from '../LandingPageLayout';




class LoggedOutPage extends React.Component {

    render() {
        return (
            <Container fluid className="home-page">
                <Row>
                    <LandingPageLayout>
                        <p>You are now logged out.</p>
                        <p><a href="./">Log back in</a></p>
                    </LandingPageLayout>
                </Row>
            </Container>
        );
    }
}

export default LoggedOutPage;
