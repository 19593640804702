import React, { PropsWithChildren } from 'react';
import { connect } from 'react-redux';

import { StoreState } from '../store/store';
import { AppAuthStatesCollection } from '../store/auth-state';
import { User } from '../store/user';
import { Alert } from 'react-bootstrap';
import { getAppName } from '../environments';
import { LoginError } from '../store/errors';
import * as sagas from '../store/sagas';


type OwnProps = {}

type DispatchProps = {
    initializeApp: () => void,
}


type AllProps = OwnProps & DispatchProps & StoreState;

// Wrapper page for all page components
class AuthenticatedPage extends React.Component<PropsWithChildren<AllProps>> {
    displayName = AuthenticatedPage.name

    componentDidMount = () => {
        document.title = getAppName();

        // initialize the application
        this.props.initializeApp();
    }

    render() {
        const appAuthStatesCollection = this.props.appAuthStatesCollection as AppAuthStatesCollection;
        const loginError: LoginError | null = this.props.loginError;
        const wasLoginSuccessful = loginError === null;
        const arePopupsBlocked = !wasLoginSuccessful && loginError && loginError.arePopupsBlocked;
        const loginErrorMessage = !wasLoginSuccessful && !arePopupsBlocked && loginError && loginError.errorMessage;
        const isWaitingForLogin = appAuthStatesCollection.areAppStartLoginsPending();
        const hasUserAuthenticated = !isWaitingForLogin && this.props.user && this.props.user as User && (this.props.user as User).isAuthenticated;

        return (
            <>
                {arePopupsBlocked && (<Alert variant="secondary">
                    <Alert.Heading>Pop-ups are blocked!</Alert.Heading>
                    <p>
                        It looks like your browser might be blocking pop-up windows. {getAppName()} requires
                        pop-ups to be enabled for it to work. Please enable pop-ups for this site
                        and reload the page.
                    </p>
                    <p>
                        For instructions on how to enable pop-ups in Chrome, see&nbsp;
                        <a href='https://support.google.com/chrome/answer/95472?co=GENIE.Platform%3DDesktop&hl=en#zippy=%2Callow-pop-ups-from-a-site'>these instructions.</a>
                        &nbsp;For other browsers see their relevant documentation.
                    </p>
                    <p>
                        If you're still seeing this error after enabling pop-ups for {getAppName()},
                        try restarting your browser.
                    </p>
                </Alert>)}
                {!wasLoginSuccessful && loginErrorMessage && (<Alert variant="warning">
                    <Alert.Heading>An error occurred during login</Alert.Heading>
                    <p>
                        An error occurred when trying to log you in:
                    </p>
                    <p>{loginErrorMessage}</p>
                    <p>
                        If this error persists, please contact a system administrator.
                    </p>
                </Alert>)}
                {isWaitingForLogin && (<div>Waiting for login...</div>)}
                {hasUserAuthenticated && this.props.children}
            </>
        );
    }
}


export default connect(
    state => Object.assign({}, state),
    sagas.mapDispatchToProps
)(AuthenticatedPage);
