import _ from "lodash";

/** Dataset categories for a patient */
export enum DatasetPatientCategory {
    Unused = 'Unused',
    Training = 'Training',
    ManualValidation = 'ManualValidation',
    ClinicalValidation = 'ClinicalValidation',
    AutomaticValidation = 'AutomaticValidation',
    GoldenData = 'GoldenData',
}

export type DatasetPatientCategoryCollection = { [patientId: string]: DatasetPatientCategory };

export const convertDatasetCategoryJsonIntoViewModels = (json: any): DatasetPatientCategoryCollection => {
    const collection: DatasetPatientCategoryCollection = {};

    // these are the fields we expect to find from the JSON and the categories they match to
    const jsonFields: { [field: string]: DatasetPatientCategory } = {
        'golden': DatasetPatientCategory.GoldenData,
        'train': DatasetPatientCategory.Training,
        'val_automated': DatasetPatientCategory.AutomaticValidation,
        'val_clinical': DatasetPatientCategory.ClinicalValidation,
        'val_manual': DatasetPatientCategory.ManualValidation,
        'unused': DatasetPatientCategory.Unused,
    };

    for (const [field, category] of Object.entries(jsonFields)) {
        const jsonData = json[field];
        if (jsonData && _.isArray(jsonData)) {
            for (const patientId of jsonData) {
                if (_.isString(patientId)) {
                    if (!!collection[patientId]) {
                        console.warn(`Dataset patient category (split categories) already contains an entry for patient id "${patientId}": ${collection[patientId]}`);
                    } else {
                        collection[patientId] = category;
                    }
                }
            }
        }
    }

    return collection;
}
