/* File browser component, shows directory tree of selected Azure fileshare. Any dicom files can be loaded.
Supported modalities are CT, MR and RTSTRUCT */

import React from 'react';
import { Button, ButtonGroup, DropdownButton, Dropdown, Col, Row, } from 'react-bootstrap';
import { connect } from 'react-redux';
import produce from 'immer';

import * as sagas from '../../store/sagas';
import { StoreState } from '../../store/store';
import { rtViewerApiClient } from '../../web-apis/rtviewer-api-client';
import { RTViewerDisplayVersion } from '../../environments';
import { DatasetGradings, GradingWorkflowState } from '../../datasets/roi-grading';
import { getUserCanEdit, LockAction } from '../../datasets/dataset-files';
import { DownloadTask } from '../../store/file-transfer-task';
import { AzureShareInfo } from '../../web-apis/azure-files';
import { AzureFileClient } from '../../web-apis/azure-file-client';
import DatasetFilter, { FilterOperator } from './models/DatasetFilter';
import FilterDialog from './FilterDialog';
import { DatasetImage } from '../../datasets/dataset-image';
import { Dataset } from '../../datasets/dataset';
import DatasetPage from './dataset-table/DatasetPage';

import 'react-contexify/dist/ReactContexify.min.css';
import './DatasetBrowser.css';
import WorkState from '../../store/work-state';
import { PagePatient } from './models/PagePatient';
import { CSVDownloader } from 'react-papaparse'
import { DatasetStructureSet } from '../../datasets/dataset-structure-set';
import _ from "lodash";

type FileShareCollection = { [storageAccountName: string]: AzureShareInfo[] | null };

type OwnProps = {
    isVisible: boolean,
    viewImage(datasetImage: DatasetImage, dataset: Dataset, canEdit: boolean): void,
    /** Optional work state that the dataset browser should automatically initialize itself to. */
    initialWorkState?: WorkState,
}

type DispatchProps = {
    startBatchJobRequest(): void,
    finishBatchJobRequest(wasSuccessful: boolean): void,
    lockDatasetImage(datasetImage: DatasetImage, dataset: Dataset, lockAction: LockAction): void,
    downloadDataset(azureShare: AzureShareInfo, reloadMetaFiles: boolean, datasetId: string): void,
    downloadDatasetImage(dataset: Dataset, datasetImage: DatasetImage): void,
    unloadImageAndStructureSets(datasetImage: DatasetImage): void,
    setCurrentWorkState(workState: WorkState | null): void,
}

type AllProps = OwnProps & StoreState & DispatchProps;

type OwnState = {

    /** The storage accounts available for the logged-in user. */
    storageAccountNames?: string[],

    /** Available file shares sorted by storage account. */
    fileShares: FileShareCollection,

    /** Optional error object we can display to user. */
    error?: any,

    /** Name of currently selected storage account. */
    currentStorageAccount?: string,

    /** Current file share as an azure share object. */
    currentFileShare?: AzureShareInfo,

    /** ID of currently selected dataset (basically corresponds to a file share). */
    currentDatasetId?: string,

    /** Second header row on the page displaying patient count statistics on current dataset.
     * Convenience object so we can update the header easily from DatasetPage after applying a filter. */
    patientCountHeader: string,

    /** Set to true if dataset browser is currently in the process of initializing to a set start state. */
    isInitializing: boolean,

    /** The work state that the dataset browser will initiate itself to. Note that this is duplicated from the props
     * and stored inside this component for convenience.
     */
    initialWorkState: WorkState | null,

    // filter stuff
    showFilterModal: boolean,
    filterText?: string,
    filterOperator: FilterOperator,
    filterCaseSensitive: boolean,
    filterMatchWholeWord: boolean,
    filterUseOriginal: boolean,
    filterGradingStates: GradingWorkflowState[],
    filterGradingStateOptions: any[],
    filterChanged: boolean,

    scansAsCsvData: any[],
    filteredByGrades: any[],
    filteredByGradesOptions: any[]
}

type PageScanForCsv = {
    approvalStatus: string;
    bestMatch: boolean;
    datasetId: string;
    imageSeriesId: string;
    label: string;
    modality: string;
    patientId: string;
    roiMappings: string;
    scanId: string;
    seriesDescription: string;
    seriesId: string;
    sopId: string;
    structureSetRoiGradings: string;
}

class DatasetBrowser extends React.Component<AllProps, OwnState> {
    displayName = DatasetBrowser.name

    constructor(props: AllProps) {
        super(props);
        this.state = {
            fileShares: {},
            patientCountHeader: '',
            isInitializing: false,
            initialWorkState: null,
            showFilterModal: false,
            filterOperator: FilterOperator.Or,
            filterCaseSensitive: false,
            filterMatchWholeWord: false,
            filterUseOriginal: true,
            filterGradingStates: [],
            filterGradingStateOptions: [],
            filterChanged: false,
            scansAsCsvData: [],
            filteredByGrades: [],
            filteredByGradesOptions: []
        };

        rtViewerApiClient.getAnnotationStorages().then((saNames) => this.setState({ storageAccountNames: saNames }));
    }

    componentDidUpdate = (prevProps: AllProps) => {

        // initialize component in the background if we're given a valid work state to initialize to,
        // but only ever do this once
        if (!this.state.isInitializing && this.props.initialWorkState !== undefined && this.state.initialWorkState === null) {

            const { initialWorkState } = this.props;

            // set up the correct initialization depending whether we're initializing into an auto-loaded scan
            // or just a dataset page index
            if (initialWorkState.hasWork() || initialWorkState.hasDatasetIndex()) {
                const datasetFile = initialWorkState.hasWork() ? initialWorkState.dataset!.datasetFile : null;
                const initialStorageAccount = initialWorkState.hasWork() ? datasetFile!.storageAccountName : initialWorkState.storageAccount!;
                const shareInfo = initialWorkState.hasWork() ? datasetFile!.getShare() :
                    initialWorkState.fileShare !== null ? new AzureShareInfo(initialStorageAccount, initialWorkState.fileShare) : undefined;
                const datasetId = initialWorkState.hasWork() || shareInfo !== undefined ? Dataset.generateDatasetId(shareInfo!) : undefined;
                this.setState({
                    // store the requested initial work state into this component's own state
                    initialWorkState: initialWorkState,
                    isInitializing: true,
                    currentStorageAccount: initialStorageAccount,
                    currentFileShare: shareInfo,
                    currentDatasetId: datasetId
                });

                // load fileshares here, since we have an initial work state here we're assuming that
                // the actual dataset itself has already been loaded by now by some other component
                this.handleSelectStorage(initialStorageAccount);
            }
        }

        if (this.props.isVisible && !prevProps.isVisible) {
            // Refresh the page if we show it again (e.g. when coming back from rtviewer).
            // Don't clear the header if we have one! This will prevent the header image counts from
            // flickering on and off.
            this.handleRefreshClick(false);
        }


    }

    isFilterOn = () => {
        return this.state.filterText || this.state.filterGradingStates.length > 0 || this.state.filteredByGrades.length > 0;
    }

    getCurrentFilter = (): DatasetFilter | undefined => {
        return this.isFilterOn() ? new DatasetFilter(this.state.filterText || '', this.state.filterOperator,
            this.state.filterCaseSensitive, this.state.filterUseOriginal, this.state.filterGradingStates, this.state.filteredByGrades, this.state.filterMatchWholeWord)
            : undefined;
    }

    setPageError = (error: any) => {
        this.setState(produce((draft: OwnState) => {
            draft.error = error;
        }));
    }

    setPatientCountHeader = (header: string) => {
        this.setState(produce((draft: OwnState) => {
            draft.patientCountHeader = header;
        }));
    }

    setScansAsCsvData = (patients: PagePatient[]) => {
        const patientImages = patients.map(patient => patient.images).flat(1).map(img => img.image)
        let tempScans: PageScanForCsv[] = []
        patientImages.forEach(tempScan => {
            const patientId = tempScan.patientId
            const seriesDescription = tempScan.seriesDescription
            const modality = tempScan.modality
            tempScan.structureSets.forEach((structureSet: DatasetStructureSet) => {
                const approvalStatus = structureSet.approvalStatus
                const bestMatch = structureSet.bestMatch
                const datasetId = structureSet.datasetId
                const gradingsSS: DatasetGradings | null = datasetId ? this.props.datasetGradings[datasetId].structureSets : null;
                const imageSeriesId = structureSet.imageSeriesId
                const label = structureSet.label
                const roiMappings = structureSet.roiMappings.map((roiMapping: { originalName: string; }) => roiMapping.originalName).join(', ')
                const scanId = structureSet.scanId
                const seriesId = structureSet.seriesId
                const sopId: string = structureSet.sopId
                const scanGradings = gradingsSS && _.has(gradingsSS, sopId) ? gradingsSS[sopId as keyof DatasetGradings] : null
                // we have to use ts-ignore here because otherwise the debugger would argue on the impossibility to use 'rois' as index type
                // @ts-ignore
                const structureSetRoiGradings = scanGradings ? Object.values(scanGradings['rois']).map(scanGrading => scanGrading.valueMeaning).join(', ') : ''
                tempScans.push({ patientId, seriesDescription, modality, approvalStatus, bestMatch, datasetId, imageSeriesId, label, scanId, seriesId, sopId, roiMappings, structureSetRoiGradings })
            })
        })
        this.setState({ scansAsCsvData: tempScans });
    }

    getCurrentDataset = (): Dataset | undefined => {
        return this.state.currentDatasetId !== undefined ? this.props.datasets[this.state.currentDatasetId] : undefined;
    }

    handleShowFilterModal = (show: boolean) => {
        this.setState({ showFilterModal: show });
        if (!show && this.state.filterChanged) {
            this.handleRefreshClick();
            this.setState({ filterChanged: false });
        }
    }

    handleFilterTextChange = (newFilter: string) => {
        this.setState({ filterText: newFilter, filterChanged: true })
    }

    handleCreateFilterClick = () => {
        this.setState({ filterText: "", showFilterModal: true });
    }

    handleEditFilterClick = () => {
        this.setState({ showFilterModal: true });
    }

    handleDeleteFilterClick = () => {
        this.setState({ filterText: undefined, showFilterModal: false, filterGradingStateOptions: [], filterGradingStates: [], filteredByGradesOptions: [], filteredByGrades: [], filterChanged: false }, () => this.handleRefreshClick());
    }

    handleFilterCaseSensitiveChange = (isCaseSensitivityOn: boolean) => {
        this.setState({ filterCaseSensitive: isCaseSensitivityOn, filterChanged: true });
    }

    handleFilterMatchWholeWordChange = (isMatchWholeWordOn: boolean) => {
        this.setState({ filterMatchWholeWord: isMatchWholeWordOn, filterChanged: true });
    }

    handleFilterUseOriginalChange = (isUseOriginalOn: boolean) => {
        this.setState({ filterUseOriginal: isUseOriginalOn, filterChanged: true });
    }

    handleFilterGradingStatesChange = (filteredStateOptions: any) => {
        const incomingFilteredStateOptions = filteredStateOptions || [];
        const filterGradingStates = incomingFilteredStateOptions.map((f: any) => f.value);

        // set these filtered values twice into state - one for the actual filtering (this array includes values only),
        // and the other for our dropdown controlled component so it knows which values are currently selected
        // (this array includes both values and labels)
        this.setState({ filterGradingStates, filterGradingStateOptions: incomingFilteredStateOptions, filterChanged: true });
    }

    handleFilterByGradesChanged = (filterGrades: any) => {
        const incomingFilteredStateOptions = filterGrades || [];
        const filteredByGrades = incomingFilteredStateOptions.map((f: any) => f.value);

        this.setState({ filteredByGrades, filteredByGradesOptions: incomingFilteredStateOptions, filterChanged: true });
    }

    handleSelectStorage = (storageAccountName: string) => {
        this.setState(produce((draft: OwnState) => {
            draft.currentStorageAccount = storageAccountName;
            draft.error = null;
            draft.fileShares = {};

            // reset any existing fileshare selection -- unless we're initializing
            if (!draft.isInitializing) {
                draft.currentFileShare = undefined;
                draft.currentDatasetId = undefined;
                draft.patientCountHeader = '';

                // set current work state to matching dataset index (unless we're initializing because that would mess up the URL)
                this.props.setCurrentWorkState(new WorkState(storageAccountName, null, null));
            }

        }), async () => {
            const azureClient = new AzureFileClient(storageAccountName);
            try {
                const fileShares = await azureClient.listFileShares();
                this.setState(produce((draft: OwnState) => {
                    draft.fileShares[storageAccountName] = fileShares;

                    // take the isInitializing flag off at this point -- its use as a safeguard has run out
                    draft.isInitializing = false;
                }));
            }
            catch (err) {
                console.log(err);
                alert("Error. Check console for details. (F12)");
            }
        });
    }

    handleSelectFileShare = (shareInfo: AzureShareInfo, clearHeader: boolean) => {
        const datasetId = Dataset.generateDatasetId(shareInfo);

        this.setState(produce((draft: OwnState) => {
            draft.currentFileShare = shareInfo;
            draft.currentDatasetId = datasetId;
            draft.error = null;
            if (clearHeader) {
                draft.patientCountHeader = '';
            }
        }));

        // set current work state to matching dataset index (unless we're initializing because that would mess up the URL)
        if (!this.state.isInitializing) {
            this.props.setCurrentWorkState(new WorkState(shareInfo.storageAccountName, shareInfo.fileShareName, null));
        }

        // load dataset in the background
        this.props.downloadDataset(shareInfo, true, datasetId);
    }

    handleRefreshClick = (clearHeader: boolean = true) => {
        const { currentFileShare } = this.state;
        if (currentFileShare) {
            this.handleSelectFileShare(currentFileShare, clearHeader);
        }
    }

    handleLockClick = async (datasetImage: DatasetImage, lockAction: LockAction) => {
        const download: DownloadTask = this.props.downloads[datasetImage.downloadKey];
        if (lockAction === LockAction.Unlock && download && !download.ready && !download.failed) { return; };
        if (lockAction === LockAction.Unlock) { this.handleUnloadClick(datasetImage); }

        const currentDataset = this.getCurrentDataset();
        if (currentDataset !== undefined) {
            this.props.lockDatasetImage(datasetImage, currentDataset, lockAction);
        }
    }

    handleLoadClick = async (datasetImage: DatasetImage) => {
        const dataset = this.getCurrentDataset();
        if (dataset !== undefined) {
            this.props.downloadDatasetImage(dataset, datasetImage);
        }
    }

    handleUnloadClick = (datasetImage: DatasetImage) => {
        this.props.unloadImageAndStructureSets(datasetImage);
    }

    handleViewImageClick = (datasetImage: DatasetImage) => {
        const dataset = this.getCurrentDataset();
        if (dataset !== undefined) {
            const canEdit = getUserCanEdit(this.props.user, this.props.datasetLocks[datasetImage.datasetId], datasetImage.seriesId);
            this.props.viewImage(datasetImage, dataset, canEdit);
        }
    }

    /** Get names of storage accounts that are available for current user */
    getStorageAccountNames = (): string[] => {
        if (!this.state.storageAccountNames || !this.props.userStorageAccess) { return []; }
        return this.state.storageAccountNames.filter(saName => this.props.userStorageAccess![saName] !== undefined);
    }

    /** Get file shares that are available for current user in currently selected storage account */
    getFileShares = (): AzureShareInfo[] | null => {
        const { currentStorageAccount, fileShares } = this.state;

        if (!currentStorageAccount || !this.props.userStorageAccess || !this.state.fileShares[currentStorageAccount]) { return null; }

        const currentStorageAccess = this.props.userStorageAccess[currentStorageAccount];
        if (!currentStorageAccess) {
            return null;
        }

        // return file shares if user's storage account access for current storage account either allows all file shares ('null')
        // or then filter by specific file shares
        return fileShares[currentStorageAccount]!
            .filter(fs => currentStorageAccess.fileShares === null
                || currentStorageAccess.fileShares.find(cfs => cfs.name === fs.fileShareName) !== undefined);
    }

    render = () => {
        const storageAccountName = this.state.currentStorageAccount;
        const storageAccountDropdownTitle = storageAccountName || "Select storage account";
        const fileShares = this.getFileShares();
        const currentFileShare = this.state.currentFileShare;
        const currentDataset = this.getCurrentDataset();
        const isDatasetLoaded = currentDataset !== undefined;
        const roiNamesOriginal = isDatasetLoaded ? Array.from(currentDataset!.originalRois).sort().join(", ") : null;
        const roiNamesStandard = isDatasetLoaded ? Array.from(currentDataset!.standardRois).sort().join(", ") : null;

        const mainHeader = currentFileShare ? currentFileShare.fileShareName : '';
        const isFilterOn = this.isFilterOn();
        const showChars = 15;
        let ft = this.state.filterText || (isFilterOn ? "<filter>" : "");
        ft = (ft.length > showChars) ? ft.substring(0, showChars) + "..." : ft;
        const operatorTitles = {
            [FilterOperator.And]: "AND",
            [FilterOperator.Or]: "OR"
        }
        //console.log(currentDataset)

        return (
            <Row className="dataset-browser">

                <FilterDialog
                    isVisible={this.state.showFilterModal}
                    onHide={() => this.handleShowFilterModal(false)}
                    filterText={this.state.filterText || ''}
                    isFilterCaseSensitive={this.state.filterCaseSensitive}
                    isMatchWholeWordOn={this.state.filterMatchWholeWord}
                    isFilterUseOriginalOn={this.state.filterUseOriginal}
                    filteredGradingStateOptions={this.state.filterGradingStateOptions}
                    onFilterTextChange={this.handleFilterTextChange}
                    onFilterCaseSensitivityChange={this.handleFilterCaseSensitiveChange}
                    onFilterMatchWholeWordChange={this.handleFilterMatchWholeWordChange}
                    onFilterUseOriginalChange={this.handleFilterUseOriginalChange}
                    onGradingStateOptionsChange={this.handleFilterGradingStatesChange}
                    roiNamesOriginal={roiNamesOriginal}
                    roiNamesStandard={roiNamesStandard}
                    filteredByGrades={this.state.filteredByGrades}
                    onfilteredByGradesChange={this.handleFilterByGradesChanged}
                    filteredByGradesOptions={this.state.filteredByGradesOptions}
                />

                <Col sm="2">
                    <Row>
                        <div className="dataset-browser-version">{"Version: " + RTViewerDisplayVersion}</div></Row>
                    <Row>
                        {this.getStorageAccountNames().length > 0 ?
                            <DropdownButton className="storage-dropdown" id="storage-account-select" title={storageAccountDropdownTitle} size="sm" variant="light">
                                {
                                    this.getStorageAccountNames().map((saName) =>
                                        <Dropdown.Item
                                            as="button"
                                            key={saName}
                                            onClick={() => this.handleSelectStorage(saName)}
                                            active={this.state.currentStorageAccount === saName}>{saName}</Dropdown.Item>
                                    )}
                            </DropdownButton> : <div>Loading available storage accounts...</div>}
                    </Row>
                    <Row>
                        {!!fileShares &&
                            (<div className="file-share-select">
                                {fileShares.map((item, key) =>
                                    <div className={currentFileShare === item ? "file-share-select-item file-share-select-item-highlight" : "file-share-select-item"} key={key}
                                        onClick={() => this.handleSelectFileShare(item, true)}>{item.fileShareName}</div>
                                )}
                            </div>)
                        }
                    </Row>
                </Col>
                <Col sm="10">
                    <div className="dataset-div">
                        <h4>{mainHeader}</h4>
                        <h5>{this.state.patientCountHeader}</h5>

                        {isDatasetLoaded && (
                            <div className="filter-buttons">
                                {isFilterOn ?
                                    (<div>
                                        <div className="filter-text-preview">{}</div>
                                        <ButtonGroup className="mr-2">
                                            <DropdownButton className="filter-operation-dropdown" id="filter-operation-dropdown" title={operatorTitles[this.state.filterOperator]} size="sm" variant="light">
                                                <Dropdown.Item as="button" key={"OR"} onClick={() => this.setState({ filterOperator: FilterOperator.Or }, () => this.handleRefreshClick())}>{operatorTitles[FilterOperator.Or]}</Dropdown.Item>
                                                <Dropdown.Item as="button" key={"AND"} onClick={() => this.setState({ filterOperator: FilterOperator.And }, () => this.handleRefreshClick())}>{operatorTitles[FilterOperator.And]}</Dropdown.Item>
                                            </DropdownButton>
                                            <Button variant={"light"} size="sm" onClick={this.handleEditFilterClick}>{ft}</Button>
                                            <Button variant={"light"} size="sm" onClick={this.handleDeleteFilterClick}>Reset filter</Button>
                                            <Button variant={"light"} size="sm" title="Refresh dataset table" onClick={() => this.handleRefreshClick()}>Refresh</Button>
                                            <CSVDownloader
                                                filename={'scans'}
                                                bom={true}
                                                data={this.state.scansAsCsvData}
                                            >
                                                <Button variant={"light"} size="sm" title="Download CSV">Download CSV</Button>
                                            </CSVDownloader>
                                        </ButtonGroup>
                                    </div>) :
                                    (<div>
                                        <ButtonGroup className="mr-2">
                                            <Button variant={"light"} size="sm" onClick={this.handleCreateFilterClick}>Apply filter...</Button>
                                            <Button variant={"light"} size="sm" title="Refresh dataset table" onClick={() => this.handleRefreshClick()}>Refresh</Button>
                                            <CSVDownloader
                                                filename={'scans'}
                                                bom={true}
                                                data={this.state.scansAsCsvData}
                                            >
                                                <Button variant={"light"} size="sm" title="Download CSV">Download CSV</Button>
                                            </CSVDownloader>
                                        </ButtonGroup>
                                    </div>)}
                            </div>
                        )}

                        <DatasetPage
                            storageAccountName={this.state.currentStorageAccount}
                            fileShare={this.state.currentFileShare}
                            dataset={currentDataset}
                            error={this.state.error}
                            setPageError={this.setPageError}
                            setPatientCountHeader={this.setPatientCountHeader}
                            getCurrentFilter={this.getCurrentFilter}
                            filterText={this.state.filterText}
                            initialWorkState={this.state.initialWorkState}
                            onLockClick={this.handleLockClick}
                            onLoadClick={this.handleLoadClick}
                            onUnloadClick={this.handleUnloadClick}
                            onViewImageClick={this.handleViewImageClick}
                            setScansAsCsvData={this.setScansAsCsvData}
                        />

                    </div>
                </Col>
            </Row>
        );
    }
}


export default connect(
    state => Object.assign({}, state),
    sagas.mapDispatchToProps
)(DatasetBrowser);


