// TODO: These shaders should not be needed in the future!

export const MARGIN_SMOOTHING_VS = `#version 300 es
#define POSITION_LOCATION 0
#define TEXCOORD_LOCATION 1

precision highp float;
precision highp int;

layout(location = POSITION_LOCATION) in vec2 position;
layout(location = TEXCOORD_LOCATION) in vec2 in_texcoord;

out vec2 v_texcoord;

void main()
{
    v_texcoord = vec2(in_texcoord.x, 1.0 - in_texcoord.y);
    gl_Position = vec4(position, 0.0, 1.0);
}
`;

export const MARGIN_SMOOTHING_FS = `#version 300 es
precision mediump float;
precision mediump int;

precision mediump sampler2D;
uniform sampler2D textureData;
uniform vec2 textureSize;


in vec2 v_texcoord;

out vec4 color;


void main()
{

    vec2 pixel = v_texcoord * textureSize;

    // Top row
    vec4 p1 = texture(textureData, (pixel + vec2(-1.0, -1.0)) / textureSize );
    vec4 p2 = texture(textureData, (pixel + vec2(0.0, -1.0)) / textureSize );
    vec4 p3 = texture(textureData, (pixel + vec2(1.0, -1.0)) / textureSize );
    
    // Middle row
    vec4 p4 = texture(textureData, (pixel + vec2(-1.0, 0.0)) / textureSize );
    vec4 p5 = texture(textureData, (pixel + vec2(0.0, 0.0)) / textureSize );
    vec4 p6 = texture(textureData, (pixel + vec2(1.0, 0.0)) / textureSize );

    // Bottom row
    vec4 p7 = texture(textureData, (pixel + vec2(-1.0, 1.0)) / textureSize );
    vec4 p8 = texture(textureData, (pixel + vec2(0.0, 1.0)) / textureSize );
    vec4 p9 = texture(textureData, (pixel + vec2(1.0, 1.0)) / textureSize );

    float inVal = 0.0;
    float inCount = 0.0;
    if(p1.r <= 0.5) {
        inCount += 1.0;
        inVal = p1.r;
    }
    if(p2.r <= 0.5) {
        inCount += 1.0;
        inVal = p2.r;
    }
    if(p3.r <= 0.5) {
        inCount += 1.0;
        inVal = p3.r;
    }
    if(p4.r <= 0.5) {
        inCount += 1.0;
        inVal = p4.r;
    }
    if(p6.r <= 0.5) {
        inCount += 1.0;
        inVal = p6.r;
    }
    if(p7.r <= 0.5) {
        inCount += 1.0;
        inVal = p7.r;
    }
    if(p8.r <= 0.5) {
        inCount += 1.0;
        //inVal = p8.r;
    }
    if(p9.r <= 0.5) {
        inCount += 1.0;
        //inVal = p9.r;
    }

    if(p5.r <= 0.5 && inCount < 3.0) {
        p5.r = 1.0;
    }
    else if(p5.r > 0.5 && inCount > 5.0) {
        p5.r = inVal;
    }

    color = p5;
    
}
`;