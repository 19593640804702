export class Vector2f {

    static XAxis = new Vector2f(1.0, 0.0);
    static YAxis = new Vector2f(0.0, 1.0);

    public x!: number;
    public y!: number;

    constructor(x?: number | Vector2f | Array<number>, y?: number){
        if(x !== undefined){
            this.set(x, y);
        } else {
            this.x = this.y = 0.0;
        }
    }

    set(x: number | Vector2f | Array<number> | {x: number, y: number}, y?: number){
        if(typeof x === 'number'){
            if(y !== undefined){
                this.x = x;
                this.y = y;
            }
        } else if(x instanceof Array){
            if(x.length >= 2){
                this.x = x[0];
                this.y = x[1];
            }
        } else {
            this.x = x.x;
            this.y = x.y;
        }
        return this;
    }

    add(ref: Vector2f): Vector2f {
        this.x += ref.x;
        this.y += ref.y;
        return this;
    }

    subtract(ref: Vector2f): Vector2f {
        this.x -= ref.x;
        this.y -= ref.y;
        return this;
    }

    scale(scale: number): Vector2f {
        this.x *= scale;
        this.y *= scale;
        return this;
    }

    getLength(): number {
        return Math.sqrt(this.x * this.x + this.y * this.y);
    }

    normalize(): Vector2f {
        const length = this.getLength();
        return this.scale(1/length);
    }

    invert(): Vector2f {
        this.x = -this.x;
        this.y = -this.y;
        return this;
    }

    equals(other: Vector2f): boolean {
        return this.x === other.x && this.y === other.y;
    }

    static dotProduct(a: Vector2f, b: Vector2f): number {
        return a.x * b.x + a.y * b.y;
    }
}