export const SEEDING_VS = `#version 300 es
#define POSITION_LOCATION 0
#define TEXCOORD_LOCATION 1

precision highp float;
precision highp int;

layout(location = POSITION_LOCATION) in vec2 position;
layout(location = TEXCOORD_LOCATION) in vec2 in_texcoord;

out vec3 v_texcoord;
uniform mat4 orientation;

void main()
{
    v_texcoord = (orientation * vec4(in_texcoord, 0.0, 1.0)).stp;
    gl_Position = vec4(position, 0.0, 1.0);
}
`;

export const SEEDING_FS = `#version 300 es
precision mediump float;
precision mediump int;

precision mediump sampler3D;
uniform sampler3D textureData;
uniform vec3 textureSize;

in vec3 v_texcoord;

out vec4 color;

void main()
{

    vec3 pixel = v_texcoord * textureSize;

    // Top row
    float p1 = texture(textureData, (pixel + vec3(-1.0, -1.0, 0.0)) / textureSize ).r;
    float p2 = texture(textureData, (pixel + vec3(0.0, -1.0, 0.0)) / textureSize ).r;
    float p3 = texture(textureData, (pixel + vec3(1.0, -1.0, 0.0)) / textureSize ).r;
    
    // Middle row
    float p4 = texture(textureData, (pixel + vec3(-1.0, 0.0, 0.0)) / textureSize ).r;
    float p5 = texture(textureData, (pixel + vec3(0.0, 0.0, 0.0)) / textureSize ).r;
    float p6 = texture(textureData, (pixel + vec3(1.0, 0.0, 0.0)) / textureSize ).r;

    // Bottom row
    float p7 = texture(textureData, (pixel + vec3(-1.0, 1.0, 0.0)) / textureSize ).r;
    float p8 = texture(textureData, (pixel + vec3(0.0, 1.0, 0.0)) / textureSize ).r;
    float p9 = texture(textureData, (pixel + vec3(1.0, 1.0, 0.0)) / textureSize ).r;

    bool inside = p5 <= 0.5;
    if( (p1 <= 0.5) != inside 
    || (p2 <= 0.5) != inside 
    || (p3 <= 0.5) != inside 
    || (p4 <= 0.5) != inside 
    || (p6 <= 0.5) != inside 
    || (p7 <= 0.5) != inside 
    || (p8 <= 0.5) != inside 
    || (p9 <= 0.5) != inside ) {

        // Pixel is on the edge
        float z = abs(0.5 - p5) * 2.0; // Distance is scaled to [0, maxDistance], the sign is lost.
        color = vec4(0.0, 0.0, z, 1.0);
    }
    else {
        // Not on the edge
        color = vec4(1.0, 1.0, 1.0, 1.0);
    }
}
`;