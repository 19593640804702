import Immerable from "../../../store/immerable";

export enum NotificationType {
    Information, Success, Failure, Warning, Error
}

export class SessionNotification extends Immerable {
    id: string;
    message: string;
    type: NotificationType;
    detailedMessage?: string;

    constructor(id: string | undefined, message: string, type: NotificationType, detailedMessage?: string) {
        super();

        // if id was undefined, generate it
        // (this is random enough for our needs for now, considering using uuids in the future)
        this.id = id || `mv-notif-${new Date().getTime() + Math.random()}`;

        this.message = message;
        this.type = type;
        this.detailedMessage = detailedMessage;
    }
}

export function notificationTypeToBootstrapVariant(type: NotificationType) {
    switch (type) {
        case NotificationType.Success:
            return 'success';
        case NotificationType.Failure:
            return 'danger';
        case NotificationType.Warning:
            return 'warning';
        case NotificationType.Error:
            return 'danger';
        case NotificationType.Information:
        default:
            return 'secondary';
    }
}
