import * as twgl from 'twgl.js';
import { ViewManager } from "../../../view-manager";
import * as interpolationShaders from './interpolation-shaders';
import { Roi, StructureSet } from "../../../../dicom/structure-set";
import { SdfOperations } from "../boolean/sdf-operations";
import { createVertexBuffers } from "../../create-vertex-buffers";
import * as m4 from '../../../../math/m4';
import { mouseTools } from '../../../mouse-tools/mouse-tools';
import { Propagation } from '../propagation/sdf-propagation';

export class Interpolation {

    private viewManager: ViewManager;
    private interpolationProgram: WebGLProgram;
    private interpolationUniformLoc: any;

    constructor(viewManager: ViewManager) {
        this.viewManager = viewManager;
        let gl = viewManager.getWebGlContext();
        this.interpolationProgram = twgl.createProgramInfo((gl as any), [interpolationShaders.INTERPOLATION_VS, interpolationShaders.INTERPOLATION_FS]).program;
        this.interpolationUniformLoc = {
            textureMatrixTop: gl.getUniformLocation(this.interpolationProgram, 'orientationTop'),
            textureMatrixBottom: gl.getUniformLocation(this.interpolationProgram, 'orientationBottom'),
            textureData: gl.getUniformLocation(this.interpolationProgram, 'textureData'),
            interpolationWeight: gl.getUniformLocation(this.interpolationProgram, 'interpolationWeight')
        }
    }

    public interpolate(ss: StructureSet, roi: Roi ) {
    
        const vm = this.viewManager;
        const vs = vm.viewerState;
        const img = vm.image;
        const imgBb = img.getRealBoundingBox();
        if(!roi.sdf) return;

        const pg = new Propagation(this.viewManager);
        if(roi.sdf.propagationPending) {
            pg.propagate(roi);
        }

        vs.undoStack.pushRoiStateBeforeEdit(roi);

        const sourceSdf = roi.sdf;
        const drawnSliceIndices = sourceSdf.findSlicesWithContours();
        if(drawnSliceIndices.length === 0) return;

        const destSdf = new SdfOperations(vm).copy(roi.sdf, roi.sdf.boundingBox, false);
        const gl = vm.getWebGlContext();
        gl.useProgram(this.interpolationProgram);
        const interpolatedSliceIndices: number[] = [];

        for(let sdfZ = 0; sdfZ < sourceSdf.size[2]; ++sdfZ) {

            const getImgZ = (sdf_Z: number) => {return sdf_Z + Math.round( (sourceSdf.boundingBox.minK - imgBb.minK) / img.kSpacing )}
            const imgZ = getImgZ(sdfZ);

            if(drawnSliceIndices.includes(imgZ)) continue; // Check if slice already has contours
            
            let bottomZ = -1;
            let topZ = -1;

            // Find bottom slice and top slice to interpolate between
            for(let i = 0; i < sourceSdf.size[2]; ++i) {
                const contourFound = drawnSliceIndices.includes( getImgZ(i) );
                if(i < sdfZ && contourFound) {
                    bottomZ = i;
                }
                else if(i > sdfZ && contourFound)
                {
                    topZ = i;
                    break;
                }
            }
            if(topZ === -1 || bottomZ === -1) continue; // Slice cannot be interpolated
            interpolatedSliceIndices.push(imgZ);

            const fb = gl.createFramebuffer();
            gl.bindFramebuffer(gl.FRAMEBUFFER, fb);
            gl.framebufferTextureLayer(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, destSdf.data, 0, sdfZ);
            
            gl.uniform1i(this.interpolationUniformLoc.textureData, 0);
            gl.activeTexture(gl.TEXTURE0);
            gl.bindTexture(gl.TEXTURE_3D, sourceSdf.data);
    
            let left = Math.round( (sourceSdf.boundingBox.minI - destSdf.boundingBox.minI) / destSdf.resolutionMm );
            let top = Math.round( (sourceSdf.boundingBox.minJ - destSdf.boundingBox.minJ) / destSdf.resolutionMm );
            let width = Math.round( sourceSdf.size[0] * sourceSdf.resolutionMm / destSdf.resolutionMm );
            let height = Math.round( sourceSdf.size[1] * sourceSdf.resolutionMm / destSdf.resolutionMm );
            gl.viewport(left, top, width, height);
            
            let imageVertexPositions = [
                -1, -1,
                1, -1,
                1, 1,
                1, 1,
                -1, 1,
                -1, -1
            ];
            let imageTextureCoords = [
                0.0, 1.0,
                1.0, 1.0,
                1.0, 0.0,
                1.0, 0.0,
                0.0, 0.0,
                0.0, 1.0
            ];
            gl.bindVertexArray(createVertexBuffers(gl, imageVertexPositions, imageTextureCoords));
    
            let step = 1 / (sourceSdf.size[2]);

            const scrollBottom = (0.5 + bottomZ) * step;
            let mBottom = m4.translation(0, 1, scrollBottom );
            mBottom = m4.xRotate(mBottom, Math.PI);
            gl.uniformMatrix4fv(this.interpolationUniformLoc.textureMatrixBottom, false, mBottom);

            const scrollTop = (0.5 + topZ) * step;
            let mTop = m4.translation(0, 1, scrollTop );
            mTop = m4.xRotate(mTop, Math.PI);
            gl.uniformMatrix4fv(this.interpolationUniformLoc.textureMatrixTop, false, mTop);

            const distance = topZ - bottomZ;
            const weight =  ( (topZ - sdfZ) / distance );
            gl.uniform1f(this.interpolationUniformLoc.interpolationWeight, weight);

            gl.drawArraysInstanced(gl.TRIANGLES, 0, 6, 1);
            gl.bindFramebuffer(gl.FRAMEBUFFER, null);
            gl.deleteFramebuffer(fb);
            gl.disable(gl.BLEND);
        }
        roi.sdf = destSdf;
        if(interpolatedSliceIndices.length) 
        {
            ss.unsaved = true;
            roi.setContoursChanged(interpolatedSliceIndices.map(i => img.sliceIds[i]));
        }
        pg.propagate(roi);
        if(mouseTools.brush.brushBuffer) {
            mouseTools.brush.createDrawBuffer();
        }
    }
}